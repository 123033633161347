import { IRouterOption } from 'src/router'
import { Connections } from './components/connections'
import { Dashboard } from './components/dashboard'
import { Messages } from './components/messages'
import { TalentDetail } from './components/talent-detail'
import { VibeTalent } from './components/vibe-talent'
import { ETalentRoutes } from './routes.enum'

export const TalentRoutes: IRouterOption[] = [
  {
    path: '/talent/dashboard',
    component: Dashboard,
    name: ETalentRoutes.TALENT_DASHBOARD,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/talent/messages',
    component: Messages,
    name: ETalentRoutes.TALENT_MESSAGES,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/talent/vibe-talent',
    component: VibeTalent,
    name: ETalentRoutes.TALENT_VIBE_TALENT,
    exact: true
  },
  {
    path: '/talent/connections',
    component: Connections,
    name: ETalentRoutes.TALENT_CONNECTIONS,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/talent/:lookupId',
    component: TalentDetail,
    name: ETalentRoutes.TALENT_DETAIL,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
