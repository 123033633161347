import { FC, useEffect } from 'react'
import { browserHistory, ERoutes, generate } from 'src/router'

export const HomePage: FC = () => {
  useEffect(() => {
    browserHistory.push(generate(ERoutes.TALENT_VIBE_TALENT))
  }, [])

  return null
}
