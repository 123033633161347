import clsx from 'clsx'
import { ComponentProps, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ProfileApi } from 'src/api'
import { TourApi } from 'src/api/tour'
import { Button, VideoPlayer, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useBehaviorMapper, useIfMobileL, useImageFromFile } from 'src/hooks'
import { IconCameraPlus, IconPlayVideoInverse, IconUpload, IconVideo } from 'src/icons'
import { useUploadPercentage } from 'src/modules/campaign/hooks/useUploadPercentage'
import { useUploadStatus } from 'src/modules/campaign/hooks/useUploadStatus'
import { SamplePlayers } from 'src/modules/recording/components/sample-player'
import { MobileSamplePlayers } from 'src/modules/recording/components/sample-player/mobile'
import { TourPopper } from 'src/partials/tour'
import { CampaignMutationService, OverlayService, RecordingService } from 'src/services'
import { CreateJobTourService, TARGET_SHOW_HUMAN_SIDE } from 'src/services/tour/create-job-tour.service'
import { FirstTimeClaimedJobTourService, TARGET_CLAIMED_JOB_VVC } from 'src/services/tour/first-time-claimed-job.service'
import { UploadService } from 'src/services/upload.service'
import { AuthModule } from 'src/store'
import { allowedAudioTypes, getVideoSource } from 'src/utils'
import { VideoUploadProgress } from '../video-upload-progress'
import Style from './style.module.scss'

interface IProps {
  uploadVideoFile?: File
  uploadVideoUrl?: string
  uploadVideoDuration?: number
  onRecordVideo?: () => void
  onChangeUploadVideo?: (file: File) => void
  onUsePfv?: () => void
  onRemoveVideo?: string
  disableUpdateVideo?: boolean
  videoBackground?: ComponentProps<typeof VideoPlayer>['image']
}

export const IntroduceYourselfVideo = forwardRef<HTMLDivElement, IProps>(
  (
    {
      uploadVideoFile,
      uploadVideoUrl,
      onRecordVideo,
      onUsePfv,
      uploadVideoDuration,
      videoBackground,
      onChangeUploadVideo,
      disableUpdateVideo
    },
    ref
  ) => {
    const { eventHandler } = useAnalytic('')
    const recordResultRef = useRef<HTMLInputElement>(null)
    const profile = useBehaviorMapper(AuthModule.profile$)
    const mapThumbnails = useBehaviorMapper(RecordingService.mapThumbnails$)

    const createJobTourEnabled = useBehaviorMapper(CreateJobTourService.enableTour$)
    const createJobTourCurrentStep = useBehaviorMapper(CreateJobTourService.currentStep$)
    const claimedTourEnabled = useBehaviorMapper(FirstTimeClaimedJobTourService.enableTour$)
    const claimedTourCurrentStep = useBehaviorMapper(FirstTimeClaimedJobTourService.currentStep$)

    const uploadStatus = useUploadStatus()
    const percentage = useUploadPercentage()

    const _recordingService = useMemo(() => new RecordingService(), [])
    const isMobile = useIfMobileL()
    const [openSample, setOpenSample] = useState(false)

    const imageSrcFromFile = useImageFromFile(videoBackground)
    const imageSrcFromThumbnailCache = mapThumbnails.get(uploadVideoFile?.name)

    useEffect(() => {
      if (uploadVideoUrl || uploadVideoFile) {
        return
      }

      if (profile?.pfv) {
        onUsePfv?.()
      }
    }, [onUsePfv, profile, uploadVideoFile, uploadVideoUrl])

    const submitVideo = async (record: File) => {
      if (onChangeUploadVideo) {
        onChangeUploadVideo(record)
      }

      FirstTimeClaimedJobTourService.stopTour()
      CampaignMutationService.patchData({
        uploadVideo: undefined,
        uploadVideoFile: record,
        uploadVideoUrl: undefined,
        uploadAudioBackground: _recordingService.placeholder,
        uploadVideoRefId: UploadService.genUploadItemId(),
        _ignoreDraft: true
      })

      TourApi.finish({ firstTimeClaimedJob: true })
        .then(() => ProfileApi.getProfile())
        .then(({ data }) => AuthModule.authenticated(data))
    }

    const videoSrc = useMemo(() => uploadVideoUrl || uploadVideoFile || getVideoSource(profile?.pfv), [profile?.pfv, uploadVideoFile, uploadVideoUrl])

    const handleOpenSample = useCallback(() => {
      if (isMobile) {
        return setOpenSample(true)
      }

      OverlayService.setOverlay({
        open: true,
        content: <SamplePlayers/>,
        onClose: () => setOpenSample(false)
      })
    }, [isMobile])

    return (
      <>
        <div className={Style.container} ref={ref} id={TARGET_CLAIMED_JOB_VVC.getValue()}>
          <div className={Style.showHumanSideTarget} id={TARGET_SHOW_HUMAN_SIDE.getValue()}/>
          {createJobTourEnabled && createJobTourCurrentStep === 1 && (<TourPopper {...CreateJobTourService.getStepConfig()}/>)}

          <VideoUploadProgress percentage={percentage} uploadStatus={uploadStatus}/>

          <div className="d-none">
            <input
              type="file"
              ref={recordResultRef}
              accept={['video/*', ...allowedAudioTypes].join(', ')}
              onChange={(e) => {
                if (!e.target.files?.[0]) return
                submitVideo(e.target.files?.[0])
                e.target.value = ''
              }}
            />
          </div>

          {!videoSrc
            ? (
              <div className={Style.emptyContainer}>
                <div className="fx gap-1 flex-column fx-ai-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 20H11C11 21.1 10.1 22 9 22C7.9 22 7 21.1 7 20ZM5 18C5 18.55 5.45 19 6 19H12C12.55 19 13 18.55 13 18C13 17.45 12.55 17 12 17H6C5.45 17 5 17.45 5 18ZM16.5 9.5C16.5 13.32 13.84 15.36 12.73 16H5.27C4.16 15.36 1.5 13.32 1.5 9.5C1.5 5.36 4.86 2 9 2C13.14 2 16.5 5.36 16.5 9.5ZM21.37 7.37L20 8L21.37 8.63L22 10L22.63 8.63L24 8L22.63 7.37L22 6L21.37 7.37ZM19 6L19.94 3.94L22 3L19.94 2.06L19 0L18.06 2.06L16 3L18.06 3.94L19 6Z" fill="#181920"/>
                  </svg>
                  <span className={clsx(Style.title_required, 'body2-bold color-neutral-theme-900 text-center')}>
                    Introduce Yourself With A Video Vibe Check
                  </span>
                  <Button
                    variant="secondary"
                    onClick={handleOpenSample}
                    className="mt-3"
                  >
                    <IconPlayVideoInverse width={12} height={13}/>
                    View Samples
                  </Button>
                </div>

                <div className={Style.recordZone}>
                  <IconVideo width={60} height={60}/>
                  <div className="fx flex-column fx-ai-center gap-2 w-100">
                    <Button
                      className="w-100-p"
                      onClick={onRecordVideo}
                    >
                      <IconCameraPlus width={18} height={18}/>
                      Record New Video
                    </Button>
                    <Button
                      className="w-100-p"
                      variant="secondary"
                      onClick={eventHandler(ETrackingEvent.RECORDING_BTN_REC_UPLOAD, () => recordResultRef.current?.click?.())}
                    >
                      <IconUpload width={17} height={17}/>
                      Upload
                    </Button>
                  </div>
                </div>
              </div>
            )
            : (
              <div className={Style.videoContainer}>
                <VideoPlayer
                  forceRender={!imageSrcFromThumbnailCache?.url && !videoBackground}
                  className={Style.video}
                  url={videoSrc}
                  duration={uploadVideoDuration}
                  hideStartEnd
                  shouldFlipBg={imageSrcFromThumbnailCache?.flip}
                  image={imageSrcFromThumbnailCache?.url || imageSrcFromFile || undefined}
                  showProcessing
                />
                {!disableUpdateVideo && (
                  <div className={Style.videoButtons}>
                    <Button
                      className="w-100-p"
                      onClick={onRecordVideo}
                    >
                      <IconCameraPlus width={18} height={18}/>
                      Record New Video
                    </Button>
                    <Button
                      className="w-100-p"
                      variant="secondary"
                      onClick={eventHandler(ETrackingEvent.RECORDING_BTN_REC_UPLOAD, () => recordResultRef.current?.click?.())}
                    >
                      <IconUpload width={17} height={17}/>
                      Upload
                    </Button>
                  </div>
                )}
              </div>
            )}

          {openSample && isMobile && (
            <div className={Style.mobileSampleContainer}>
              <MobileSamplePlayers
                onClose={() => setOpenSample(false)}
              />
            </div>
          )}
        </div>
        {claimedTourEnabled && claimedTourCurrentStep === 1 && (
          <TourPopper {...FirstTimeClaimedJobTourService.getStepConfig()}/>
        )}
      </>
    )
  }
)
