import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChat: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9356 10.7516H7.56211L7.48561 10.7569C7.34442 10.7763 7.21587 10.8486 7.12593 10.9591C7.03599 11.0697 6.99137 11.2103 7.00109 11.3524C7.0108 11.4946 7.07412 11.6278 7.17826 11.7251C7.2824 11.8224 7.41959 11.8766 7.56211 11.8766H10.9356L11.0121 11.8714C11.1533 11.8519 11.2818 11.7796 11.3718 11.6691C11.4617 11.5585 11.5063 11.4179 11.4966 11.2758C11.4869 11.1336 11.4236 11.0004 11.3194 10.9031C11.2153 10.8058 11.0781 10.7516 10.9356 10.7516Z" fill={color}/>
        <path d="M12.4409 8.1266H7.56211L7.48561 8.13185C7.34442 8.15128 7.21587 8.22357 7.12593 8.33413C7.03599 8.44468 6.99137 8.58525 7.00109 8.72744C7.0108 8.86963 7.07412 9.00282 7.17826 9.10012C7.2824 9.19742 7.41959 9.25156 7.56211 9.2516H12.4409L12.5166 9.24635C12.6577 9.22693 12.7862 9.1547 12.8762 9.04422C12.9661 8.93375 13.0108 8.79326 13.0012 8.65113C12.9915 8.50901 12.9284 8.37582 12.8244 8.27846C12.7204 8.18111 12.5833 8.12683 12.4409 8.1266Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.34961 10.0005C2.34961 5.77589 5.77462 2.35156 9.99961 2.35156C14.2246 2.35156 17.6496 5.77589 17.6496 10.0005C17.6496 14.2249 14.2249 17.6491 10.0001 17.6493C8.84758 17.6511 7.71077 17.3916 6.67481 16.8915L3.90887 17.6116C3.69903 17.6659 3.47785 17.6648 3.26869 17.6078C3.05954 17.5508 2.86888 17.4402 2.71558 17.287C2.56228 17.1337 2.45166 16.943 2.39467 16.7339C2.33769 16.5247 2.33631 16.3043 2.39069 16.0944L3.11024 13.3288C2.61238 12.3012 2.34961 11.1685 2.34961 10.0005ZM9.99961 3.65156C6.4924 3.65156 3.64961 6.49405 3.64961 10.0005C3.64961 11.0448 3.90181 12.0516 4.37618 12.952L4.49359 13.1748L3.67275 16.3297L6.8276 15.5084L7.05016 15.6252C7.95954 16.1025 8.97152 16.351 9.99856 16.3493L9.99961 16.3493C13.5068 16.3493 16.3496 13.5069 16.3496 10.0005C16.3496 6.49405 13.5068 3.65156 9.99961 3.65156Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
