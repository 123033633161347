import qs from 'qs'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ReactionApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { Modal, Spinner } from 'src/components'
import { EReaction } from 'src/enums'
import { useBehaviorMapper, useDebouncedLoading } from 'src/hooks'
import { IReactionModel } from 'src/interfaces'
import { ReactionTalentCard } from 'src/partials/card/reaction-talent-card'
import { ModalTalentDetail } from 'src/partials/modal-talent-detail'
import { LoadMoreService } from 'src/services'

interface IProps {
  keyword?: string
}

export const PeopleILiked: FC<IProps> = ({ keyword }) => {
  const _loadMoreService = useMemo(() => new LoadMoreService<IReactionModel>({
    axiosInstance: axiosHiringApi,
    endpoint: ReactionApi._prefix + '?' + qs.stringify({
      reaction: EReaction.LIKE,
      keyword
    })
  }), [keyword])

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useEffect(() => {
    if (inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, inView, loading])

  useEffect(() => {
    _loadMoreService.loadMore()
  }, [_loadMoreService])

  const debounceLoading = useDebouncedLoading(!items.length && loading)

  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const canPreviousProps = useMemo(() => !currentIndex, [currentIndex])
  const canNextProps = useMemo(() => currentIndex + 1 === items?.length, [currentIndex, items?.length])
  const user = useMemo(() => items?.[currentIndex]?.toUser, [currentIndex, items])

  const onDetail = (index: number) => {
    setCurrentIndex(index)
    setOpen(true)
  }

  const onPrevious = useCallback(() => setCurrentIndex(prev => prev - 1), [])

  const onNext = useCallback(() => {
    setCurrentIndex(prev => prev + 1)

    if (currentIndex === items.length - 2) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, currentIndex, items.length])

  return (
    <>
      {debounceLoading
        ? (
          <div className="fx-1 fx fx-center">
            <Spinner/>
          </div>
        )
        : (
          <div className="fx fx-wrap-wrap gap-4">
            {items?.map((item, index) => (
              <ReactionTalentCard
                key={item.id}
                reaction={item}
                isInvite
                onChange={(values) => item.id && _loadMoreService.updateOneItem(item.id, values)}
                onDetail={() => onDetail(index)}
              />
            ))}
            <div ref={refLoadMore}/>
          </div>
        )}

      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          closeBtn
          contentClass="fx-1 fx fx-center round-0-p"
        >
          <ModalTalentDetail
            lookupId={user?.lookupId}
            pfv={user?.pfv}
            onPrevious={onPrevious}
            onNext={onNext}
            canPreviousProps={canPreviousProps}
            canNextProps={canNextProps}
          />
        </Modal>
      )}
    </>
  )
}
