import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconKebab: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.39062 5.50078C9.39062 6.1601 9.93131 6.70078 10.5906 6.70078C11.2499 6.70078 11.7906 6.1601 11.7906 5.50078C11.7906 4.84147 11.2499 4.30078 10.5906 4.30078C9.93131 4.30078 9.39062 4.84147 9.39062 5.50078Z" fill={color}/>
        <path d="M11.7906 10.0008C11.7906 9.34147 11.2499 8.80078 10.5906 8.80078C9.93131 8.80078 9.39062 9.34147 9.39062 10.0008C9.39062 10.6601 9.93131 11.2008 10.5906 11.2008C11.2499 11.2008 11.7906 10.6601 11.7906 10.0008Z" fill={color}/>
        <path d="M11.7906 14.5008C11.7906 13.8415 11.2499 13.3008 10.5906 13.3008C9.93131 13.3008 9.39062 13.8415 9.39062 14.5008C9.39062 15.1601 9.93131 15.7008 10.5906 15.7008C11.2499 15.7008 11.7906 15.1601 11.7906 14.5008Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
