import { AxiosError } from 'axios'
import {
  ChangeEventHandler,
  ComponentProps,
  FC,
  useCallback,
  useState
} from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { PasswordApi } from 'src/api'
import { useUnsubscribe, useValidation } from 'src/hooks'
import { SnackbarService } from 'src/services'
import { EventUtils, getApiErrorMessage } from 'src/utils'
import { validCompanyEmail } from 'src/validation/email'
import { object, string } from 'yup'
import { ModalAuthentication } from '../modal-authentication'
import { Form } from './form'
import { Success } from './success'

const schema = object().shape({
  email: string().required('Enter email').test(
    'error_email_domain',
    'Enter your work email',
    (email) => email ? validCompanyEmail(email) : false
  )
})

interface IProps {
  onSwitch?: (stage: ComponentProps<typeof ModalAuthentication>['stage']) => void
}

export const ForgotPasswordForm: FC<IProps> = ({ onSwitch }) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [formValues, setFormValues] = useState({
    email: ''
  })
  const { errors, validate } = useValidation({
    data: formValues,
    schema
  })

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    e.persist()
    const { name, value } = e.target
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onSubmit = useCallback<Required<ComponentProps<'form'>>['onSubmit']>(async (e) => {
    EventUtils.preventDefault(e)
    const { isValid, value } = await validate()
    if (!isValid || !value) {
      return
    }

    setLoading(true)
    from(PasswordApi.forgotPasswordRequest(value))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => setIsSuccess(true))
  }, [unsubscribe$, validate])

  return isSuccess
    ? <Success email={formValues.email} onSubmit={onSubmit}/>
    : (
      <Form
        email={formValues.email}
        error={errors.getError('email')}
        disabled={loading}
        onChange={onChange}
        onSubmit={onSubmit}
        onGoToSignup={onSwitch ? () => onSwitch?.('sign-up') : undefined}
      />
    )
}
