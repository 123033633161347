import {
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo
} from 'react'
import { useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { setLayoutScrollToBottom } from 'src/store/actions'
import { getLayoutIsScrollToBottom } from 'src/store/selectors'
import { Header } from './header'
import { Sidebar } from './sidebar'
import Style from './style.module.scss'

export const LayoutContainer: FC<{ children?: ReactNode }> = (props) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)

  const onScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    const element = e.target as HTMLElement
    const scrollBottom =
      Math.floor(element.scrollHeight - element.scrollTop) <=
      element.clientHeight

    if (scrollBottom) {
      return dispatch(setLayoutScrollToBottom(true))
    }

    if (isScrollToBottom) {
      return dispatch(setLayoutScrollToBottom(false))
    }
  }, [dispatch, isScrollToBottom])

  const isPreviewCampaign = useMemo(
    () => location.pathname.startsWith('/campaigns/summary/preview'),
    [location.pathname]
  )

  return (
    <section className={Style.layoutContainer}>
      {!isPreviewCampaign && <Sidebar/>}

      {/* May need position: relative */}
      <section className={Style.layoutContent}>
        {!isPreviewCampaign && <Header/>}

        <div onScroll={onScroll} className={Style.pageContent}>
          {props.children}
        </div>
      </section>
    </section>
  )
}
