import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useClickOutside } from 'src/hooks'
import { IconSearch } from 'src/icons'
import { Button } from '../button'
import Style from './style.module.scss'

interface IProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  onShowSearchBarChanged?: (isShowSearchBar: boolean) => void
}

export const InputSearch: FC<IProps> = ({ handleChange, disabled, onShowSearchBarChanged }) => {
  const isMax1200 = useMediaQuery('screen and (max-width: 1200px)')
  const is600To840 = useMediaQuery('screen and (min-width: 600px) and (max-width: 840px)')

  const [isShowSearchBar, setIsShowSearchBar] = useState(false)

  useEffect(() => {
    onShowSearchBarChanged?.(isShowSearchBar)
  }, [isShowSearchBar, onShowSearchBarChanged])

  const handleOnClickShowSearchBar = useCallback(() => {
    setIsShowSearchBar(true)
  }, [])

  const handleCloseSearchBar = useCallback(() => {
    setIsShowSearchBar(false)
  }, [])

  const searchBarRef = useClickOutside<HTMLDivElement>(handleCloseSearchBar)

  const searchComponent = useMemo(() => {
    if (isMax1200 && !is600To840) {
      return (
        <>
          {!isShowSearchBar && (
            <Button
              type="button"
              variant="secondary"
              className={Style.ButtonIcon}
              onClick={handleOnClickShowSearchBar}
            >
              <IconSearch/>
            </Button>
          )}
          {isShowSearchBar && (
            <div
              ref={searchBarRef}
              className={Style.InputSearchBar}
            >
              <div className={Style.InputSearchContainer}>
                <input type="text" placeholder="Search" className={Style.Input} onChange={handleChange}/>
                <IconSearch/>
              </div>
            </div>
          )}
        </>
      )
    } else {
      return (
        <div
          className={Style.InputSearch}
        >
          <div className={Style.InputSearchContainer}>
            <input type="text" placeholder="Search" className={Style.Input} onChange={handleChange}/>
            <IconSearch/>
          </div>
        </div>
      )
    }
  }, [handleChange, handleOnClickShowSearchBar, isMax1200, isShowSearchBar, searchBarRef, is600To840])

  return <div className={clsx(Style.SearchWrapper, isShowSearchBar && Style.SearchWrapper_isShowSearchBar, disabled && Style.disabledWrapper)}>{searchComponent}</div>
}
