import { isEqual } from 'lodash'
import { BehaviorSubject, from, tap } from 'rxjs'
import { ConversationApi, MessageApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { IConversationModel, IMessageModel } from 'src/interfaces'
import { LoadMoreService } from 'src/services'
import { AuthModule } from 'src/store'
import { getAvatar, getFirstCompany, getFullName } from 'src/utils'

export const MessengerService = new (class {
  readonly _loadMoreConversations = new LoadMoreService<IConversationModel>({
    axiosInstance: axiosHiringApi,
    endpoint: ConversationApi._prefix
  })

  private readonly _conversations$ = this._loadMoreConversations.items$
  get conversations$(): Omit<typeof this._conversations$, 'next' | 'complete'> {
    return this._conversations$
  }

  private readonly _conversation$ =
    new BehaviorSubject<IConversationModel | null>(null)

  get conversation$(): Omit<typeof this._conversation$, 'next' | 'complete'> {
    return this._conversation$
  }

  private readonly _metadata$ = new BehaviorSubject<
    Awaited<ReturnType<typeof ConversationApi.pull>>['data']
  >({})

  private readonly _countUnRead$ = new BehaviorSubject<
    Awaited<ReturnType<typeof ConversationApi.countUnread>>['data']
  >({ countUnread: 0 })

  get countUnRead$(): Omit<typeof this._countUnRead$, 'next' | 'complete'> {
    return this._countUnRead$
  }

  private _pulling = false
  private _pausePulling = false
  private _pull() {
    if (!this._pulling) {
      return
    }

    if (this._pausePulling) {
      return setTimeout(() => {
        this._pull()
      }, 5000)
    }

    ConversationApi.pull()
      .then(({ data }) => {
        if (!isEqual(data, this._metadata$.value)) {
          this._metadata$.next(data)
        }
      })
      .catch(() => {}) // ignore errors
      .finally(() => {
        setTimeout(() => {
          this._pull()
        }, 5000)
      })
  }

  private _pullingCountUnread = false
  private _pullCountUnread() {
    if (!this._pullingCountUnread) {
      return
    }

    ConversationApi.countUnread()
      .then(({ data }) => {
        if (!isEqual(data, this._countUnRead$.value)) {
          this._countUnRead$.next(data)
        }
      })
      .catch(() => {}) // ignore errors
      .finally(() => {
        setTimeout(() => {
          this._pullCountUnread()
        }, 5000)
      })
  }

  constructor() {
    this._conversation$.subscribe((conversation) => {
      if (!conversation) {
        return
      }

      if (this.isUnread(conversation)) {
        this.read(conversation)
      }

      if (this._conversations$.value.some(({ id }) => id === conversation.id)) {
        return
      }
      this._loadMoreConversations.addFirst([conversation])
    })

    this._metadata$.subscribe((metadata) => {
      if (metadata.conversations) {
        const conversations = this._conversations$.value
        for (const [conversationId, lastMessageTime] of Object.entries(
          metadata.conversations
        )) {
          const existed = conversations.find(
            ({ id }) => id === +conversationId
          )
          if (
            !existed ||
            (existed.lastMessage?.updatedAt &&
              new Date(existed.lastMessage.updatedAt).getTime() <
                lastMessageTime)
          ) {
            this._pausePulling = true
            return ConversationApi.paginate()
              .then(({ data }) => {
                if (!data.rows?.length) {
                  return
                }
                this._loadMoreConversations.addFirst(data.rows)
                if (!this.conversation$.value) {
                  return
                }
                for (const conversation of data.rows) {
                  if (conversation.id === this.conversation$.value.id) {
                    this.setConversation(conversation)
                  }
                }
              })
              .finally(() => {
                this._pausePulling = false
              })
          }
        }
      }
    })
  }

  pull() {
    if (!this._pulling) {
      this._pulling = true
      setTimeout(() => {
        this._pull()
      }, 3000)
    }
  }

  stopPull() {
    this._pulling = false
  }

  pullCountUnRead() {
    if (!this._pullingCountUnread) {
      this._pullingCountUnread = true
      setTimeout(() => {
        this._pullCountUnread()
      }, 3000)
    }
  }

  stopPullCountUnRead() {
    this._pullingCountUnread = false
  }

  setConversation(conversation?: IConversationModel) {
    if (!conversation) {
      return this._conversation$.next(null)
    }
    // if (this._conversation$.value?.id === conversation.id) {
    //   // prevent re-render on messages
    //   return this._conversation$.next(Object.assign(this._conversation$.value, conversation))
    // }
    this._conversation$.next(conversation)
  }

  isUnread(conversation: IConversationModel) {
    const _userId = AuthModule.profile$.value?.id
    if (!_userId) {
      return
    }
    return conversation.conversationUsers?.some(({ userId, readMessageId }) => {
      return userId === _userId && readMessageId !== conversation.lastMessageId
    })
  }

  read(
    conversation: IConversationModel,
    message?: IMessageModel,
    moveToTop?: boolean
  ) {
    const _userId = AuthModule.profile$.value?.id
    if (!_userId) {
      return
    }
    conversation.updatedAt = new Date().toISOString()
    if (message) {
      conversation.updatedAt = message.updatedAt
      conversation.lastMessageId = message.id
      conversation.lastMessage = message
    }
    const conversationUser = conversation.conversationUsers?.find(
      ({ userId }) => userId === _userId
    )
    if (conversationUser) {
      conversationUser.readMessageId = conversation.lastMessageId
    }

    // force re-render
    if (moveToTop) {
      this._loadMoreConversations.addFirst(conversation)
    }
    this._loadMoreConversations.addFirst([])
  }

  getConversationUser(conversation: IConversationModel, isMyUser?: boolean) {
    if (isMyUser) {
      return conversation.conversationUsers?.find(
        ({ userId }) => userId === AuthModule.profile$.value?.id
      )
    }

    return conversation.conversationUsers?.find(
      ({ userId }) => userId !== AuthModule.profile$.value?.id
    )
  }

  // getConversationUnread(conversation: IConversationModel) {
  //   const conversationUser = this.getConversationUser(conversation, true)
  //   if (conversationUser?.readMessageId && conversation.lastMessageId) {
  //     return conversation.lastMessageId - conversationUser.readMessageId
  //   }
  //   return 0
  // }

  getConversationTitle(conversation: IConversationModel) {
    if (conversation.title) {
      return conversation.title
    }
    return this.getConversationUser(conversation)?.alias
  }

  getConversationAvatar(conversation: IConversationModel) {
    const conversationUser = this.getConversationUser(conversation)
    return getAvatar(conversationUser?.user) || conversationUser?.alias
  }

  getConversationUserFullName(conversation: IConversationModel) {
    const conversationUser = this.getConversationUser(conversation)
    if (conversationUser?.user) {
      return conversationUser?.alias || getFullName(conversationUser.user)
    }
    return '---'
  }

  getConversationUserName(conversation: IConversationModel) {
    const conversationUser = this.getConversationUser(conversation)

    if (conversationUser?.user) {
      return conversationUser.user?.username
    }

    return conversationUser?.alias
  }

  getConversationUserCompany(conversation: IConversationModel) {
    const conversationUser = conversation.conversationUsers?.find(
      ({ userId }) => userId !== AuthModule.profile$.value?.id
    )

    if (conversationUser?.user) {
      return getFirstCompany(conversationUser?.user)
    }
  }

  getAuthorLookupId(conversation: IConversationModel) {
    const conversationUser = conversation.conversationUsers?.find(
      ({ userId }) => userId !== AuthModule.profile$.value?.id
    )

    if (conversationUser?.user?.lookupId) {
      return conversationUser?.user?.lookupId
    }
  }

  sendMessage(payload: Parameters<typeof MessageApi.create>[0]) {
    const conversation = this.conversation$.value
    if (!payload.conversationId && !conversation?.id) {
      return from(Promise.reject(new Error('No conversation selected')))
    }
    const promise = MessageApi.create({
      ...payload,
      conversationId: payload.conversationId || conversation?.id
    })
    return from(promise).pipe(
      tap(({ data }) => {
        const conversation = this.conversations$.value.find(
          ({ id }) => id === data.conversationId
        )
        conversation && this.read(conversation, data, true)
      })
    )
  }
})()
