import { ComponentProps, createElement, FunctionComponent } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'
import { v4 } from 'uuid'

interface IOptions {
  hidden?: boolean
  /**
   * @default false
   * @description Whether to persist the dialog when route changes
   */
  persist?: boolean
}

interface IDialog {
  id: string
  // component: FunctionComponent<any>
  // props?: ComponentProps<FunctionComponent<any>>
  unmount: () => void
  options: IOptions
  update$: Subject<IOptions>
  content: ReturnType<typeof createElement>
}

export class DialogService {
  private static readonly _items$ = new BehaviorSubject<IDialog[]>([])
  static get items$(): WithOutNextComplete<typeof DialogService._items$> {
    return this._items$
  }

  static open<T extends FunctionComponent<any>>(
    component: T,
    props?: Omit<ComponentProps<T>, 'open' | 'onClose'> & Partial<Pick<ComponentProps<T>, 'open' | 'onClose'>> & { onUnmounted?: () => void },
    options?: IOptions
  ) {
    const { onUnmounted, ...maskProps } = (props || {}) as any

    const unmount = () => {
      this._items$.next(
        this._items$.value.filter(
          (item) => item.id !== dialog.id
        )
      )
      onUnmounted?.()
    }

    const dialog: IDialog = {
      id: v4(),
      unmount,
      options: { ...options },
      update$: new Subject(),
      content: createElement(component, {
        ...props,
        open: true,
        onClose(...args: any[]) {
          unmount()
          maskProps?.onClose?.(...args)
        },
        onCancel(...args: any[]) {
          unmount()
          maskProps?.onCancel?.(...args)
        },
        onConfirm(...args: any[]) {
          unmount()
          maskProps?.onConfirm?.(...args)
        }
      })
    }

    this._items$.next([...this._items$.value, dialog])

    return {
      update: (value: IOptions) => {
        dialog.options = { ...dialog.options, ...value }
        dialog.update$.next(dialog.options)
      },
      unmount
    }
  }
}
