import clsx from 'clsx'
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  switchMap,
  takeUntil
} from 'rxjs'
import { MessageApi, UploadApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { Divider } from 'src/components'
import { useAsRef, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { IMessageModel } from 'src/interfaces'
import { LoadMoreService, SnackbarService } from 'src/services'
import { MessengerService } from 'src/services/messenger.service'
import { ChatEditor } from './chat-editor'
import { ContactUser } from './contact-user'
import { Header } from './header'
import { Messages } from './messages'
import Style from './style.module.scss'

interface IProps {
  removeConversation: (id: number) => void
}

export const MessagesBox: FC<IProps> = ({ removeConversation }) => {
  const unsubscribe$ = useUnsubscribe()

  const [openAside, setOpenAside] = useState(false)

  const _loadMoreService = useMemo(() => new LoadMoreService<IMessageModel>({
    axiosInstance: axiosHiringApi,
    endpoint: MessageApi._prefix
  }), [])
  const messages = useBehaviorMapper(_loadMoreService.items$)
  const sortedMessages = useMemo(
    () => messages.sort((a, b) => new Date(b.createdAt || Date.now()).getTime() - new Date(a.createdAt || Date.now()).getTime()),
    [messages]
  )

  const conversation = useBehaviorMapper(MessengerService.conversation$)
  const loadMore = useCallback(() => {
    if (conversation?.id && _loadMoreService.hasMore) {
      return _loadMoreService.loadMore({
        conversationId: conversation.id,
        limit: 10
      })
    }
  }, [_loadMoreService, conversation])

  useEffect(() => {
    if (!conversation?.id) {
      return
    }

    _loadMoreService.reset()
    loadMore()
  }, [_loadMoreService, conversation?.id, loadMore])

  useEffect(() => {
    if (!conversation?.id || !messages[0] || conversation.id !== messages[0]?.conversationId) {
      return
    }

    const lastMessage = messages[0]
    if (
      lastMessage.updatedAt &&
      conversation.lastMessage?.updatedAt &&
      new Date(conversation.lastMessage.updatedAt).getTime() > new Date(lastMessage.updatedAt).getTime()
    ) {
      from(MessageApi.paginate({ conversationId: conversation.id }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            SnackbarService.axiosError(error)
            return EMPTY
          })
        )
        .subscribe(({ data }) => {
          if (data.rows?.length) {
            _loadMoreService.addFirst(data.rows)
            MessengerService.read(conversation)
          }
        })
    }
  }, [_loadMoreService, conversation, messages, unsubscribe$])

  const [attachments, setAttachments] = useState<Array<{
    file: File
    conversationId: number
    uploading?: boolean
  }>>([])

  const onChooseFiles = useCallback((files: FileList, conversationId: number) => {
    setAttachments((prev) => [
      ...prev,
      ...Array.from(files).map((file) => ({ file, conversationId }))
    ])
  }, [])

  const uploadAttachments = useCallback((_attachments: typeof attachments) => {
    if (!_attachments[0] || _attachments[0]?.uploading) {
      return
    }
    const attachment = _attachments[0]

    const promise = UploadApi.uploadF({
      entity: 'Message',
      file: attachment.file,
      filename: attachment.file.name
    })

    attachment.uploading = true
    const contentType = attachment.file.type
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        switchMap((url) => MessengerService.sendMessage({
          contentType,
          content: url,
          filename: attachment.file.name
        })),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setAttachments((prev) => {
          const [, ...keep] = prev
          return keep
        }))
      )
      .subscribe(({ data }) => _loadMoreService.addFirst(data))
  }, [_loadMoreService, unsubscribe$])

  useEffect(() => {
    uploadAttachments(attachments)
  }, [attachments, uploadAttachments])

  const onSubmit = useCallback((content: string) => {
    if (!conversation) {
      return
    }

    MessengerService.sendMessage({ content })
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        _loadMoreService.addFirst(data)
      })
  }, [_loadMoreService, conversation, unsubscribe$])

  const onSubmitRef = useAsRef(onSubmit)

  if (!conversation?.id) return <div>No Data</div>

  return (
    <div className={clsx('fx-1 fx fx-column', Style.messagesBox)}>
      <Header conversation={conversation} openAside={openAside} setOpenAside={setOpenAside} removeConversation={removeConversation}/>

      <Divider color="#EFEFEF"/>

      <Messages
        messages={sortedMessages}
        hasMore={_loadMoreService.hasMore}
        next={() => loadMore()}
      />

      <ChatEditor
        onSubmitRef={onSubmitRef}
        onChooseFiles={(files) => onChooseFiles(files, conversation.id as number)}
      />

      {openAside && (
        <ContactUser/>
      )}
    </div>
  )
}
