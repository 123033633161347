import clsx from 'clsx'
import ReactEmojiPicker from 'emoji-picker-react'
import {
  ComponentProps,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import { useAsRef, useOnClickOutside } from 'src/hooks'
import { IconEmoji } from 'src/icons'
import { Button } from '../button'
import Style from './style.module.scss'

interface IProps extends ComponentProps<typeof ReactEmojiPicker> {
  children?: ReactNode
  open?: boolean
  onOpen?: () => void
}

export const EmojiPicker: FC<IProps> = (props) => {
  const [open, setOpen] = useState(props.open)
  const pickerRef = useRef<HTMLDivElement>(null)
  const onOpenRef = useAsRef(props.onOpen)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  useEffect(() => {
    if (open) {
      onOpenRef.current?.()
    }
  }, [onOpenRef, open])

  useOnClickOutside(pickerRef, () => setOpen(false))

  return (
    <div className={clsx('fx fx-center', Style.container)}>
      <span
        className="fx pointer"
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation()
          setOpen((prev) => !prev)
        }}
      >
        {props.children
          ? props.children
          : (
            <Button variant="icon"><IconEmoji/></Button>
          )}
      </span>

      {open && (
        <div ref={pickerRef} className={Style.picker}>
          <ReactEmojiPicker {...props}/>
        </div>
      )}
    </div>
  )
}
