import clsx from 'clsx'
import { FC, ReactNode } from 'react'

export const ProfileRow: FC<{
  longLabel?: boolean
  label?: string
  value?: ReactNode
  valueClass?: string
  className?: string
}> = (props) => {
  return (
    <div className={clsx('fx fx-ai-center gap-5', props.className)}>
      <div
        className="fs-14 fw-500 txt-neutral-50"
        style={{ minWidth: props.longLabel ? '160px' : '80px' }}
      >
        {props.label}
      </div>
      <div className={clsx('fs-14 fw-500 txt-black-01 fx-1 fx-column', props.valueClass)}>
        {typeof props.value === 'boolean'
          ? props.value
            ? 'Yes'
            : 'No'
          : props.value
            ? props.value
            : <span className="fs-10 txt-neutral-N500">N/A</span>}
      </div>
    </div>
  )
}
