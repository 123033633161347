import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { BehaviorSubject } from 'rxjs'
import { IObject, IPaginationQuery, IPaginationResponse } from 'src/interfaces'

export class PaginationService<T = any, P = IObject> {
  axiosInstance: AxiosInstance
  endpoint: string

  static get defaultData() {
    return {
      from: 1,
      to: 0,
      page: 0,
      limit: 10,
      offset: 0,
      total: 0,
      pages: 1,
      rows: []
    }
  }

  constructor(api: {
    axiosInstance: AxiosInstance
    _prefix?: string
    endpoint?: string
  } = { axiosInstance: axios }) {
    this.axiosInstance = api.axiosInstance
    const endpoint = api._prefix || api.endpoint
    if (!endpoint) {
      throw new Error('api endpoint is required')
    }

    this.endpoint = endpoint
  }

  readonly loading$ = new BehaviorSubject(false)
  get loading() {
    return this.loading$.getValue()
  }

  readonly pagination$ = new BehaviorSubject<Required<IPaginationResponse<T>>>(PaginationService.defaultData)
  get pagination() {
    return this.pagination$.getValue()
  }

  reset() {
    this.loading$.next(false)
    this.pagination$.next(PaginationService.defaultData)
  }

  paging(params: IPaginationQuery & P) {
    this.loading$.next(true)
    return this.axiosInstance
      .get(this.endpoint, { params })
      .then(({ data }: AxiosResponse<IPaginationResponse<T>>) => {
        this.pagination$.next(data as Required<IPaginationResponse<T>>)
        return data as Required<IPaginationResponse<T>>
      })
      // TODO: Uncomment this code with SnackbarService
      // .catch((error: AxiosError) => {
      //   SnackbarService.error(AxiosUtils.getApiErrorMessage(error).replace(/^"(params|body|query)\./, '"'))
      //   throw error
      // })
      .finally(() => this.loading$.next(false))
  }
}
