import { FC } from 'react'
import { Tabs } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IMinimumSubmissionData } from 'src/interfaces'
import { TourPopper } from 'src/partials/tour'
import { ShareJobTourService, TARGET_CARD_JOB } from 'src/services/tour/share-job-tour.service'
import { tabs } from '../../my-jobs'
import { CardJob } from '../../my-jobs/components/card-job'
import Style from '../../my-jobs/style.module.scss'

const submissions: IMinimumSubmissionData[] = Array.from({ length: 12 }).map((value, index) => ({
  id: index,
  author: {
    id: index,
    fullName: '',
    pfp: {
      url: `https://i.pravatar.cc/${index + 125}`
    }
  }
})
)

export const MyJobsSandbox: FC = () => {
  const currentStep = useBehaviorMapper(ShareJobTourService.currentStep$)

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <div className={Style.header_tab}>
          <Tabs tab={0} tabs={tabs}/>
        </div>
      </div>

      <div className={Style.content}>
        <CardJob
          htmlId={TARGET_CARD_JOB.getValue()}
          campaign={{
            id: 1,
            slug: 'be83a495-8bea-411c-a301-98ed35c48a0d',
            jobTitle: 'Customer Success',
            companyName: 'Know Me',
            submissions,
            allowRemote: false,
            shareOnMarket: true,
            requireZipCode: false,
            requiredResume: false,
            requireLinkedInUrl: false,
            questionIds: []
          }}
          onTourTrigger={() => ShareJobTourService.nextStep()}
        />
        {currentStep === 0 && <TourPopper {...ShareJobTourService.getStepConfig()}/>}
      </div>
    </div>
  )
}
