import { FC, useEffect } from 'react'
import { NotAllowAuthenticated } from 'src/components'
import { useAppDispatch, useQueryParams } from 'src/hooks'
import { SignInForm } from 'src/partials'
import { setLayoutSharingToken } from 'src/store/actions'

export const SignIn: FC = () => {
  const dispatch = useAppDispatch()
  const { sharingToken } = useQueryParams()

  useEffect(() => {
    if (sharingToken) {
      dispatch(setLayoutSharingToken(sharingToken.toString()))
    }
  }, [sharingToken, dispatch])

  return (
    <NotAllowAuthenticated>
      <div className="fx-1 fx fx-center">
        <SignInForm/>
      </div>
    </NotAllowAuthenticated>
  )
}
