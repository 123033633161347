import { FC, useState } from 'react'
import { FeedbackApi } from 'src/api'
import { Button, Select, Textarea } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { OverlayService, SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { getApiErrorMessage } from 'src/utils'
import { reasonOptions } from './helper'
import Style from './style.module.scss'

export const PopupContactUs: FC = () => {
  const [content, setContent] = useState('')
  const [reason, setReason] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const profile = useBehaviorMapper(AuthModule.profile$)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      if (!reason) {
        SnackbarService.error('Please Provide Your Reason')
        return
      }
      if (!content) {
        SnackbarService.error('Please Provide Your Feedback')
        return
      }

      await FeedbackApi.create({ reason, content, userId: profile?.id })

      OverlayService.reset()
      SnackbarService.success('Sent')
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      setIsSubmitting(false)
    }
  }

  const onClose = () => {
    OverlayService.reset()
  }

  return (
    <div className={Style.STContainer}>
      <div className={Style.STHeader}>
        <span className={Style.STHeader__Title}>Contact Us</span>
      </div>
      <div className={Style.STBody}>
        <div className={Style.STForm}>
          <div className="fx-column fx-1 gap-2">
            <div className="body2-bold">
              Reason <span className="color-negative-500">*</span>
            </div>
            <Select
              className={Style.ViewOption}
              options={reasonOptions.map((option) => ({
                label: option.label,
                value: option.value
              }))}
              onChange={(value) => setReason(value as string)}
              value={reason}
            />
          </div>

          <div className="body2-bold mt-6">
            Question / Feedback <span className="color-negative-500">*</span>
          </div>
          <Textarea
            placeholder="Ask us anything, anytime..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            autoComplete="off"
            disableResize
            minRows={4}
          />
          <div className="fx-column gap-4 mt-6">
            <Button
              disabled={isSubmitting || (!reason && !content)}
              className="round-16"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              variant="secondary"
              className="round-16"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
