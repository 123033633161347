import { Box, Typography } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { from, takeUntil } from 'rxjs'
import { ProfileApi } from 'src/api'
import { useIfMobileScreen, useUnsubscribeEffect } from 'src/hooks'
import { IconPasswordSuccess } from 'src/icons'
import { AuthModule } from 'src/store'
import Style from './style.module.scss'

export const Success: FC = () => {
  const history = useHistory()
  const isMobile = useIfMobileScreen()

  const timer = useRef<ReturnType<typeof setInterval> | null>(null)
  const [count, setCount] = useState<number>(5)

  useEffect(() => {
    history.push({
      pathname: history.location.pathname,
      search: ''
    })
  }, [history])

  useEffect(() => {
    timer.current = setInterval(() => {
      setCount(prevCount => prevCount - 1)
    }, 1000)

    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [])

  useUnsubscribeEffect((unsubscribe$) => {
    if (count < 1) {
      from(ProfileApi.getProfile())
        .pipe(takeUntil(unsubscribe$))
        .subscribe(({ data }) => AuthModule.authenticated(data))
    }
  }, [count])

  return (
    <form className={Style.STContainer}>
      <Box className={Style.STWrapper}>
        <IconPasswordSuccess/>
        <Typography variant={isMobile ? 'h4' : 'h3'} align="center" paddingX={3}>
          Sign Up Successful
        </Typography>
      </Box>
    </form>
  )
}
