import { Box, Typography, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VideoAuthor } from 'src/components/video-author'
import { IUserModel } from 'src/interfaces'
import { formatSecondsToMinutes } from 'src/utils'
import { IProgress } from '..'
import { Control } from '../control'
import { useMouse } from '../mouse.context'
import { Range } from '../range'
import Style from './style.module.scss'

interface IProps {
  mini?: boolean
  author?: Partial<IUserModel>
  playing: boolean
  onBeforeGoToDetail?: () => void
  hideStartEnd?: boolean
  shouldRenderControl?: boolean
  duration?: number
  progress?: IProgress
  onProgressChange?: (percent: number) => void
  detailUrl?: string
  onDetailClicked?: () => void
  showSpeed?: boolean
  speed?: number
  handleChangeSpeed?: () => void
}

export const AuthorAndControl: FC<IProps> = (props) => {
  const [showCurrentPeriod, setShowCurrentPeriod] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState<number>(0)
  const [isShowSpeed, setIsShowSpeed] = useState(false)
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const mouseIn = useMouse()
  const theme = useTheme()

  const handleOnProgressChange = useCallback((percent: number) => {
    if (props.mini) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        setShowCurrentPeriod(false)
      }, 2000)

      setSelectedPeriod((props.duration || 0) * percent)
      setShowCurrentPeriod(true)
    }

    props.onProgressChange?.(percent)
  }, [props, timerRef])

  const opacityStyle = useMemo(() => {
    return {
      opacity: (mouseIn || props.playing) ? 1 : 0
    }
  }, [mouseIn, props.playing])

  useEffect(() => {
    if (mouseIn) {
      setSelectedPeriod(props.progress?.playedSeconds || 0)
      setShowCurrentPeriod(true)
      setIsShowSpeed(true)
    } else {
      setShowCurrentPeriod(false)
      setIsShowSpeed(false)
    }
  }, [mouseIn, props.progress?.playedSeconds])

  return (
    <Control
      mini={props.mini}
    >
      {props?.showSpeed && isShowSpeed && (
        <div
          className={Style.speedBox}
          onClick={(e) => {
            e.stopPropagation()
            props?.handleChangeSpeed?.()
          }}
        >
          <Typography variant="body2-bold" align="center" color={theme.colors['--color-neutral-theme-100']}>
            {props?.speed || 1}x
          </Typography>
        </div>
      )}

      {props.mini && showCurrentPeriod && (
        <div className={Style.periodBox}>
          <Typography variant="meta-bold" align="center" fontSize={10}>
            {formatSecondsToMinutes(selectedPeriod || 0)}
          </Typography>
        </div>
      )}

      {props.author && (
        <VideoAuthor
          detailUrl={props.detailUrl}
          className={Style.STVideoAuthor}
          author={props.author}
          onBeforeGoToDetail={props.onBeforeGoToDetail}
          onDetailClicked={props.onDetailClicked}
          playing={props.playing}
        />
      )}

      {props.shouldRenderControl && (
        <>
          {props.hideStartEnd
            ? null
            : (
              <Box>{formatSecondsToMinutes(props.progress?.playedSeconds || 0)}</Box>
            )}

          <Range
            mini={props.mini}
            value={props.progress?.played || 0}
            onChange={handleOnProgressChange}
            style={opacityStyle}
          />
          {props.hideStartEnd
            ? null
            : (
              <Box>{formatSecondsToMinutes(props.duration || 0)}</Box>
            )}
        </>
      )}
    </Control>
  )
}
