import clsx from 'clsx'
import { debounce, orderBy, uniqWith } from 'lodash'
import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory, useParams } from 'react-router'
import { finalize, from, takeUntil, timer } from 'rxjs'
import { CampaignApi } from 'src/api'
import {
  Button,
  CardReactionAndResume,
  EmptyVideos,
  FlipCard,
  FlipCardBack,
  InputSearch,
  Select,
  Tooltip,
  VideoPlayer,
  useAnalytic
} from 'src/components'
import { CacheProvider } from 'src/components/video-player/cache.context'
import { ECandidateReaction, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useBehaviorMapper, useQueryParams, useUnsubscribeEffect } from 'src/hooks'
import { IconCopyWhite, IconGroup, IconTrash } from 'src/icons'
import { LikeCardEmptyImg } from 'src/images'
import { ICampaignPublicModel, ICampaignSubmissionModel, IPaginationResponse } from 'src/interfaces'
import { STFlipFront } from 'src/modules/intros/components/list/styled'
import { JobClaimedRestrictModal, SignUpModal } from 'src/partials'
import { TourPopper } from 'src/partials/tour'
import { ERoutes, generate } from 'src/router'
import { OverlayService, SnackbarService } from 'src/services'
import { GuestManageJobTourService, TARGET_PUBLIC_GUEST_COPY_JOB, TARGET_PUBLIC_GUEST_SEARCH_AND_SORT } from 'src/services/tour/guest-manage-job-tour.service'
import { GuestViewJobTourService, TARGET_PUBLIC_GUEST_FLIP_BUTTON, TARGET_PUBLIC_GUEST_FLIP_BUTTON_ANCHOR, TARGET_PUBLIC_GUEST_VIEW_DETAIL, TARGET_PUBLIC_GUEST_VIEW_DETAIL_ANCHOR } from 'src/services/tour/guest-view-job-tour.service'
import {
  setLayoutAside,
  setLayoutLoading,
  setLayoutPageTitle,
  setLayoutScrollToBottom
} from 'src/store/actions'
import { getLayoutIsLoading, getLayoutIsScrollToBottom } from 'src/store/selectors'
import { StorageUtils, ensureArray, getApiErrorMessage, getFirstName, getVideoSource } from 'src/utils'
import { getCampaignShareLink } from '../../utils'
import { Empty } from '../empty'
import { JobDetailPopup } from '../job-detail-popup'
import { GuestSubmissionDetail } from '../submission-detail/guest-index'
import { ButtonEdit } from './components/button-edit/guest-index'
import { defaultFilterOptions, defaultViewOptions } from './constants'
import Style from './style.module.scss'

const mapSubmissionReaction = (submission: ICampaignSubmissionModel, reactions: ({ id: number; createdAt: Date })[]) => {
  const reaction = reactions.find(reaction => reaction.id === submission.id)

  return {
    ...submission,
    video: {
      ...submission.video,
      createdReaction: {
        reaction: reaction ? ECandidateReaction.LIKE : undefined
      }
    },
    likedAt: reaction?.createdAt
  }
}

export const PublicSubmissions: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const flippedMapRef = useRef<Map<number, boolean>>(new Map())
  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)
  const isLoading = useAppSelector(getLayoutIsLoading)
  const { hashId } = useParams<{ hashId: string }>()
  const [campaign, setCampaign] = useState<ICampaignPublicModel>()
  const [link, setLink] = useState<string>('')
  const campaignId = useMemo(() => campaign?.id, [campaign])
  const [isCopySuccess, setIsCopySuccess] = useState(false)
  const rootAnalyticData = useMemo(() => {
    return {
      campaignId
    }
  }, [campaignId])

  const { eventHandler } = useAnalytic(history.location.pathname, rootAnalyticData)

  const viewTourEnabling = useBehaviorMapper(GuestViewJobTourService.enableTour$)
  const currentViewTourStep = useBehaviorMapper(GuestViewJobTourService.currentStep$)

  const manageTourEnabling = useBehaviorMapper(GuestManageJobTourService.enableTour$)
  const currentManageTourStep = useBehaviorMapper(GuestManageJobTourService.currentStep$)

  const { detailSubmissionId: paramDetailSubmissionId } = useQueryParams()
  const [detailSubmissionId, setDetailSubmissionId] = useState<number>()

  const [lastLikeOffsetY, setLastLikeOffsetY] = useState<number>()
  const containerRef = useRef<any>()

  useUnsubscribeEffect((unsubscribe$) => {
    dispatch(setLayoutLoading(true))
    from(CampaignApi.publicCampaign(hashId))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => dispatch(setLayoutLoading(false)))
      )
      .subscribe({
        next({ data }) {
          // already claimed
          setCampaign(data)
          setLink(getCampaignShareLink(data.slug))

          if (data.authorId) {
            OverlayService.setOverlay(JobClaimedRestrictModal.getOverlayState(hashId, data.id, getFirstName(data.author)))
          }
        },
        error(/** error */) {
          history.push(generate(ERoutes.ERROR_404))
        }
      })
  }, [hashId])

  useEffect(() => {
    setDetailSubmissionId(
      paramDetailSubmissionId
        ? +paramDetailSubmissionId
        : undefined
    )

    if (!paramDetailSubmissionId) {
      const currentReactions = StorageUtils.getItem(`guess.${campaignId}.like`) || []
      setData((prev) => ({
        ...prev,
        rows: ensureArray(prev.rows).map(submission => mapSubmissionReaction(submission, currentReactions))
      }))
    }
  }, [campaignId, paramDetailSubmissionId])

  const [data, setData] = useState<IPaginationResponse<ICampaignSubmissionModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const [sortValue, setSortValue] = useState(defaultFilterOptions[0].value)
  const [viewValue, setViewValue] = useState(defaultViewOptions[0].value)
  const [searchTerm, setSearchTerm] = useState('')
  const [currOpenedTraitId, setCurrOpenedTraitId] = useState(0)
  const [isChangeView, setIsChangeView] = useState(false)
  const watchedTimeMapRef = useRef<Map<number, number>>(new Map())

  const loadSubmission = useCallback(
    async (
      page = 1,
      limit = 10,
      isReaction = false
    ) => {
      if (!campaignId) {
        return
      }

      try {
        if (isReaction) {
          dispatch(setLayoutLoading(false))
        } else {
          dispatch(setLayoutLoading(true))
        }

        const conditions: any = {
          page,
          limit
        }

        conditions.order = sortValue

        const sort = (rows: ICampaignSubmissionModel[]) => {
          if (viewValue === 'LIKED_FIRST') {
            return orderBy(rows, ['likedAt'], ['asc'])
          }

          return rows
        }

        if (searchTerm) {
          conditions.search = searchTerm
        }

        const { data } = await CampaignApi.publicPaginateSubmission(
          hashId,
          conditions
        )

        const currentReactions = StorageUtils.getItem(`guess.${campaignId}.like`) || []

        setData((prev) => ({
          ...prev,
          page: data.page,
          total: data.total,
          pages: data.pages,
          rows: sort((!conditions.page || conditions.page === 1)
            ? [...ensureArray(data.rows)].map(submission => mapSubmissionReaction(submission, currentReactions))
            : uniqWith([
              ...ensureArray(prev.rows),
              ...ensureArray(data.rows).map(submission => mapSubmissionReaction(submission, currentReactions))
            ], (a, b) => a.id === b.id))
        }))
      } catch (error) {
        SnackbarService.error(getApiErrorMessage(error))
      } finally {
        dispatch(setLayoutLoading(false))
      }
    }, [searchTerm, hashId, campaignId, sortValue, viewValue, dispatch]
  )

  const handleReaction = useCallback((submission: ICampaignSubmissionModel) => {
    try {
      eventHandler(ETrackingEvent.BTN_VIDEO_LIKE)()

      const dataRows = data.rows?.map((item: ICampaignSubmissionModel) => {
        if (item.video.id === submission.video?.id) {
          return { ...item, video: { ...item.video, createdReaction: { reaction: item.video.createdReaction?.reaction ? undefined : ECandidateReaction.LIKE } } }
        }
        return item
      })

      setData((prev) => ({
        ...prev,
        rows: dataRows
      }))

      const currentReactions = StorageUtils.getItem(`guess.${campaignId}.like`) || []

      const existedReaction = currentReactions.find((reaction: { id: number }) => reaction.id === submission.id)
      if (existedReaction) {
        currentReactions.splice(currentReactions.indexOf(existedReaction), 1)
        StorageUtils.setItem(`guess.${campaignId}.like`, currentReactions)
        return
      }

      currentReactions.push({
        id: submission.id,
        createdAt: new Date()
      })

      StorageUtils.setItem(`guess.${campaignId}.like`, currentReactions)
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    }
  }, [eventHandler, data.rows, campaignId])

  const handleSearch = useMemo(() => debounce(setSearchTerm, 500), [])

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    eventHandler({ key: ETrackingEvent.CAMPAIGN_INPUT_JOBS_SEARCH, contextData: { keyword: value } })()
    handleSearch(value)
  }

  const handelProgressVideo = (videoId: number, playedSecond: number) => {
    watchedTimeMapRef.current.set(videoId, playedSecond)
  }

  const showSignUpModal = useCallback(() => {
    if (manageTourEnabling) {
      return GuestManageJobTourService.nextStep()
    }

    return OverlayService.setOverlay({
      content: <SignUpModal/>,
      open: true
    })
  }, [manageTourEnabling])

  const handleClickToDetail = useCallback((submission: ICampaignSubmissionModel, index: number) => {
    if (index < 5) {
      setLastLikeOffsetY(containerRef.current?.parentElement.scrollTop)
      history.push({
        search: `?detailSubmissionId=${submission.id}&detailTab=${0}`
      })

      return
    }

    return showSignUpModal()
  }, [history, showSignUpModal])

  const checkIfCanPlay = useCallback((submission: ICampaignSubmissionModel, index: number) => {
    if (index < 5) {
      return true
    }

    showSignUpModal()
    return false
  }, [showSignUpModal])

  useEffect(() => {
    loadSubmission()
  }, [loadSubmission])

  // set page title when page entered
  useEffect(() => {
    dispatch(setLayoutAside(true))
  }, [dispatch])

  // when scroll to bottom, load more submissions
  useEffect(() => {
    if (
      data.pages &&
      data.page &&
      data.page !== data.pages &&
      isScrollToBottom
    ) {
      loadSubmission(data.page + 1)
    }
  }, [data.page, data.pages, dispatch, isScrollToBottom, loadSubmission])

  useEffect(() => {
    return () => {
      // Cleanup the debounce function on component unmount
      handleSearch.cancel()
    }
  }, [handleSearch])

  const handleListCampaignScroll = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
    const element = (e.target as HTMLElement)
    const scrollBottom = Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight

    if (scrollBottom) {
      return dispatch(setLayoutScrollToBottom(true))
    }

    if (isScrollToBottom) {
      return dispatch(setLayoutScrollToBottom(false))
    }
  }, [dispatch, isScrollToBottom])

  const openJobDetail = () => {
    eventHandler(ETrackingEvent.CAMPAIGN_JOBS_DETAIL)()

    OverlayService.setOverlay({
      content: <JobDetailPopup hashId={hashId}/>,
      open: true
    })
  }

  useEffect(() => {
    if (!detailSubmissionId && containerRef.current) {
      containerRef.current?.parentElement.scrollTo(0, lastLikeOffsetY || 0)
    }
  }, [detailSubmissionId, lastLikeOffsetY])

  const handleOnCopy = useCallback(() => {
    eventHandler(ETrackingEvent.CAMPAIGN_BTN_JOBS_COPY_URL)()
    setIsCopySuccess(true)
    GuestManageJobTourService.nextStep()

    timer(2000).subscribe(() => {
      setIsCopySuccess(false)
    })
  }, [eventHandler])

  const handleFlipButtonClick = (flipFunc: () => void, candidateId: number, videoId?: number) => {
    eventHandler({
      key: ETrackingEvent.CAMPAIGN_BTN_VIDEO_INFO,
      contextData: {
        campaignId,
        candidateId
      }
    })()

    flipFunc()

    if (videoId) {
      const isFlipped = flippedMapRef.current.get(videoId)
      flippedMapRef.current.set(videoId, !isFlipped)
    }
  }

  const pageTitle = useMemo(() => {
    if (detailSubmissionId) {
      return `My Jobs/${campaign?.jobTitle || ''}/Detail`
    }

    return `My Jobs/${campaign?.jobTitle || ''}`
  }, [campaign?.jobTitle, detailSubmissionId])

  useEffect(() => {
    dispatch(setLayoutPageTitle(pageTitle))
  }, [dispatch, pageTitle])

  return (
    <div className={Style.guessContainerWrapper} ref={containerRef}>
      <div className={Style.STContainerGuess}>
        {campaign
          ? !detailSubmissionId
            ? (
              <div className={Style.listCampaignContent} onScroll={handleListCampaignScroll}>
                <div className={Style.STCampaignHeader}>
                  <div className={Style.STCampaignHeaderLeft}>
                    <Tooltip
                      title={campaign.jobTitle}
                      arrow
                      placement="top"
                    >
                      <span className={Style.STCampaignHeaderTitle} onClick={openJobDetail}>
                        {campaign.jobTitle}
                      </span>
                    </Tooltip>
                    <ButtonEdit
                      onClick={eventHandler({
                        key: ETrackingEvent.CAMPAIGN_BTN_JOB_EDIT,
                        contextData: {
                          campaignId
                        }
                      }, showSignUpModal)}
                    />
                    {manageTourEnabling && currentManageTourStep === 0 && (<TourPopper {...GuestManageJobTourService.getStepConfig()}/>)}
                    <Button
                      type="button"
                      variant="secondary"
                      className={Style.STCampaignHeaderAction}
                      onClick={eventHandler({
                        key: ETrackingEvent.CAMPAIGN_JOBS_DELETE,
                        contextData: {
                          campaignId
                        }
                      }, showSignUpModal)}
                    >
                      <IconTrash className="svg-color-neutral-theme-900"/>
                    </Button>

                    <CopyToClipboard text={link} onCopy={handleOnCopy}>
                      <Button
                        id={TARGET_PUBLIC_GUEST_COPY_JOB.getValue()}
                        type="button"
                        variant="secondary"
                        className={Style.STCampaignHeaderAction}
                      >
                        <IconGroup/>
                      </Button>
                    </CopyToClipboard>
                    {manageTourEnabling && currentManageTourStep === 1 && (<TourPopper {...GuestManageJobTourService.getStepConfig()}/>)}

                    {isCopySuccess && (
                      <div className={Style.ToastCopy}>
                        <Button
                          type="button"
                          className={Style.BtnCopy}
                        >
                          <IconCopyWhite/>
                          Link Copied
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className={clsx(Style.STCampaignHeaderRight)}>
                    <InputSearch
                      handleChange={handleChangeSearch}
                    />

                    <div id={TARGET_PUBLIC_GUEST_SEARCH_AND_SORT.getValue()} style={{ width: 0, height: 0, position: 'absolute', left: '50px', bottom: 0 }}/>
                    {manageTourEnabling && currentManageTourStep === 2 && <TourPopper {...GuestManageJobTourService.getStepConfig()}/>}

                    <Select
                      options={defaultFilterOptions.map((option) => ({
                        label: option.label,
                        value: option.value
                      }))}
                      onChange={(value) => setSortValue(value as string)}
                      value={sortValue}
                      className={Style.FilterOption}
                    />
                    <Select
                      options={defaultViewOptions.map((option) => ({
                        label: option.label,
                        value: option.value
                      }))}
                      onChange={(value) => {
                        setViewValue(value as string)
                        setIsChangeView(true)
                      }}
                      value={viewValue}
                      className={Style.ViewOption}
                    />
                    {manageTourEnabling && currentManageTourStep === 3 && <TourPopper {...GuestManageJobTourService.getStepConfig()}/>}
                  </div>
                </div>

                {isLoading
                  ? <EmptyVideos className={Style.emptyVideos} quantity={10} variant="applicant"/>
                  : !data.rows?.length
                    ? (
                      <div className={Style.EmptyWrapper}>
                        <Empty
                          cover={LikeCardEmptyImg}
                          placeholder="There are no Talent yet"
                          className={Style.EmptyContainer}
                        />
                      </div>
                    )
                    : (
                      <div className={Style.campaignContainerWrapper}>
                        <div className={Style.campaignContainerData}>
                          {viewTourEnabling && currentViewTourStep === 2 && (<TourPopper {...GuestViewJobTourService.getStepConfig()}/>)}
                          {viewTourEnabling && currentViewTourStep === 1 && (<TourPopper {...GuestViewJobTourService.getStepConfig()}/>)}
                          {data.rows?.map((item: ICampaignSubmissionModel, index) => {
                            return (
                              <div key={item.id} className={Style.STLikesItem}>
                                <FlipCard flipped={isChangeView || flippedMapRef.current.get(item.video.id)}>
                                  {(flipFunc) => [
                                    (
                                      <STFlipFront key={'front_' + item.id}>
                                        <div className={clsx(Style.STVideo, Style.STVideoGuest, index > 4 && Style.Blur)}>
                                          <VideoPlayer
                                            id={'video' + item.id}
                                            onBeforePlay={() => checkIfCanPlay(item, index)}
                                            trackingEvent
                                            onProgress={({ playedSeconds }) => handelProgressVideo(item.video.id, playedSeconds)}
                                            playAt={watchedTimeMapRef.current.get(item.video.id)}
                                            videoId={item?.video?.id}
                                            tracks={item?.video?.tracks}
                                            image={item?.video?.urlVideoImageThumbnail}
                                            animatedImage={item?.video?.urlVideoAnimatedImage}
                                            url={getVideoSource(item?.video)}
                                            style={{ borderRadius: '16px 16px 0 0' }}
                                            isStop={currOpenedTraitId === item.video.id}
                                            onDetailClicked={() => handleClickToDetail(item, index)}
                                            isMuted={false}
                                            mini
                                            author={item?.author}
                                            hideSpeaker
                                            hideStartEnd
                                            mimeType={item?.video?.internalSourceMetadata?.mimeType}
                                            onPlayingChange={(playing) => {
                                              if (playing && currOpenedTraitId === item.video.id) {
                                                setCurrOpenedTraitId(0)
                                              }
                                            }}
                                            action={(
                                              <CardReactionAndResume
                                                item={item}
                                                newCandidateIds={[]}
                                                onFlip={() => handleFlipButtonClick(flipFunc, item.id, item.video?.id)}
                                                onShare={eventHandler({
                                                  key: ETrackingEvent.CAMPAIGN_BTN_VIDEO_SHARE,
                                                  contextData: {
                                                    candidateId: item.author?.id,
                                                    campaignId
                                                  }
                                                }, showSignUpModal)}
                                                isSubmission
                                                resumeButtonId={index === 0 ? TARGET_PUBLIC_GUEST_FLIP_BUTTON.getValue() : undefined}
                                                isSubmissionLiked={item.video?.createdReaction?.reaction === ECandidateReaction.LIKE}
                                                onLikeSubmission={handleReaction}
                                              />
                                            )}
                                            showSpeed
                                          />
                                        </div>
                                      </STFlipFront>
                                    ),
                                    <FlipCardBack
                                      key={'back_' + item.id}
                                      onFlip={() => { flipFunc(); flippedMapRef.current.set(item.video.id, false) }}
                                      educations={item?.author?.linkedinEducations}
                                      workingExperiences={item.author?.linkedinWorkingExperiences}
                                      video={item?.video}
                                      author={item?.author}
                                      showFullName
                                      detailButtonId={index === 0 ? TARGET_PUBLIC_GUEST_VIEW_DETAIL.getValue() : undefined}
                                      onDetailClick={() => {
                                        GuestViewJobTourService.nextStep()
                                        handleClickToDetail(item, index)
                                      }}
                                    />
                                  ]}
                                </FlipCard>
                                {index === 0 && (<div id={TARGET_PUBLIC_GUEST_FLIP_BUTTON_ANCHOR.getValue()} className={Style.flipAnchor}/>)}
                                {index === 0 && (<div id={TARGET_PUBLIC_GUEST_VIEW_DETAIL_ANCHOR.getValue()} className={Style.viewAnchor}/>)}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}

              </div>
            )
            : (
              <CacheProvider data={watchedTimeMapRef.current}>
                <GuestSubmissionDetail
                  hashId={hashId}
                  submissionId={detailSubmissionId}
                />
              </CacheProvider>
            )
          : null}
      </div>
    </div>
  )
}
