import { FC, ReactNode } from 'react'
import { Button, ModalCenter } from 'src/components'

interface IProps {
  open: boolean
  onClose?: () => void
  onConfirm?: () => void
  title: string
  description: string | ReactNode
  cancelLabel?: string
  confirmLabel?: string
}

export const DialogConfirm: FC<IProps> = ({
  open = false,
  title,
  description,
  onClose,
  onConfirm,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm'
}) => {
  return (
    <ModalCenter open={open} onClose={onClose}>
      <div className="fx-column gap-6">
        <div className="fx-column gap-2">
          <p className="heading-5 m-0 text-center txt-neutral-700">{title || 'Confirm'}</p>
          {!!description && (
            <span className="body-2 text-center txt-neutral-300">
              {description}
            </span>
          )}
        </div>
        <div className="fx-1 fx-column gap-4">
          <Button
            className="p-2"
            variant="secondary"
            size="large"
            onClick={() => {
              onConfirm?.()
              onClose?.()
            }}
          >
            {confirmLabel}
          </Button>
          <Button
            className="p-2"
            variant="primary"
            size="large"
            onClick={onClose}
          >
            {cancelLabel}
          </Button>
        </div>
      </div>
    </ModalCenter>
  )
}
