import { Button } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { ModalChangePassword } from 'src/partials/modal-change-password'
import { DialogService } from 'src/services'
import { AuthModule } from 'src/store'
import { AvatarSection } from '../profile-component/avatar-section'
import { CompanySection } from '../profile-component/company-section'
import { PhoneSection } from '../profile-component/phone-section'
import { ProfileBlock } from '../profile-component/profile-block'
import { ProfileRow } from '../profile-component/profile-row'

export const Information = () => {
  const profile = useBehaviorMapper(AuthModule.profile$)

  return (
    <div className="fx-1 fx-column gap-8">
      <AvatarSection/>

      <ProfileBlock>
        <ProfileRow label="Email" value={profile?.email}/>
        <ProfileRow
          label="Password"
          value={(
            <Button variant="secondary" className="fx-as-flex-start" onClick={() => DialogService.open(ModalChangePassword)}>
              Reset Password
            </Button>
          )}
        />
      </ProfileBlock>

      <PhoneSection/>

      <CompanySection/>
    </div>
  )
}
