import { AxiosError } from 'axios'
import { uniqWith } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { IPaginationQuery } from 'src/interfaces'
import { PaginationService } from './pagination.service'

export class LoadMoreService<T extends { id?: number }> {
  private _paginationService: PaginationService<T>

  constructor(
    api: ConstructorParameters<typeof PaginationService>[0],
    { loading } = { loading: false }
  ) {
    this._paginationService = new PaginationService(api)
    this._paginationService.loading$.next(loading)
  }

  get loading$() {
    return this._paginationService.loading$
  }

  get loading() {
    return this._paginationService.loading
  }

  get pagination$() {
    return this._paginationService.pagination$
  }

  get pagination() {
    return this._paginationService.pagination
  }

  readonly items$ = new BehaviorSubject<Array<T>>(
    PaginationService.defaultData.rows
  )

  get items() {
    return this.items$.getValue()
  }

  addFirst(item: T | T[]) {
    this.items$.next(
      uniqWith(
        [...(Array.isArray(item) ? item : [item]), ...this.items],
        (a, b) => (a as { id: number }).id === (b as { id: number }).id
      )
    )
  }

  addLast(item: T | T[]) {
    this.items$.next(
      uniqWith(
        [...this.items, ...(Array.isArray(item) ? item : [item])],
        (a, b) => (a as { id: number }).id === (b as { id: number }).id
      )
    )
  }

  removeItem(item: T) {
    this.items$.next(this.items.filter((i) => i !== item))
  }

  readonly error$ = new BehaviorSubject<AxiosError | null>(null)
  get error() {
    return this.error$.getValue()
  }

  get hasMore() {
    return (
      !this.error &&
      !this.loading &&
      this._paginationService.pagination.page <
        this._paginationService.pagination.pages
    )
  }

  reset() {
    this.items$.next([])
    this.error$.next(null)
    this._paginationService.reset()
  }

  loadMore(params: Omit<IPaginationQuery, 'page'> = {}) {
    if (!this.loading && this.hasMore) {
      this._paginationService
        .paging({
          ...params,
          page: this._paginationService.pagination.page + 1
        })
        .then(({ rows }) =>
          this.items$.next(
            uniqWith(
              [...this.items, ...rows],
              (a, b) => (a as { id: number }).id === (b as { id: number }).id
            )
          )
        )
        .catch((error: AxiosError) => {
          this.error$.next(error)
          throw error
        })
    }
  }

  updateOneItem(id: number, updateInfo: Partial<T>) {
    const items = [...this.items]
    const needChangedItemIndex = (items as T[]).findIndex(
      (item) => item?.id === id
    )
    const needChangedItem = items[needChangedItemIndex]

    if (needChangedItemIndex > -1) {
      items[needChangedItemIndex] = { ...needChangedItem, ...updateInfo }

      this.items$.next(items)
    }
  }

  removeOneItem(id: number) {
    const items = [...this.items]?.filter((item: any) => item?.id !== id)
    this.items$.next(items)
  }

  refresh() {
    this._paginationService
      .paging({
        page: 1,
        limit: this.pagination.limit
      })
      .then(({ rows }) =>
        this.items$.next(
          uniqWith(
            rows,
            (a, b) => (a as { id: number }).id === (b as { id: number }).id
          )
        )
      )
      .catch((error: AxiosError) => {
        this.error$.next(error)
        throw error
      })
  }
}
