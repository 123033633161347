
export enum EReaction {
  UNSURE = 'unsure',
  NOPE = 'nope',
  SKIP = 'skip',
  LIKE = 'like', // display in like/superlike/supernova screen
  SUPER_LIKE = 'superlike',
  SUPER_NOVA = 'supernova',
  BOOKMARK = 'bookmark'
}

export enum EReactionableType {
  VIDEO = 'Video',
  NOTIFICATION = 'Notification',
  CAMPAIGN = 'Campaign',
  CAMPAIGN_SUBMISSION = 'CampaignSubmission'
}
