import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconPencilFilled: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 18, color = 'white' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7041 2.60628C14.2445 2.1468 13.6212 1.88867 12.9713 1.88867C12.3213 1.88867 11.698 2.1468 11.2384 2.60628L10.6608 3.1847L14.7049 7.22878L15.2817 6.65117C15.5094 6.42357 15.69 6.15336 15.8132 5.85597C15.9364 5.55858 15.9998 5.23984 15.9998 4.91794C15.9998 4.59604 15.9364 4.27729 15.8132 3.9799C15.69 3.68251 15.5094 3.4123 15.2817 3.1847L14.7041 2.60628ZM13.5489 8.384L9.50469 4.33992L2.0781 11.7671C1.91558 11.9297 1.80205 12.1346 1.75048 12.3586L0.909779 15.9983C0.878454 16.1334 0.882047 16.2743 0.920219 16.4076C0.958392 16.541 1.02989 16.6624 1.12797 16.7605C1.22605 16.8586 1.34749 16.9301 1.48084 16.9682C1.61419 17.0064 1.75506 17.01 1.89019 16.9787L5.53077 16.1388C5.75447 16.0871 5.95915 15.9736 6.12147 15.8112L13.5489 8.384Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
