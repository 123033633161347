import clsx from 'clsx'
import { FC } from 'react'
import { Button } from 'src/components/button'
import Style from './style.module.scss'

interface IProps {
  onClick?: () => void
  className?: string
}

export const CardSingle: FC<IProps> = (props) => {
  return (
    <div className={clsx(Style.container, Style.containerSingle, props.className)}>
      <div className={Style.infoContainer}>
        <div className={Style.header}>
          <IconSingle/>
          <div className={Style.header_info}>
            <span className={Style.header_name}>Single Buy</span>
            <span className={Style.header_number_credit}>1 Intro Credit</span>
          </div>
        </div>

        <div className={Style.pricing}>
          <span className={Style.pricing_price}>$8</span>
          <span className={Style.pricing_info}>
            for
            <span className={Style.pricing_credit}>
              1 Credit
            </span>
          </span>
        </div>
      </div>
      <Button onClick={props.onClick} className="w-100-p">
        Buy Now
      </Button>
    </div>
  )
}

const IconSingle = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="10" fill="#F1F2F3"/>
    <path d="M25 11.8056C21.5006 11.8056 18.1446 13.1957 15.6701 15.6701C13.1957 18.1446 11.8056 21.5006 11.8056 25C11.8056 28.4994 13.1957 31.8554 15.6701 34.3299C18.1446 36.8043 21.5006 38.1944 25 38.1944L25 25V11.8056Z" fill="#AFB0BE"/>
    <path d="M25 38.1944C28.4994 38.1944 31.8554 36.8043 34.3299 34.3299C36.8043 31.8554 38.1944 28.4994 38.1944 25C38.1944 21.5006 36.8043 18.1446 34.3299 15.6701C31.8554 13.1957 28.4994 11.8056 25 11.8056L25 25L25 38.1944Z" fill="#DADBE7"/>
    <path opacity="0.8" d="M30.3279 26.8518C31.478 26.8518 32.4102 27.7841 32.4102 28.9341V29.7845C32.4102 30.3154 32.2443 30.833 31.9356 31.2649C30.5042 33.268 28.1669 34.2603 25 34.2603C21.8324 34.2603 19.4964 33.2675 18.0684 31.2635C17.7611 30.8321 17.5959 30.3156 17.5959 29.7859V28.9341C17.5959 27.7841 18.5281 26.8518 19.6782 26.8518H30.3279ZM25 15.745C27.5569 15.745 29.6296 17.8178 29.6296 20.3747C29.6296 22.9315 27.5569 25.0043 25 25.0043C22.4431 25.0043 20.3704 22.9315 20.3704 20.3747C20.3704 17.8178 22.4431 15.745 25 15.745Z" fill="white"/>
  </svg>

)

export const CardRegular: FC<IProps> = (props) => {
  return (
    <div className={clsx(Style.container, Style.containerRegular, props.className)}>
      <div className={clsx(Style.badge, Style.badge_primary)}>Most Popular</div>
      <div className={Style.infoContainer}>
        <div className={Style.header}>
          <IconRegular/>
          <div className={Style.header_info}>
            <span className={Style.header_name}>Regular Package</span>
            <span className={Style.header_number_credit}>4 Intro Credits</span>
          </div>
        </div>

        <div className={Style.pricingWrapper}>
          <div className={Style.pricing}>
            <span className={Style.pricing_price}>$28</span>
            <span className={Style.pricing_info}>
              for
              <span className={Style.pricing_credit}>
                4 Credits
              </span>
            </span>
          </div>
          <span className={Style.pricing_info}>($7 per Credit)</span>
        </div>
      </div>
      <Button className="w-100-p" onClick={props.onClick}>
        Buy Now
      </Button>
    </div>
  )
}

const IconRegular = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="10" fill="white"/>
    <rect x="24.6433" y="11.8056" width="13.5511" height="26.3889" rx="2" fill="#AFB0BE"/>
    <rect x="24.6433" y="24.6433" width="13.5511" height="13.5511" rx="2" fill="#F1F2F3"/>
    <rect x="11.1111" y="11.8056" width="13.5511" height="26.3889" rx="2" fill="#F1F2F3"/>
    <rect x="11.1111" y="24.6433" width="13.5511" height="13.5511" rx="2" fill="#AFB0BE"/>
  </svg>
)

export const CardSaving: FC<IProps> = (props) => {
  return (
    <div className={clsx(Style.container, Style.containerSaving, props.className)}>
      <div className={clsx(Style.badge, Style.badge_purple)}>Best Value</div>
      <div className={Style.infoContainer}>
        <div className={Style.header}>
          <IconSaving/>
          <div className={Style.header_info}>
            <span className={Style.header_name}>Saving Package</span>
            <span className={Style.header_number_credit}>20 Intro Credits</span>
          </div>
        </div>

        <div className={Style.pricingWrapper}>
          <div className={Style.pricing}>
            <span className={Style.pricing_price}>$128</span>
            <span className={Style.pricing_info}>
              for
              <span className={Style.pricing_credit}>
                20 Credits
              </span>
            </span>
          </div>
          <span className={Style.pricing_info}>($6.4 per Credit)</span>
        </div>
      </div>
      <Button className="w-100-p" onClick={props.onClick}>
        Buy Now
      </Button>
    </div>
  )
}

const IconSaving = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14906_1186)">
      <rect width="50" height="50" rx="10" fill="#F1F2F3"/>
      <path d="M39.6972 32.3933L39.7036 32.3898H39.691L32.3117 28.3912L24.9201 24.3856L17.5282 28.3912L10.1488 32.3898H10.1363L10.1427 32.3933L10.1363 32.3968H10.1488L17.5282 36.3954L24.9201 40.4011L32.3117 36.3954L39.691 32.3968H39.7036L39.6972 32.3933Z" fill="#DADBE7"/>
      <path d="M39.2831 17.0355L39.2896 17.0323H39.277L31.8977 13.0334L24.506 9.02777L17.1141 13.0334L9.73479 17.0323H9.72223L9.72865 17.0355L9.72223 17.039H9.73479L17.1141 21.0379L24.506 25.0436L31.8977 21.0379L39.277 17.039H39.2896L39.2831 17.0355Z" fill="#80818E"/>
      <path d="M10.1488 16.7101V32.7188L24.9201 24.7146L10.1488 16.7101Z" fill="#AFB0BE"/>
      <path d="M39.6909 16.7101V32.7188L24.92 24.7146L39.6909 16.7101Z" fill="#AFB0BE"/>
    </g>
    <defs>
      <clipPath id="clip0_14906_1186">
        <rect width="50" height="50" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
