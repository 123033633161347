import { ComponentProps, FC, useCallback, useMemo } from 'react'
import { Button, Modal } from 'src/components'
import { useAsRef } from 'src/hooks'
import { IconTalentInvite, IconTalentLike, IconTalentViewMore } from 'src/icons'
import { DialogService } from 'src/services/dialog.service'
import { AuthModule } from 'src/store'
import { ModalAuthentication } from '../modal-authentication'

interface IProps {
  kind?: 'like' | 'talent-view-more' | 'invite'
  open?: boolean
  onClose?: () => void
  onAuthenticated?: () => void
  actionMethod?: () => void
  redirectTo?: () => void
}

export const ModalAlmostThere: FC<IProps> = (props) => {
  const { kind, redirectTo } = props

  const onAuthenticatedRef = useAsRef(props.onAuthenticated)
  const onClick = useCallback((stage: ComponentProps<typeof ModalAuthentication>['stage']) => {
    DialogService.open(ModalAuthentication, {
      stage,
      onClose: (isAuthenticated) => {
        if (isAuthenticated) {
          onAuthenticatedRef.current?.()
          redirectTo?.()
        }
      }
    })
    props.onClose?.()
  }, [onAuthenticatedRef, props, redirectTo])

  const { image, text } = useMemo(() => {
    switch (kind) {
      case 'invite':
        return {
          image: <IconTalentInvite/>,
          text: 'invite a candidate to apply'
        }
      case 'talent-view-more':
        return {
          image: <IconTalentViewMore/>,
          text: 'view more talent'
        }
      default:
        return {
          image: <IconTalentLike/>,
          text: 'like a vibe check'
        }
    }
  }, [kind])

  return (
    <Modal
      center
      closeBtn
      open={props.open}
      onClose={props.onClose}
    >
      <div className="fx-column fx-ai-center gap-8" style={{ minWidth: '300px' }}>
        {image}

        <div className="fx-column gap-2">
          <strong className="fs-24 lh-28 txt-black-01 fx fx-jc-center">Almost there!</strong>
          <div className="fs-14 txt-black-02 lh-20 text-center">
            To {text}, please<br/>
            <b>sign up</b> or <b>sign in</b>.
          </div>
        </div>

        <div className="fx-column gap-3" style={{ width: '100%' }}>
          <Button className="p-4" onClick={() => onClick('sign-up')}>
            Sign Up
          </Button>
          <Button className="p-4" variant="secondary" onClick={() => onClick('sign-in')}>
            Sign In
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const mustAuthenticated = (props: ComponentProps<typeof ModalAlmostThere>) => {
  if (!AuthModule.isAuthenticated$.value) {
    return DialogService.open(ModalAlmostThere, props)
  }
  props.actionMethod?.()
}
