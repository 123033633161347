import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconThumbsUpDup: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.17389 2.6036C6.41781 2.35969 6.74863 2.22266 7.09358 2.22266C7.42533 2.22264 7.74456 2.34939 7.98594 2.57698C8.22674 2.80404 8.37191 3.11434 8.39191 3.44464L8.39421 3.52555V5.46981C8.39421 5.55777 8.46552 5.62907 8.55347 5.62907H9.52676C9.84547 5.62902 10.1531 5.74599 10.3913 5.95778C10.6288 6.16904 10.7808 6.45999 10.8184 6.77561L10.8251 6.85316L10.8271 6.92386L10.8192 7.00133L10.3317 9.43999C10.1549 10.191 9.65387 10.6722 9.12465 10.6674L9.04786 10.6637L9.0401 10.6635H5.14706C5.06688 10.6635 4.9895 10.634 4.92958 10.5808C4.87069 10.5284 4.8327 10.4566 4.82251 10.3785L4.81969 10.3314L4.82017 5.69577C4.82023 5.63836 4.83539 5.58198 4.86412 5.53228C4.89285 5.48258 4.93416 5.44129 4.98388 5.41259C5.21404 5.27966 5.40792 5.09208 5.54837 4.86643C5.68883 4.64078 5.77162 4.38401 5.78927 4.1188L5.79295 4.01544V3.52329C5.79295 3.17834 5.92998 2.84752 6.17389 2.6036Z"
          fill={color}
        />
        <path
          d="M2.16011 10.4464C2.0103 10.3074 1.91824 10.1173 1.90213 9.91371L1.8999 9.84684V6.44302C1.89984 6.23766 1.9774 6.03986 2.11704 5.88928C2.25595 5.73947 2.44609 5.64741 2.64968 5.6313L2.71656 5.62907H3.20053C3.28071 5.62909 3.35809 5.65853 3.41801 5.7118C3.4769 5.76417 3.51489 5.83599 3.52508 5.91404L3.5279 5.9612V10.3361C3.52789 10.4163 3.49845 10.4937 3.44517 10.5536C3.3928 10.6125 3.32098 10.6505 3.24293 10.6607L3.19578 10.6635H2.71385C2.50849 10.6636 2.31069 10.586 2.16011 10.4464Z"
          fill={color}
        />
        <path
          d="M13.826 8.66417C13.5821 8.42025 13.2512 8.28322 12.9063 8.28322C12.5745 8.2832 12.2553 8.40996 12.0139 8.63755C11.7731 8.8646 11.628 9.1749 11.6079 9.50521L11.6056 9.58611V11.5304C11.6056 11.6183 11.5343 11.6896 11.4464 11.6896H10.4731C10.1544 11.6896 9.84676 11.8066 9.6086 12.0183C9.37102 12.2296 9.21908 12.5205 9.18142 12.8362L9.17473 12.9137L9.17273 12.9844L9.18063 13.0619L9.66817 15.5006C9.845 16.2515 10.346 16.7328 10.8752 16.728L10.952 16.7242L10.9598 16.7241H14.8528C14.933 16.724 15.0104 16.6946 15.0703 16.6413C15.1292 16.589 15.1672 16.5171 15.1774 16.4391L15.1802 16.3919L15.1797 11.7563C15.1796 11.6989 15.1645 11.6425 15.1357 11.5928C15.107 11.5431 15.0657 11.5019 15.016 11.4731C14.7858 11.3402 14.5919 11.1526 14.4515 10.927C14.311 10.7013 14.2282 10.4446 14.2106 10.1794L14.2069 10.076V9.58385C14.2069 9.2389 14.0699 8.90808 13.826 8.66417Z"
          fill={color}
        />
        <path
          d="M17.8398 16.5069C17.9896 16.368 18.0816 16.1779 18.0977 15.9743L18.1 15.9074V12.5036C18.1 12.2982 18.0225 12.1004 17.8828 11.9498C17.7439 11.8 17.5538 11.708 17.3502 11.6919L17.2833 11.6896H16.7993C16.7192 11.6897 16.6418 11.7191 16.5819 11.7724C16.523 11.8247 16.485 11.8966 16.4748 11.9746L16.472 12.0218V16.3967C16.472 16.4769 16.5014 16.5542 16.5547 16.6142C16.6071 16.6731 16.6789 16.711 16.7569 16.7212L16.8041 16.7241H17.286C17.4914 16.7241 17.6892 16.6466 17.8398 16.5069Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
