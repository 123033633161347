import clsx from 'clsx'
import get from 'lodash/get'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IReactMicStopEvent } from 'react-mic'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ProfileApi } from 'src/api'
import { Button, SpotlightTour, Teleprompter, VideoPlayer, useAnalytic } from 'src/components'
import { Spotlight } from 'src/components/spotlight/spotlight'
import { RECORD_FILE_NAME } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import {
  useAppDispatch,
  useBehaviorMapper,
  useIfMobileL,
  useIfTablet,
  useQueryParams,
  useUnsubscribe
} from 'src/hooks'
import { IconVideoAdd } from 'src/icons'
import { ESourceFrom } from 'src/interfaces'
import { RecorderVideo } from 'src/partials'
import { CampaignMutationService, ERecordFor, OverlayService, ProfileService, RecordingService, SnackbarService } from 'src/services'
import { PopupTourService } from 'src/services/tour/popup.service'
import { UploadService } from 'src/services/upload.service'
import { AuthModule } from 'src/store'
import {
  setLayoutLoading,
  setLayoutPageTitle
} from 'src/store/actions'
import { formatSecondsToMinutes, getApiErrorMessage, getVideoThumbnailAtPercentDuration } from 'src/utils'
import { ActionMenu } from './action-menu'
import { SamplePlayers } from './sample-player'
import { MobileSamplePlayers } from './sample-player/mobile'
import { StartOver } from './start-over'
import Style from './style.module.scss'
import { ThumbnailPicker } from './thumbnail-picker'
import { useIfHasSpaceForThumbnailPicker } from './thumbnail-picker/useIfHasSpaceForThumbnailPicker'
import { TutorialPopper } from './tutorial-popper'

const MIN_WIDTH_FOR_THUMBNAIL_PICKER = 300

export const Recording: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const router = useHistory()
  const dispatch = useAppDispatch()
  const { eventHandler } = useAnalytic('recording')
  const profile = useBehaviorMapper(AuthModule.profile$)
  const [recording, setRecording] = useState(false)

  const _recordingService = useMemo(() => new RecordingService(), [])
  const teleprompter = useBehaviorMapper(_recordingService.teleprompter$)
  const { file: video } = useBehaviorMapper(_recordingService.recordResult$) || {}
  const { ref, hasSpace, space } = useIfHasSpaceForThumbnailPicker(MIN_WIDTH_FOR_THUMBNAIL_PICKER)

  useEffect(() => {
    recording
      ? _recordingService.startRecording()
      : _recordingService.stopRecording()

    if (recording) {
      window.onbeforeunload = () => 'Are you sure you want to leave?'
      return () => {
        window.onbeforeunload = null
      }
    }
  }, [_recordingService, recording])

  const recordResultRef = useRef<HTMLInputElement>(null)
  const backgroundImageAudioRef = useRef<HTMLInputElement>(null)
  const queryParams = useQueryParams()
  // TODO: validate type relate data
  const type = queryParams.type as ERecordFor
  const isMobile = useIfMobileL()
  const playerRef = useRef<ReactPlayer>(null)
  const isTablet = useIfTablet()
  // const isMax768 = useMediaQuery('(max-width:768px)')
  // const isMobile = useIfMobileL()

  const [openSample, setOpenSample] = useState(false)
  const [openTutorial, setOpenTutorial] = useState(false)
  const [audioBgSrc, setAudioBgSrc] = useState<string>()
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const thumbnailUrlRef = useRef<string>('')
  const [generatingThumbnail, setGeneratingThumbnail] = useState(false)
  const durationRef = useRef<number>(0)

  const handleChangeThumbnailSlider = useCallback(async (_: any, percent: number | number[], shouldSeekTo = true) => {
    const recordResult = _recordingService.recordResult
    if (!recordResult?.file) {
      return
    }

    if (Array.isArray(percent)) {
      return
    }

    try {
      setGeneratingThumbnail(true)
      const { thumbnailUrl, offset, duration } = await getVideoThumbnailAtPercentDuration(recordResult?.file, percent)
      durationRef.current = duration

      if (shouldSeekTo) {
        playerRef.current?.seekTo(offset)
      }

      setThumbnailUrl(thumbnailUrl)
      _recordingService.thumbnailAt = offset
    } finally {
      setGeneratingThumbnail(false)
    }
  }, [_recordingService])

  const formatThumbnailSliderValue = useCallback((percent: number) => {
    if (!durationRef.current) {
      return '0:00'
    }

    const seconds = Math.round(durationRef.current * percent / 100)
    return formatSecondsToMinutes(seconds)
  }, [])

  useEffect(() => {
    if (thumbnailUrlRef.current !== thumbnailUrl) {
      URL.revokeObjectURL(thumbnailUrlRef.current)
      thumbnailUrlRef.current = thumbnailUrl
    }
  }, [thumbnailUrl])

  useEffect(() => {
    return () => {
      if (audioBgSrc?.startsWith('blob:')) {
        URL.revokeObjectURL(audioBgSrc)
      }
    }
  }, [audioBgSrc])

  useEffect(() => {
    _recordingService.placeholder$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((placeholder) => {
        setAudioBgSrc(
          placeholder?.file
            ? URL.createObjectURL(placeholder.file as Blob)
            : placeholder?.src
        )
      })
  }, [_recordingService, unsubscribe$])

  const onOpenSample = useCallback(() => {
    eventHandler(ETrackingEvent.RECORDING_BTN_REC_SAMPLES)()

    if (isMobile) {
      return setOpenSample(true)
    }

    OverlayService.setOverlay({
      open: true,
      content: <SamplePlayers/>,
      onClose: () => setOpenSample(false)
    })
  }, [isMobile, eventHandler])

  useEffect(() => {
    dispatch(setLayoutPageTitle('Recorder'))
  }, [dispatch])

  /**
   * TODO: ?? what is this for? Idk let keep it for now
   */
  useEffect(() => {
    return () => {
      ProfileApi.getProfile().then(({ data }) => AuthModule.authenticated(data))
    }
  }, [])

  const handleStartOver = useCallback(
    () => {
      OverlayService.setOverlay({
        open: true,
        disabled: true,
        content: (
          <StartOver
            onStartOver={() => {
              setRecording(false)
              _recordingService.setRecordResult()
              OverlayService.reset()
            }}
            onKeepThis={() => OverlayService.reset()}
          />
        )
      })
    },
    [_recordingService]
  )

  const handleCompletedRecord = useCallback(
    (recordOutput: Blob | File | IReactMicStopEvent) => {
      setRecording(false)

      const result = recordOutput as IReactMicStopEvent

      const parsedOutput = result.blob ?? recordOutput

      if (!(parsedOutput instanceof File)) {
        _recordingService.setRecordResult(new File([parsedOutput], RECORD_FILE_NAME, { type: parsedOutput.type }), ESourceFrom.RECORD)
        handleChangeThumbnailSlider(null, 0, false)
        return
      }

      _recordingService.setRecordResult(parsedOutput, ESourceFrom.UPLOAD)
      handleChangeThumbnailSlider(null, 0, false)
    }, [_recordingService, handleChangeThumbnailSlider]
  )

  const submitPfv = useCallback((file: File | Blob) => {
    dispatch(setLayoutLoading(true))
    const promise = ProfileService.uploadPfv(
      file,
      _recordingService.placeholder,
      _recordingService.teleprompter?.text,
      _recordingService.thumbnailAt
    )
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => {
          dispatch(setLayoutLoading(false))
          return router.goBack()
        })
      )
      .subscribe((pfv) => {
        // cached, but since video was uploaded, we will use video.id as key, and no flip
        RecordingService.pushToMapThumbnail(pfv.id.toString(), { url: thumbnailUrlRef.current, flip: get(file, 'name') === RECORD_FILE_NAME })
      })
  }, [_recordingService, dispatch, router, unsubscribe$])

  const handleSubmit = useCallback(() => {
    if (!video) {
      return
    }

    if (type === ERecordFor.PFV_TOUR_SERVICE) {
      PopupTourService
        .setPfvFile({
          file: video,
          placeholder: _recordingService.placeholder,
          thumbnailOffset: _recordingService.thumbnailAt
        })
        .showVVCPopup()

      return router.goBack()
    }

    if (type === ERecordFor.PFV) {
      return submitPfv(video)
    }

    // for campaign videos, which is uploaded asynchronously, we will cache by video.name
    if (thumbnailUrlRef.current) {
      RecordingService.pushToMapThumbnail(video.name, { url: thumbnailUrlRef.current, flip: video?.name === RECORD_FILE_NAME })
    }

    if (type === ERecordFor.CAMPAIGN) {
      CampaignMutationService.patchData({
        uploadVideo: undefined,
        uploadVideoFile: video,
        thumbnailOffset: _recordingService.thumbnailAt,
        uploadVideoUrl: undefined,
        uploadAudioBackground: _recordingService.placeholder,
        uploadVideoRefId: UploadService.genUploadItemId(),
        _ignoreDraft: true
      })

      return router.goBack()
    }

    SnackbarService.error('Recording type is not supported')
  }, [video, type, _recordingService.placeholder, _recordingService.thumbnailAt, router, submitPfv])

  const handleOpenTutorial = useCallback(() => {
    eventHandler(ETrackingEvent.RECORDING_BTN_REC_TIPS)()
    if (!profile.id) return

    setOpenTutorial(true)
  }, [profile.id, eventHandler])

  useEffect(() => {
    // TODO: uncomment this when tutorial is ready
    const key = `${profile.id}.recording.tip`
    const value = localStorage.getItem(key)

    if (!value) {
      localStorage.setItem(key, 'true')
      handleOpenTutorial()
    }
  }, [profile, handleOpenTutorial])

  const handleDoneTutorial = useCallback(() => {
    setOpenTutorial(false)
  }, [])

  const renderGuides = useMemo(() => {
    if (!openTutorial) return []

    const sampleVideo = (
      <TutorialPopper
        key={1}
        color="cyan"
        title="Sample Videos"
        target="#target-sample"
        placement={isTablet ? 'bottom' : 'left'}
        description="Unsure how to begin? Check out our video samples for inspiration!"
        width={175}
      />
    )

    const teleprompter = (
      <TutorialPopper
        key={2}
        color="purple"
        title="Teleprompter"
        target="#target-teleprompter"
        placement={isTablet ? 'top' : 'right'}
        description="🎬 Need help remembering what to say on your video vibe check? Write a script, and a teleprompter will appear on your screen for seamless recording!"
        width={219}
      />
    )

    const upload = (
      <TutorialPopper
        key={3}
        color="yellow"
        title="Upload Your Video"
        target="#target-upload"
        placement={isTablet || isMobile ? 'bottom' : 'left'}
        description="Upload your pre-recorded videos here."
        width={isTablet ? 170 : 224}
      />
    )

    return isMobile
      ? [upload, sampleVideo, teleprompter]
      : [sampleVideo, teleprompter, upload]
  }, [openTutorial, isTablet, isMobile])

  return (
    <SpotlightTour
      Spotlight={Spotlight}
      open={openTutorial}
      onClose={handleDoneTutorial}
      Guides={renderGuides}
      stepByStep={isMobile}
    >
      <div className={Style.introRecordingScreen}>
        <div className="d-none">
          <input
            type="file"
            ref={recordResultRef}
            accept={'video/*'}
            onChange={(e) => {
              if (!e.target.files?.[0]) return
              handleCompletedRecord(e.target.files?.[0])

              e.target.value = ''
            }}
          />
          <input
            type="file"
            ref={backgroundImageAudioRef}
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0]
              if (file) {
                _recordingService.setPlaceholder({ file })
              }
              e.target.value = ''
            }}
          />
        </div>

        {openSample && isMobile && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              zIndex: 99999999
            }}
          >
            <MobileSamplePlayers onClose={() => setOpenSample(false)}/>
          </div>
        )}

        <div className={clsx(Style.recorderContainer, { [Style.recorded]: !!video })}>
          <div
            className={clsx(Style.floatOnVideo, {
              'd-none': video || !teleprompter.text
            })}
          >
            <Teleprompter
              recordingService={_recordingService}
              play={recording}
              delay={3000}
              height="154px"
            />

          </div>

          {/* Action buttons ========================================================================================== */}
          <ActionMenu
            recordingService={_recordingService}
            onOpenSample={onOpenSample}
            onUploadButtonClick={eventHandler(ETrackingEvent.RECORDING_BTN_REC_UPLOAD, () => recordResultRef.current?.click?.())}
            show={!video && !recording}
          />

          {video && (
            <ThumbnailPicker
              onSliderChanged={handleChangeThumbnailSlider}
              formatThumbnailSliderValue={formatThumbnailSliderValue}
              generatingThumbnail={generatingThumbnail}
              thumbnailUrl={thumbnailUrl}
              onContinueClick={handleSubmit}
              onStartOverClick={handleStartOver}
              isMobile={!hasSpace}
              width={space}
              url={video}
            />
          )}
          {/* End action buttons ========================================================================================== */}

          <div className={Style.recorderMain} ref={ref}>
            {!video && !openTutorial && (
              <RecorderVideo
                onStartRecording={() => setRecording(true)}
                onStop={handleCompletedRecord}
                onSubmit={handleSubmit}
                onStartOver={handleStartOver}
              />
            )}

            {video && (
              <>
                <VideoPlayer
                  isPlay
                  hideCC
                  hideStartEnd
                  image={audioBgSrc}
                  url={video}
                  duration={_recordingService.recordingDuration}
                  className={Style.zIndex1001}
                  ref={playerRef}
                />

                {hasSpace && (
                  <Button
                    className={Style.buttonStartOver}
                    onClick={handleStartOver}
                  >
                    <IconVideoAdd className="svg-color-neutral-theme-50"/>
                    Start Over
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </SpotlightTour>
  )
}
