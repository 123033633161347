import { IFeedbackModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class FeedbackApi {
  static readonly _prefix = '/feedback'

  static create(payload: IFeedbackModel): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${FeedbackApi._prefix}`, payload)
  }
}
