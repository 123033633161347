import { Menu, MenuItem, MenuProps, alpha, styled, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconArrowDown, IconCheckmarkRegular } from 'src/icons'
import Style from './style.module.scss'

const StyledMenu = styled((props: MenuProps & { width?: number }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))(({ theme, width }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    padding: '12px 12px 12px 8px',
    // minWidth: 180,
    width: width ? `${width}px` : 'auto',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      '0px 40px 80px -12px rgba(37, 38, 46, 0.1)',
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      },
      position: 'initial'
    }
  }
}))

interface IOption {
  label: string
  value: string
  isChecked: boolean
}

interface IProps {
  id?: string
  options: IOption[]
  className?: string
  mobileLabel?: string
  handleClickItem: (option: IOption) => void
  disabled?: boolean
  wrapperClassName?: string
}

const style = { flex: 1 }

export const FilterDropdown: FC<IProps> = ({ id, options, handleClickItem, className, mobileLabel, disabled, wrapperClassName }) => {
  const isMax840 = useMediaQuery('screen and (max-width: 840px)')
  const isMax700 = useMediaQuery('screen and (max-width: 700px)')
  const isMax550 = useMediaQuery('screen and (max-width: 550px)')
  const recentElWidthRef = useRef<number | undefined>(undefined)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnClickItem = useCallback((option: IOption) => {
    handleClickItem(option)
    handleClose()
  }, [handleClickItem])

  const [currentOption, setCurrentOption] = useState({
    label: '',
    value: '',
    isChecked: false
  })

  useEffect(() => {
    const currentOption: any = options?.find(option => option.isChecked)
    setCurrentOption(currentOption)
  }, [options])

  const buttonWidth = useMemo(() => {
    if (anchorEl?.offsetWidth) {
      let width = anchorEl.offsetWidth
      if (width < 140) {
        width = 140
      }
      recentElWidthRef.current = width
    }

    return recentElWidthRef.current
  }, [anchorEl])

  const buttonComponent = useMemo(() => {
    if (isMax550) {
      return <p className={Style.FilterDropdownBtnLabel}>{mobileLabel}</p>
    } else if (isMax700) {
      return <p className={Style.FilterDropdownBtnLabel}>{currentOption?.label}</p>
    } else if (isMax840) {
      return <p className={Style.FilterDropdownBtnLabel}>{mobileLabel}</p>
    } else {
      return <p className={Style.FilterDropdownBtnLabel}>{currentOption?.label}</p>
    }
  }, [currentOption?.label, isMax550, isMax700, isMax840, mobileLabel])

  return (
    <div id={id} style={style} className={clsx(disabled && Style.disableWrapper, wrapperClassName)}>
      <button
        className={clsx([Style.FilterDropdownBtn, className])}
        onClick={handleClick}
      >
        <div className={Style.FilterDropdownBtnContainer}>
          {buttonComponent}
          <IconArrowDown/>
        </div>
      </button>

      <StyledMenu
        id="filter-menu"
        MenuListProps={{
          'aria-labelledby': 'filter-button'
        }}
        width={buttonWidth}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={Style.Menu}
      >
        {options.map((option, index) => (
          <MenuItem onClick={() => handleOnClickItem(option)} disableRipple className={Style.MenuItem} key={index}>
            {option.label}
            {option?.isChecked && <IconCheckmarkRegular/>}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
