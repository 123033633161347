export enum ESettingKeys {
  APP_VIBE_FLOW = 'appVibeFlow',
  APP_ENABLE_SUBTITLE = 'appEnableSubtitle',
  VERSION = 'version',
  VIBE_CHECK_TUTORIAL = 'vibeCheckTutorial'
}

export enum ESettingTabKey {
  INFORMATION = 'information',
  NOTIFICATIONS = 'notifications'
}
