import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChevronFilled: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = '#595959' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.33471 11.7657C6.02229 11.4533 6.02229 10.9467 6.33471 10.6343L8.96902 8L6.33471 5.36568C6.02229 5.05327 6.02229 4.54673 6.33471 4.23431C6.64712 3.9219 7.15366 3.9219 7.46608 4.23431L10.6661 7.43431C10.9785 7.74673 10.9785 8.25327 10.6661 8.56569L7.46608 11.7657C7.15366 12.0781 6.64712 12.0781 6.33471 11.7657Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
