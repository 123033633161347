import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconFile: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 24, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.41517 2.86009C8.98588 2.28937 9.75994 1.96875 10.567 1.96875C11.3742 1.96875 12.1482 2.28937 12.7189 2.86009C13.2896 3.4308 13.6103 4.20486 13.6103 5.01197C13.6103 5.81908 13.2896 6.59314 12.7189 7.16385L8.82868 11.0541C8.49606 11.3867 8.04494 11.5736 7.57455 11.5736C7.10416 11.5736 6.65303 11.3867 6.32042 11.0541C5.9878 10.7215 5.80094 10.2704 5.80094 9.79997C5.80094 9.32958 5.9878 8.87846 6.32042 8.54584L10.2107 4.65559C10.4075 4.45876 10.7266 4.45876 10.9234 4.65559C11.1203 4.85241 11.1203 5.17153 10.9234 5.36835L7.03318 9.2586C6.8896 9.40218 6.80894 9.59692 6.80894 9.79997C6.80894 10.003 6.8896 10.1978 7.03318 10.3413C7.17676 10.4849 7.3715 10.5656 7.57455 10.5656C7.7776 10.5656 7.97234 10.4849 8.11592 10.3413L12.0062 6.45109C12.3878 6.06941 12.6023 5.55174 12.6023 5.01197C12.6023 4.4722 12.3878 3.95453 12.0062 3.57285C11.6245 3.19117 11.1068 2.97675 10.567 2.97675C10.0273 2.97675 9.50961 3.19117 9.12793 3.57285L5.23768 7.4631C4.61791 8.08288 4.26972 8.92348 4.26972 9.79997C4.26972 10.6765 4.61791 11.5171 5.23768 12.1368C5.85746 12.7566 6.69805 13.1048 7.57455 13.1048C8.45104 13.1048 9.29164 12.7566 9.91142 12.1368L13.8017 8.24659C13.9985 8.04976 14.3176 8.04976 14.5144 8.24659C14.7113 8.44341 14.7113 8.76253 14.5144 8.95935L10.6242 12.8496C9.81537 13.6584 8.71838 14.1128 7.57455 14.1128C6.43072 14.1128 5.33373 13.6584 4.52492 12.8496C3.7161 12.0408 3.26172 10.9438 3.26172 9.79997C3.26172 8.65614 3.7161 7.55915 4.52492 6.75034L8.41517 2.86009Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
