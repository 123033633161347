import { Snackbar, Typography } from '@mui/material'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useDidMountEffect, useUnsubscribe } from 'src/hooks'
import { IconClose, IconMessageError, IconMessageInfo, IconMessageSuccess, IconMessageWarning } from 'src/icons'
import { EMessage, IMessage, SnackbarService } from 'src/services'
import Style from './style.module.scss'

export const SnackbarContainer: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [openMessage, setOpenMessage] = useState<IMessage>()
  const [messages, setMessages] = useState<IMessage[]>([])

  const icons: { [key in EMessage]: ReactNode } = {
    [EMessage.NONE]: null,
    [EMessage.SUCCESS]: <IconMessageSuccess className="fx-shrink-0 w-6 h-6"/>,
    [EMessage.ERROR]: <IconMessageError className="fx-shrink-0 w-6 h-6"/>,
    [EMessage.WARNING]: <IconMessageWarning className="fx-shrink-0 w-6 h-6"/>,
    [EMessage.INFO]: <IconMessageInfo className="fx-shrink-0 w-6 h-6"/>
  }

  const colors: { [key in EMessage]: string } = useMemo(() => ({
    [EMessage.NONE]: 'var(--color-primary-400)',
    [EMessage.SUCCESS]: 'var(--color-positive-500)',
    [EMessage.ERROR]: 'var(--color-negative-500)',
    [EMessage.WARNING]: 'var(--color-orange-400)',
    [EMessage.INFO]: 'var(--color-purple-300)'
  }), [])

  useDidMountEffect(() => {
    const hasClosedMessages = messages.some(message => !message.open)
    if (hasClosedMessages) {
      setMessages(prev => prev.filter(message => message.open))
    }

    const _openMessage = messages.find(message => message.open)
    if (_openMessage) {
      setOpenMessage(() => _openMessage)
    }
  }, [messages])

  useEffect(() => {
    SnackbarService.message$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(message => setMessages(prev => [...prev, message]))
  }, [unsubscribe$])

  const handleClose = (
    reason?: 'primaryClicked' | 'secondaryClicked' | 'timeout' | 'clickaway' | 'escapeKeyDown',
    id?: IMessage['id']
  ) => {
    if (reason && ['clickaway', 'escapeKeyDown'].includes(reason)) {
      return
    }

    const message = id && messages.find(item => item.id === id)
    if (message) {
      message.open = false
      setMessages(prev => [...prev])

      if (reason === 'primaryClicked') {
        message.onClick?.(message)
      }

      if (reason === 'secondaryClicked') {
        message.onClickSecondary?.(message)
      }
    }
  }

  return (
    <>
      {openMessage && (
        <Snackbar
          key={openMessage.id}
          open={openMessage.open}
          anchorOrigin={openMessage.position}
          autoHideDuration={openMessage.duration}
          onClose={(e, reason) => handleClose(reason, openMessage.id)}
          sx={{
            '& .MuiSnackbarContent-root, .MuiSnackbarContent-message, .MuiSnackbarContent-action': {
              width: '100%',
              padding: 0,
              borderRadius: '8px'
            }
          }}
          message={(
            <div className={Style.snackbar}>
              <div className="flex gap-2 fx-ai-center">
                {icons[openMessage.severity]}
                <Typography
                  component="div"
                  variant="body2-medium"
                  sx={{
                    wordBreak: 'break-word'
                  }}
                >
                  {openMessage.content}
                </Typography>
              </div>
              <div className="flex fx-ai-center">
                {openMessage.secondaryText?.trim() && (
                  <Typography
                    variant="body2-bold"
                    color="var(--color-neutral-theme-300)"
                    px={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleClose('secondaryClicked', openMessage.id)}
                  >
                    {openMessage.secondaryText}
                  </Typography>
                )}

                {openMessage.actionText?.trim() && (
                  <Typography
                    variant="body2-bold"
                    color={colors[openMessage.severity]}
                    sx={{ cursor: 'pointer' }}
                    px={1}
                    onClick={() => handleClose('primaryClicked', openMessage.id)}
                  >
                    {openMessage.actionText}
                  </Typography>
                )}

                <IconClose className="svg-neutral-white h-4 w-4 px-3px fx-shrink-0 pointer" onClick={() => handleClose(undefined, openMessage.id)}/>
              </div>
            </div>
          )}
        />
      )}
    </>
  )
}
