export enum EAuthView {
  EMAIL_REGISTER = 'EMAIL_REGISTER',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  EMAIL_VERIFIED_SUCCESS = 'EMAIL_VERIFIED_SUCCESS',
  PASSWORD = 'PASSWORD',
  ROLE = 'ROLE',
  INFORMATION = 'INFORMATION',
  SUCCESS = 'SUCCESS',
  NAME = 'NAME'
}
