import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconTalentViewMore: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 111 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="111" height="113" viewBox="0 0 111 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.1703 11.8844C27.1703 11.8844 26.9403 27.6644 33.8903 28.5744C40.8403 29.4844 44.4103 19.5544 44.5103 15.2644C44.6103 10.9744 45.0603 8.66437 44.5103 8.61437C43.9603 8.56437 39.7703 11.7444 37.0503 10.5344C34.3303 9.32437 33.7203 4.98438 33.7203 4.98438C33.7203 4.98438 32.6003 7.31437 30.3503 8.33437C28.1003 9.35437 27.1703 9.71438 27.1703 9.71438V11.8844Z" fill="#AFF6C5"/>
        <path d="M52.96 48.8242C59.0351 48.8242 63.96 43.8994 63.96 37.8242C63.96 31.7491 59.0351 26.8242 52.96 26.8242C46.8848 26.8242 41.96 31.7491 41.96 37.8242C41.96 43.8994 46.8848 48.8242 52.96 48.8242Z" fill="#AFF6C5"/>
        <path d="M23.6202 8.79395C22.5502 17.0339 24.6102 25.5539 32.4602 29.6139C33.5402 30.1739 35.0802 28.3239 33.7802 27.6439C26.5902 23.9239 25.0102 15.8939 25.9702 8.47395C26.1602 7.03395 23.7802 7.59395 23.6202 8.79395Z" fill="black"/>
        <path d="M33.82 29.8346C43.07 26.0546 44.81 16.1946 43.9 7.20461C43.77 5.88461 41.46 6.83461 41.58 8.04461C42.34 15.5646 41.38 24.6446 33.39 27.9046C32.07 28.4446 32.29 30.4546 33.81 29.8346H33.82Z" fill="black"/>
        <path d="M24.12 9.70519C28.91 12.0852 32.81 7.4352 34.68 3.6052C35.36 2.2152 32.99 2.2952 32.46 3.3852C31.17 6.0352 28.82 9.4252 25.44 7.7352C24.35 7.1952 22.81 9.0452 24.12 9.70519Z" fill="black"/>
        <path d="M32.5602 3.77401C32.8502 9.58401 39.2702 11.714 43.5102 8.13401C44.7602 7.07401 43.0502 5.99401 41.9602 6.91401C39.0902 9.34401 35.0802 6.92401 34.9102 3.46401C34.8402 2.00401 32.5002 2.57401 32.5602 3.78401V3.77401Z" fill="black"/>
        <path d="M56.7702 54.3247C53.7602 57.8947 56.0902 60.9047 58.2902 64.3347C62.7002 71.2147 63.7902 79.4947 61.6902 87.3447C61.2902 88.8547 63.5902 88.7747 63.9102 87.5647C65.2102 82.6947 65.5102 77.7547 64.4602 72.7947C64.0002 70.6347 63.2802 68.5047 62.3502 66.4947C61.1902 64.0047 55.9702 58.3147 58.3202 55.5347C59.3602 54.2947 57.7102 53.1947 56.7702 54.3147V54.3247Z" fill="black"/>
        <path d="M58.4203 55.6751C60.9603 51.9651 65.9503 62.5751 66.5203 63.7851C68.3303 67.6151 69.7203 71.6251 71.1203 75.6251C71.5703 76.9151 73.8403 75.9351 73.4403 74.7851C72.1603 71.1251 63.5203 44.6351 56.4703 54.9351C55.5503 56.2851 57.6803 56.7651 58.4203 55.6751Z" fill="black"/>
        <path d="M72.29 74.1853C74.34 73.6253 76.4 75.3953 76.73 77.3353C76.96 78.6653 79.25 77.7053 79.05 76.4953C78.48 73.1653 74.99 71.4053 71.87 72.2553C70.47 72.6353 70.72 74.6053 72.29 74.1853Z" fill="black"/>
        <path d="M77.5202 76.0548C79.9002 75.4848 82.1902 77.2748 82.4502 79.6548C82.6002 80.9748 84.9002 80.0248 84.7702 78.8148C84.4102 75.4948 80.9102 73.2148 77.7102 73.9748C77.1402 74.1148 76.4802 74.5148 76.4402 75.1748C76.4102 75.7448 76.9502 76.1848 77.5202 76.0548Z" fill="black"/>
        <path d="M83.4401 78.2639C88.7801 77.2039 90.2601 83.1039 90.1401 86.9939C90.0601 89.7439 89.5101 92.5039 89.3101 95.2439C88.9501 100.324 89.1801 105.314 90.1801 110.304C90.4501 111.634 92.7401 110.664 92.5001 109.464C91.5901 104.944 91.3301 100.404 91.6001 95.8039C91.8201 91.9839 92.8301 88.0439 92.5001 84.2139C92.0801 79.4139 88.8101 75.1639 83.6301 76.1839C82.3501 76.4339 81.8401 78.5739 83.4401 78.2639Z" fill="black"/>
        <path d="M61.6102 87.2055C61.6102 87.2055 55.5102 80.4455 54.7402 79.6055C52.8902 77.5855 50.8302 75.5655 48.2302 74.5455C45.4802 73.4655 41.7902 73.9855 42.4702 77.8755C42.9402 80.5555 45.8802 83.5855 47.2102 85.9555C48.9302 89.0055 50.5302 92.1255 52.3902 95.0955C54.0102 97.6655 55.8102 100.115 58.0002 102.235C60.8502 105.005 65.0002 106.875 65.8502 111.115C66.1202 112.445 68.4102 111.475 68.1702 110.275C67.0502 104.705 60.8402 102.155 57.4802 98.0055C53.3902 92.9555 50.8502 86.8855 47.4402 81.3955C47.2102 81.0255 45.0502 78.2855 45.1102 77.8355C45.5402 74.7355 49.0802 77.6955 50.0602 78.4755C52.6002 80.4755 54.4702 83.2155 56.6302 85.5855C58.4102 87.5355 61.2502 90.9755 63.5702 87.9655C64.5702 86.6755 62.4102 86.1955 61.6202 87.2255L61.6102 87.2055Z" fill="black"/>
        <path d="M50.8803 27.885C45.2603 29.755 41.3003 35.235 42.5503 41.295C43.8003 47.355 50.4403 50.005 56.1703 48.295C61.9003 46.585 65.6703 40.985 64.2403 35.165C62.6703 28.765 56.2303 26.085 50.2403 27.725C48.8403 28.105 49.0903 30.075 50.6603 29.655C62.0203 26.555 66.9303 43.885 55.3103 46.435C43.6903 48.985 40.8803 33.285 51.3003 29.805C52.6703 29.345 52.4303 27.365 50.8803 27.875V27.885Z" fill="black"/>
        <path d="M30.28 28.2048C29.8 49.1048 30.24 69.9948 31.58 90.8548C31.66 92.1648 33.97 91.2248 33.9 90.0148C32.57 69.3348 32.16 48.6148 32.63 27.8948C32.66 26.4348 30.31 26.9948 30.28 28.2148V28.2048Z" fill="black"/>
        <path d="M31.6203 91.3443C32.7503 93.8643 35.7503 94.1043 38.2103 94.2543C42.7503 94.5243 47.3603 94.2343 51.9003 94.1843C53.1103 94.1743 54.2203 92.0743 52.6803 92.0943C49.4103 92.1343 46.1503 92.1843 42.8803 92.2043C40.1903 92.2243 35.0403 92.9243 33.7403 90.0243C33.2303 88.8843 31.0903 90.1743 31.6203 91.3443Z" fill="black"/>
        <path d="M43.1903 18.9748C53.0003 18.5948 62.8103 18.4648 72.6303 18.5548C73.8503 18.5648 74.9603 16.4748 73.4103 16.4648C63.4003 16.3648 53.3903 16.5048 43.3803 16.8948C42.0503 16.9448 41.5703 19.0348 43.1903 18.9748Z" fill="black"/>
        <path d="M69.2402 41.9847C69.1702 46.0547 69.1002 50.1147 69.0402 54.1847C69.0202 55.6547 71.3702 55.0847 71.3902 53.8647C71.4602 49.7947 71.5302 45.7347 71.5902 41.6647C71.6102 40.1947 69.2602 40.7647 69.2402 41.9847Z" fill="black"/>
        <path d="M70.02 55.4946L89.07 55.9046C90.29 55.9346 91.4 53.8446 89.85 53.8146L70.8 53.4046C69.58 53.3746 68.47 55.4646 70.02 55.4946Z" fill="black"/>
        <path d="M91.1 54.4754L91.12 42.8854C91.12 41.4154 88.78 41.9854 88.77 43.2054L88.75 54.7954C88.75 56.2654 91.09 55.6954 91.1 54.4754Z" fill="black"/>
        <path d="M69.9802 42.7443C72.8902 44.8943 75.9502 46.8143 79.1402 48.5243C80.2102 49.0943 81.7502 47.2543 80.4602 46.5543C77.4202 44.9243 74.5302 43.0843 71.7502 41.0343C70.7602 40.3043 68.8902 41.9443 69.9802 42.7443Z" fill="black"/>
        <path d="M80.5202 48.5955C83.8702 47.0655 87.1202 45.3555 90.2702 43.4455C91.6102 42.6355 90.5902 41.0055 89.2502 41.8155C86.2902 43.6055 83.2502 45.2255 80.1002 46.6655C78.8102 47.2555 79.0302 49.2755 80.5202 48.5955Z" fill="black"/>
        <path d="M70.2202 43.0242C76.6402 43.2242 83.0602 43.4342 89.4802 43.6342C90.7002 43.6742 91.8102 41.5942 90.2602 41.5442C83.8402 41.3442 77.4202 41.1342 71.0002 40.9342C69.7802 40.8942 68.6702 42.9742 70.2202 43.0242Z" fill="black"/>
        <path d="M74.61 55.2648C74.82 61.5348 74.8 67.8048 74.55 74.0848C74.49 75.5448 76.85 74.9848 76.9 73.7648C77.15 67.4948 77.17 61.2248 76.96 54.9448C76.91 53.4748 74.57 54.0548 74.61 55.2648Z" fill="black"/>
        <path d="M76.5403 41.0351C76.8003 35.2251 76.9403 29.4051 76.8903 23.5851C76.8703 20.8551 77.4103 17.4251 74.1603 16.6451C73.0003 16.3651 71.4603 18.2751 72.8403 18.6151C75.8103 19.3251 74.5503 28.1851 74.5303 30.3451C74.4803 34.0251 74.3603 37.6951 74.1903 41.3651C74.1203 42.8251 76.4803 42.2551 76.5403 41.0451V41.0351Z" fill="black"/>
        <path d="M41.26 58.7755C45.92 58.3655 50.56 58.3255 55.23 58.6355C56.46 58.7155 57.56 56.6455 56.01 56.5455C51.15 56.2255 46.31 56.2755 41.45 56.6955C40.13 56.8155 39.63 58.9155 41.26 58.7755Z" fill="black"/>
        <path d="M40.8003 66.7054C46.7503 66.6254 52.6903 66.5354 58.6403 66.4554C59.8503 66.4354 60.9603 64.3454 59.4203 64.3654C53.4703 64.4454 47.5303 64.5354 41.5803 64.6154C40.3703 64.6354 39.2603 66.7254 40.8003 66.7054Z" fill="black"/>
      </svg>
    </IconWrapper>
  )
}
