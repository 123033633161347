import { AxiosRequestConfig } from 'axios'
import {
  IConversationModel,
  IMessageModel,
  IPaginationQuery,
  TAxiosResponseData,
  TAxiosResponsePagination
} from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class MessageApi {
  static readonly _prefix = '/chat/messages'

  static paginate(params: IPaginationQuery & {
    conversationId: IConversationModel['id']
  }, config?: AxiosRequestConfig): TAxiosResponsePagination<IMessageModel> {
    return axiosHiringApi.get(`${MessageApi._prefix}`, { ...config, params })
  }

  static create(
    payload: Pick<IMessageModel, 'conversationId' | 'content'> & {
      contentType?: string
      filename?: string
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IMessageModel> {
    return axiosHiringApi.post(`${MessageApi._prefix}`, payload, config)
  }
}
