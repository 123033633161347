import { FC } from 'react'
import Style from './style.module.scss'

interface IProps {
  onClick?: () => void
}

export const StartRecordBtn: FC<IProps> = ({ onClick }) => {
  return (
    <button className={Style.RecordBtn} onClick={onClick}>
      <div className={Style.RecordBtn}>
        <div className={Style.RecordBtnBackground}/>
      </div>
    </button>
  )
}
