import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconUser: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#636363' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0017 4.2751C8.01344 4.2751 6.40167 5.88687 6.40167 7.8751C6.40167 9.86332 8.01344 11.4751 10.0017 11.4751C11.9899 11.4751 13.6017 9.86332 13.6017 7.8751C13.6017 5.88687 11.9899 4.2751 10.0017 4.2751ZM12.7232 11.9505C14.0368 11.0715 14.9017 9.57431 14.9017 7.8751C14.9017 5.1689 12.7079 2.9751 10.0017 2.9751C7.29548 2.9751 5.10167 5.1689 5.10167 7.8751C5.10167 9.57431 5.96659 11.0715 7.28019 11.9505C5.48899 12.6023 4.0114 13.8814 3.06409 15.5183C2.88428 15.829 2.99038 16.2266 3.30109 16.4064C3.61179 16.5862 4.00944 16.4801 4.18925 16.1694C5.36764 14.1332 7.49723 12.7751 10.0017 12.7751C12.5061 12.7751 14.6357 14.1332 15.8141 16.1694C15.9939 16.4801 16.3915 16.5862 16.7023 16.4064C17.013 16.2266 17.1191 15.829 16.9393 15.5183C15.9919 13.8814 14.5144 12.6023 12.7232 11.9505Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
