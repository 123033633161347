import { TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class PaymentApi {
  static readonly _prefix = '/payments'

  static get(): TAxiosResponseData<{ clientSecret: string }> {
    return axiosHiringApi.get(this._prefix)
  }

  static create(payload: {
    items: [
      {
        credits: number
        qty?: number
      }
    ]
  }): TAxiosResponseData<{ clientSecret: string }> {
    return axiosHiringApi.post(this._prefix, payload)
  }
}
