import clsx from 'clsx'
import { FC, ForwardedRef, useMemo } from 'react'
import { abbreviateFullName } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  fullName?: string
  avatarUrl?: string
  size?: number
  className?: string
  forwardedRef?: ForwardedRef<HTMLDivElement>
}

export const Avatar: FC<IProps> = ({
  size = 44,
  fullName = '',
  avatarUrl,
  className,
  forwardedRef
}) => {
  const abbreviation = useMemo(() => {
    return abbreviateFullName(fullName)
  }, [fullName])

  const avatarStyles = {
    width: size,
    height: size,
    fontSize: size / 2
  }

  return avatarUrl
    ? (
      <div
        className={clsx(Style.avatar, className)}
        style={avatarStyles}
        ref={forwardedRef}
      >
        <img
          src={avatarUrl}
          alt="profile-pic"
          style={{ width: '100%', height: '100%', borderRadius: '50%' }}
        />
      </div>
    )
    : (
      <div
        className={clsx(Style.avatar, className, 'ff-primary')}
        style={avatarStyles}
        ref={forwardedRef}
      >
        {abbreviation}
      </div>
    )
}
