import {
  ChangeEvent,
  ComponentProps,
  FC,
  useCallback,
  useRef
} from 'react'
import { useAsRef } from 'src/hooks'

interface IProps {
  inputProps?: Omit<ComponentProps<'input'>, 'type' | 'className' | 'onChange'>
  onChoose?: (files: FileList) => void
}

export const FilePicker: FC<Omit<ComponentProps<'div'>, keyof IProps> & IProps> = ({
  inputProps,
  onChoose,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChooseRef = useAsRef(onChoose)
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChooseRef.current && e.target.files) {
      onChooseRef.current(e.target.files)
    }
    e.target.value = ''
  }, [onChooseRef])

  return (
    <div
      {...props}
      onClick={e => {
        inputRef.current?.click()
        props.onClick?.(e)
      }}
    >
      <input
        {...inputProps}
        ref={inputRef}
        type="file"
        className="d-none"
        onChange={onChange}
      />
      {props.children}
    </div>
  )
}
