import { IRouterOption } from 'src/router'
import { IntrosDetail } from './components/detail'
import { Intros } from './components/list'
import { EIntrosRoutes } from './routes.enum'

export const IntrosRoutes: IRouterOption[] = [
  {
    path: '/intros',
    component: Intros,
    name: EIntrosRoutes.INTROS,
    exact: true,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/intros/:id',
    component: IntrosDetail,
    name: EIntrosRoutes.INTROS_DETAIL,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
