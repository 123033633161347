import { FC } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { IconSetting } from 'src/icons'
import { ModalAuthentication } from 'src/partials'
import { ERoutes } from 'src/router'
import { DialogService } from 'src/services/dialog.service'
import { AuthModule } from 'src/store'
import { NavItem } from '../nav-item'

interface IProps {
  expanded: boolean
}

export const SettingsNav: FC<IProps> = ({ expanded: sidebarExpanded }) => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  return (
    <nav className="fx-column px-6 relative">
      <NavItem
        mini={!sidebarExpanded}
        route={isAuthenticated ? ERoutes.SETTING : undefined}
        icon={<IconSetting className="svg-current-color" width={16}/>}
        label="Settings"
        onClick={() => {
          if (!isAuthenticated) {
            DialogService.open(ModalAuthentication, { stage: 'sign-in' })
          }
        }}
      />
    </nav>
  )
}
