import { Box, StyledEngineProvider, Typography } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { PromoCodeApi } from 'src/api'
import { Button, Divider, Input, Upgrade, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { OverlayService } from 'src/services'
import Styles from './style.module.scss'

export const BuyMoreCredit: FC = () => {
  const { eventHandler } = useAnalytic('')
  const [promoCode, setPromoCode] = useState<string>('')
  const [isPromoCodeError, setIsPromoCodeError] = useState<string>('')
  const [isApplyingPromo, setIsApplyingPromo] = useState(false)

  const handleBuyMoreUnit = () => {
    OverlayService.setOverlay({
      open: true,
      content: <Upgrade/>
    })
  }

  const handleChangePromoCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value)
    setIsPromoCodeError('')
  }, [setPromoCode, setIsPromoCodeError])

  const applyPromo = useCallback(async () => {
    try {
      setIsApplyingPromo(true)
      await PromoCodeApi.apply(promoCode)
    } catch (error) {
      setIsPromoCodeError('Promo code is invalid or expired.')
    } finally {
      setIsApplyingPromo(false)
    }
  }, [promoCode])

  return (
    <StyledEngineProvider injectFirst>
      <div className={Styles.STContainer}>
        <Button
          className="w-100-p"
          style={{ height: 56 }}
          onClick={eventHandler(ETrackingEvent.BTN_STORE, handleBuyMoreUnit)}
        >
          Buy More Credits
        </Button>
        <Box style={{ width: '80%' }}>
          <Divider/>
        </Box>
        <Box>
          <Typography className={Styles.STPromoLabel}>Enter Promo Code</Typography>
          <Input
            className={Styles.STPromoInput}
            value={promoCode}
            placeholder="Ex: 348HDF"
            disabled={isApplyingPromo}
            onChange={handleChangePromoCode}
            suffix={(
              <Button
                className={Styles.STPromoButton}
                variant="secondary"
                disabled={isApplyingPromo}
                onClick={applyPromo}
              >
                Apply
              </Button>
            )}
          />
          <Typography className={Styles.STPromoInputError} sx={{ visibility: isPromoCodeError ? '' : 'hidden' }}>{isPromoCodeError}</Typography>
        </Box>
      </div>
    </StyledEngineProvider>
  )
}
