import clsx from 'clsx'
import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IconChat } from 'src/icons'
import { AvatarDefault, Img } from 'src/images'
import { IConversationModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { MessengerService } from 'src/services/messenger.service'
import Style from './style.module.scss'

interface IProps {
  isRedirect?: boolean
  conversation: IConversationModel
}

export const ConversationCard: FC<IProps> = ({ isRedirect, conversation }) => {
  const history = useHistory()
  const activeConversation = useBehaviorMapper(MessengerService.conversation$)

  const isActivated = activeConversation?.id === conversation.id

  const isUnread = useMemo(
    () => MessengerService.isUnread(conversation),
    [conversation]
  )
  const avatar = useMemo(
    () => MessengerService.getConversationAvatar(conversation),
    [conversation]
  )
  const previewContent = useMemo(
    () => conversation?.lastMessage?.content?.replace(/<[^>]+>/g, ''),
    [conversation?.lastMessage?.content]
  )
  const fullname = useMemo(
    () => MessengerService.getConversationUserFullName(conversation),
    [conversation]
  )
  const username = useMemo(
    () => MessengerService.getConversationUserName(conversation),
    [conversation]
  )
  const userInfo = useMemo(
    () => MessengerService.getConversationUser(conversation),
    [conversation]
  )

  const handleReadMessage = useCallback(() => {
    if (isRedirect) {
      history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId: userInfo?.user?.lookupId }))
      return
    }

    MessengerService.setConversation(conversation)
  }, [conversation, history, isRedirect, userInfo?.user?.lookupId])

  return (
    <div
      className={clsx(Style.conversationCard, 'pointer', { [Style.activate]: isActivated })}
      onClick={handleReadMessage}
    >
      <div className="fx gap-4">
        <Img
          src={avatar?.startsWith('http') ? avatar : AvatarDefault}
          width={70}
          height={70}
          style={{ objectFit: 'cover' }}
          className="round-4"
          alt="Avatar"
        />
        <div className="fx fx-column fx-jc-space-between">
          <div className="three-dot-1 fs-16 fw-500 lh-20 txt-black-01">{fullname}</div>
          {username && <div className="fs-12 txt-grey-01 three-dot-1">@{username}</div>}
          <div style={{ maxWidth: '120px' }} className="fs-12 lh-16 txt-grey-01 oneline-text">
            {previewContent}
          </div>
        </div>
      </div>
      <div className="fx fx-column fx-jc-space-between fx-ai-end w-min-fit">
        {isUnread
          ? (
            <div className="fx fx-center gap-2 round-8 px-4 py-2 bg-red-02">
              <IconChat size={20} className={Style.iconChat}/>
            </div>
          )
          : (
            <Button
              size={32}
              variant="icon"
            >
              <IconChat size={20}/>
            </Button>
          )}
        <div className="fs-12 lh-16 txt-grey-01">
          {moment(conversation?.lastMessage?.updatedAt).format('dddd HH:mm')}
        </div>
      </div>
    </div>
  )
}
