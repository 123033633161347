import qs from 'qs'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { VibeApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { BreadcrumbHeading, Modal, Spinner } from 'src/components'
import { useBehaviorMapper, useDebouncedLoading } from 'src/hooks'
import { IReactionModel, IUserModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { TalentCard } from 'src/partials/card/talent-card'
import { TalentCardBlur } from 'src/partials/card/talent-card-blur'
import { ModalTalentDetail } from 'src/partials/modal-talent-detail'
import { LoadMoreService } from 'src/services'
import { AuthModule } from 'src/store'

export const VibeTalent: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [keyword, setKeyword] = useState<string>()

  const _loadMoreService = useMemo(() => new LoadMoreService<IUserModel & { reaction?: IReactionModel; createdReaction?: IReactionModel }>({
    axiosInstance: axiosHiringApi,
    endpoint: VibeApi._vibeCandidate + '?' + qs.stringify({ keyword })
  }), [keyword, isAuthenticated]) // refresh on keyword/isAuthenticated change

  const { ref: refLoadMore, inView } = useInView({ threshold: 0 })
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const items = useBehaviorMapper(_loadMoreService.items$)

  useEffect(() => {
    if (inView && !loading && _loadMoreService.hasMore) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, inView, loading])

  const debounceLoading = useDebouncedLoading(!items.length && loading)

  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const canPreviousProps = useMemo(() => !currentIndex, [currentIndex])
  const canNextProps = useMemo(() => currentIndex + 1 === items?.length, [currentIndex, items?.length])
  const user = useMemo(() => items?.[currentIndex], [currentIndex, items])

  const onDetail = (index: number) => {
    setCurrentIndex(index)
    setOpen(true)
  }

  const onPrevious = useCallback(() => setCurrentIndex(prev => prev - 1), [])

  const onNext = useCallback(() => {
    setCurrentIndex(prev => prev + 1)

    if (currentIndex === items.length - 2 && isAuthenticated) {
      _loadMoreService.loadMore()
    }
  }, [_loadMoreService, currentIndex, isAuthenticated, items.length])

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>Vibe Talent</BreadcrumbHeading>
      </Breadcrumbs>

      {debounceLoading && (
        <div className="fx-1 fx fx-center">
          <Spinner/>
        </div>
      )}

      <section
        className="p-8 fx fx-wrap-wrap gap-4"
        style={{
          height: debounceLoading ? '0' : undefined,
          opacity: debounceLoading ? 0 : undefined
        }}
      >

        {items?.map((item, index) => (
          <TalentCard
            key={item.id}
            talent={item}
            onChange={(values) => item.id && _loadMoreService.updateOneItem(item.id, values)}
            onDetail={() => onDetail(index)}
          />
        ))}

        {!isAuthenticated && !!items.length && (
          <TalentCardBlur talent={items[0]}/>
        )}

        <div ref={refLoadMore}/>
      </section>

      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          closeBtn
          contentClass="fx-1 fx fx-center round-0-p"
        >
          <ModalTalentDetail
            lookupId={user?.lookupId}
            pfv={user?.pfv}
            onPrevious={onPrevious}
            onNext={onNext}
            canPreviousProps={canPreviousProps}
            canNextProps={canNextProps}
          />
        </Modal>
      )}
    </>
  )
}
