import clsx from 'clsx'
import { ChangeEvent, ComponentProps, CSSProperties, ForwardedRef, forwardRef, useRef } from 'react'
import Style from './style.module.scss'

enum EVariant {
  LABEL = 'label',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary', // TODO: not implemented
  ICON = 'icon',
  POSITIVE = 'positive'
}

interface IAttributes {
  /**
   * @deprecated
   * Not implemented
   */
  event?: () => void

  /**
   * @deprecated
   * Field input only for file selection
   */
  field?: 'button' | 'input'
  inputProps?: Pick<ComponentProps<'input'>, 'accept' | 'onChange'>

  loading?: boolean // TODO: not implemented
  variant?: `${EVariant}`
  /**
   * Gradient state, only for label buttons
   */
  gradient?: boolean
  /**
   * Green state, apply to primary and icon buttons
   */
  green?: boolean

  /**
   * Red state, apply to icon buttons
   */
  red?: boolean

  /**
   * Red state, apply to icon buttons
   */
  black?: boolean
  /**
   * Emphasis state, apply to secondary and icon buttons
   */
  emphasis?: boolean
  /**
   * Active state, only for icon buttons
   */
  active?: boolean
  /**
   * Only for icon buttons
   */
  size?: CSSProperties['width'] | CSSProperties['height']
}

interface IProps extends Omit<ComponentProps<'button'>, keyof IAttributes>, IAttributes {}

export const Button = forwardRef<HTMLButtonElement, IProps>(
  function _({
    event,
    field,
    inputProps,

    loading,
    variant = EVariant.PRIMARY,
    active,
    gradient,
    green,
    red,
    black,
    emphasis,
    size = 32,
    className,
    children,
    ...props
  }, ref: ForwardedRef<HTMLButtonElement>) {
    const inputRef = useRef<HTMLInputElement | null>(null)

    return (
      <button
        {...props}
        ref={ref}
        disabled={props.disabled || loading}
        onClick={e => {
          if (field === 'input') {
            event?.()
            return inputRef.current?.click()
          }
          return props.onClick?.(e)
        }}
        className={clsx(
          'fx fx-center pointer border-none outline-none trans-ease-in-out',
          'gap-1', // TODO: temporary fix for all existing buttons, remove in the future
          [
            Style.btn,
            {
              [Style.gradient]: gradient,
              [Style.green]: green,
              [Style.red]: red,
              [Style.black]: black,
              [Style.emphasis]: emphasis,
              [Style.active]: active,
              [Style.label]: variant === EVariant.LABEL,
              [Style.primary]: variant === EVariant.PRIMARY,
              [Style.secondary]: variant === EVariant.SECONDARY,
              [Style.positive]: variant === EVariant.POSITIVE,
              [Style.icon]: variant === EVariant.ICON
            },
            className
          ]
        )}
        style={{
          ...props.style,
          ...(variant === EVariant.ICON && size
            ? {
              width: size,
              height: size
            }
            : {})
        }}
      >
        {children}

        {field === 'input' && (
          <input
            type="file"
            ref={inputRef}
            accept={inputProps?.accept || 'video/*;image/*'}
            style={{ display: 'none' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              inputProps?.onChange?.(e)
              e.target.value = ''
            }}
          />
        )}
      </button>
    )
  }
)
