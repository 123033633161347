import { Tooltip } from 'src/components'
import { IconInfo } from 'src/icons'
import Style from './style.module.scss'

export const TooltipPostToKnowme = () => {
  return (
    <Tooltip
      title={<span>Selecting this box will post your job to the <span className="color-cyan-500">KnowMe Jobs Marketplace</span> exposing your job to a wider pool of job seekers.</span>}
      placement="top"
      arrow
    >
      <IconInfo
        className={Style.iconPostToKnowMe}
      />
    </Tooltip>

  )
}
