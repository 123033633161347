import { useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { BreadcrumbHeading, Tabs, useAnalytic } from 'src/components'
import { Overlay } from 'src/components/overlay'
import { ECampaignStatus, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useBehaviorMapper, useQueryParams, useUnsubscribe } from 'src/hooks'
import { ICampaignModelExtended, ITab } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { PopupTourService } from 'src/services/tour/popup.service'
import { ShareJobTourService } from 'src/services/tour/share-job-tour.service'
import { setLayoutAside, setLayoutLoading, setLayoutPageTitle } from 'src/store/actions'
import { getLayoutIsLoading } from 'src/store/selectors'
import { CampaignDetail } from '../detailV2/view-detail'
import { Sandbox } from '../sandbox'
import { CardJob } from './components/card-job'
import { CreateJobButton } from './components/create-job-button'
import { EmptyJob } from './components/empty-job'
import Style from './style.module.scss'

const campaignStatus = {
  [ECampaignStatus.PUBLISHED]: 'Published',
  [ECampaignStatus.DRAFT]: 'Drafts'
  // [ECampaignStatus.UNVERIFIED]: 'Unverified'
}

export const tabs: ITab[] = [
  { title: campaignStatus[ECampaignStatus.PUBLISHED] },
  { title: campaignStatus[ECampaignStatus.DRAFT] }
  // { title: campaignStatus[ECampaignStatus.UNVERIFIED] }
]

export type Counts = {
  count: number
  campaign: number
  campaignDraft: number
}

export const MyJobs = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [_tab, setTab] = useState<number>(0)
  const isLayoutLoading = useAppSelector(getLayoutIsLoading)
  const shareJobTourEnabled = useBehaviorMapper(ShareJobTourService.enableTour$)
  const { eventHandler } = useAnalytic('')

  const isMax505 = useMediaQuery('(max-width:505px)')
  const { tab } = useQueryParams()
  const [data, setData] = useState<ICampaignModelExtended[]>([])
  const unsubscribe$ = useUnsubscribe()
  const [counts, setCounts] = useState<Counts>()
  const [currentCampaign, setCurrentCampaign] = useState<ICampaignModelExtended>()

  const handleBannerVibeTalent = useCallback(() => {
    eventHandler(ETrackingEvent.BTN_VIBE_TALENT)()
    history.push('/vibes')
  }, [eventHandler, history])

  const fetchCount = useCallback(() => {
    dispatch(setLayoutLoading(true))

    from(CampaignApi.count())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => dispatch(setLayoutLoading(false)))
      )
      .subscribe((resCount) => {
        setCounts({
          count: resCount.data.count || 0,
          campaign: resCount.data.campaign || 0,
          campaignDraft: resCount.data.campaignDraft || 0
        })
      })
  }, [dispatch, unsubscribe$])

  useEffect(() => {
    fetchCount()
  }, [fetchCount])

  useEffect(() => {
    if (!counts?.count) {
      return
    }

    const query = {
      status: (+(tab || 0)) === 0 ? ECampaignStatus.PUBLISHED : ECampaignStatus.DRAFT
    }

    dispatch(setLayoutLoading(true))
    from(CampaignApi.paginate(query))
      .pipe(finalize(() => dispatch(setLayoutLoading(false))))
      .subscribe(res => setData(res.data))
  }, [tab, dispatch, counts?.count])

  const handleSetTab = useCallback((newTab: number) => {
    setTab(newTab)

    if (+(tab || 0) !== newTab) {
      history.replace({ search: `?tab=${newTab}` })
    }
  }, [history, tab])

  useEffect(() => {
    if (tab) {
      setTab(+tab)
    }
  }, [tab])

  useEffect(() => {
    if ((counts?.campaign || 0) > 0 && counts?.campaignDraft === 0) {
      history.replace({ search: `?tab=${0}` })
      return
    }

    if ((counts?.campaignDraft || 0) > 0 && counts?.campaign === 0) {
      history.replace({ search: `?tab=${1}` })
    }
  }, [counts, history])

  const removeJobFromList = useCallback((data: { id?: number; draftId?: number }) => {
    PopupTourService.fetchMissionIfShowing()
    fetchCount()

    if (data.draftId) {
      setData(prev => prev.filter(item => item.draftId !== data.draftId))
      return
    }

    if (data.id) {
      setData(prev => prev.filter(item => item.id !== data.id))
    }
  }, [fetchCount])

  useEffect(() => {
    dispatch(setLayoutPageTitle('My Jobs'))
    dispatch(setLayoutAside(true))
  }, [dispatch])

  const tabsWithCount: ITab[] = tabs.map((tab) => {
    if (!counts) return tab
    const mapper = {
      [campaignStatus[ECampaignStatus.PUBLISHED]]: `${tab.title} (${counts.campaign})`,
      [campaignStatus[ECampaignStatus.DRAFT]]: `${tab.title} (${counts.campaignDraft})`
      // [campaignStatus[ECampaignStatus.UNVERIFIED]]: `${tab.title} (0)` // todo: need API support
    }
    return {
      ...tab,
      title: mapper[tab.title]
    }
  })

  const handleOpenCampaign = useCallback((item: ICampaignModelExtended) => setCurrentCampaign(item), [])

  const onClose = useCallback(() => setCurrentCampaign(undefined), [])

  if (shareJobTourEnabled) {
    return (
      <>
        <Breadcrumbs>
          <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
        </Breadcrumbs>

        <Sandbox/>
      </>
    )
  }

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      <div className={Style.container}>
        {!!counts?.count && (
          <div className={Style.headerMyJob}>
            <span className={Style.title}>
              My Jobs <span className={Style.count}>({counts.count})</span>
            </span>
            <CreateJobButton onlyIcon={isMax505} text="Create Job Posting" disabled={isLayoutLoading}/>
          </div>
        )}

        {(counts?.count || 0) > 0 && (
          <div className={Style.header}>
            <div className={Style.header_tab}>
              <Tabs tab={_tab} tabs={tabsWithCount} setTab={handleSetTab}/>
            </div>
          </div>
        )}

        {data.length === 0
          ? (
            <EmptyJob
              tab={_tab || 0}
              withBanner={counts?.count === 0}
              onBannerClick={handleBannerVibeTalent}
            />
          )
          : (
            <div className={Style.content}>
              {data.map((item) => (
                <CardJob
                  key={(item.draftId ? 'draft-' : 'posted-') + item.id}
                  campaign={item}
                  onAfterDelete={removeJobFromList}
                  handleOpenCampaign={handleOpenCampaign}
                />
              ))}
            </div>
          )}

        {currentCampaign && (
          <Overlay>
            <CampaignDetail campaign={currentCampaign} onClose={onClose}/>
          </Overlay>
        )}
      </div>
    </>
  )
}
