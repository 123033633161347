import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconEdit: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.0914 3.48748C13.4555 3.33666 13.8457 3.25903 14.2398 3.25903C14.634 3.25903 15.0242 3.33666 15.3883 3.48748C15.7524 3.6383 16.0833 3.85936 16.362 4.13804C16.6406 4.41672 16.8617 4.74757 17.0125 5.11168C17.1633 5.4758 17.241 5.86605 17.241 6.26016C17.241 6.65428 17.1633 7.04453 17.0125 7.40865C16.8617 7.77276 16.6406 8.1036 16.362 8.38228L15.5318 9.21248C15.5314 9.21283 15.5311 9.21318 15.5307 9.21354C15.5304 9.21389 15.53 9.21424 15.5297 9.21459L7.63384 17.1104C7.51194 17.2323 7.34661 17.3008 7.17422 17.3008L3.84922 17.3008C3.49023 17.3008 3.19922 17.0098 3.19922 16.6508L3.19922 13.3258C3.19922 13.1534 3.2677 12.9881 3.3896 12.8662L12.1177 4.13804C12.3964 3.85936 12.7272 3.6383 13.0914 3.48748ZM11.7461 6.34815L4.49922 13.595L4.49922 16.0008L6.90498 16.0008L14.1519 8.75391L11.7461 6.34815ZM15.0711 7.83468L12.6653 5.42891L13.037 5.05728C13.1949 4.89932 13.3825 4.77401 13.5889 4.68852C13.7952 4.60303 14.0164 4.55903 14.2398 4.55903C14.4632 4.55903 14.6844 4.60303 14.8908 4.68852C15.0972 4.77401 15.2848 4.89932 15.4427 5.05728C15.6007 5.21525 15.726 5.40278 15.8115 5.60917C15.897 5.81556 15.941 6.03677 15.941 6.26016C15.941 6.48356 15.897 6.70477 15.8115 6.91116C15.726 7.11755 15.6007 7.30508 15.4427 7.46304L15.0711 7.83468Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
