import { Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import { AnalyticApi, AuthApi } from 'src/api'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useAppDispatch, useQueryParams } from 'src/hooks'
import { IconPaperPlane } from 'src/icons'
import { SnackbarService } from 'src/services'
import { setLayoutLoading } from 'src/store/actions'
import { getApiErrorMessage } from 'src/utils'
import Style from './style.module.scss'

export const VerifyEmail: FC<{ email: string }> = ({ email }) => {
  const theme = useTheme()
  const { inviteToken } = useQueryParams()
  const dispatch = useAppDispatch()
  const { eventHandler } = useAnalytic('signup_verify_email')

  const resendEmail = async () => {
    try {
      dispatch(setLayoutLoading(true))
      await AuthApi.signUpRequest({
        email,
        inviteToken: inviteToken as string,
        sessionId: AnalyticApi._session
      })
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }

  return (
    <div className={Style.SectionSendMail}>
      <IconPaperPlane size={120}/>
      <Typography className={Style.mainText}>Verify Your Email</Typography>
      <Typography className={Style.subText} variant="body1-medium">
        We sent you a verification link to <span className={clsx('body1-bold', Style.email)}>{email}</span>.
        <br/>
        Click the link to verify your email.
      </Typography>
      <Typography
        variant="body2-regular"
        className={Style.resendEmail}
        color={theme.colors['--color-neutral-theme-300']}
      >
        Didn’t receive the email?
        <br/>
        Check your spam folder or
        <Typography
          component="a"
          variant="body2-regular"
          sx={{ cursor: 'pointer' }}
          onClick={eventHandler(ETrackingEvent.BTN_RESEND_VERIFY_EMAIL, resendEmail)}
        >
          {' '} click to resend.
        </Typography>
      </Typography>
    </div>
  )
}
