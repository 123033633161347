import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconPaperPlane: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 120 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7046_97202)">
          <path d="M88.34 48.9692C80.6001 58.1992 72.87 67.4292 65.1301 76.6592C64.18 77.7892 63.18 79.3592 63.9501 80.6192C64.2101 81.0592 64.65 81.3592 65.08 81.6492C67.07 83.0092 69.0601 84.3692 71.0601 85.7292C64.4501 91.9392 57.85 98.1592 51.24 104.369C51.89 103.759 43.58 84.8692 42.85 82.7492C41.52 78.8492 40.2 74.9292 39.12 70.9492C38.04 66.9692 38.17 66.4092 42.15 64.8192C57.26 58.7692 72.75 53.5592 88.35 48.9492L88.34 48.9692Z" fill="#AFF6C5"/>
          <path d="M64.8297 22.4999L67.5497 30.9199C67.9697 32.2199 70.2397 31.2399 69.8697 30.0799L67.1497 21.6599C66.7297 20.3599 64.4597 21.3399 64.8297 22.4999Z" fill="black"/>
          <path d="M75.4502 15.9896C75.4002 20.4296 75.3402 24.8596 75.2902 29.2996C75.2702 30.7696 77.6202 30.1996 77.6402 28.9796C77.6902 24.5396 77.7502 20.1096 77.8002 15.6696C77.8202 14.1996 75.4702 14.7696 75.4502 15.9896Z" fill="black"/>
          <path d="M82.9204 28.2594L87.7304 18.8294C88.4304 17.4594 86.0604 17.5294 85.5104 18.6094L80.7004 28.0394C80.0004 29.4094 82.3704 29.3394 82.9204 28.2594Z" fill="black"/>
          <path d="M6.35056 55.6996C41.6206 45.8796 77.4106 38.0496 113.561 32.1996C114.861 31.9896 115.361 29.8596 113.751 30.1196C77.4006 35.9996 41.4106 43.8996 5.93056 53.7696C4.53056 54.1596 4.78056 56.1296 6.35056 55.6996Z" fill="black"/>
          <path d="M112.93 31.3495C105.03 52.9395 96.1301 74.1395 86.2601 94.8995C85.6001 96.2995 87.9601 96.2195 88.4801 95.1195C98.3501 74.3595 107.25 53.1595 115.15 31.5695C115.69 30.0995 113.36 30.1895 112.93 31.3495Z" fill="black"/>
          <path d="M6.16962 55.8601C16.2196 59.9401 26.2796 64.0301 36.3296 68.1101C37.4496 68.5701 38.9996 66.6901 37.6496 66.1401C27.5996 62.0601 17.5396 57.9701 7.48961 53.8901C6.36961 53.4301 4.81962 55.3101 6.16962 55.8601Z" fill="black"/>
          <path d="M63.1001 81.8698C70.3201 87.1298 77.9801 91.6298 86.0801 95.3898C87.1801 95.8998 88.7301 94.0398 87.4001 93.4198C79.4501 89.7298 71.9601 85.3198 64.8801 80.1598C63.8901 79.4398 62.0101 81.0798 63.1101 81.8698H63.1001Z" fill="black"/>
          <path d="M37.4402 68.1495C54.6802 61.8995 71.9302 55.6395 89.1702 49.3895C90.5202 48.8995 90.2902 46.9095 88.7502 47.4595C71.5102 53.7095 54.2602 59.9695 37.0202 66.2195C35.6702 66.7095 35.9002 68.6995 37.4402 68.1495Z" fill="black"/>
          <path d="M64.4302 80.8401L90.2102 48.6801C91.2302 47.4101 89.0702 46.9301 88.2602 47.9401C79.6702 58.6601 71.0702 69.3801 62.4802 80.1001C61.4602 81.3701 63.6202 81.8501 64.4302 80.8401Z" fill="black"/>
          <path d="M36.0496 67.7796C40.5596 79.3596 45.0696 90.9396 49.5796 102.52C50.0796 103.79 52.3396 102.81 51.8996 101.68C47.3896 90.0996 42.8796 78.5196 38.3696 66.9396C37.8696 65.6696 35.6096 66.6496 36.0496 67.7796Z" fill="black"/>
          <path d="M52.1099 102.4L71.6599 86.1497C72.9199 85.0997 71.1999 84.0197 70.1098 84.9297L50.5599 101.18C49.2999 102.23 51.0199 103.31 52.1099 102.4Z" fill="black"/>
          <path d="M52.2002 102.05L64.3802 80.9498C65.1902 79.5398 63.1002 79.0598 62.4302 80.2098C58.3702 87.2398 54.3102 94.2798 50.2502 101.31C49.4402 102.72 51.5302 103.2 52.2002 102.05Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_7046_97202">
            <rect width="120" height="120" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  )
}
