import { IRouterOption } from 'src/router'
import { Likes } from './components/list'
import { ELikesRoutes } from './routes.enum'

export const LikesRoutes: IRouterOption[] = [
  {
    path: '/likes',
    component: Likes,
    name: ELikesRoutes.LIKES,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
  // {
  //   path: '/likes/:id',
  //   component: LikesDetail,
  //   name: ELikesRoutes.LIKES_DETAIL,
  //   exact: true,
  //   meta: {
  //     requireAuth: true
  //   }
  // }
]
