import { useState } from 'react'
import { IconEye, IconEyeInvisible } from 'src/icons'

interface IProps {
  onClick?: (show: boolean) => void
}

export const TogglePassword = ({ onClick }: IProps) => {
  const [show, setShow] = useState(false)

  const handleClick = () => {
    const newValue = !show
    setShow(newValue)
    onClick?.(newValue)
  }

  return (
    <div onClick={handleClick} className="fx fx-row gap-1 fx-ai-center pointer">
      {show
        ? <IconEye color="currentColor" className="txt-neutral-300"/>
        : <IconEyeInvisible color="currentColor" className="txt-neutral-300"/>}
    </div>
  )
}
