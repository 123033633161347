import { FC } from 'react'
import { Button } from 'src/components'
import Style from './style.module.scss'

interface IProps {
  onStartOver: () => void
  onKeepThis: () => void
}

export const StartOver: FC<IProps> = ({
  onKeepThis,
  onStartOver
}) => {
  return (
    <div className={Style.StartOver}>
      <p className={Style.Title}>Start Over</p>
      <p className={Style.Label}>
        Do you wish to record again?
      </p>
      <div className={Style.BtnActions}>
        <Button
          onClick={onStartOver}
          variant="secondary"
          className={Style.StartOverBtn}
        >
          Yes
        </Button>
        <Button
          onClick={onKeepThis}
          className={Style.SecondaryBtn}
        >
          No
        </Button>
      </div>
    </div>
  )
}
