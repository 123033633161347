import { isCompanyEmail } from 'company-email-validator'
import { object, string } from 'yup'

const BYPASS_COMPANY_EMAILS = [
  'ssspecialist805@gmail.com',
  'jmuncy4@icloud.com'
]

export const validCompanyEmail = (email: string): boolean => {
  return BYPASS_COMPANY_EMAILS.includes(email) || isCompanyEmail(email)
}

export const workEmailSchema = object().shape({
  email: string().required('This field is required!').test(
    'error_email_domain',
    'Your email must be a work email',
    (email) => email ? validCompanyEmail(email) : false
  )
})
