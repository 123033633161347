import { isEmpty } from 'lodash'
import { FC, useMemo } from 'react'
import { MotivatorBadge, Tooltip } from 'src/components'
import { MotivatorsBg, MotivatorsBgLight } from 'src/constants/motivators'
import { EMotivator } from 'src/enums'
import { IconExplanation } from 'src/icons'
import { ICampaignModel } from 'src/interfaces'
import { isValidMotivators, mapMotivatorToExplanation } from 'src/utils/motivators.utils'
import { CampaignVideoCard } from '../card/campaign-video-card'
import { CampaignInfo } from './campaign-info'
import Style from './style.module.scss'

interface IProps {
  campaign: ICampaignModel
  // updateCampaign?: (values: { [K in keyof ICampaignModel]?: ICampaignModel[K] }) => void
}

export const CampaignDetail: FC<IProps> = ({ campaign }) => {
  const video = campaign.uploadVideo ?? campaign.author?.pfv

  const motivators = useMemo(() => {
    return Object.keys(campaign.motivator ?? {})
      .filter(isValidMotivators)
      .sort((key1, key2) => (campaign.motivator?.[key2] as number) - (campaign.motivator?.[key1] as number))
      .slice(0, 3)
  }, [campaign.motivator])

  const jobBriefDescription = useMemo(() => {
    if (!campaign.briefDescription) {
      return campaign.briefDescription
    }

    if (!campaign.motivator || isEmpty(campaign.motivator)) {
      return campaign.briefDescription
    }

    const motivatorsHasQuote = Object.keys(campaign.motivator).filter(key => key.startsWith('quote')).map(key => {
      const motivator = key.replace('quote', '') as EMotivator
      return {
        key: motivator,
        values: Array.ensure(campaign.motivator?.[key] as unknown as string ?? []).slice(1)
      }
    })

    let jd = campaign.briefDescription

    motivatorsHasQuote.forEach(({ key, values }) => {
      values.forEach(quote => {
        try {
          const qTrimDot = quote.replace(/\.$/g, '')
          const pattern = new RegExp(qTrimDot.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, '\\$&'), 'ig')
          jd = jd?.replace(pattern, `<span style="background-color: ${MotivatorsBg[key]}">${qTrimDot}</span>`)
        } catch (error) {
          console.error(error)
        }
      })
    })

    return jd
  }, [campaign])

  return (
    <div className={Style.campaignDetail}>
      <div className="fx gap-8 bg-neutral-white round-4">
        {video && (
          <div style={{ width: 272 }}>
            <CampaignVideoCard campaign={campaign}/>
          </div>
        )}

        <CampaignInfo
          loading
          campaign={campaign}
          motivators={motivators}
        />
      </div>

      {!!motivators?.length && (
        <div className="fx fx-column gap-5 bg-neutral-white round-4">
          <div className="fs-16 fw-600 lh-20 txt-black-01 fx fx-ai-center gap-1">
            <span>Ideal Applicant’s Motivators</span>
            <Tooltip placement="bottom" title="Powered by KNOWME&trade; Insights">
              <IconExplanation/>
            </Tooltip>
          </div>
          <div className="fx fx-wrap-wrap gap-4">
            {motivators?.map(item => (
              <div key={item} className="w-full p-4 fx fx-column gap-3 round-4" style={{ maxWidth: 234, backgroundColor: MotivatorsBgLight[item] }}>
                <div className="w-fit">
                  <MotivatorBadge key={item} small value={item}/>
                </div>
                <div className="fs-12 lh-18 txt-black-01">
                  {mapMotivatorToExplanation(item, campaign).join('\n')}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {jobBriefDescription && (
        <div className="fx fx-column gap-5 bg-neutral-white round-4">
          <div className="fs-16 fw-600 lh-20 txt-black-01">About The Job</div>
          <div>
            <pre className={Style.jobDescription} dangerouslySetInnerHTML={{ __html: String(jobBriefDescription).replaceAll('&amp;nbsp;', '&nbsp;') }}/>
          </div>
        </div>
      )}
    </div>
  )
}
