import { AxiosRequestConfig } from 'axios'
import { IPaginationQuery, IReactionModel, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class ReactionApi {
  static readonly _prefix = '/reactions'

  static paginate(
    params: IPaginationQuery & {
      opposite?: boolean // query Reaction of other users to current user if this is true
      reaction?: Array<Required<IReactionModel>['reaction']>
      reactionableType?: Required<IReactionModel>['reactionableType'] // Default to 'User'
    },
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<IReactionModel> {
    return axiosHiringApi.get(ReactionApi._prefix, { ...config, params })
  }

  /**
   * Delete a reaction
   */
  static destroy(
    id: Required<IReactionModel>['id'],
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<IReactionModel> {
    return axiosHiringApi.delete(`${ReactionApi._prefix}/${id}`, config)
  }
}
