import { AxiosRequestConfig } from 'axios'
import { IConversationModel, IReactionModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class DashboardApi {
  static readonly _prefix = '/dashboard'

  static dashboard(config?: AxiosRequestConfig): TAxiosResponseData<{
    countUnReads?: number
    countMatches?: number
    countLikedMe?: number
    countILiked?: number
    unReads?: IConversationModel[]
    matches?: Array<IReactionModel>
    iLiked?: Array<IReactionModel>
    likedMe?: Array<IReactionModel>
  }> {
    return axiosHiringApi.get(DashboardApi._prefix, config)
  }
}
