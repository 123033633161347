import clsx from 'clsx'
import { ComponentProps, FC, ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { browserHistory, generate } from 'src/router'
import Style from './style.module.scss'

interface IProps extends Pick<ComponentProps<'a'>, 'onClick'> {
  mini?: boolean
  icon?: ReactNode
  label?: ReactNode
  route?: string
  className?: string
}

export const NavItem: FC<IProps> = (props) => {
  const url = useMemo(() => props.route && generate(props.route), [props.route])

  return (
    <NavLink
      to={url || '#'}
      className={clsx(Style.navItem, 'pointer fx fx-ai-center gap-3 py-3 oneline-text', {
        'px-4': !props.mini,
        'px-3': props.mini
      }, props.className)}
      activeClassName={props.route ? Style.active : ''}
      onClick={(e?: any) => {
        e?.preventDefault()
        props.route && browserHistory.push(generate(props.route))
        props.onClick?.(e)
      }}
    >
      {props.icon && (
        <div className="fx fx-center fx-shrink-0" style={{ height: 20, width: 20 }}>
          {props.icon}
        </div>
      )}
      {!props.mini && props.label}
    </NavLink>
  )
}
