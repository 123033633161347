import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconEye: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2493 5.69258C8.87003 5.69258 7.52181 6.10212 6.37556 6.86929C5.23427 7.63314 4.34439 8.71729 3.81764 9.98528C3.81495 9.99523 3.81495 10.0057 3.81764 10.0157C4.34439 11.2837 5.23427 12.3678 6.37556 13.1317C7.52181 13.8988 8.87003 14.3084 10.2493 14.3084C11.6286 14.3084 12.9768 13.8988 14.1231 13.1317C15.2644 12.3678 16.1542 11.2837 16.681 10.0157C16.6837 10.0057 16.6837 9.99523 16.681 9.98528C16.1542 8.71729 15.2644 7.63314 14.1231 6.86929C12.9768 6.10212 11.6286 5.69258 10.2493 5.69258ZM5.65249 5.78893C7.01269 4.87857 8.61258 4.39258 10.2493 4.39258C11.8861 4.39258 13.4859 4.87857 14.8461 5.78893C16.2063 6.6993 17.2656 7.99304 17.8896 9.50615C17.8926 9.51331 17.8954 9.52052 17.8981 9.52778C18.0113 9.83274 18.0113 10.1682 17.8981 10.4732C17.8954 10.4804 17.8926 10.4876 17.8896 10.4948C17.2656 12.0079 16.2063 13.3017 14.8461 14.212C13.4859 15.1224 11.8861 15.6084 10.2493 15.6084C8.61258 15.6084 7.01269 15.1224 5.65249 14.212C4.29229 13.3017 3.23303 12.0079 2.609 10.4948C2.60605 10.4876 2.60322 10.4804 2.60053 10.4732C2.48732 10.1682 2.48732 9.83274 2.60053 9.52778C2.60322 9.52052 2.60605 9.51331 2.609 9.50615C3.23303 7.99304 4.29229 6.6993 5.65249 5.78893ZM10.2493 8.52548C9.4347 8.52548 8.77432 9.18585 8.77432 10.0005C8.77432 10.8151 9.4347 11.4755 10.2493 11.4755C11.0639 11.4755 11.7243 10.8151 11.7243 10.0005C11.7243 9.18585 11.0639 8.52548 10.2493 8.52548ZM7.47432 10.0005C7.47432 8.46789 8.71673 7.22547 10.2493 7.22547C11.7819 7.22547 13.0243 8.46789 13.0243 10.0005C13.0243 11.5331 11.7819 12.7755 10.2493 12.7755C8.71673 12.7755 7.47432 11.5331 7.47432 10.0005Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
