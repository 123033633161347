import { BehaviorSubject } from 'rxjs'
import { _Window, WindowService } from 'src/window'

export const PlayerService = new (
  class {
    private playerRef = 0
    genRef() {
      return ++this.playerRef
    }

    readonly playing$ = new BehaviorSubject<number | string | undefined>(undefined)
    get playing() {
      return this.playing$.getValue()
    }

    set playing(refNo) {
      this.playing$.next(refNo)
    }

    readonly muted$ = new BehaviorSubject<boolean>(true)
    get muted() {
      return this.muted$.getValue()
    }

    setMuted(value?: boolean) {
      this.muted$.next(!!value)
    }

    readonly bandwidthEstimate$ = new BehaviorSubject<number>(0)
    get bandwidthEstimate() {
      return this.bandwidthEstimate$.getValue()
    }

    set bandwidthEstimate(refNo) {
      this.bandwidthEstimate$.next(refNo)
    }

    constructor() {
      if (_Window) {
        const onClick = () => {
          this.muted$.next(false)
          window.removeEventListener('click', onClick)
          window.removeEventListener('contextmenu', onClick)
        }
        window.addEventListener('click', onClick)
        window.addEventListener('contextmenu', onClick)

        WindowService.hidden$.subscribe((hidden) => {
          if (hidden) {
            this.playing = undefined
          }
        })
      }
    }
  }
)()
