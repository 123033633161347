import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconMediaSetting: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3018 4.23438C11.194 4.23438 10.2615 4.98427 9.98389 6.00404L3.44764 6.00498L3.36884 6.01005L3.36213 6.01097C3.05391 6.05278 2.81641 6.3168 2.81641 6.63646C2.81641 6.98509 3.09903 7.2677 3.44766 7.26771L9.9837 7.26817C10.2611 8.28831 11.1937 9.03854 12.3018 9.03854C13.4099 9.03854 14.3426 8.2883 14.62 7.26816L16.5518 7.26793L16.6306 7.26286L16.6374 7.26195C16.9456 7.22014 17.1831 6.95612 17.1831 6.63646C17.1831 6.28784 16.9004 6.00522 16.5518 6.00521L14.62 6.00476C14.3426 4.98462 13.4099 4.23438 12.3018 4.23438ZM13.4414 6.63646C13.4414 6.73672 13.4285 6.83383 13.4043 6.92627L13.377 7.01502C13.2209 7.45845 12.7983 7.77604 12.3018 7.77604C11.8217 7.77604 11.4107 7.4791 11.2429 7.05849L11.2261 7.0136L11.1994 6.92637C11.1752 6.83391 11.1622 6.73677 11.1622 6.63646C11.1622 6.55518 11.1707 6.476 11.1868 6.39971L11.2127 6.29998L11.226 6.25951C11.3817 5.81524 11.8047 5.49688 12.3018 5.49688C12.7814 5.49688 13.1919 5.79309 13.3601 6.21289L13.3916 6.30219L13.4041 6.34611L13.4242 6.43819C13.4355 6.50251 13.4414 6.56876 13.4414 6.63646Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.69766 10.9635C6.58954 10.9635 5.65689 11.7138 5.37953 12.7339L3.44763 12.7342L3.36884 12.7392L3.36213 12.7401C3.05391 12.7819 2.81641 13.046 2.81641 13.3656C2.81641 13.7142 3.09904 13.9969 3.44766 13.9969L5.37953 13.9973C5.65689 15.0175 6.58954 15.7677 7.69766 15.7677C8.80576 15.7677 9.73841 15.0175 10.0158 13.9973L16.5518 13.9971L16.6306 13.992L16.6374 13.9911C16.9456 13.9493 17.1831 13.6853 17.1831 13.3656C17.1831 13.017 16.9004 12.7344 16.5518 12.7344L10.0156 12.7332C9.73798 11.7134 8.80551 10.9635 7.69766 10.9635ZM8.83724 13.3656C8.83724 13.4659 8.82432 13.563 8.80009 13.6554L8.77289 13.7442C8.61677 14.1876 8.19417 14.5052 7.69766 14.5052C7.21755 14.5052 6.80655 14.2083 6.63875 13.7877L6.62198 13.7428L6.59525 13.6555C6.57101 13.5631 6.55807 13.4659 6.55807 13.3656C6.55807 13.2844 6.56656 13.2052 6.58268 13.1289L6.60858 13.0291L6.62186 12.9887C6.77752 12.5444 7.20054 12.226 7.69766 12.226C8.17719 12.226 8.58777 12.5223 8.75596 12.9421L8.78741 13.0314L8.7999 13.0753L8.82007 13.1674C8.83135 13.2317 8.83724 13.2979 8.83724 13.3656Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
