import { _Window } from 'src/window'

export class ClientUtils {
  static onlyClient<T = any>(callback: (() => T)) {
    if (typeof window !== 'undefined') {
      return callback()
    }
  }

  static scrollToTop(smooth = false) {
    _Window?.scrollTo({
      top: 0,
      behavior: (smooth ? 'smooth' : 'instant') as ScrollBehavior
    })
  }
}
