import clsx from 'clsx'
import { FC, ReactElement, useEffect, useState } from 'react'
import Style from './style.module.scss'

interface IProps {
  children: ReactElement
  alwayShow?: boolean
  mini?: boolean
}

export const Control: FC<IProps> = (props) => {
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (props.alwayShow && opacity !== 1) {
      setOpacity(1)
      return
    }

    if (opacity && !props.alwayShow) {
      const timer = setTimeout(() => setOpacity(0), 2000)
      return () => clearTimeout(timer)
    }
  }, [opacity, props.alwayShow])

  return (
    <div
      className={clsx([Style.control, { [Style.Mini]: props.mini }])}
      onMouseMove={() => setOpacity(1)}
    >
      <div
        className={clsx('fw-medium meta txt-neutral-white', Style.controller)}
        style={{ opacity }}
      >
        {props.children}
      </div>
    </div>
  )
}
