import { Chip } from '@mui/material'
import clsx from 'clsx'
import uniqWith from 'lodash/uniqWith'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { CardReactionAndResume, EmptyVideos, FlipCard, FlipCardBack, Share, VideoPlayer, useAnalytic } from 'src/components'
import { CacheProvider } from 'src/components/video-player/cache.context'
import { ECandidateReaction, EOrderLike, EReactionTable, ETrackingEvent } from 'src/enums'
import { useAppDispatch, useAppSelector, useNotificationNewCandidateIds, useNumberOfSkeletons, useQueryParams, useUnsubscribe } from 'src/hooks'
import { ICampaignSubmissionModel, ICandidateModel, IPaginationResponse } from 'src/interfaces'
import { OverlayService, SnackbarService } from 'src/services'
import {
  setLayoutAside,
  setLayoutLoading,
  setLayoutPageTitle
} from 'src/store/actions'
import { getLayoutIsScrollToBottom } from 'src/store/selectors'
import { getReadCommentOnVideoId } from 'src/store/selectors/vibes'
import { ensureArray, getApiErrorMessage, getVideoSource } from 'src/utils'
import { mapReactionToEventTracking } from 'src/utils/analytic.utils'
import { LikesDetail } from '../detail'
import { Empty } from '../empty'
import Style from './style.module.scss'

export const Likes: FC = () => {
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const flippedMapRef = useRef<Map<number, boolean>>(new Map())
  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)
  const readCommentOnVideoId = useAppSelector(getReadCommentOnVideoId)
  const history = useHistory()
  const { eventHandler } = useAnalytic()
  const { detailCandidateId: paramDetailCandidateId } = useQueryParams()
  const numberOfLoadingVideos = useNumberOfSkeletons()
  const newCandidateIds = useNotificationNewCandidateIds('likeList')
  const watchedTimeMapRef = useRef<Map<number, number>>(new Map())
  const likeItemRef = useRef<HTMLDivElement>(null)
  const [detailCandidateId, setDetailCandidateId] = useState<number>()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IPaginationResponse<ICandidateModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })
  const [currOpenedTraitId, setCurrOpenedTraitId] = useState(0)
  // const likeItemHeight = useMemo(() => likeItemRef?.current?.clientHeight || 0, [likeItemRef])

  const changeReaction = useCallback((candidateId: number, newReaction?: string) => {
    const _handleSkip = (rows?: ICandidateModel[]) => {
      const toRemoveRowIndex = rows?.findIndex((row) => row.id === candidateId)
      return rows?.filter((row, index) => index !== toRemoveRowIndex) || []
    }

    const _handleLike = (rows?: ICandidateModel[]) => {
      return rows?.map(item => item.id === candidateId
        ? {
          ...item,
          pfv: {
            ...item.pfv,
            createdReaction: {
              ...item.pfv?.createdReaction,
              reaction: newReaction || item.pfv?.createdReaction?.reaction
            }
          }
        } as ICandidateModel
        : item) || []
    }

    setData(prev => ({
      ...prev,
      rows: newReaction === ECandidateReaction.SKIP ? _handleSkip(prev.rows) : _handleLike(prev.rows)
    }))
  }, [])

  const handleChangeReaction = async (option: ICandidateModel, reaction: ECandidateReaction) => {
    try {
      const eventKey = mapReactionToEventTracking(reaction)
      if (eventKey) {
        eventHandler({
          key: eventKey,
          contextData: { videoId: option.pfv?.id }
        })()
      }

      dispatch(setLayoutLoading(true))
      const payload = {
        reactionableType: EReactionTable.VIDEO,
        reactionableId: option.pfv?.id
      }

      const currentReaction = option.pfv?.createdReaction?.reaction
      changeReaction(option.id, reaction)

      await CandidateApi.action(option.id, reaction, payload)
        .catch((error) => {
          changeReaction(option.id, currentReaction)
          throw error
        })
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }

  const handleSyncLatestCandidateData = useCallback((candidate: ICandidateModel) => {
    changeReaction(candidate.id, candidate.reaction?.reaction)
  }, [changeReaction])

  const handleIntroductionSuccess = useCallback(async (candidateId: number) => {
    setData(prev => ({
      ...prev,
      rows: prev?.rows?.filter(item => item.id !== candidateId)
    }))
  }, [])

  const loadLikes = useCallback((page = 1, limit = 10, orderLike = EOrderLike.MOST_RECENT) => {
    if (page === 1) {
      setData({
        page: 1,
        pages: 1,
        total: 0,
        rows: []
      })
    }

    const promise = CandidateApi.pagination({
      page,
      limit,
      orderLike,
      reaction: [ECandidateReaction.LIKE, ECandidateReaction.SUPER_LIKE, ECandidateReaction.SUPER_NOVA]
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        const rows = data.rows
        // const rows = data.rows?.map(item => ({
        //   ...item,
        //   linkedinWorkingExperiences: LinkedinUtil.checkValidData(item.linkedinWorkingExperiences)
        // }))

        setData(prev => ({
          ...prev,
          page: data.page,
          total: data.total,
          pages: data.pages,
          rows: uniqWith(
            page === 1
              ? rows
              : [...ensureArray(prev.rows), ...ensureArray(rows)],
            (a, b) => a.id === b.id
          )
        }))
      })
  }, [unsubscribe$])

  useEffect(() => {
    if (data.rows?.length) {
      setData((prev) => ({
        ...prev,
        rows: prev.rows?.map((item) => ({
          ...item,
          pfv: {
            ...item.pfv,
            isCommentRead: item.pfv?.isCommentRead || item.pfv?.id === readCommentOnVideoId
          }
        }) as ICandidateModel)
      }))
    }
  }, [readCommentOnVideoId, data.rows?.length])

  const setPageMetaData = useCallback(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutPageTitle('Likes'))
  }, [dispatch])

  useEffect(() => {
    return setPageMetaData()
  }, [setPageMetaData])

  useEffect(() => {
    loadLikes()
  }, [dispatch, loadLikes])

  const loadMorePage = useMemo(
    () => !loading && data.page && data.pages && data.page < data.pages ? data.page + 1 : false,
    [loading, data]
  )
  useEffect(() => {
    if (!detailCandidateId && loadMorePage && isScrollToBottom) {
      loadLikes(loadMorePage)
    }
  }, [detailCandidateId, isScrollToBottom, loadLikes, loadMorePage])

  const playedMapRef = useRef<Map<number, boolean>>(new Map([]))
  const handleWatchedVideo = useCallback(async (videoId: number, playing: boolean) => {
    try {
      if (!playing) {
        return
      }

      if (playedMapRef.current.get(videoId)) {
        return
      }

      playedMapRef.current.set(videoId, true)
      await CandidateApi.play(videoId)
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    }
  }, [playedMapRef])

  useEffect(() => {
    setDetailCandidateId(
      paramDetailCandidateId
        ? +paramDetailCandidateId
        : undefined
    )
  }, [paramDetailCandidateId])

  const [lastLikeOffsetY, setLastLikeOffsetY] = useState<number>()
  const containerRef = useRef<any>()

  const goToDetail = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, id: number, tabId = 0) => {
    setLastLikeOffsetY(containerRef.current.parentElement.scrollTop)
    history.push({ search: `?detailCandidateId=${id}&detailTab=${tabId}` })
  }, [history])

  useEffect(() => {
    if (!detailCandidateId && containerRef.current) {
      containerRef.current.parentElement?.scrollTo(0, lastLikeOffsetY || 0)
    }
  }, [detailCandidateId, lastLikeOffsetY])

  const handleBeforeGoToDetail = useCallback(() => {
    setLastLikeOffsetY(containerRef.current?.parentElement?.scrollTop)
  }, [containerRef])

  const handleBackToList = useCallback((callback?: () => any) => {
    setDetailCandidateId(undefined)
    history.push({ search: '' })
    setPageMetaData()
    callback?.()
  }, [history, setPageMetaData])

  const handleTouch = async (candidateId: number) => {
    try {
      await CandidateApi.touch(candidateId, 'like')
    } catch (error) {
      console.log('error to touch candidate', error)
    }
  }

  const handleOpenShare = (videoId: number, candidateId: number) => {
    OverlayService.setOverlay({
      open: true,
      content: <Share videoId={videoId} candidateId={candidateId}/>
    })
  }

  const handelProgressVideo = (videoId: number, playedSecond: number) => {
    watchedTimeMapRef.current.set(videoId, playedSecond)
  }

  // const handleToggleTraitCollapse = (traitId: number) => {
  //   if (traitId === currOpenedTraitId) {
  //     setCurrOpenedTraitId(0)
  //     return
  //   }

  //   setCurrOpenedTraitId(traitId)
  // }

  const handleFlipButtonClick = (flipFunc: () => void, candidateId: number, videoId?: number) => {
    flipFunc()

    if (videoId) {
      const isFlipped = flippedMapRef.current.get(videoId)
      flippedMapRef.current.set(videoId, !isFlipped)
    }

    eventHandler({
      key: ETrackingEvent.BTN_VIDEO_INFO,
      contextData: { videoId }
    }, () => {
      if (newCandidateIds.includes(candidateId)) {
        handleTouch(candidateId)
      }
    })()
  }

  return !data.rows?.length
    ? loading ? <EmptyVideos quantity={numberOfLoadingVideos} variant="like"/> : <Empty/>
    : (
      <div
        ref={containerRef}
        className={clsx(Style.STContainerWrapper, { [Style.showDetail]: detailCandidateId })}
      >
        {!detailCandidateId && (
          <div className={Style.STContainer}>
            {data.rows?.filter(item => !item.isDeleted).map((item) => {
              // const isSuperNova = item.video?.createdReaction?.reaction === ECandidateReaction.SUPER_NOVA
              // const isSuperLike = item.video?.createdReaction?.reaction === ECandidateReaction.SUPER_LIKE

              return (
                <div key={item.id} className={Style.STLikesItem} {...(item?.pfv?.videoTranscription ? { ref: likeItemRef } : {})}>
                  <FlipCard flipped={item.pfv && flippedMapRef.current.get(item.pfv.id)}>
                    {(flipFunc) => [
                      (
                        <div key={0}>
                          <div className={Style.STVideoWrapper}>
                            <div
                              className={clsx(
                                Style.STVideoContainer,
                                !!item?.pfv?.videoTranscription
                              )}
                            >
                              <div className={Style.STVideo}>
                                <VideoPlayer
                                  id={'video' + item.id}
                                  trackingEvent
                                  onProgress={({ playedSeconds }) => item.pfv && handelProgressVideo(item.pfv.id, playedSeconds)}
                                  playAt={item.pfv && watchedTimeMapRef.current.get(item.pfv.id)}
                                  videoId={item?.pfv?.id}
                                  tracks={item?.pfv?.tracks}
                                  image={item?.pfv?.urlVideoImageThumbnail}
                                  animatedImage={item?.pfv?.urlVideoAnimatedImage}
                                  url={getVideoSource(item?.pfv)}
                                  style={{ borderRadius: '16px 16px 0 0' }}
                                  isStop={currOpenedTraitId === item.pfv?.id}
                                  isMuted={false}
                                  mini
                                  hideStartEnd
                                  author={item?.pfv?.author}
                                  onBeforeGoToDetail={handleBeforeGoToDetail}
                                  mimeType={item?.pfv?.internalSourceMetadata?.mimeType}
                                  onPlayingChange={(playing) => {
                                    item.pfv && handleWatchedVideo(item.pfv.id, playing)
                                    if (newCandidateIds.includes(item.id) && playing) {
                                      handleTouch(item.id)
                                    }

                                    if (playing && currOpenedTraitId === item.pfv?.id) {
                                      setCurrOpenedTraitId(0)
                                    }
                                  }}
                                  action={(
                                    <CardReactionAndResume
                                      item={item as ICampaignSubmissionModel}
                                      newCandidateIds={newCandidateIds}
                                      onTouch={() => handleTouch(item.id)}
                                      onFlip={() => handleFlipButtonClick(flipFunc, item.id, item.pfv?.id)}
                                      onReactionChange={(item, reaction) => handleChangeReaction(item, reaction)}
                                      onShare={handleOpenShare}
                                    />
                                  )}
                                  showSpeed
                                />
                                {newCandidateIds.includes(item.id) && (
                                  <Chip className={Style.STNewButton} label="New"/>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                      (
                        <FlipCardBack
                          key={1}
                          onFlip={() => {
                            flipFunc()
                            if (item.pfv) {
                              flippedMapRef.current.set(item.pfv.id, false)
                            }
                          }}
                          onDetailClick={(e) => goToDetail(e, item.id)}
                          workingExperiences={item.linkedinWorkingExperiences}
                          educations={item.linkedinEducations}
                          video={item.pfv}
                          author={item}
                        />
                      )
                    ]}
                  </FlipCard>
                </div>
              )
            })}
          </div>
        )}

        <CacheProvider data={watchedTimeMapRef.current}>
          {!!detailCandidateId && (
            <LikesDetail
              id={detailCandidateId}
              onBack={handleBackToList}
              onIntroductionSuccess={handleIntroductionSuccess}
              onSync={handleSyncLatestCandidateData}
            />
          )}
        </CacheProvider>
      </div>
    )
}
