import { IRouterOption } from 'src/router'
import { Setting } from './components/setting'
import { ESettingRoutes } from './routes.enum'

export const SettingRoutes: IRouterOption[] = [
  {
    path: '/setting',
    component: Setting,
    name: ESettingRoutes.SETTING,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
