import { AxiosRequestConfig } from 'axios'
import { IConversationModel, IReactionModel, IUserModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class PeopleApi {
  static readonly _prefix = '/people'

  static toggleLike(
    params: {
      id: Required<IUserModel>['id']
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<void | IReactionModel> {
    return axiosHiringApi.put(`${PeopleApi._prefix}/${params.id}/like`, null, config)
  }

  static getConversation(
    lookupId: Required<IUserModel>['lookupId'],
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IConversationModel> {
    return axiosHiringApi.get(`${PeopleApi._prefix}/${lookupId}/conversation`, config)
  }
}
