import { Typography } from '@mui/material'
import { ChangeEventHandler, Dispatch, FC, FormEvent, useCallback, useState } from 'react'
import { AuthApi } from 'src/api'
import { Button, Input, RequiredAsterisk, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { useValidation } from 'src/hooks'
import { Logo } from 'src/images'
import { EAuthView } from 'src/interfaces'
import { setNameSchema } from 'src/validation'
import Style from './style.module.scss'

interface ISetName {
  firstName: string
  lastName: string
}

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

interface IProps {
  handleChangeFormData: (name: string, value: TSignUpPayload[keyof TSignUpPayload]) => void
  setView: Dispatch<EAuthView>
  signUpInfo: TSignUpPayload
  onChangeView: () => void
}

export const SetName: FC<IProps> = ({ handleChangeFormData, onChangeView }) => {
  const [formValues, setFormValues] = useState<ISetName>({ firstName: '', lastName: '' })
  const { errors, validate } = useValidation({
    data: formValues,
    schema: setNameSchema
  })
  const { eventHandler } = useAnalytic('signup_name')
  const [loading, setLoading] = useState(false)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    e.persist()
    const { name, value } = e.target
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [])

  const onNext = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    setLoading(true)
    handleChangeFormData('firstName', formValues.firstName)
    handleChangeFormData('lastName', formValues.lastName)
    onChangeView()
  }, [formValues, handleChangeFormData, onChangeView, validate])

  return (
    <form className={Style.STForm} onSubmit={onNext}>
      <div className={Style.Logo}>
        <img src={Logo} alt="logo"/>
      </div>
      <div className={Style.LoginHeader}>
        <Typography className={Style.Title}>Sign Up to KNOWME<span>TM</span></Typography>
        <Typography align="center" className={Style.Description}>
          Hiring
        </Typography>
      </div>

      <div className={Style.FormInput}>
        <Input
          label={<>First Name<RequiredAsterisk/></>}
          id="firstName"
          type="text"
          name="firstName"
          placeholder="Enter your first name"
          value={formValues.firstName || ''}
          onChange={handleChange}
          onClick={eventHandler(ETrackingEvent.INPUT_NAME)}
          error={errors.getError('firstName')}
          required
        />
        <Input
          label={<>Last Name<RequiredAsterisk/></>}
          id="lastName"
          type="text"
          name="lastName"
          placeholder="Enter your last name"
          value={formValues.lastName || ''}
          onChange={handleChange}
          onClick={eventHandler(ETrackingEvent.INPUT_NAME)}
          error={errors.getError('lastName')}
          required
        />

        <Button
          type="submit"
          className="round-16"
          disabled={errors.hasError() || loading}
          onClick={eventHandler(ETrackingEvent.BTN_PSWD_NEXT)}
        >
          Next
        </Button>
      </div>
    </form>
  )
}
