import { uniqBy } from 'lodash'
import qs from 'qs'
import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { ReactionApi } from 'src/api'
import { axiosHiringApi } from 'src/api/axios'
import { DashboardApi } from 'src/api/dashboard'
import { BreadcrumbHeading, Button, Modal, Spinner } from 'src/components'
import { InputDebounce } from 'src/components/input-debounce'
import { EConnectionTabKey, EReaction } from 'src/enums'
import { useBehaviorMapper, useDebouncedLoading, useUnsubscribeEffect } from 'src/hooks'
import { IconChatFilled, IconSearch, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { IConversationModel, IReactionModel } from 'src/interfaces'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { ECoreRoutes } from 'src/modules/core/routes.enum'
import { ConversationCard } from 'src/partials/card/converstation-card'
import { ReactionTalentCard } from 'src/partials/card/reaction-talent-card'
import { ModalTalentDetail } from 'src/partials/modal-talent-detail'
import { ERoutes, generate } from 'src/router'
import { LoadMoreService, SnackbarService } from 'src/services'

type TModel = 'matches' | 'iLiked' | 'likedMe'

interface IDashboard {
  countUnReads?: number
  countMatches?: number
  countLikedMe?: number
  countILiked?: number
  unReads?: IConversationModel[]
  matches?: IReactionModel[]
  iLiked?: IReactionModel[]
  likedMe?: IReactionModel[]
}

export const Dashboard: FC = () => {
  const history = useHistory()
  const [dashboard, setDashboard] = useState<IDashboard>()
  const [keyword, setKeyword] = useState<string>()
  const [loading, setLoading] = useState(false)
  const debounceLoading = useDebouncedLoading(!dashboard && loading)

  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [reactions, setReactions] = useState<IReactionModel[]>([])
  const [model, setModel] = useState<TModel>('matches')

  const _loadMoreService = useMemo(() => new LoadMoreService<IReactionModel>({
    axiosInstance: axiosHiringApi,
    endpoint: ReactionApi._prefix + '?' + qs.stringify({
      keyword
    })
  }), [keyword])

  const items = useBehaviorMapper(_loadMoreService.items$)
  const reactionsFiltered = useMemo(() => uniqBy([...reactions, ...items], 'id'), [items, reactions])
  const canPreviousProps = useMemo(() => !currentIndex, [currentIndex])
  const canNextProps = useMemo(() => currentIndex + 1 === reactionsFiltered?.length, [currentIndex, reactionsFiltered?.length])
  const user = useMemo(() => reactionsFiltered?.[currentIndex]?.toUser, [currentIndex, reactionsFiltered])

  const loadReactions = useCallback(() => {
    const params = {
      matches: { reaction: [EReaction.SUPER_LIKE, EReaction.SUPER_NOVA] },
      iLiked: { reaction: EReaction.LIKE },
      likedMe: { reaction: EReaction.LIKE, opposite: true }
    }

    _loadMoreService.loadMore(params[model])
  }, [_loadMoreService, model])

  const onDetail = (index: number, reactions: IReactionModel[], model: TModel) => {
    setCurrentIndex(index)
    setReactions(reactions || [])
    setOpen(true)
    setModel(model)
    _loadMoreService.reset()
  }

  const onPrevious = useCallback(() => setCurrentIndex(prev => prev - 1), [])

  const onNext = useCallback(() => {
    setCurrentIndex(prev => prev + 1)

    if (currentIndex === reactionsFiltered.length - 2) {
      loadReactions()
    }
  }, [currentIndex, loadReactions, reactionsFiltered.length])

  const handleUpdateReaction = useCallback((type: TModel, id: number, values: IReactionModel) => {
    const dashboardMapper = dashboard?.[type]?.map(item => {
      if (item.id === id) {
        item = { ...item, ...values }
      }

      return item
    })

    setDashboard(prev => ({
      ...prev,
      [type]: dashboardMapper
    }))
  }, [dashboard, setDashboard])

  useUnsubscribeEffect((unsubscribe$) => {
    setLoading(true)
    from(DashboardApi.dashboard())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          history.push(generate(ECoreRoutes.ERROR_404))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setDashboard(data)
      })
  }, [])

  return (
    <>
      <Breadcrumbs>
        <div className="fx fx-ai-center gap-6">
          <BreadcrumbHeading active>Dashboard</BreadcrumbHeading>

          <InputDebounce
            prefix={<IconSearch size={20}/>}
            style={{ width: 350 }}
            placeholder="Search people..."
            value={keyword}
            onChange={setKeyword}
          />
        </div>
      </Breadcrumbs>

      {debounceLoading
        ? (
          <div className="fx-1 fx fx-center">
            <Spinner/>
          </div>
        )
        : (
          <section className="p-8 fx fx-column gap-12">
            <div>
              <div className="fx fx-ai-center gap-3">
                <Button
                  size={30}
                  red
                  variant="icon"
                  className="round-2 pointer-none"
                >
                  <IconChatFilled size={16}/>
                </Button>

                <span className="fs-32 fw-500 lh-36 txt-black-01">Unreads</span>
                {!!dashboard?.countUnReads && (
                  <Button variant="secondary" onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES))}>View Messages ({dashboard?.countUnReads})</Button>
                )}
              </div>

              {dashboard?.countUnReads
                ? (
                  <div className="fx fx-wrap-wrap gap-4">
                    {dashboard?.unReads?.map(item => <ConversationCard key={item.id} isRedirect conversation={item}/>)}
                  </div>
                )
                : (
                  <div className="fs-14 fw-400 lh-18 txt-black-02 mt-2">
                    No messages yet. You can start a conversation with talent you’ve matched with.
                  </div>
                )}
            </div>

            <div>
              <div className="fx fx-column gap-2">
                <div className="fx fx-ai-center gap-3">
                  <Button
                    size={30}
                    emphasis
                    variant="icon"
                    className="round-2 pointer-none"
                  >
                    <IconThumbsUpDup size={16}/>
                  </Button>
                  <span className="fs-32 fw-500 lh-36 txt-black-01">It’s a Match!</span>

                  {!!dashboard?.countMatches && (
                    <Button
                      variant="secondary"
                      onClick={() => history.push(generate(ERoutes.TALENT_CONNECTIONS, { tab: EConnectionTabKey.MATCHES }))}
                    >
                      View All ({dashboard?.countMatches})
                    </Button>
                  )}
                </div>

                {!!dashboard?.countMatches && (
                  <div className="fs-14 lh-20 txt-black-02">You and the candidate like each other’s vibes. Send a direct message today!</div>
                )}
              </div>

              {dashboard?.countMatches
                ? (
                  <div className="fx fx-wrap-wrap gap-4 mt-5">
                    {dashboard?.matches?.map((item, index) => (
                      <ReactionTalentCard
                        isDetail
                        key={item.id}
                        reaction={item}
                        onChange={(values) => item.id && handleUpdateReaction('matches', item.id, values)}
                        onDetail={() => dashboard?.matches && onDetail(index, dashboard.matches, 'matches')}
                      />
                    ))}
                  </div>
                )
                : (
                  <div className="fs-14 fw-400 lh-18 txt-black-02 mt-2">
                    You haven’t mutually liked anyone yet.
                  </div>
                )}
            </div>

            <div>
              <div className="fx fx-column gap-2">
                <div className="fx fx-ai-center gap-3">
                  <Button
                    size={30}
                    green
                    variant="icon"
                    className="round-2 pointer-none"
                  >
                    <IconThumbsUpFilled size={16}/>
                  </Button>
                  <span className="fs-32 fw-500 lh-36 txt-black-01">People Who Liked Your Vibe</span>

                  {!!dashboard?.countLikedMe && (
                    <Button
                      variant="secondary"
                      onClick={() => history.push(generate(ERoutes.TALENT_CONNECTIONS, { tab: EConnectionTabKey.LIKED_MY_VIBE }))}
                    >
                      View All ({dashboard?.countLikedMe})
                    </Button>
                  )}
                </div>

                {!!dashboard?.countLikedMe && (
                  <div className="fs-14 lh-20 txt-black-02">The below Talent vibe you - if you like their vibe, you’ll see their detailed information and be able to send DMs to them.</div>
                )}
              </div>

              {dashboard?.countLikedMe
                ? (
                  <div className="fx fx-wrap-wrap gap-4 mt-5">
                    {dashboard?.likedMe?.map((item, index) => (
                      <ReactionTalentCard
                        key={item.id}
                        reaction={item}
                        onChange={(values) => item.id && handleUpdateReaction('likedMe', item.id, values)}
                        onDetail={() => dashboard?.likedMe && onDetail(index, dashboard.likedMe, 'likedMe')}
                      />
                    ))}
                  </div>
                )
                : (
                  <div className="fs-14 fw-400 lh-18 txt-black-02 mt-2">
                    Nobody has liked your vibe yet.
                  </div>
                )}
            </div>

            <div>
              <div className="fx fx-column gap-2">
                <div className="fx fx-ai-center gap-3">
                  <Button
                    size={30}
                    green
                    variant="icon"
                    className="round-2 pointer-none"
                  >
                    <IconThumbsUpFilled size={16}/>
                  </Button>
                  <span className="fs-32 fw-500 lh-36 txt-black-01">People I Liked</span>

                  {!!dashboard?.countILiked && (
                    <Button variant="secondary" onClick={() => history.push(generate(ERoutes.TALENT_CONNECTIONS, { tab: EConnectionTabKey.PEOPLE_I_LIKED }))}>View All ({dashboard?.countILiked})</Button>
                  )}
                </div>
              </div>

              {dashboard?.countILiked
                ? (
                  <div className="fx fx-wrap-wrap gap-4 mt-5">
                    {dashboard?.iLiked?.map((item, index) => (
                      <ReactionTalentCard
                        key={item.id}
                        reaction={item}
                        isInvite
                        onChange={(values) => item.id && handleUpdateReaction('iLiked', item.id, values)}
                        onDetail={() => dashboard?.iLiked && onDetail(index, dashboard.iLiked, 'iLiked')}
                      />
                    ))}
                  </div>
                )
                : (
                  <div className="fs-14 fw-400 lh-18 txt-black-02 mt-2">
                    You haven’t liked anyone yet. <span className="txt-blue-1000 pointer" style={{ textDecoration: 'underline' }} onClick={() => history.push(generate(ERoutes.TALENT_VIBE_TALENT))}>Vibe talents</span> and start liking to connect.
                  </div>
                )}
            </div>
          </section>
        )}

      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          closeBtn
          contentClass="fx-1 fx fx-center round-0-p"
        >
          <ModalTalentDetail
            lookupId={user?.lookupId}
            pfv={user?.pfv}
            onPrevious={onPrevious}
            onNext={onNext}
            canPreviousProps={canPreviousProps}
            canNextProps={canNextProps}
          />
        </Modal>
      )}
    </>
  )
}
