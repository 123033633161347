import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { PaymentSuccessImg } from 'src/images'
import { OverlayService } from 'src/services'
import Style from './style.module.scss'

export const PaymentSuccess: FC = () => {
  const { eventHandler } = useAnalytic('store_sale')

  const handleClose = () => OverlayService.reset()

  return (
    <div className={Style.container}>
      <img src={PaymentSuccessImg} alt="payment success"/>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Typography variant="h3">Thank you!</Typography>
        <Typography variant="body1-regular">Your order has been successfully placed</Typography>
      </Box>
      <Button className="w-100-p" variant="secondary" onClick={eventHandler(ETrackingEvent.BTN_FINISH, handleClose)}>Close</Button>
    </div>
  )
}
