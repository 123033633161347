import { ICandidateModel, IPaginationQuery, IPrivateNoteModel, IUserStatsModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class CandidateApi {
  static readonly _prefix = '/candidates'

  static readonly _publicVibe = '/candidates/public-vibe'

  static pagination(
    params: IPaginationQuery
  ): TAxiosResponsePagination<ICandidateModel> {
    return axiosHiringApi.get(this._prefix, { params })
  }

  static getPublicOne(): TAxiosResponseData<ICandidateModel> {
    return axiosHiringApi.get(`${this._prefix}/public-vibe`)
  }

  static interview(
    params: IPaginationQuery
  ): TAxiosResponsePagination<ICandidateModel> {
    return axiosHiringApi.get(`${this._prefix}/interview`, { params })
  }

  static detail(
    idOrLookupId: string | number,
    page?: 'like' | 'interview'
  ): TAxiosResponseData<ICandidateModel> {
    return axiosHiringApi.get(`${this._prefix}/${idOrLookupId}`, { params: { page } })
  }

  static touch(
    id: number,
    page?: 'like' | 'interview'
  ): TAxiosResponseData<ICandidateModel> {
    return axiosHiringApi.put(`${this._prefix}/${id}/touch`, { page })
  }

  static action(
    id: number,
    action: string,
    payload: {
      reactionableType?: string
      reactionableId?: number
    }
  ): TAxiosResponseData<IUserStatsModel> {
    return axiosHiringApi.put(`${this._prefix}/${id}/${action}`, payload)
  }

  static hired(
    userType: string,
    payload: {
      candidateIds: number[]
    }
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/hired?${userType}`, payload)
  }

  static intro(
    payload: {
      candidateIds: number[]
    }
  ): TAxiosResponseData<IUserStatsModel> {
    return axiosHiringApi.post(`${this._prefix}/intro`, payload)
  }

  static play(
    videoId: number
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/play/${videoId}`)
  }

  static note(
    id: number,
    payload: {
      content: string
    }
  ): TAxiosResponseData<IPrivateNoteModel> {
    return axiosHiringApi.post(`${this._prefix}/${id}/note`, payload)
  }

  static share(
    id: number,
    payload: {
      emails: string[]
      shareableType: string
      shareableId: number
      message?: string
    }
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/${id}/share`, payload)
  }

  static subscribeLinkedin(
    id: number
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/${id}/linked-in/notification`)
  }

  static unSubscribeLinkedin(
    id: number
  ): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/${id}/linked-in/notification`)
  }

  static deleteLiked(
    id: number
  ): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/likes/${id}`)
  }

  static undoDeleteLiked(
    id: number
  ): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/likes/${id}/undo`)
  }
}
