import { IRouterOption } from 'src/router'
import { Archive } from './components/list'
import { EArchiveRoutes } from './routes.enum'

export const ArchiveRoutes: IRouterOption[] = [
  {
    path: '/archive',
    component: Archive,
    name: EArchiveRoutes.ARCHIVE,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
