import clsx from 'clsx'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { Dot } from 'src/components'
import { useAppSelector, useBehaviorMapper, useDidMountEffect } from 'src/hooks'
import { IconChevronFilled, IconLogo } from 'src/icons'
import { AuthModule } from 'src/store'
import { getLayoutIsAside } from 'src/store/selectors'
import { MyJobsNav } from './my-jobs-nav'
import { MyVideosNav } from './my-videos-nav'
import { PublicTalentNav } from './public-talent-nav'
import { SettingsNav } from './settings-nav'
import Style from './style.module.scss'
import { TalentNav } from './talent-nav'

const year = new Date().getFullYear()
const state = {
  expanded: false
}

export const Sidebar: FC<ComponentProps<'aside'>> = (props) => {
  const isAside = useAppSelector(getLayoutIsAside)
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [expanded, setExpanded] = useState(state.expanded)

  useDidMountEffect(() => {
    state.expanded = expanded
  }, [expanded])

  useEffect(() => {
    const autoToggleOnResize = () => {
      if (window?.innerWidth && window.innerWidth < 1024) {
        setExpanded(false)
      }
    }
    window?.addEventListener('resize', autoToggleOnResize)
    return () => window?.removeEventListener('resize', autoToggleOnResize)
  }, [])

  return (
    <aside
      {...props}
      className={clsx(Style.sidebar, 'fx-column gap-6', props.className, {
        [Style.expanded]: expanded,
        'd-none': !isAside
      })}
      onClick={() => setExpanded(true)}
    >
      <span
        className={clsx(Style.toggleIcon, 'fx fx-center pointer')}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setExpanded((prev) => !prev)
        }}
      >
        <IconChevronFilled
          className={clsx(
            'pointer',
            expanded && 'rotate-180'
          )}
        />
      </span>

      <div className="p-6 mb-6" style={{ width: 60, height: 60 }}>
        <IconLogo/>
      </div>

      {isAuthenticated && (
        <>
          <MyJobsNav expanded={expanded}/>
          <TalentNav expanded={expanded}/>
          <MyVideosNav expanded={expanded}/>
        </>
      )}

      {!isAuthenticated && (
        <>
          <MyJobsNav expanded={expanded}/>
          <PublicTalentNav expanded={expanded}/>
        </>
      )}

      <div style={{ borderTop: '1px solid #303030' }}/>
      <SettingsNav expanded={expanded}/>

      <div style={{ borderTop: '1px solid #303030' }}/>

      <div
        className="fx-column meta-regular px-6 pb-6"
        style={{ display: !expanded ? 'none' : undefined }}
      >
        <div className="fx fx-ai-center gap-2">Terms <Dot size={4} color="rgba(249, 254, 231, 0.6)"/> Privacy</div>
        <span>©{year} KNOWME, Inc.</span>
      </div>
    </aside>
  )
}
