import { ClickAwayListener } from '@mui/material'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import { Tooltip } from 'src/components'
import { useTouchDevice } from 'src/hooks'
import { IconPeople } from 'src/icons'
import { CampaignOwnersTooltip } from '../campaign-owners-tooltip'
import Style from './style.module.scss'

interface IProps {
  id: number
  companyDomain: string
  size?: 'large' | 'small'
  onOpen?: () => void
  onClose?: () => void
}

export const CampaignOwners: FC<IProps> = ({
  id,
  companyDomain,
  size = 'small',
  onOpen,
  onClose
}) => {
  const isTouchDevice = useTouchDevice()
  const [open, setOpen] = useState(false)

  const handleTouch = useCallback((e: any) => {
    e.stopPropagation()
    if (!isTouchDevice) return
    setOpen((prev) => !prev)
  }, [isTouchDevice])

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    if (open) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }, [onClose, onOpen, open])

  return (
    <ClickAwayListener onClickAway={close}>
      <Tooltip
        arrow
        open={open}
        placement="top"
        title={<CampaignOwnersTooltip id={id} companyDomain={companyDomain}/>}
      >
        <div
          onMouseEnter={() => !isTouchDevice && setOpen(true)}
          onMouseLeave={() => !isTouchDevice && setOpen(false)}
          className={clsx(Style.buttonLikeCircle, size === 'large' && Style.large)}
          onClick={handleTouch}
        >
          <IconPeople width={size === 'large' ? 16 : 12} height={size === 'large' ? 16 : 12}/>
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}
