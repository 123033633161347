import { FC } from 'react'
import { IconVideoTutorial } from 'src/icons'
import { ERoutes } from 'src/router'
import { NavItem } from '../nav-item'

interface IProps {
  expanded: boolean
}

export const MyVideosNav: FC<IProps> = ({ expanded: sidebarExpanded }) => {
  return (
    <nav className="fx-column px-6 relative">
      <NavItem
        mini={!sidebarExpanded}
        route={ERoutes.MY_VIDEOS}
        icon={<IconVideoTutorial className="svg-current-color"/>}
        label="My Videos"
      />
    </nav>
  )
}
