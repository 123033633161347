import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconWarning: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = '#EA2E4E' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.17024 3.31742L2.51249 11.3843C2.42835 11.53 2.38405 11.6952 2.38403 11.8635C2.38402 12.0317 2.42829 12.197 2.5124 12.3426C2.59651 12.4883 2.71749 12.6093 2.86318 12.6934C3.00887 12.7775 3.17413 12.8218 3.34236 12.8218H12.6571C12.8253 12.8218 12.9906 12.7775 13.1363 12.6934C13.282 12.6093 13.403 12.4883 13.4871 12.3426C13.5712 12.197 13.6155 12.0317 13.6154 11.8635C13.6154 11.6952 13.5711 11.53 13.487 11.3843L8.82974 3.31742C8.74564 3.17177 8.62469 3.05083 8.47904 2.96674C8.33339 2.88265 8.16817 2.83838 7.99999 2.83838C7.8318 2.83838 7.66658 2.88265 7.52093 2.96674C7.37528 3.05083 7.25433 3.17177 7.17024 3.31742Z"
          fill={color}
        />
        <path
          d="M8.06071 5.80566H7.93921C7.64008 5.80566 7.39758 6.04816 7.39758 6.34729V8.93616C7.39758 9.2353 7.64008 9.47779 7.93921 9.47779H8.06071C8.35984 9.47779 8.60233 9.2353 8.60233 8.93616V6.34729C8.60233 6.04816 8.35984 5.80566 8.06071 5.80566Z"
          fill="white"
        />
        <path
          d="M7.99996 11.5309C8.33264 11.5309 8.60233 11.2612 8.60233 10.9285C8.60233 10.5959 8.33264 10.3262 7.99996 10.3262C7.66728 10.3262 7.39758 10.5959 7.39758 10.9285C7.39758 11.2612 7.66728 11.5309 7.99996 11.5309Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  )
}
