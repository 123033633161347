import {
  LAYOUT_SET_ASIDE,
  LAYOUT_SET_LOADING,
  LAYOUT_SET_PAGE_TITLE,
  LAYOUT_SET_SCROLL_TO_BOTTOM,
  LAYOUT_SET_SHARING_TOKEN
} from '../types'
import { ILayoutAction } from '../types/layout'

export const setLayoutAside = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_ASIDE,
  value
})

/**
 * @deprecated
 */
export const setLayoutPageTitle = (value: string): ILayoutAction => ({
  type: LAYOUT_SET_PAGE_TITLE,
  value
})

export const setLayoutScrollToBottom = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_SCROLL_TO_BOTTOM,
  value
})

export const setLayoutLoading = (value: boolean): ILayoutAction => ({
  type: LAYOUT_SET_LOADING,
  value
})

export const setLayoutSharingToken = (value: string | null): ILayoutAction => ({
  type: LAYOUT_SET_SHARING_TOKEN,
  value
})
