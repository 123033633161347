import { matchPath } from 'react-router'
import { BehaviorSubject } from 'rxjs'
import { useBehaviorMapper } from 'src/hooks/useBehaviorMapper'
import { ClientUtils } from 'src/utils/client.utils'
import { _Window } from 'src/window'
import { WithOutNextComplete } from 'types/rxjs'
import { browserHistory } from './history'
import { IRouterOption } from './interface'
import { routes } from './routes'

export const findRoute = (asPath: string) => {
  return routes.find((route) => {
    const pathname = asPath.split('?')[0]
    return route.path === pathname || matchPath(pathname, {
      path: route.path,
      exact: true
    })
  })
}

const _currentRoute$ = new BehaviorSubject<IRouterOption | undefined>(ClientUtils.onlyClient(() => findRoute(_Window?.location.pathname)))

browserHistory.listen((location) => {
  _currentRoute$.next(findRoute(location.pathname))
})

export const currentRoute$: WithOutNextComplete<typeof _currentRoute$> = _currentRoute$
export const useRoute = () => useBehaviorMapper(currentRoute$)
export const useRouteName = () => useRoute()?.name
