import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { GUEST_ONBOARDING_BASE_PATH } from 'src/constants'

/**
 * @deprecated
 */
export const useIsAtGuestOnboarding = () => {
  const location = useLocation()

  const isAtGuestOnboarding = useMemo(() => {
    return location.pathname.startsWith(`/${GUEST_ONBOARDING_BASE_PATH}`)
  }, [location])

  return isAtGuestOnboarding
}
