import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import { Button } from 'src/components'
import { IconPencilStroke } from 'src/icons'
import Style from './style.module.scss'

export const ProfileBlock: FC<ComponentProps<'div'> & {
  editable?: boolean
  btnProps?: ComponentProps<typeof Button>
}> = ({ editable, btnProps, ...props }) => (
  <div
    {...props}
    className={clsx(Style.profileBlock, props.className, 'fx-column gap-5 p-6 round-4 bg-neutral-white')}
  >
    {props.children}
    {editable && (
      <Button
        {...btnProps}
        variant="icon"
        className={Style.pencil}
      >
        <IconPencilStroke size={20} color="currentColor"/>
      </Button>
    )}
  </div>
)
