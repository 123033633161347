export const MotivatorsColor = Object.freeze({
  Altruistic: '#754648',
  Competitive: '#606137',
  Creative: '#6A4866',
  Economic: '#1E6065',
  Individualistic: '#425E3B',
  Learner: '#7F5317',
  'Mission-Driven': '#3A5583'
})

export const MotivatorsBg = Object.freeze({
  Altruistic: '#F8D4D4',
  Competitive: '#ECEE9D',
  Creative: '#F6D1F0',
  Economic: '#AEEBF0',
  Individualistic: '#C6EABE',
  Learner: '#FFE2C3',
  'Mission-Driven': '#CCDFFF'
})

export const MotivatorsBgLight = Object.freeze({
  Altruistic: MotivatorsBg.Altruistic + '4C',
  Competitive: MotivatorsBg.Competitive + '4C',
  Creative: MotivatorsBg.Creative + '4C',
  Economic: MotivatorsBg.Economic + '4C',
  Individualistic: MotivatorsBg.Individualistic + '4C',
  Learner: MotivatorsBg.Learner + '4C',
  'Mission-Driven': MotivatorsBg['Mission-Driven'] + '4C'
})
