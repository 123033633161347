import { isNil } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { CampaignApi } from 'src/api'
import { useAppDispatch, useBehaviorMapper, useUnsubscribeEffect } from 'src/hooks'
import { ICampaignPublicModel } from 'src/interfaces'
import { JobClaimedRestrictModal, SignUpModal } from 'src/partials'
import { TourPopper } from 'src/partials/tour'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { GuestViewJobTourService, TARGET_PUBLIC_GUEST_CARD_JOB } from 'src/services/tour/guest-view-job-tour.service'
import { setLayoutAside, setLayoutLoading, setLayoutPageTitle } from 'src/store/actions'
import { getFirstName } from 'src/utils'
import { PublicCardJob } from './components/card-job/guest-index'
import { CreateJobButton } from './components/create-job-button'
import Style from './style.module.scss'

export const PublicMyJobs: FC = () => {
  const history = useHistory()
  const { hashId } = useParams<{ hashId?: string }>()
  const dispatch = useAppDispatch()
  const [data, setData] = useState<ICampaignPublicModel>()
  const tourEnabling = useBehaviorMapper(GuestViewJobTourService.enableTour$)
  const currentStep = useBehaviorMapper(GuestViewJobTourService.currentStep$)

  useUnsubscribeEffect((unsubscribed$) => {
    if (!hashId) return

    from(CampaignApi.publicCampaign(hashId))
      .pipe(
        takeUntil(unsubscribed$),
        catchError(() => {
          history.push(generate(ERoutes.ERROR_404))
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        if (data.slug !== hashId) {
          return history.push(generate([ERoutes.CAMPAIGNS_PUBLIC, { hashId: data.slug }]))
        }

        // already claimed
        setData(data)

        if (!isNil(data.sameCompany)) {
          if (data.sameCompany) {
            history.push(generate([ERoutes.CAMPAIGN_SUBMISSIONS, { id: data.id }]))
            return
          }

          history.push(generate(ERoutes.ERROR_404))
          return
        }

        if (data.authorId) {
          OverlayService.setOverlay(JobClaimedRestrictModal.getOverlayState(hashId, data.id, getFirstName(data.author)))
        }
      })
  }, [hashId])

  useEffect(() => {
    dispatch(setLayoutPageTitle('My Jobs'))
    dispatch(setLayoutAside(true))
  }, [dispatch])

  useEffect(() => {
    dispatch(setLayoutLoading(!data))
    return () => {
      dispatch(setLayoutLoading(false))
    }
  }, [data, dispatch])

  const handleSignUp = useCallback(() => {
    OverlayService.setOverlay({
      content: (
        <SignUpModal/>
      ),
      open: true
    })
  }, [])

  return (
    <div className={Style.containerGuest}>
      <div className={Style.container}>
        <div className={Style.header}>
          <div className={Style.header_tab}/>
          <CreateJobButton onClick={handleSignUp}/>
        </div>

        <div className={Style.content}>
          {data
            ? (
              <>
                <PublicCardJob
                  htmlId={TARGET_PUBLIC_GUEST_CARD_JOB.getValue()}
                  hashId={hashId}
                  logoUrl={data.logoUrl}
                  jobTitle={data.jobTitle}
                  companyName={data.companyName}
                  submissions={data.submissions}
                  countSubmission={data.countSubmission}
                  onEdit={handleSignUp}
                  onDelete={handleSignUp}
                />
                {tourEnabling && currentStep === 0 && <TourPopper {...GuestViewJobTourService.getStepConfig()}/>}
              </>
            )
            : null}
        </div>
      </div>
    </div>
  )
}
