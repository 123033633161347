import { ICampaignModel } from 'src/interfaces'
import { convertEquivalentSalaryRate, formatNumber } from './helpers.utils'

const LOCATION_TYPE = {
  HYBRID: 'Hybrid',
  ON_SITE: 'Onsite',
  REMOTE: 'Remote'
}

type TLocationType = keyof typeof LOCATION_TYPE

export class CampaignUtils {
  static getSalaryTxt(
    campaign: ICampaignModel,
    options?: { noRate?: boolean },
    defaultTxt = ''
  ): string {
    if (!campaign?.salaryValue) {
      return defaultTxt
    }
    const salaryValue = campaign.salaryValue as {
      min?: number
      max?: number
    }
    const salaryRate = convertEquivalentSalaryRate(campaign.salaryRate)
    const salary = [salaryValue.min, salaryValue.max].filter(Boolean) as number[]
    if (!salary.length) {
      return defaultTxt
    }
    return salary.map((s) => `$${formatNumber(s)}`).join(' - ') + (options?.noRate ? '' : ` / ${salaryRate}`)
  }

  static getQuestion(campaign?: ICampaignModel) {
    return campaign?.question || campaign?.questions?.[0]?.text
  }

  static parse(campaign?: ICampaignModel) {
    if (!campaign) {
      return {
        salary: '',
        salaryRate: '',
        location: '',
        locationType: '',
        companyLogoUrl: '',
        companyName: ''
      }
    }

    const locationType = LOCATION_TYPE?.[campaign.locationType as TLocationType] || ''

    return {
      salary: CampaignUtils.getSalaryTxt(campaign, { noRate: true }),
      salaryRate: campaign?.salaryValue ? ` / ${convertEquivalentSalaryRate(campaign?.salaryRate)}` : '',
      location: campaign.location ? `${campaign.location}` : '',
      locationType,
      companyLogoUrl: campaign?.logoUrl || '',
      companyName: campaign?.companyName || ''
    }
  }
}
