import { IRouterOption } from 'src/router'
import { HomePage } from './pages/home'
import { LandingPage } from './pages/landing'
import { QuickPassword } from './pages/quick-password'
import { EHomeRoutes } from './routes.enum'

export const HomeRoutes: IRouterOption[] = [
  {
    path: '/',
    component: HomePage,
    name: EHomeRoutes.ROOT,
    exact: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/home/:sharingToken',
    component: LandingPage,
    name: EHomeRoutes.HOME_LANDING,
    exact: true,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/quick-password/:sharingToken',
    component: QuickPassword,
    name: EHomeRoutes.QUICK_PASSWORD,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]
