import { Tooltip, useTheme } from '@mui/material'
import * as Popover from '@radix-ui/react-popover'
import { FC } from 'react'
import { ECandidateReaction } from 'src/enums'
import { IconArchive, IconDocument, IconLikeWhite, IconLikesFull, IconShare, IconSuperLikeFull, IconSuperNova } from 'src/icons'
import { ICampaignSubmissionModel } from 'src/interfaces'
import { GuestViewJobTourService } from 'src/services/tour/guest-view-job-tour.service'
import { Button } from '../button'
import { IconWrapper } from '../icon-wrapper'
import Style from './style.module.scss'

interface IProps {
  item: ICampaignSubmissionModel
  newCandidateIds: number[]
  onTouch?: (id: number) => void
  onReactionChange?: (item: ICampaignSubmissionModel, reaction: ECandidateReaction) => void
  onFlip: () => void
  onShare?: (videoId: number, candidateId: number) => void

  isSubmission?: boolean
  onLikeSubmission?: (submission: ICampaignSubmissionModel) => void
  isSubmissionLiked?: boolean
  /**
   * this id is used to target the tour
   */
  resumeButtonId?: string
}

export const CardReactionAndResume: FC<IProps> = ({
  item,
  newCandidateIds,
  onTouch,
  onReactionChange,
  onFlip,
  onShare,
  isSubmission,
  onLikeSubmission,
  isSubmissionLiked,
  resumeButtonId
}) => {
  const theme = useTheme()
  const isSuperNova = item.video?.createdReaction?.reaction === ECandidateReaction.SUPER_NOVA
  const isSuperLike = item.video?.createdReaction?.reaction === ECandidateReaction.SUPER_LIKE
  const isLike = item.video?.createdReaction?.reaction === ECandidateReaction.LIKE

  return (
    <div className={Style.STIcons} onClick={e => e.stopPropagation()}>
      <IconWrapper
        id="share"
        width={36}
        height={36}
        opacity={0.7}
        background="rgba(24, 25, 32, 0.7)"
        onClick={() => {
          item.video && onShare?.(item.video?.id, item.id)
          if (newCandidateIds.includes(item.id)) {
            onTouch?.(item.id)
          }
        }}
      >
        <Tooltip title="Share" placement="left">
          <IconShare className="svg-color-white"/>
        </Tooltip>
      </IconWrapper>

      <div className="fx flex-column gap-3">
        {(isSuperLike || isLike) && !isSubmission
          ? (
            <Popover.Root>
              <Popover.Trigger asChild>
                <Button
                  variant="icon"
                  disabled={!onReactionChange}
                  onClick={() => {
                    if (newCandidateIds.includes(item.id)) {
                      onTouch?.(item.id)
                    }
                  }}
                >
                  {isSuperLike && (
                    <IconWrapper
                      width={36}
                      height={36}
                      background={theme.colors['--color-orange-500']}
                    >
                      <IconSuperLikeFull/>
                    </IconWrapper>
                  )}
                  {isLike && (
                    <IconWrapper
                      width={36}
                      height={36}
                      background={theme.colors['--color-cyan-400']}
                    >
                      <IconLikesFull/>
                    </IconWrapper>
                  )}
                </Button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content side="top">
                  <div className={Style.STPopper}>
                    <Popover.Close asChild>
                      <IconWrapper
                        width={36}
                        height={36}
                        color={theme.colors['--color-neutral-theme-50']}
                        background={theme.colors['--color-neutral-theme-300']}
                        onClick={() => onReactionChange?.(item, ECandidateReaction.SKIP)}
                      >
                        <IconArchive/>
                      </IconWrapper>
                    </Popover.Close>
                    {!isLike && (
                      <Popover.Close asChild>
                        <IconWrapper
                          width={36}
                          height={36}
                          color={theme.colors['--color-neutral-theme-50']}
                          background={theme.colors['--color-cyan-500']}
                          onClick={() => onReactionChange?.(item, ECandidateReaction.LIKE)}
                        >
                          <IconLikesFull/>
                        </IconWrapper>
                      </Popover.Close>
                    )}
                    {!isSuperLike && (
                      <Popover.Close asChild>
                        <IconWrapper
                          width={36}
                          height={36}
                          background={theme.colors['--color-orange-500']}
                          onClick={() => onReactionChange?.(item, ECandidateReaction.SUPER_LIKE)}
                        >
                          <IconSuperLikeFull/>
                        </IconWrapper>
                      </Popover.Close>
                    )}
                  </div>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          )
          : null}

        {isSuperNova && (
          <IconWrapper
            width={36}
            height={36}
            background="linear-gradient(219.81deg, #ECA0FF 15.34%, #AAB2FF 51.08%, #84FFC9 84.66%)"
            onClick={() => {
              if (newCandidateIds.includes(item.id)) {
                onTouch?.(item.id)
              }
            }}
          >
            <Tooltip title="Supernova">
              <IconSuperNova width={27} height={27}/>
            </Tooltip>
          </IconWrapper>
        )}

        {isSubmission && (
          <IconWrapper
            className={Style.Icon}
            width={36}
            height={36}
            onClick={() => onLikeSubmission?.(item)}
          >
            <Tooltip title="Like" placement="left">
              {isSubmissionLiked ? <IconLikesFull/> : <IconLikeWhite/>}
            </Tooltip>
          </IconWrapper>
        )}

        {/* {Boolean(item.skills || item.characteristics) && ( */}
        <IconWrapper
          width={36}
          height={36}
          background={theme.colors['--color-neutral-theme-900']}
          opacity={0.7}
          id={resumeButtonId}
          onClick={() => {
            onFlip()
            if (newCandidateIds.includes(item.id)) {
              onTouch?.(item.id)
            }
            GuestViewJobTourService.nextStep()
          }}
        >
          <Tooltip title="Resume" placement="left">
            <IconDocument color="white"/>
          </Tooltip>
        </IconWrapper>
        {/* )} */}
      </div>
    </div>
  )
}
