import { AxiosError } from 'axios'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi, ProfileApi } from 'src/api'
import { useAppDispatch, useBehaviorMapper, useQueryParams, useUnsubscribeEffect } from 'src/hooks'
import { EAuthView } from 'src/interfaces'
import { RegisterEmailForm } from 'src/partials/authentication/sign-up/register-email'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { setLayoutLoading } from 'src/store/actions'
import { decodeJWTPayload, getApiErrorMessage } from 'src/utils'
import { SetName, SetPassword, VerifyEmail } from './components'
import { Success } from './components/success'

type TSignUpPayload = Parameters<typeof AuthApi.signUpProfile>[0]

export const SignUp: FC = () => {
  const { token } = useQueryParams()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const email = history.location.state as string // email from RegisterEmailForm
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [history, isAuthenticated])

  const [view, setView] = useState<EAuthView>(EAuthView.EMAIL_REGISTER)
  useEffect(() => {
    if (email) {
      setView(EAuthView.EMAIL_VERIFIED)
    }
  }, [email])

  const [formValues, setFormValues] = useState<TSignUpPayload>({
    interested: '',
    subscribeMarketingEmails: true,
    firstName: '',
    lastName: '',
    companyName: '',
    companyUrl: '',
    userType: '',
    password: ''
  })

  const handleChangeFormData = useCallback((name: string, value: TSignUpPayload[keyof TSignUpPayload]): void => {
    setFormValues(
      (prev) => ({
        ...prev,
        [name]: value
      })
    )
  }, [])

  const onSubmit = useCallback(async (information: Partial<TSignUpPayload>) => {
    try {
      const submitData: TSignUpPayload = { ...formValues, ...information }

      await AuthApi.signUpProfile(submitData, { token: token as string })
        .then(({ data }) => {
          if (data?.profile) {
            return Promise.resolve(data.profile)
          }
          return ProfileApi.getProfile().then(({ data }) => data)
        })
        .then((profile) => AuthModule.authenticated(profile))

      history.push({
        pathname: history.location.pathname,
        search: ''
      })

      /**
       * TODO: copy authenticated logic
       */
      // const { fromGuestView } = decodeJWTPayload(token as string)
      // dispatch({ type: AUTH_GET_PROFILE, payload: { afterSignUp: true, fromGuestView } })
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    }
  }, [formValues, history, token])

  useUnsubscribeEffect((unsubscribe$) => {
    if (token) {
      dispatch(setLayoutLoading(true))
      from(AuthApi.signUpVerify({ token: token as string }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            const message = (error as AxiosError).response?.status === 406
              ? 'Sign out first then open the link again'
              : getApiErrorMessage(error)
            SnackbarService.error(message)
            return EMPTY
          }),
          finalize(() => dispatch(setLayoutLoading(false)))
        )
        .subscribe(({ data }) => {
          const { sessionId } = decodeJWTPayload(token as string)

          if (sessionId) {
            AnalyticApi.setSession(sessionId)
          }

          setView(EAuthView.NAME)
        })
    }
  }, [token])

  const renderView = useMemo(() => {
    const passingProps = {
      onSubmit,
      handleChangeFormData,
      setView,
      signUpInfo: formValues
    }

    switch (view) {
      case EAuthView.EMAIL_REGISTER:
        return <RegisterEmailForm onSuccess={() => setView(EAuthView.EMAIL_VERIFIED)}/>

      case EAuthView.EMAIL_VERIFIED:
        return <VerifyEmail email={email}/>

        // case EAuthView.EMAIL_VERIFIED_SUCCESS:
        //   return <VerifyEmailSuccess onChangeView={() => setView(EAuthView.PASSWORD)}/>

      case EAuthView.NAME:
        return <SetName onChangeView={() => setView(EAuthView.PASSWORD)} {...passingProps}/>

      case EAuthView.PASSWORD:
        return <SetPassword {...passingProps}/>

      case EAuthView.SUCCESS:
        return <Success/>

      default:
        return <SetPassword {...passingProps}/>
    }
  }, [onSubmit, handleChangeFormData, formValues, view, email])

  return (
    <div className="fx-1 fx fx-center">
      {renderView}
    </div>
  )
}
