import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconBell: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#636363' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0011 2.81299C13.0386 2.81299 15.5186 5.20904 15.623 8.24978L15.6261 8.43799L15.6261 11.5107L16.6611 13.8777C16.7129 13.9962 16.7396 14.124 16.7396 14.2533C16.7396 14.7711 16.3199 15.1908 15.8021 15.1908L12.2511 15.1919C12.2511 16.4346 11.2438 17.4419 10.0011 17.4419C8.80286 17.4419 7.82338 16.5053 7.75494 15.3241L7.75078 15.1902L4.20727 15.1908C4.07877 15.1908 3.95163 15.1644 3.83377 15.1132C3.35887 14.9069 3.1411 14.3547 3.34738 13.8798L4.37612 11.5114L4.37612 8.43791C4.37657 5.32181 6.89019 2.81299 10.0011 2.81299ZM11.1258 15.1902L8.87612 15.1919C8.87612 15.8133 9.3798 16.3169 10.0011 16.3169C10.5859 16.3169 11.0665 15.8708 11.121 15.3003L11.1258 15.1902ZM10.0011 3.93799C7.511 3.93799 5.50148 5.94368 5.50112 8.43799L5.50112 11.7452L4.49314 14.0658L15.5155 14.0658L14.5011 11.7459L14.5012 8.44763L14.4984 8.27873C14.4151 5.85362 12.4323 3.93799 10.0011 3.93799Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
