import { RefObject, useEffect } from 'react'
import { useAsRef } from './useAsRef'

export function useOnClickOutside<T extends RefObject<HTMLElement>>(ref: T, handler: (e: Event) => void) {
  const handlerRef = useAsRef(handler)
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || (ref.current.contains && ref.current?.contains(event.target as Node))) {
        return
      }
      handlerRef.current(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handlerRef])
}
