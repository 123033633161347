import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'src/hooks'
import { Input } from '../input'

interface IProps<T> extends Omit<ComponentProps<typeof Input>, 'value' | 'onChange'> {
  value?: T
  debounce?: number
  onChange?: (value?: T) => void
}

export const InputDebounce = <T extends string>({
  debounce = 400,
  ...props
}: IProps<T>) => {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onChange = useCallback<Required<ComponentProps<typeof Input>>['onChange']>(
    (e) => setValue(e.target.value as T),
    []
  )

  useDebounce(() => {
    props.onChange?.(value)
  }, debounce, [value])

  return (
    <Input
      {...props}
      value={value}
      onChange={onChange}
    />
  )
}
