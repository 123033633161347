import { RE_URL_WITH_OUT_PROTOCOL } from 'src/constants'
import { object, ref, string } from 'yup'
import { validCompanyEmail } from './email'

const Pattern: Record<string, RegExp> = {
  // mustHaveLetterAndNumber: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].+$/,
  // mustHaveLowercaseAndUppercase: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d].+$/,
  // mustHaveSpecialCharacter: /^(?=.*?[#?!@$%^&*-]).+$/,
  mustHaveNumber: /^(?=.*\d).+$/,
  mustHaveUppercase: /^(?=.*[A-Z]).+$/
}

export const signInSchema = object().shape({
  email: string().required('Enter email')
    .test(
      'error_email_domain',
      'Enter your work email',
      (email) => email ? validCompanyEmail(email) : false
    ),
  password: string().required('Enter password')
    .min(8, 'Password must be at least 8 characters.')
    // .test(
    //   'at-least-one-letter-and-number',
    //   'Password must be at least 1 letter and 1 number.',
    //   (password) => password ? Pattern.mustHaveLetterAndNumber.test(password) : false)
    // .test(
    //   'at-least-one-lowercase-and-uppercase',
    //   'Password must be at least 1 lowercase and 1 uppercase.',
    //   (password) => password ? Pattern.mustHaveLowercaseAndUppercase.test(password) : false)
    // .test(
    //   'at-least-one-special-character',
    //   'Password must be at least 1 special character (#?!@$%^&*-)',
    //   (password) => password ? Pattern.mustHaveSpecialCharacter.test(password) : false)
    // .test(
    //   'at-least-one-uppercase',
    //   'Password must be at least 1 uppercase.',
    //   (password) => password ? Pattern.mustHaveUppercase.test(password) : false)
    // .test(
    //   'at-least-one-number',
    //   'Password must be at least 1 number',
    //   (password) => password ? Pattern.mustHaveNumber.test(password) : false)
})

export const registerPasswordSchema = object().shape({
  companyName: string().required('This field is required.'),
  companyUrl: string().required('This field is required.'),
  userType: string().required('This field is required.'),
  password: string().required('Please enter your password.')
    .min(8, 'Password must be at least 8 characters.')
    // .test(
    //   'at-least-one-letter-and-number',
    //   'Password must be at least 1 letter and 1 number.',
    //   (password) => password ? Pattern.mustHaveLetterAndNumber.test(password) : false)
    // .test(
    //   'at-least-one-lowercase-and-uppercase',
    //   'Password must be at least 1 lowercase and 1 uppercase.',
    //   (password) => password ? Pattern.mustHaveLowercaseAndUppercase.test(password) : false)
    // .test(
    //   'at-least-one-special-character',
    //   'Password must be at least 1 special character (#?!@$%^&*-)',
    //   (password) => password ? Pattern.mustHaveSpecialCharacter.test(password) : false),
    .test(
      'at-least-one-uppercase',
      'Password must be at least 1 uppercase.',
      (password) => password ? Pattern.mustHaveUppercase.test(password) : false)
    .test(
      'at-least-one-number',
      'Password must be at least 1 number',
      (password) => password ? Pattern.mustHaveNumber.test(password) : false),
  confirmPassword: string().required('Please retype your password.')
    .when('password', (val, schema) => {
      return val
        ? string().required('Please retype your password.')
          .oneOf([ref('password'), ''], 'Password does not match')
        : schema
    })
})

export const setInformationSchema = object().shape({
  firstName: string().required('This field is required.'),
  lastName: string().required('This field is required.'),
  companyName: string().required('This field is required.'),
  companyUrl: string().required('This field is required.')
    .matches(RE_URL_WITH_OUT_PROTOCOL, 'Follow the format https://www.example.com')
})

export const setPasswordSchema = object().shape({
  newPassword: string().required('Please enter your password.')
    .min(8, 'Password must be at least 8 characters.')
    // .test(
    //   'at-least-one-letter-and-number',
    //   'Password must be at least 1 letter and 1 number.',
    //   (newPassword) => newPassword ? Pattern.mustHaveLetterAndNumber.test(newPassword) : false)
    // .test(
    //   'at-least-one-lowercase-and-uppercase',
    //   'Password must be at least 1 lowercase and 1 uppercase.',
    //   (newPassword) => newPassword ? Pattern.mustHaveLowercaseAndUppercase.test(newPassword) : false)
    // .test(
    //   'at-least-one-special-character',
    //   'Password must be at least 1 special character (#?!@$%^&*-)',
    //   (newPassword) => newPassword ? Pattern.mustHaveSpecialCharacter.test(newPassword) : false),
    .test(
      'at-least-one-uppercase',
      'Password must be at least 1 uppercase.',
      (password) => password ? Pattern.mustHaveUppercase.test(password) : false)
    .test(
      'at-least-one-number',
      'Password must be at least 1 number',
      (password) => password ? Pattern.mustHaveNumber.test(password) : false),
  confirmPassword: string().required('Please retype your password.')
    .when('newPassword', (val, schema) => {
      return val
        ? string().required('Please retype your password.')
          .oneOf([ref('newPassword'), ''], 'Password does not match')
        : schema
    })
})

export const resetPasswordSchema = object().shape({
  newPassword: string().required('Please enter your password.')
    .min(8, 'Password must be at least 8 characters.')
    // .test(
    //   'at-least-one-letter-and-number',
    //   'Password must be at least 1 letter and 1 number.',
    //   (newPassword) => newPassword ? Pattern.mustHaveLetterAndNumber.test(newPassword) : false)
    // .test(
    //   'at-least-one-lowercase-and-uppercase',
    //   'Password must be at least 1 lowercase and 1 uppercase.',
    //   (newPassword) => newPassword ? Pattern.mustHaveLowercaseAndUppercase.test(newPassword) : false)
    // .test(
    //   'at-least-one-special-character',
    //   'Password must be at least 1 special character (#?!@$%^&*-)',
    //   (newPassword) => newPassword ? Pattern.mustHaveSpecialCharacter.test(newPassword) : false),
    .test(
      'at-least-one-uppercase',
      'Password must be at least 1 uppercase.',
      (password) => password ? Pattern.mustHaveUppercase.test(password) : false)
    .test(
      'at-least-one-number',
      'Password must be at least 1 number',
      (password) => password ? Pattern.mustHaveNumber.test(password) : false),
  confirmPassword: string().required('Please retype your password.')
    .when('newPassword', (val, schema) => {
      return val
        ? string().required('Please retype your password.')
          .oneOf([ref('newPassword'), ''], 'Password does not match')
        : schema
    })
})

export const setNameSchema = object().shape({
  firstName: string().required('This field is required.'),
  lastName: string().required('This field is required.')
})
