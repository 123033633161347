import { ImgHTMLAttributes, RefObject } from 'react'

/* eslint-disable no-multiple-empty-lines */
export { default as AvatarDefault } from './avatar.jpg'
export { default as CommentCardEmptyImg } from './comment-card-empty.png'
export { ReactComponent as NoCommentImg } from './comment-card-empty.svg'
export { default as DefaultCompany } from './company-default.jpg'
export { default as ImageGetStartFirst } from './get-start-1.png'
export { default as ImageGetStartSecondMobile } from './get-start-2-mobile.png'
export { default as ImageGetStartSecond } from './get-start-2.png'
export { default as ImageGetStartThird } from './get-start-3.png'
export { default as OnboardingLikeGif } from './gif/onboarding-like.gif'
export { default as OnboardingNopeGif } from './gif/onboarding-nope.gif'
export { default as OnboardingSkipGif } from './gif/onboarding-skip.gif'
export { default as OnboardingSuperlikeGif } from './gif/onboarding-superlike.gif'
export { default as HomeBGImg } from './home-bg.png'
export { default as IconUploadVideo } from './i-upload-video.png'
export { default as DefaultImg } from './image-default.png'
export { default as IntroCardEmptyImg } from './intro-card-empty.png'
export { default as LikeCardEmptyImg } from './like-card-empty.png'
export { default as Logo } from './logo-48.png'
export { default as OnboardingStatic } from './onboarding-static.png'
export { default as PaymentCardEmptyImg } from './payment-card-empty.png'
export { default as PaymentSuccessImg } from './payment-success.png'
export { ReactComponent as PreviewBackground } from './preview-backgorund.svg'
export { default as RecordReadyImg } from './record-ready.svg'
export { default as SignInUpImg } from './sign-in-up.svg'
export { default as SkipCardEmptyImg } from './skip-card-empty.svg'
export { default as ImageTabletsImg } from './tablets.svg'
export { default as UnSaveChangeImg } from './unsave-change.png'
export { default as IntrosNow } from './vibes-notice.png'
export { default as YohooImg } from './yohoo.png'
// export { default as SignInUpLogoImg } from './sign-in-up-logo.svg'

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  ref?: RefObject<HTMLImageElement>
}
export const Img = (props: IProps) => <img {...props} alt={props.alt}/>
