import { FC, ReactNode, useRef, useState } from 'react'
import { useOnClickOutside } from 'src/hooks'
import Style from './style.module.scss'

interface IProps {
  dropdownRender?: ReactNode
  children?: ReactNode
  options?: { label: string; value: string }
}

export const MenuDropdown: FC<IProps> = ({ children, dropdownRender }) => {
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => setOpen((prev) => !prev)

  useOnClickOutside(dropdownRef, () => setOpen(false))

  return (
    <div className={Style.dropdownContainer} ref={dropdownRef}>
      <div className={Style.dropdownButton} onClick={toggleDropdown}>
        {children}
      </div>

      {open && <div className={Style.dropdownMenu}>{dropdownRender}</div>}
    </div>
  )
}
