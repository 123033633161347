import {
  ILayoutAction,
  ILayoutState,
  LAYOUT_SET_ASIDE,
  LAYOUT_SET_LOADING,
  LAYOUT_SET_PAGE_TITLE,
  LAYOUT_SET_SCROLL_TO_BOTTOM,
  LAYOUT_SET_SHARING_TOKEN
} from '../types'

const initState: ILayoutState = {
  isAside: true,
  pageTitle: null,
  isScrollToBottom: false,
  isLoading: false,
  sharingToken: null
}

export const reducer = (state = initState, action: ILayoutAction) => {
  switch (action.type) {
    case LAYOUT_SET_ASIDE:
      return {
        ...state,
        isAside: action.value
      }
    case LAYOUT_SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.value
      }
    case LAYOUT_SET_SCROLL_TO_BOTTOM:
      return {
        ...state,
        isScrollToBottom: action.value
      }
    case LAYOUT_SET_LOADING:
      return {
        ...state,
        isLoading: action.value
      }
    case LAYOUT_SET_SHARING_TOKEN:
      return {
        ...state,
        sharingToken: action.value
      }
    default:
      return state
  }
}
