import { AxiosRequestConfig } from 'axios'
import { ICampaignModel, IUserModel } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class CampaignInviteApplyApi {
  static readonly _prefix = '/campaign-invite-apply'

  static send(
    data: {
      campaignIds: Array<ICampaignModel['id']>
      inviteeIds: Array<IUserModel['id']>
    },
    config?: AxiosRequestConfig
  ) {
    return axiosHiringApi.post<void>(this._prefix, data, config)
  }
}
