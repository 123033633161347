import { IVideoModel } from 'src/interfaces'

export const renderVideoTitle = (video?: Partial<IVideoModel>) => {
  return video?.title ? `“${video?.title}”` : '---'
}

export const getVideoSource = (video?: Partial<IVideoModel>) => {
  return video?.urlVideoStandardQuality || video?.urlVideoSource
}

export const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')

    // this is important
    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)

    video.onloadeddata = () => {
      const ctx = canvas.getContext('2d')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      const img = canvas.toDataURL('image/png')
      URL.revokeObjectURL(video.src)
      return resolve(img)
    }
  })
}

// convert image to object part instead of base64 for better performance
// https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
export const importFileandPreview = (
  file: File,
  revoke?: boolean
): Promise<string> => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL
    const preview = window.URL.createObjectURL(file)

    if (revoke) {
      window.URL.revokeObjectURL(preview)
    }

    setTimeout(() => {
      resolve(preview)
    }, 100)
  })
}

/**
 *
 * @param videoFile {FIle} // the video file
 * @param numberOfThumbnails {number} //number of thumbnails you want to generate
 * @returns {string[]} // an array of base64 thumbnails images
 *
 * @abstract
 * Idea taken from - https://codepen.io/aertmann/pen/mrVaPx
 * The original functionality of getVideoThumbnail() function is customized as per working code
 * If it didn't work in future then replace it with about links working example
 */
export const generateVideoThumbnails = async (
  videoFile: File,
  numberOfThumbnails: number,
  type: string
) => {
  const thumbnail: string[] = []
  const fractions: number[] = []
  return type !== 'url'
    // eslint-disable-next-line no-async-promise-executor
    ? new Promise(async (resolve, reject) => {
      if (!videoFile.type?.includes('video')) { reject(new Error('not a valid video file')) }
      await getVideoDuration(videoFile).then(async (duration) => {
        // divide the video timing into particular timestamps in respective to number of thumbnails
        // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
        // we will use this timestamp to take snapshots
        for (let i = 0; i <= duration; i += duration / numberOfThumbnails) {
          fractions.push(Math.floor(i))
        }
        // the array of promises
        const promiseArray = fractions.map((time) => {
          return getVideoThumbnail(videoFile, time)
        })
        // console.log('promiseArray', promiseArray)
        // console.log('duration', duration)
        // console.log('fractions', fractions)
        await Promise.all(promiseArray)
          .then((res) => {
            res.forEach((res) => {
              // console.log('res', res.slice(0,8))
              thumbnail.push(res)
            })
            // console.log('thumbnail', thumbnail)
            resolve(thumbnail)
          })
          .catch((err) => {
            console.error(err)
          })
      })

      reject(new Error('something went wrong'))
    })
    // eslint-disable-next-line no-async-promise-executor
    : new Promise(async (resolve, reject) => {
      await getVideoDuration(videoFile).then(async (duration) => {
        // divide the video timing into particular timestamps in respective to number of thumbnails
        // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
        // we will use this timestamp to take snapshots
        for (let i = 0; i <= duration; i += duration / numberOfThumbnails) {
          fractions.push(Math.floor(i))
        }
        // the array of promises
        const promiseArray = fractions.map((time) => {
          return getVideoThumbnail(videoFile, time)
        })
        // console.log('promiseArray', promiseArray)
        // console.log('duration', duration)
        // console.log('fractions', fractions)
        await Promise.all(promiseArray)
          .then((res) => {
            res.forEach((res) => {
              // console.log('res', res.slice(0,8))
              thumbnail.push(res)
            })
            // console.log('thumbnail', thumbnail)
            resolve(thumbnail)
          })
          .catch((err) => {
            reject(err)
          })
      })

      reject(new Error('something went wrong'))
    })
}

/**
 *
 * @param {File} file
 * @param {number} videoTimeInSeconds
 * @returns string // base64Image
 */

const getVideoThumbnail = (file: File | string, videoTimeInSeconds: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (file instanceof File && file?.type?.match('video')) {
      importFileandPreview(file).then((urlOfFIle) => {
        generateVideoThumbnailViaUrl(urlOfFIle, videoTimeInSeconds).then(
          (res) => {
            resolve(res)
          }
        )
      })
    } else if (typeof file === 'string') {
      generateVideoThumbnailViaUrl(file, videoTimeInSeconds)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    } else {
      reject(new Error('file not valid'))
    }
  })
}

/**
 *
 * @param {File} file
 * @param {number} videoTimeInSeconds
 * @returns string // base64Image
 */

export const getVideoThumbnailAtPercentDuration = (file: File | string, percent: number): Promise<{ thumbnailUrl: string; offset: number; duration: number }> => {
  return new Promise((resolve, reject) => {
    if (file instanceof File && file?.type?.match('video')) {
      importFileandPreview(file).then(async (urlOfFIle) => {
        const duration = await generateVideoDuration(urlOfFIle, true)
        const videoTimeInSeconds = Math.ceil(duration * percent / 100)

        generateVideoThumbnailViaUrl(urlOfFIle, videoTimeInSeconds).then(
          (res) => {
            resolve({ thumbnailUrl: res, offset: videoTimeInSeconds, duration })
          }
        )
      })
    } else if (typeof file === 'string') {
      generateVideoDuration(file, true).then(duration => {
        const videoTimeInSeconds = Math.ceil(duration * percent / 100)

        generateVideoThumbnailViaUrl(file, videoTimeInSeconds)
          .then((res) => {
            resolve({ thumbnailUrl: res, offset: videoTimeInSeconds, duration })
          })
      })
    } else {
      reject(new Error('file not valid'))
    }
  })
}

const generateVideoThumbnailViaUrl = (urlOfFIle: string, videoTimeInSeconds: number): Promise<string> => {
  return new Promise((resolve) => {
    try {
      const video = document.createElement('video')
      const timeupdate = function () {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate)
          video.pause()
        }
      }
      video.addEventListener('loadeddata', function () {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate)
        }
      })

      const snapImage = function () {
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
        const image = canvas.toDataURL()
        const success = image.length > 100000
        if (success) {
          URL.revokeObjectURL(urlOfFIle)
          resolve(image)
        }
        return success
      }
      video.addEventListener('timeupdate', timeupdate)
      video.preload = 'metadata'
      video.src = urlOfFIle
      // Load video in Safari / IE11
      video.muted = true
      video.playsInline = true
      video.setAttribute('crossOrigin', '')
      video.currentTime = videoTimeInSeconds
      video
        .play()
        .then()
        .catch((err) => {
          resolve('')
          console.error(err)
        })
    } catch (error) {
      resolve('')
    }
  })
}

/**
 *
 * @param videoFile {File}
 * @returns {number} the duration of video in seconds
 */
export const getVideoDuration = (videoFile?: File | string): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (videoFile) {
      if (typeof videoFile === 'string') {
        generateVideoDuration(videoFile).then((res) => {
          resolve(res)
        })
        return
      }

      if (videoFile?.type?.match('video')) {
        importFileandPreview(videoFile).then((url) => {
          generateVideoDuration(url).then((res) => {
            resolve(res)
          })
        })

        return
      }
    }

    reject(new Error('file not valid'))
  })
}

// generate the video duration either via url
const generateVideoDuration = (url: string, noRevoke?: boolean): Promise<number> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.addEventListener('loadeddata', function () {
      resolve(video.duration)
      if (!noRevoke) {
        window.URL.revokeObjectURL(url)
      }
    })
    video.preload = 'metadata'
    video.src = url
    // Load video in Safari / IE11
    video.muted = true
    video.playsInline = true
    video.play()
  })
}

export class VideoUtils {
  static getVideoSource(video?: Partial<IVideoModel>) {
    return video?.urlVideoStandardQuality || video?.urlVideoSource
  }

  static getVideoThumbnailAtTime() {
    const canvas = document.getElementById('canvasVideoCover') as HTMLCanvasElement
    const video = document.getElementById('containerVideoCover')?.children[0] as HTMLVideoElement
    if (!canvas || !video) {
      return
    }

    canvas.width = 720
    canvas.height = 1280
    canvas?.getContext('2d')?.drawImage(video, 0, 0, 720, 1280)
    return canvas?.toDataURL()
  }
}
