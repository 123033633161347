import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { PeopleApi } from 'src/api'
import { Button } from 'src/components'
import { VideoPlayerNew } from 'src/components/video-player-new'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IconChat, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { Img } from 'src/images'
import { ICampaignSubmissionModel, IReactionModel } from 'src/interfaces'
import { ModalApplicantDetail } from 'src/partials/modal-applicant-detail'
import { ModalViewVideo } from 'src/partials/modal-view-video'
import { ERoutes, generate } from 'src/router'
import { DialogService, SnackbarService } from 'src/services'
import {
  ReactionUtils,
  StyleUtils,
  VideoUtils,
  getAvatar,
  renderVideoTitle
} from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  isDetail?: boolean
  submission: ICampaignSubmissionModel
  onChange?: (values: Partial<ICampaignSubmissionModel>) => void
}

export const CampaignApplicantCard: FC<IProps> = ({ submission, isDetail, onChange }) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)

  const [loading, setLoading] = useState(false)
  const [showPreviewVideo, setShowPreviewVideo] = useState(false)

  const video = useMemo(() => submission?.video, [submission?.video])
  const reaction = useMemo(() => submission.peopleReaction, [submission.peopleReaction])
  const lookupId = useMemo(() => submission?.author?.lookupId, [submission?.author?.lookupId])

  const onToggleLike = useCallback(() => {
    if (!submission.authorId) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: submission.authorId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.({ peopleReaction: data || null })
      })
  }, [submission.authorId, onChangeRef, unsubscribe$])

  return (
    <div key={submission.id} className={Style.campaignContainer}>
      <div className="w-full relative bg-neutral-900 fx-ai-center fx-jc-center fx-row round-4 overflow-hidden">
        <div className="absolute b-6 z-3 w-full fx fx-ai-center fx-jc-space-between gap-4 px-4">
          <div className="fx fx-ai-center gap-4">
            <Img
              src={getAvatar(submission?.author, true)}
              width={32}
              height={32}
              alt="logo"
              className="round-10 bg-neutral-20"
              style={{ objectFit: 'cover' }}
            />

            {submission?.author?.username && (
              <div className="fs-12 fw-400 txt-neutral-white three-dot-1">@{submission?.author?.username}</div>
            )}
          </div>

          {onToggleLike && (
            <div className="fx fx-ai-center gap-3">
              <Button
                size={32}
                variant="icon"
                green={!!reaction}
                emphasis={ReactionUtils.isEmphasis(reaction as IReactionModel)}
                disabled={loading}
                onClick={onToggleLike}
              >
                {!reaction
                  ? <IconThumbsUp size={20}/>
                  : ReactionUtils.isEmphasis(reaction)
                    ? <IconThumbsUpDup size={20}/>
                    : <IconThumbsUpFilled size={20}/>}
              </Button>

              <Button
                size={32}
                variant="icon"
                onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId }))}
              >
                <IconChat size={20}/>
              </Button>
            </div>
          )}
        </div>

        <div
          className="w-full pointer"
          style={{
            aspectRatio: '9/16',
            ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
          }}
          onMouseEnter={() => setShowPreviewVideo(true)}
          onMouseLeave={() => setShowPreviewVideo(false)}
          onClick={() => video && DialogService.open(ModalViewVideo, { video })}
        >
          {showPreviewVideo && (
            <VideoPlayerNew
              mini
              autoplay
              hideControl
              hidePlayIcon
              hideStartEnd
              url={VideoUtils.getVideoSource(video) ?? ''}
              tracks={video?.tracks}
              image={video?.urlVideoImageThumbnail}
            />
          )}
        </div>
      </div>

      <div className="fw-600 txt-black-01 three-dot-1 text-center">{renderVideoTitle(submission?.video)}</div>

      {isDetail && (
        <Button
          className="w-fit"
          onClick={() => DialogService.open(ModalApplicantDetail, { submission, onChange })}
        >
          Details
        </Button>
      )}
    </div>
  )
}
