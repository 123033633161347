import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconEmailStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.49903 3.15408C6.70352 2.59046 8.06304 2.45005 9.3573 2.75558C10.6516 3.06112 11.8048 3.7947 12.63 4.83749C13.4553 5.88027 13.9043 7.17115 13.9043 8.50099L13.9043 9.03933C13.9043 9.60557 13.6794 10.1486 13.279 10.549C12.8786 10.9494 12.3356 11.1743 11.7693 11.1743C11.2031 11.1743 10.66 10.9494 10.2597 10.549C10.1694 10.4587 10.088 10.3612 10.0161 10.2577C9.52605 10.8194 8.80497 11.1743 8.001 11.1743C6.52455 11.1743 5.32766 9.97744 5.32766 8.50099C5.32766 7.02455 6.52455 5.82766 8.001 5.82766C8.61606 5.82766 9.18261 6.03538 9.63433 6.38448L9.63433 6.34766C9.63433 6.06047 9.86714 5.82766 10.1543 5.82766C10.4415 5.82766 10.6743 6.06047 10.6743 6.34766L10.6743 9.03933C10.6743 9.32974 10.7897 9.60826 10.995 9.81361C11.2004 10.019 11.4789 10.1343 11.7693 10.1343C12.0597 10.1343 12.3383 10.019 12.5436 9.81361C12.749 9.60826 12.8643 9.32974 12.8643 9.03933L12.8643 8.50099C12.8643 7.40543 12.4944 6.34197 11.8145 5.48289C11.1346 4.62382 10.1846 4.01947 9.11836 3.76776C8.05211 3.51605 6.9321 3.63173 5.9398 4.09606C4.9475 4.56038 4.14105 5.34615 3.6511 6.32605C3.16115 7.30595 3.01641 8.42257 3.24033 9.495C3.46425 10.5674 4.04371 11.5328 4.88483 12.2348C5.72595 12.9368 6.77945 13.3342 7.87464 13.3627C8.96983 13.3912 10.0425 13.049 10.919 12.3917C11.1487 12.2193 11.4747 12.2659 11.647 12.4957C11.8193 12.7254 11.7727 13.0513 11.543 13.2237C10.4791 14.0216 9.17701 14.4369 7.84761 14.4023C6.51822 14.3678 5.23944 13.8854 4.21845 13.0333C3.19746 12.1812 2.49409 11.0093 2.22228 9.70757C1.95048 8.4058 2.12617 7.05039 2.72089 5.86094C3.31562 4.6715 4.29453 3.7177 5.49903 3.15408ZM9.63433 8.50099C9.63433 7.59893 8.90306 6.86766 8.001 6.86766C7.09893 6.86766 6.36766 7.59893 6.36766 8.50099C6.36766 9.40306 7.09893 10.1343 8.001 10.1343C8.90306 10.1343 9.63433 9.40306 9.63433 8.50099Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
