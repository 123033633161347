import clsx from 'clsx'
import { ComponentProps, FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, PeopleApi } from 'src/api'
import { Button, ModalCenter, Textarea } from 'src/components'
import { VideoPlayerNew } from 'src/components/video-player-new'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IconChat, IconEmailStroke, IconFile, IconLinkedInStroke, IconPhoneStroke, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { Img } from 'src/images'
import { ICampaignSubmissionModel, IReactionModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { SnackbarService } from 'src/services'
import {
  FileUtils,
  ReactionUtils,
  StyleUtils,
  VideoUtils,
  convertPhoneNumToInternationalFormat,
  getAvatar,
  removePrefixPhoneNumber,
  renderVideoTitle
} from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  submission: ICampaignSubmissionModel
  onChange?: (values: Partial<ICampaignSubmissionModel>) => void
}

export const ModalApplicantDetail: FC<ComponentProps<typeof ModalCenter> & IProps> = ({ submission, onChange, ...props }) => {
  const history = useHistory()
  const unsubscribe$ = useUnsubscribe()
  const onChangeRef = useAsRef(onChange)

  const [privateNotes, setPrivateNotes] = useState<string>(submission.privateNote?.content || '')
  const [reaction, setReaction] = useState<IReactionModel | null>(submission.peopleReaction || null)
  const [loading, setLoading] = useState(false)

  const video = useMemo(() => submission?.video, [submission?.video])
  const lookupId = useMemo(() => submission?.author?.lookupId, [submission?.author?.lookupId])

  const handleSavePrivateNotes = useCallback(() => {
    if (!submission?.id || !submission.campaignId) return

    from(CampaignApi.writeNote(submission.campaignId, submission.id, privateNotes))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        })
      ).subscribe(({ data }) => {
        onChangeRef.current?.({ privateNote: data || '' })
      })
  }, [submission.campaignId, submission.id, onChangeRef, privateNotes, unsubscribe$])

  const onToggleLike = useCallback(() => {
    if (!submission.authorId) return

    setLoading(true)
    from(PeopleApi.toggleLike({ id: submission.authorId }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        onChangeRef.current?.({ peopleReaction: data || null })
        setReaction(data || null)
      })
  }, [submission.authorId, onChangeRef, unsubscribe$])

  return (
    <ModalCenter {...props} contentStyles={{ borderRadius: 0 }}>
      <div className={clsx(Style.modalApplicantDetail, 'p-8 fx fx-ai-center fx-jc-center')}>
        <div className={Style.applicantWrapper}>
          <div className={Style.videoContainer}>
            {submission && (
              <div className="fx fx-column gap-6">
                <div className="w-full relative bg-neutral-900 fx-ai-center fx-jc-center fx-row round-4 overflow-hidden">
                  <div className="absolute b-6 z-3 w-full fx fx-ai-center fx-jc-space-between gap-4 px-4">
                    <div className="fx fx-ai-center gap-4">
                      <Img
                        src={getAvatar(submission?.author, true)}
                        width={32}
                        height={32}
                        alt="logo"
                        className="round-10 bg-neutral-20"
                        style={{ objectFit: 'cover' }}
                      />

                      {submission?.author?.username && (
                        <div className="fs-12 fw-400 txt-neutral-white three-dot-1">@{submission?.author?.username}</div>
                      )}
                    </div>

                    {onToggleLike && (
                      <div className="fx fx-ai-center gap-3">
                        <Button
                          size={32}
                          variant="icon"
                          green={!!reaction}
                          emphasis={ReactionUtils.isEmphasis(reaction as IReactionModel)}
                          disabled={loading}
                          onClick={onToggleLike}
                        >
                          {!reaction
                            ? <IconThumbsUp size={20}/>
                            : ReactionUtils.isEmphasis(reaction)
                              ? <IconThumbsUpDup size={20}/>
                              : <IconThumbsUpFilled size={20}/>}
                        </Button>

                        <Button
                          size={32}
                          variant="icon"
                          onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES, { lookupId }))}
                        >
                          <IconChat size={20}/>
                        </Button>
                      </div>
                    )}
                  </div>

                  <div
                    className="w-full pointer"
                    style={{
                      aspectRatio: '9/16',
                      ...StyleUtils.backgroundCover(video?.urlVideoImageThumbnail)
                    }}
                  >
                    <VideoPlayerNew
                      url={VideoUtils.getVideoSource(video) ?? ''}
                      tracks={video?.tracks}
                      image={video?.urlVideoImageThumbnail}
                    />
                  </div>
                </div>

                <div className="fw-600 txt-black-01 three-dot-1 text-center">{renderVideoTitle(submission?.video)}</div>
              </div>
            )}
          </div>

          {/* TODO: clear duplicate */}
          <div className={Style.contactUser}>
            <div className="fx fx-column gap-3 mb-6 txt-grey-01">
              <div className="fw-500 txt-black-01">Contact</div>
              <div className="fx fx-ai-center gap-2">
                <IconPhoneStroke/>
                <div className="fs-12 three-dot-1">
                  {submission?.author?.userPhoneNo ? convertPhoneNumToInternationalFormat(removePrefixPhoneNumber(submission?.author?.userPhoneNo)) : '---'}
                </div>
              </div>
              <div className="fx fx-ai-center gap-2">
                <IconEmailStroke/>
                <div className="fs-12 three-dot-1">{submission?.author?.email || '---'}</div>
              </div>
              <div className="fx fx-ai-center gap-2">
                <IconLinkedInStroke/>
                <div className="fs-12 three-dot-1">{submission?.author?.linkedInUrl || '---'}</div>
              </div>
            </div>

            {submission?.resumeFileName && (
              <div className="fx fx-column gap-3 mb-6">
                <div className="fw-500 txt-black-01">Resume</div>
                <div className="fx gap-2">
                  <IconFile size={16}/>
                  <div className="fs-12 txt-grey-01 pointer" onClick={() => FileUtils.download(submission.resumeFileUrl, submission.resumeFileName)}>
                    {submission?.resumeFileName}
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className="fs-16 fw-500 txt-black-01 mb-3">Private Notes</div>
              <Textarea
                label=""
                placeholder="Only you can see your private notes"
                value={privateNotes}
                autoComplete="off"
                disableResize
                minRows={4}
                maxRows={6}
                style={{ marginTop: 8, fontSize: '14px', background: 'transparent' }}
                onChange={(event) => setPrivateNotes(event.target.value)}
                onBlur={handleSavePrivateNotes}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  )
}
