import { ComponentProps, FC } from 'react'

export const Dot: FC<Omit<ComponentProps<'span'>, 'children' | 'size'> & { size?: number }> = ({
  size = 12,
  color,
  ...props
}) => {
  return (
    <span
      {...props}
      style={{
        display: 'inline-block',
        color,
        ...props.style,
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
      }}
    />
  )
}
