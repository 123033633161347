import { useEffect, useRef, useState } from 'react'

export const useDebouncedLoading = (loading: boolean, debounceTime = 1000) => {
  const [debounceLoading, setDebounceLoading] = useState(true)
  const loadingAtRef = useRef(0)

  useEffect(() => {
    if (loading) {
      loadingAtRef.current = Date.now()
      setDebounceLoading(true)
      return
    }
    const timeout = setTimeout(() => {
      setDebounceLoading(false)
    }, Math.min(debounceTime, debounceTime - (Date.now() - loadingAtRef.current)))
    return () => clearTimeout(timeout)
  }, [loading, debounceTime])

  return debounceLoading
}
