import clsx from 'clsx'
import {
  CSSProperties,
  FC,
  useEffect,
  useMemo,
  useState
} from 'react'
import { IconFile } from 'src/icons'
import { Img } from 'src/images'
import { FileUtils } from 'src/utils'
import { VideoPlayerNew } from '../video-player-new'
import Style from './style.module.scss'

interface IProps {
  size?: number | string
  src?: string
  fileName?: string
  mimeType?: string
  file?: File | Blob
  allowInteraction?: boolean
  className?: string
  style?: CSSProperties
}

export const FilePreview: FC<IProps> = ({
  size = 50,
  src,
  fileName,
  mimeType,
  file,
  allowInteraction,
  ...props
}) => {
  const fileKind = useMemo(() => ({
    isPdf: FileUtils.isPdf(file || mimeType || src, !!mimeType),
    isPhoto: FileUtils.isPhoto(file || mimeType || src, !!mimeType),
    isAudio: FileUtils.isAudio(file || mimeType || src, !!mimeType),
    isVideo: FileUtils.isVideo(file || mimeType || src, !!mimeType)
  }), [file, mimeType, src])

  const isSupported = useMemo(
    () => Object.values(fileKind).some(Boolean),
    [fileKind]
  )

  const [previewUrl, setPreviewUrl] = useState<string>()
  useEffect(() => {
    if (src || !file) {
      return
    }
    const url = URL.createObjectURL(file)
    setPreviewUrl(url)
    return () => URL.revokeObjectURL(url)
  }, [src, file])

  const displayTxt = useMemo(
    () => (file as File)?.name || previewUrl,
    [file, previewUrl]
  )

  const previewContent = useMemo(() => {
    return !isSupported
      ? ''
      : (
        <>
          {fileKind.isPhoto && (
            <Img src={src || previewUrl} alt="preview"/>
          )}

          {fileKind.isAudio && (
            <audio src={src || previewUrl}/>
          )}

          {fileKind.isVideo && (
            allowInteraction
              ? (
                <VideoPlayerNew
                  url={src || previewUrl}
                  style={{
                    width: 'auto',
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%'
                  }}
                />
              )
              : (
                <>
                  <video
                    preload="metadata"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    ref={(video) => video?.load()}
                  >
                    <source
                      src={src || previewUrl}
                      type={mimeType || file?.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                </>
              )
          )}

          {fileKind.isPdf && (
            <div className="py-3 px-4 bg-neutral-100 round-10 fx fx-ai-center gap-2">
              <IconFile/> {fileName || src}
            </div>
          )}
        </>
      )
  }, [isSupported, fileKind.isPhoto, fileKind.isAudio, fileKind.isVideo, fileKind.isPdf, src, previewUrl, allowInteraction, mimeType, file?.type, fileName])

  return (
    <span
      title={displayTxt}
      style={{
        width: size,
        height: size,
        ...props.style
      }}
      className={clsx(Style.FilePreview, props.className)}
    >
      {previewContent}
    </span>
  )
}
