import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { uniqWith } from 'lodash'
import { FC, SVGProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import {
  EEmptyVideoVariant,
  IconWrapper,
  Tooltip,
  VideoPlayer,
  useAnalytic
} from 'src/components'
import {
  ECandidateReaction,
  EReactionTable,
  ETrackingEvent
} from 'src/enums'
import { useAppDispatch, useAppSelector, useElementSize, useUnsubscribe } from 'src/hooks'
import { IconLikes, IconNope, IconSuperLike } from 'src/icons'
import { ICandidateModel, IPaginationResponse } from 'src/interfaces'
import { SnackbarService } from 'src/services'
import {
  setLayoutAside,
  setLayoutLoading,
  setLayoutPageTitle
} from 'src/store/actions'
import { getLayoutIsScrollToBottom } from 'src/store/selectors'
import { ensureArray, getApiErrorMessage, getVideoSource } from 'src/utils'
import { Empty } from '../empty'
import Style from './style.module.scss'

interface IIcon {
  title: string
  event: ETrackingEvent
  action: ECandidateReaction
  icon: FC<SVGProps<SVGSVGElement>>
  color?: string
  active?: {
    color?: string
    background?: string
  }
}

export const Archive: FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const unsubscribe$ = useUnsubscribe()
  const isScrollToBottom = useAppSelector(getLayoutIsScrollToBottom)
  const { eventHandler } = useAnalytic()
  const [firstLoading, setFirstLoading] = useState(false)
  // const [isPlay, setIsPlay] = useState(false)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IPaginationResponse<ICandidateModel>>({
    page: 1,
    pages: 1,
    total: 0,
    rows: []
  })

  const icons: IIcon[] = useMemo(() => [
    {
      title: 'Nope',
      event: ETrackingEvent.BTN_VIDEO_TRASH,
      action: ECandidateReaction.NOPE,
      icon: IconNope,
      active: {
        color: theme.colors['--color-neutral-theme-50'],
        background: theme.colors['--color-negative-400']
      }
    },
    {
      title: 'Superlike',
      event: ETrackingEvent.BTN_VIDEO_SUPERLIKE,
      action: ECandidateReaction.SUPER_LIKE,
      icon: IconSuperLike,
      active: {
        color: theme.colors['--color-neutral-theme-50'],
        background: theme.colors['--color-orange-500']
      }
    },
    {
      title: 'Like',
      event: ETrackingEvent.BTN_VIDEO_LIKE,
      action: ECandidateReaction.LIKE,
      icon: IconLikes,
      color: theme.colors['--color-cyan-500'],
      active: {
        color: theme.colors['--color-neutral-theme-50'],
        background: theme.colors['--color-cyan-400']
      }
    }
  ], [theme.colors])

  const handleReaction = useCallback(async (candidate: ICandidateModel, action: ECandidateReaction) => {
    try {
      dispatch(setLayoutLoading(true))
      const toRemoveRow = data.rows?.find((row) => row.id === candidate.id)
      const toRemoveRowIndex = data.rows?.findIndex(
        (row) => row.id === candidate.id
      )
      const remainedRows =
          data.rows?.filter((row, index) => index !== toRemoveRowIndex) || []

      // remove the video from data
      setData((prev) => {
        return {
          ...prev,
          rows: remainedRows
        }
      })

      await CandidateApi.action(candidate.id, action, {
        reactionableType: EReactionTable.VIDEO,
        reactionableId: candidate.pfv?.id
      }).catch((error) => {
        // in case of api fail, add the video back to data
        if (!toRemoveRowIndex || !toRemoveRow) return
        const newRows = [...remainedRows]
        newRows.splice(toRemoveRowIndex, 0, toRemoveRow)

        setData((prev) => {
          return {
            ...prev,
            rows: newRows
          }
        })

        throw error
      })
    } catch (error) {
      SnackbarService.error(getApiErrorMessage(error))
    } finally {
      dispatch(setLayoutLoading(false))
    }
  }, [data.rows, dispatch])

  const loadArchive = useCallback((page = 1, limit = 10) => {
    if (page === 1) {
      setFirstLoading(true)

      setData({
        page: 1,
        pages: 1,
        total: 0,
        rows: []
      })
    }

    const promise = CandidateApi.pagination({
      page,
      limit,
      reaction: [ECandidateReaction.SKIP]
    })

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => {
          setFirstLoading(false)
          setLoading(false)
        })
      )
      .subscribe(({ data }) => {
        setData((prev) => ({
          page: data.page,
          total: data.total,
          pages: data.pages,
          rows: uniqWith(
            [...ensureArray(prev.rows), ...ensureArray(data.rows)],
            (a, b) => a.id === b.id
          )
        }))
      })
  }, [unsubscribe$])

  useEffect(() => {
    loadArchive()
  }, [loadArchive])

  const loadMorePage = useMemo(
    () => !loading && data.page && data.pages && data.page < data.pages ? data.page + 1 : false,
    [loading, data]
  )
  useEffect(() => {
    if (loadMorePage && isScrollToBottom) {
      loadArchive(loadMorePage)
    }
  }, [isScrollToBottom, loadArchive, loadMorePage])

  useEffect(() => {
    dispatch(setLayoutAside(true))
    dispatch(setLayoutPageTitle('Skips'))
  }, [dispatch])

  const playedMapRef = useRef<Map<number, boolean>>(new Map([]))
  const handleWatchedVideo = useCallback(
    async (videoId: number, playing: boolean) => {
      try {
        if (!playing) {
          return
        }

        if (playedMapRef.current.get(videoId)) {
          return
        }

        playedMapRef.current.set(videoId, true)
        await CandidateApi.play(videoId)
      } catch (error) {
        SnackbarService.error(getApiErrorMessage(error))
      }
    },
    []
  )

  const renderSkip = (candidate: ICandidateModel) => {
    return (
      <>
        <div className={Style.videoPlayerWrapper}>
          <VideoPlayer
            id="video"
            videoId={candidate.pfv?.id}
            tracks={candidate.pfv?.tracks}
            trackingEvent
            // isPlay={isPlay}
            image={candidate.pfv?.urlVideoImageThumbnail}
            animatedImage={candidate.pfv?.urlVideoAnimatedImage}
            url={getVideoSource(candidate.pfv)}
            onPlayingChange={(playing) => candidate.pfv && handleWatchedVideo(candidate.pfv?.id, playing)}
            mimeType={candidate.pfv?.internalSourceMetadata?.mimeType}
          />
        </div>

        <div className={Style.IconWrapper}>
          <div className={clsx('fx gap-4', Style.icons)}>
            {icons.map(
              ({ title, event, action, icon: Icon, color, active }) => (
                <IconWrapper
                  key={title}
                  id={title}
                  width={40}
                  height={40}
                  color={color}
                  active={active}
                  // disabled={isReactionSending}
                  className={Style.icon}
                  onClick={eventHandler(
                    {
                      key: event,
                      contextData: { videoId: candidate.pfv?.id }
                    },
                    () => handleReaction(candidate, action)
                  )}
                >
                  <Tooltip title={title}>
                    <Icon width={24} height={24}/>
                  </Tooltip>
                </IconWrapper>
              )
            )}
          </div>
        </div>
      </>
    )
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)

  return (
    <>
      <div className={clsx(Style.STContainer, { 'd-none': firstLoading || !data?.rows?.length })}>
        {data.rows?.map(candidate => (
          <div
            key={candidate.id}
            ref={containerRef}
            className={Style.vibes}
          >
            <div
              className={Style.vibesWrapper}
              style={{
                aspectRatio: '445 / 851',
                [elWidth / elHeight > 445 / 851 ? 'height' : 'width']: '100%'
              }}
            >
              {renderSkip(candidate)}
            </div>
          </div>
        ))}
      </div>

      <div className={clsx(Style.STContainer, { 'd-none': !firstLoading })}>
        <EEmptyVideoVariant.Skip/>
        <EEmptyVideoVariant.Skip/>
        <EEmptyVideoVariant.Skip/>
      </div>

      {!data.rows?.length && !firstLoading && <Empty/>}
    </>
  )
}
