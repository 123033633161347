import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconBookmark: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#111111' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.18984 3.19141C5.78842 3.19141 4.65234 4.32748 4.65234 5.72891V16.9758C4.65234 17.5157 5.2635 17.8289 5.70176 17.5136L10.0017 14.4199L14.3017 17.5136C14.74 17.8289 15.3511 17.5157 15.3511 16.9758V5.72891C15.3511 4.32748 14.2151 3.19141 12.8136 3.19141H7.18984ZM5.97734 5.72891C5.97734 5.05926 6.5202 4.51641 7.18984 4.51641H12.8136C13.4833 4.51641 14.0261 5.05926 14.0261 5.72891V15.6831L10.3887 13.066C10.1575 12.8997 9.84597 12.8997 9.61483 13.066L5.97734 15.6831V5.72891Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
