import { ESettingKeys } from 'src/enums'
import { TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class ManifestApi {
  static readonly _prefix = '/manifest'

  static get(): TAxiosResponseData<{ [key in ESettingKeys]?: string }> {
    return axiosHiringApi.get(this._prefix)
  }
}
