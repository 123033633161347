import clsx from 'clsx'
import { CSSProperties, ComponentProps, FC } from 'react'
import Style from './style.module.scss'

enum EDividerType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

interface IProps extends Omit<ComponentProps<'hr'>, 'children' | 'type' | 'color' | 'borderColor'> {
  type?: `${EDividerType}`
  color?: CSSProperties['color']
  borderColor?: CSSProperties['borderColor']
}

export const Divider: FC<IProps> = ({
  className,
  type = 'vertical',
  color = 'gray',
  borderColor,
  ...props
}) => {
  return (
    <hr
      {...props}
      className={clsx(Style.base, className, {
        [Style.horizontal]: type === EDividerType.HORIZONTAL,
        [Style.vertical]: type === EDividerType.VERTICAL
      })}
      style={{
        ...props.style,
        borderColor: color || borderColor
      }}
    />
  )
}
