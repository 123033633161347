import clsx from 'clsx'
import { FC, SyntheticEvent, useMemo } from 'react'
import Styles from './style.module.scss'

interface IProps {
  mini?: boolean
  value: number // percentage
  onChange?: (value: number) => void
  onMouseUpCapture?: (value: number) => void
  style?: React.CSSProperties
}

export const Range: FC<IProps> = (props) => {
  const inputValue = useMemo(
    () => props.value * 100,
    [props.value]
  )

  const handleChange = (e: Event|SyntheticEvent) => {
    e.stopPropagation()
  }

  return (
    <input
      className={clsx([Styles.PlayerRange, {
        [Styles.Mini]: props.mini
      }])}
      style={{ backgroundSize: `${inputValue}% 100%`, zIndex: 2, ...(props.style || {}) }}
      type="range"
      min={0}
      max={100}
      value={inputValue}
      onChange={e => props.onChange && props.onChange(e.target.valueAsNumber / 100)}
      onClick={handleChange}
      onMouseUpCapture={(e) => props?.onMouseUpCapture && props.onMouseUpCapture((e.target as HTMLInputElement).valueAsNumber / 100)}
    />
  )
}
