import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChecked: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 10, color = '#fff' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.24386 5.28686L1.34736 3.39036C1.25305 3.29928 1.12675 3.24888 0.995655 3.25002C0.864557 3.25116 0.739151 3.30374 0.646447 3.39645C0.553743 3.48915 0.501158 3.61456 0.500019 3.74566C0.49888 3.87675 0.549277 4.00306 0.640356 4.09736L2.89036 6.34736C2.98412 6.44109 3.11127 6.49375 3.24386 6.49375C3.37644 6.49375 3.50359 6.44109 3.59736 6.34736L9.09736 0.847356C9.18843 0.753055 9.23883 0.626754 9.23769 0.495655C9.23655 0.364557 9.18397 0.239151 9.09126 0.146447C8.99856 0.0537427 8.87315 0.00115811 8.74206 1.89012e-05C8.61096 -0.00112031 8.48466 0.0492771 8.39036 0.140356L3.24386 5.28686Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
