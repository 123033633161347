import { AxiosRequestConfig } from 'axios'
import { RECORD_FILE_NAME } from 'src/constants'
import { EFileUploadKind, ESourceFrom, IPlaceholder, TAxiosResponseData } from 'src/interfaces'
import { EUploadStatus, UploadService } from 'src/services/upload.service'
import { IS3PresignedMultipartResult, IS3PresignedResult, presignedMultipartUpload, S3Utils } from 'src/utils/s3.utils'
import { axiosHiringApi } from './axios'

export class UploadApi {
  static readonly _prefix = '/upload'

  static getPlaceholders(): TAxiosResponseData<{ placeholders: IPlaceholder[]}> {
    return axiosHiringApi.get(`${UploadApi._prefix}/placeholders`)
  }

  static presigned(
    params: {
      kind: EFileUploadKind
      contentType: string
      filename?: string
    }
  ): TAxiosResponseData<IS3PresignedResult> {
    const contentType = params.contentType.split(';')[0]
    return axiosHiringApi.get(`${UploadApi._prefix}/presigned`, { params: { ...params, contentType } })
  }

  static presignedMultipart(
    params: {
      entity?: string
      kind?: EFileUploadKind
      contentType?: string
      filename?: string
      type?: string
      size: number
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<IS3PresignedMultipartResult> {
    return axiosHiringApi.get(`${UploadApi._prefix}/presigned-multipart`, { ...config, params })
  }

  static abortPresignedMultipartUpload(params: Omit<IS3PresignedMultipartResult, 'parts'>): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${UploadApi._prefix}/presigned-multipart`, { params })
  }

  static completePresignedMultipartUpload(payload: Omit<IS3PresignedMultipartResult, 'parts'> & { parts: Awaited<ReturnType<typeof presignedMultipartUpload>>}): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${UploadApi._prefix}/presigned-multipart`, payload)
  }

  /**
   * @deprecated Use `uploadF` instead
   * TODO: Remove this method
   */
  static async upload(payload: {
    kind: EFileUploadKind
    file: File | Blob
    filename?: string
    sessionId?: string
  }, options?: {
    id: string
  }): Promise<string> {
    const presignedParams = {
      kind: payload.kind,
      contentType: payload.file.type,
      size: payload.file.size,
      filename: payload.filename,
      sourceFrom: (payload.file as File).name === RECORD_FILE_NAME
        ? ESourceFrom.RECORD
        : undefined,
      sessionId: payload.sessionId
    }

    // always use multipart upload for Tagging support
    // if (payload.file.size <= UPLOAD_CHUNK_SIZE) {
    //   const { data: signedResult } = await UploadApi.presigned(presignedParams)
    //   await presignedUpload(signedResult, payload.file)
    //   return signedResult.fields.key
    // }

    const { data: signedResult } = await UploadApi.presignedMultipart(presignedParams)
    const parts = await presignedMultipartUpload(signedResult, payload.file, options)
    await UploadApi.completePresignedMultipartUpload({ ...signedResult, parts })

    if (options?.id) {
      UploadService.updateTrackUploadItem(options.id, 100, EUploadStatus.COMPLETED)
    }

    return signedResult.key
  }

  static async uploadF(
    { file, ...payload }: Omit<Parameters<typeof UploadApi.presignedMultipart>[0], 'type' | 'size'> & {
      file: File | Blob
    },
    config?: AxiosRequestConfig
  ): Promise<string> {
    const { data: signedResult } = await UploadApi.presignedMultipart({
      ...payload,
      entity: payload.entity,
      type: file.type,
      size: file.size
    })

    const { promise } = S3Utils.presignedMultipartUpload(signedResult, file, config)
    const parts = await promise

    await UploadApi.completePresignedMultipartUpload({ ...signedResult, parts })
    return signedResult.key
  }

  static fetchContent(file: File, sessionId?: string): TAxiosResponseData<{ content: string }> {
    const formData = new FormData()
    formData.append('sessionId', sessionId || '')
    formData.append('file', file)
    return axiosHiringApi.post(`${UploadApi._prefix}/fetch-content`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
