import { StorageUtils } from './storage.utils'

const tokenKey = '_tk'
const refreshTokenKey = '_rftk'

const config = {
  token: StorageUtils.getItem(tokenKey),
  refreshToken: StorageUtils.getItem(refreshTokenKey)
}

export class AuthUtils {
  /**
   * Get token
   * @return {string}
   */
  static getToken(isRefresh = false) {
    return isRefresh
      ? config.refreshToken
      : config.token
  }

  /**
   * Set token
   * @param {string} token
   */
  static setToken(token?: string, refreshToken?: string) {
    if (token) {
      config.token = token
      StorageUtils.setItem(tokenKey, token)
    }
    if (refreshToken) {
      config.refreshToken = refreshToken
      StorageUtils.setItem(refreshTokenKey, refreshToken)
    }
  }

  /**
   * Remove storage item
   */
  static clear() {
    if (config.token) {
      config.token = ''
      StorageUtils.removeItem(tokenKey)
    }
    if (config.refreshToken) {
      config.refreshToken = ''
      StorageUtils.removeItem(refreshTokenKey)
    }
  }

  static get tokenKey() {
    return tokenKey
  }

  static get refreshTokenKey() {
    return refreshTokenKey
  }
}
