import { ComponentProps, FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EAuthView } from 'src/interfaces'
import { ModalAuthentication } from 'src/partials'
import { ERoutes, generate } from 'src/router'
import { RegisterEmailForm } from './register-email'
import { VerifyEmail } from './verify-email'

interface IProps {
  onSwitch?: (stage: ComponentProps<typeof ModalAuthentication>['stage']) => void
}

export const SignUpForm: FC<IProps> = ({ onSwitch }) => {
  const history = useHistory()
  const [view, setView] = useState<EAuthView>(EAuthView.EMAIL_REGISTER)
  const [email, setEmail] = useState<string>()
  const [inviteToken, setInviteToken] = useState<string>()

  const goToSignIn = useCallback(() => {
    if (onSwitch) {
      return onSwitch('sign-in')
    }
    history.push(generate(ERoutes.SIGN_IN))
  }, [history, onSwitch])

  const renderView = useMemo(() => {
    switch (view) {
      case EAuthView.EMAIL_VERIFIED:
        return (
          <VerifyEmail
            email={email}
            inviteToken={inviteToken}
          />
        )

      default:
        return (
          <RegisterEmailForm
            onSuccess={(email, inviteToken) => {
              setEmail(email)
              setInviteToken(inviteToken)
              setView(EAuthView.EMAIL_VERIFIED)
            }}
            onSignIn={goToSignIn}
          />
        )
    }
  }, [view, email, inviteToken, goToSignIn])

  return renderView
}
