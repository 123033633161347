import { useEffect, useMemo, useState } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { ICampaignDetail } from 'src/interfaces'
import { useCampaignSalary } from 'src/modules/campaign/hooks/useCampaignSalary'
import { formatJobLocation } from 'src/modules/campaign/utils'
import { CampaignMutationService } from 'src/services'
import { AuthModule } from 'src/store'
import { getVideoSource } from 'src/utils'

export const useJobMediaUrl = () => {
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const profile = useBehaviorMapper(AuthModule.profile$)

  const [uploadVideoUrl, setUploadVideoUrl] = useState<string>()
  const [logoUrl, setLogoUrl] = useState<string>()

  useEffect(() => {
    if (!data.uploadVideoFile && !data.uploadVideoUrl) {
      // use pfv
      setUploadVideoUrl(getVideoSource(profile.pfv))
      return
    }

    if (data.uploadVideoUrl) {
      setUploadVideoUrl(data.uploadVideoUrl)
    }

    if (data.uploadVideoFile) {
      const url = URL.createObjectURL(data.uploadVideoFile)
      setUploadVideoUrl(url)

      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [data, profile])

  useEffect(() => {
    if (data.logoUrl) {
      setLogoUrl(data.logoUrl)
    }

    if (data.logoFile) {
      const url = URL.createObjectURL(data.logoFile)

      setLogoUrl(url)

      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [data.logoUrl, data.logoFile])

  return { logoUrl, uploadVideoUrl }
}

export const useJobLocation = (): string[] => {
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const location = useMemo(() => formatJobLocation(data), [data])
  return location
}

export const useSalaryValue = () => {
  const data = useBehaviorMapper(CampaignMutationService.data$)
  const salaryValue = useCampaignSalary(data)
  return salaryValue
}

export const useCampaignRequirements = (data?: Pick<ICampaignDetail, 'requireLinkedInUrl' | 'requireZipCode' | 'requiredResume'>) => {
  return useMemo(() => [
    data?.requiredResume ? 'Resume' : '',
    data?.requireLinkedInUrl ? 'LinkedIn Profile' : '',
    data?.requireZipCode ? 'Zip Code of Resident' : ''
  ].filter(Boolean).join(', '), [data])
}
