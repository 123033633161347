import { UnregisterCallback } from 'history'
import React, { ComponentProps } from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject, finalize, take, tap } from 'rxjs'
import { PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'
import { ViewJobCompleted, type TourPopper } from 'src/partials/tour'
import { ERoutes, generate } from 'src/router'
import { Target } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'
import { OverlayService } from '../overlay.service'
import { PopupTourService } from './popup.service'
import { TARGET_JOB_VIEW_BY } from './share-job-tour.service'

const MAX_STEP_TOTAL = 2

export const TARGET_GUEST_ONBOARDING_APPLICANT = new Target('target-guest-onboarding-applicant')

const STEP_CONFIGS: ComponentProps<typeof TourPopper>[] = [
  {
    title: 'Here Are Your Applicants',
    content: 'Once you created a job and share it, applicants can submit their video vibe checks. Vibe their videos and pick the one you like!',
    currentStep: 0,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_GUEST_ONBOARDING_APPLICANT.getSelector(),
    arrowPosition: 'right',
    tip: 'Click on one video',
    ctaText: 'Next',
    onCtaClick: () => GuestOnboardingVibeApplicantTourService.nextStep(),
    onExitClick: () => {
      GuestOnboardingVibeApplicantTourService.stopTour(true)
    },
    width: '279px',
    zIndex: 0
  },
  {
    title: 'Show Applicant Insights',
    content: 'Access applicant\'s insights for a quick overview of their attributes.',
    arrowPosition: 'right',
    currentStep: 1,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_JOB_VIEW_BY.getSelector(),
    onBack: (history) => {
      GuestOnboardingVibeApplicantTourService.previousStep()
    },
    ctaText: 'End Tour',
    onCtaClick: (history) => {
      GuestOnboardingVibeApplicantTourService.nextStep(() => {
        history?.push(generate(ERoutes.CAMPAIGNS_PUBLIC_ONBOARDING))
      })
    },
    onExitClick: () => {
      GuestOnboardingVibeApplicantTourService.stopTour(true)
    }
  }
]

export class GuestOnboardingVibeApplicantTourService {
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _routeListener?: UnregisterCallback = undefined

  public static get currentStep$(): WithOutNextComplete<
    typeof GuestOnboardingVibeApplicantTourService._currentStep$
  > {
    return GuestOnboardingVibeApplicantTourService._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<
    typeof GuestOnboardingVibeApplicantTourService._enableTour$
  > {
    return GuestOnboardingVibeApplicantTourService._enableTour$
  }

  public static nextStep(onAfterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep + 1)

    if (currentStep + 1 === MAX_STEP_TOTAL) {
      PopupTourService.completeMission('guest-onboarding-vibe-applicants')
        .pipe(
          take(1),
          tap(() => {
            this.stopTour()
          }),
          finalize(() => {
            onAfterNext?.()
          })
        )
        .subscribe(() => {
          OverlayService.setOverlay({
            open: true,
            content: React.createElement(ViewJobCompleted, { toOnboarding: true })
          })
        })
    }
  }

  public static goToStep(step: number) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this._currentStep$.next(step)
  }

  public static previousStep() {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep - 1)
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    PopupTourService.hide()
    this._currentStep$.next(0)
    this._enableTour$.next(true)

    this.stopRouteListener()

    this._routeListener = history?.listen((location) => {
      if (!location.pathname.startsWith(`/${PUBLIC_CAMPAIGN_BASE_PATH}`)) {
        this.stopTour(true)
      }
    })
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour(showPopup = false) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopRouteListener()

    if (showPopup) {
      PopupTourService.show()
    }

    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }

  public static getStepConfig() {
    return STEP_CONFIGS[this._currentStep$.getValue()]
  }
}
