import clsx from 'clsx'
import { FC, useRef, useState } from 'react'
import { IconCheckmarkRegular } from 'src/icons'
import { truncateText } from 'src/utils'
import { Button } from '../button'
import { Illustration } from './illustration'
import Style from './style.module.scss'

interface IProps {
  url: string
}

export const JobPostedSuccess: FC<IProps> = (props) => {
  const [isCopied, setIsCopied] = useState(false)
  const ref = useRef<NodeJS.Timeout>()

  const copyLink = async () => {
    if (ref.current) {
      clearTimeout(ref.current)
    }

    setIsCopied(true)
    await navigator.clipboard.writeText(props.url)
    ref.current = setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <div className={Style.container}>
      <Illustration/>
      <span className={Style.title}>Awesome! Your job is created!</span>
      <span className={Style.content}>Your listing has been posted onto our marketplace - view it <a className="text-decorator-underline color-cyan-500" href={props.url} target="_blank" rel="noreferrer">here</a>.<br/>Share your job where you want to attract applicants!</span>
      <div className={clsx('fx flex-row gap-2 fx-ai-center w-100 fx-jc-center', Style.bottom)}>
        <div className={Style.jobLinkSection}>
          <span className="body2-regular color-neutral-theme-300 text-center">
            {truncateText(props.url, 24, 3)}
          </span>
        </div>
        <Button
          onClick={copyLink}
          style={{ width: 102 }}
        >
          {isCopied && <IconCheckmarkRegular/>}
          {isCopied ? 'Copied' : 'Copy Link'}
        </Button>
      </div>
    </div>
  )
}
