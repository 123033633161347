import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconCameraStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.96073 3.26454C7.08128 3.08371 7.28423 2.9751 7.50156 2.9751H12.5016C12.7189 2.9751 12.9218 3.08371 13.0424 3.26454L14.0994 4.8501H16.2516C16.7555 4.8501 17.2387 5.05028 17.5951 5.40659C17.9514 5.76291 18.1516 6.24619 18.1516 6.7501V15.5001C18.1516 16.004 17.9514 16.4873 17.5951 16.8436C17.2387 17.1999 16.7555 17.4001 16.2516 17.4001H3.75156C3.24765 17.4001 2.76438 17.1999 2.40806 16.8436C2.05174 16.4873 1.85156 16.004 1.85156 15.5001V6.7501C1.85156 6.24619 2.05174 5.76291 2.40806 5.40659C2.76438 5.05028 3.24765 4.8501 3.75156 4.8501H5.90369L6.96073 3.26454ZM7.84943 4.2751L6.7924 5.86065C6.67184 6.04148 6.46889 6.1501 6.25156 6.1501H3.75156C3.59243 6.1501 3.43982 6.21331 3.3273 6.32583C3.21478 6.43836 3.15156 6.59097 3.15156 6.7501V15.5001C3.15156 15.6592 3.21478 15.8118 3.3273 15.9244C3.43982 16.0369 3.59243 16.1001 3.75156 16.1001H16.2516C16.4107 16.1001 16.5633 16.0369 16.6758 15.9244C16.7883 15.8118 16.8516 15.6592 16.8516 15.5001V6.7501C16.8516 6.59097 16.7883 6.43836 16.6758 6.32583C16.5633 6.21331 16.4107 6.1501 16.2516 6.1501H13.7516C13.5342 6.1501 13.3313 6.04148 13.2107 5.86065L12.1537 4.2751H7.84943ZM10.0016 8.6501C8.80725 8.6501 7.83906 9.61828 7.83906 10.8126C7.83906 12.0069 8.80725 12.9751 10.0016 12.9751C11.1959 12.9751 12.1641 12.0069 12.1641 10.8126C12.1641 9.61828 11.1959 8.6501 10.0016 8.6501ZM6.53906 10.8126C6.53906 8.90031 8.08928 7.3501 10.0016 7.3501C11.9138 7.3501 13.4641 8.90031 13.4641 10.8126C13.4641 12.7249 11.9138 14.2751 10.0016 14.2751C8.08928 14.2751 6.53906 12.7249 6.53906 10.8126Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
