import { combineReducers } from 'redux'
import { reducer as layout } from './layout'
import { reducer as notification } from './notification'
import { reducer as payment } from './payment'
import { reducer as vibes } from './vibes'

export const reducers = combineReducers({
  layout,
  payment,
  notification,
  vibes
})
