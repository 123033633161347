import { Box, Typography, useTheme } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
import { Button } from 'src/components'
import Style from './style.module.scss'

interface IProps {
  onCancel?: () => void
  onDelete?: () => void
}

export const DeleteConfirmPopup: FC<IProps> = (props) => {
  const theme = useTheme()

  return (
    <div className={clsx('fx flex-column gap-8 p-10 fx-ai-center fx-jc-center', Style.container)}>
      <div className="fx flex-column gap-2 fx-jc-center">
        <Typography textAlign="center" color={theme.colors['--color-neutral-theme-900']} variant="h5">Are you sure you want to delete this job?</Typography>
        <Typography textAlign="center" color={theme.colors['--color-neutral-theme-300']} variant="body2-regular">
          This job and its applicants will be permanently removed and you won't be able to see them again.
        </Typography>
      </div>
      <Box display="flex" flexDirection="row" gap={2} width="100%" justifyContent="center">
        <Button
          className="w-100-p round-3"
          variant="secondary"
          onClick={props.onCancel}
        >Cancel
        </Button>
        <Button
          className="w-100-p round-3"
          red
          onClick={props.onDelete}
        >Delete
        </Button>
      </Box>
    </div>
  )
}
