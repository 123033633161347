import { IVideoModel } from 'src/interfaces'

const videos: Partial<IVideoModel & { raw?: string }>[] = [
  {
    raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/taryn.mov',
    urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/taryn.mov-watermark.mp4',
    urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/taryn.mov/2024-09-13/thumbnail-00001.jpg',
    urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/taryn.mov/2024-09-13/animated-100x.gif',
    urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/taryn.mov/2024-09-13/hls/master.m3u8',
    tracks: [
      {
        label: 'American English',
        kind: 'subtitles',
        src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/taryn.mov-track.vtt',
        srcLang: 'en-US',
        default: true
      }
    ]
  },
  {
    raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/swayne.mp4',
    urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/swayne.mp4-watermark.mp4',
    urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/swayne.mp4/2024-09-13/thumbnail-00001.jpg',
    urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/swayne.mp4/2024-09-13/animated-100x.gif',
    urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/swayne.mp4/2024-09-13/hls/master.m3u8',
    tracks: [
      {
        label: 'American English',
        kind: 'subtitles',
        src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/swayne.mp4-track.vtt',
        srcLang: 'en-US',
        default: true
      }
    ]
  },
  {
    raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/sarah-nelson-vvc.mov',
    urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/sarah-nelson-vvc.mov-watermark.mp4',
    urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/sarah-nelson-vvc.mov/2024-12-28-0546/thumbnail-00001.jpg',
    urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/sarah-nelson-vvc.mov/2024-12-28-0546/animated-100x.gif',
    urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/sarah-nelson-vvc.mov/2024-12-28-0546/hls/master.m3u8',
    tracks: [
      {
        label: 'American English',
        kind: 'subtitles',
        src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/sarah-nelson-vvc.mov-track.vtt',
        srcLang: 'en-US',
        default: true
      }
    ]
  }
  // {
  //   raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/melissa-lui.mp4',
  //   urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/melissa-lui.mp4-watermark.mp4',
  //   urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/melissa-lui.mp4/2024-09-13/thumbnail-00001.jpg',
  //   urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/melissa-lui.mp4/2024-09-13/animated-100x.gif',
  //   urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/melissa-lui.mp4/2024-09-13/hls/master.m3u8',
  //   tracks: [
  //     {
  //       label: 'American English',
  //       kind: 'subtitles',
  //       src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/melissa-lui.mp4-track.vtt',
  //       srcLang: 'en-US',
  //       default: true
  //     }
  //   ]
  // },
  // {
  //   raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/andrew.mov',
  //   urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/andrew.mov-watermark.mp4',
  //   urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/andrew.mov/2024-09-13/thumbnail-00001.jpg',
  //   urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/andrew.mov/2024-09-13/animated-100x.gif',
  //   urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/andrew.mov/2024-09-13/hls/master.m3u8',
  //   tracks: [
  //     {
  //       label: 'American English',
  //       kind: 'subtitles',
  //       src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/andrew.mov-track.vtt',
  //       srcLang: 'en-US',
  //       default: true
  //     }
  //   ]
  // },
  // {
  //   raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/ginno-bergunio.mov',
  //   urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/ginno-bergunio.mov-watermark.mp4',
  //   urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/ginno-bergunio.mov/2024-09-13/thumbnail-00001.jpg',
  //   urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/ginno-bergunio.mov/2024-09-13/animated-100x.gif',
  //   urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/ginno-bergunio.mov/2024-09-13/hls/master.m3u8',
  //   tracks: [
  //     {
  //       label: 'American English',
  //       kind: 'subtitles',
  //       src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/ginno-bergunio.mov-track.vtt',
  //       srcLang: 'en-US',
  //       default: true
  //     }
  //   ]
  // },
  // {
  //   raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/stephanie.mp4',
  //   urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/stephanie.mp4-watermark.mp4',
  //   urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/stephanie.mp4/2024-09-13/thumbnail-00001.jpg',
  //   urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/stephanie.mp4/2024-09-13/animated-100x.gif',
  //   urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/stephanie.mp4/2024-09-13/hls/master.m3u8',
  //   tracks: [
  //     {
  //       label: 'American English',
  //       kind: 'subtitles',
  //       src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/stephanie.mp4-track.vtt',
  //       srcLang: 'en-US',
  //       default: true
  //     }
  //   ]
  // },
  // {
  //   raw: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/alex-ryan.mov',
  //   urlVideoSource: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/alex-ryan.mov-watermark.mp4',
  //   urlVideoImageThumbnail: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/alex-ryan.mov/2024-09-13/thumbnail-00001.jpg',
  //   urlVideoAnimatedImage: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/alex-ryan.mov/2024-09-13/animated-100x.gif',
  //   urlVideoStandardQuality: 'https://dt0lhyoth840p.cloudfront.net/placeholders/videos/alex-ryan.mov/2024-09-13/hls/master.m3u8',
  //   tracks: [
  //     {
  //       label: 'American English',
  //       kind: 'subtitles',
  //       src: 'https://d269vzrvnhy0de.cloudfront.net/placeholders/videos/alex-ryan.mov-track.vtt',
  //       srcLang: 'en-US',
  //       default: true
  //     }
  //   ]
  // }
]

export const SAMPLE_VIDEO = Object.freeze(videos)
