import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconLinking: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.66693 5.22669C4.35765 5.22669 4.10693 4.97597 4.10693 4.66669C4.10693 4.35741 4.35765 4.10669 4.66693 4.10669L11.3336 4.10669C11.6429 4.10669 11.8936 4.35741 11.8936 4.66669L11.8936 11.3334C11.8936 11.6426 11.6429 11.8934 11.3336 11.8934C11.0243 11.8934 10.7736 11.6426 10.7736 11.3334L10.7736 6.01865L5.06291 11.7293C4.84422 11.948 4.48965 11.948 4.27095 11.7293C4.05226 11.5106 4.05226 11.1561 4.27095 10.9374L9.98164 5.22669L4.66693 5.22669Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
