import { IRouterOption } from 'src/router'
import { EPlayGroundRoutes } from './routes.enum'
import { PlayGround } from './screens/playground'

export const PlaygroundRoutes: IRouterOption[] = [
  {
    path: '/playground',
    component: PlayGround,
    name: EPlayGroundRoutes.PLAYGROUND,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
