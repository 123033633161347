import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { LinkedInExperienceItem } from 'src/components'
import { IconArrowDown } from 'src/icons'
import { ILinkedinWorkingExperience } from 'src/interfaces'
import { LinkedinUtil } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  linkedinWorkingExperiences?: ILinkedinWorkingExperience[]
  lightMode?: boolean
  className?: string
  onToggleCollapse?: () => void
}

export const CandidateInfoExperiencesSection: FC<IProps> = ({ onToggleCollapse, linkedinWorkingExperiences = [], lightMode = false, className }) => {
  const [open, setOpen] = useState(true)
  const itemRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    onToggleCollapse?.()
    setOpen(!open)
    itemRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (lightMode) {
      setOpen(false)
    }
  }, [lightMode])

  return (
    <div className={lightMode ? Style.containerLightMode : Style.STContainer}>
      {lightMode
        ? (
          <button className={Style.STHeaderLightMode} onClick={handleToggle}>
            <div
              className="fx fx-ai-center"
            >
              <div
                className={Style.STArrowIcon}
                style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
              >
                <IconArrowDown/>
              </div>
            </div>
            <p className={clsx(Style.STitle, className)}><span>Ex</span>perience</p>
          </button>
        )
        : (
          <button className={Style.STHeader} onClick={handleToggle}>
            <p className={clsx(Style.STitle, className)}>Experience</p>
            <div
              className="fx fx-ai-center"
            >
              <div
                className={Style.STArrowIcon}
                style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
              >
                <IconArrowDown/>
              </div>
            </div>
          </button>
        )}

      <Collapse in={open}>
        {LinkedinUtil.descByDate(linkedinWorkingExperiences).map((item, index) => (
          <LinkedInExperienceItem
            ref={index === 0 ? itemRef : null}
            isLast={index === linkedinWorkingExperiences.length - 1}
            isOnly={linkedinWorkingExperiences.length === 1}
            key={index}
            lightMode={lightMode}
            item={item}
          />
        ))}
      </Collapse>
    </div>
  )
}
