import clsx from 'clsx'
import {
  ComponentProps,
  FC,
  SyntheticEvent,
  useCallback,
  useState
} from 'react'
import {
  EMPTY,
  catchError,
  finalize,
  from,
  takeUntil
} from 'rxjs'
import { FeedbackApi } from 'src/api'
import { Button, ModalCenter, Select, Textarea } from 'src/components'
import { EFeedbackRelateType } from 'src/enums'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { SnackbarService } from 'src/services'
import { object, string } from 'yup'
import Style from './style.module.scss'

const schema = object().shape({
  reason: string().required('You need to select a reason.'),
  content: string().required('You need to enter your feedback.')
})

interface IProps {
  relateId?: number
}

export const ModalReport: FC<ComponentProps<typeof ModalCenter> & IProps> = (props) => {
  const unsubscribe$ = useUnsubscribe()

  const [formData, setFormData] = useState<{ reason: string; content: string }>({
    reason: '',
    content: ''
  })

  const { errors, validate } = useValidation({
    data: formData,
    schema
  })

  const [loading, setLoading] = useState(false)

  const onCloseRef = useAsRef(props.onClose)
  const onSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault()

      const { isValid } = await validate()
      if (!isValid) {
        return
      }

      setLoading(true)

      const payload = {
        ...formData,
        relateType: EFeedbackRelateType.USER,
        relateId: props.relateId
      }

      from(FeedbackApi.create(payload))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            SnackbarService.axiosError(error)
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(() => {
          onCloseRef.current?.()
          SnackbarService.success('Your report has been successful.')
        })
    },
    [formData, onCloseRef, props.relateId, unsubscribe$, validate]
  )

  return (
    <ModalCenter {...props}>
      <form onSubmit={onSubmit} className={clsx(Style.content, 'fx-column gap-8')}>
        <div className="fx fx-column fx-ai-center gap-2">
          <div className="fs-24 fw-500 lh-28 txt-black-01">Report Employer</div>
          <div className="txt-black-02">Please describe the problem below</div>
        </div>

        <div className="fx-column gap-3">
          <div className="fs-16 fw-bold">
            Reason <span className="txt-negative-500">*</span>
          </div>
          <Select
            placeholder="Please select one"
            options={[
              { label: 'Impersonation', value: 'Impersonation' },
              { label: 'Abuse or harassment', value: 'Abuse or harassment' },
              { label: 'Spam', value: 'Spam' },
              { label: 'Illegal activities', value: 'Illegal activities' },
              { label: 'Other', value: 'Other' }
            ]}
            onChange={value => setFormData(prev => ({ ...prev, reason: value as string || '' }))}
            value={formData.reason}
          />
          {errors.hasError('reason') && (
            <div className={Style.errorMsg}>
              {errors.getError('reason')}
            </div>
          )}
        </div>

        <div className="fx-column gap-3">
          <div className="fs-16 fw-bold">
            Details <span className="txt-negative-500">*</span>
          </div>
          <Textarea
            minRows={5}
            placeholder="Ask us anything, anytime."
            value={formData.content}
            onChange={e => setFormData(prev => ({ ...prev, content: e.target.value as string || '' }))}
          />
          {errors.hasError('content') && (
            <div className={Style.errorMsg}>
              {errors.getError('content')}
            </div>
          )}
        </div>

        <div className="fx-column gap-3">
          <Button
            type="submit"
            className="fw-bold p-4 fs-14"
            disabled={loading}
          >
            Submit
          </Button>
          <Button
            className="fw-bold p-4 fs-14"
            variant="secondary"
            disabled={loading}
            onClick={props.onClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </ModalCenter>
  )
}
