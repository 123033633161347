import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { Loading } from 'src/components'
import { EPaletteMode, TTheme, toCssVariables } from 'src/constants'
import { useAppSelector, useBehaviorMapper } from 'src/hooks'
import { OverlayService } from 'src/services'
import { getLayoutIsLoading } from 'src/store/selectors'
import { DialogContainer } from './dialog-container'
import { Overlay } from './overlay'
import { RouterView } from './router-view'
import { SnackbarContainer } from './snackbar-container'
import Style from './style.module.scss'

export * from './overlay'

const createThemeVariables = makeStyles<TTheme>(
  () => ({
    '@global': {
      ':root': () => toCssVariables(EPaletteMode.LIGHT)
    }
  })
)

export const AppLayout: FC = () => {
  const isLoading = useAppSelector(getLayoutIsLoading)
  const appOverlay = useBehaviorMapper(OverlayService.overlay$)
  createThemeVariables()

  return (
    <div className={clsx(Style.layout, { [Style.loading]: isLoading })}>
      <DialogContainer/>
      <SnackbarContainer/>

      <main className="fx-1 fx fx-column">
        <RouterView/>
      </main>

      {appOverlay.open && <Overlay/>}

      <Loading show={isLoading}/>
    </div>
  )
}
