import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconLoopkup: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 18, color = '#ffffff' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.40156 2.1501V15.8501H12.6016V6.5251H8.87656C8.51758 6.5251 8.22656 6.23408 8.22656 5.8751V2.1501H1.40156ZM9.52656 3.06934L11.6823 5.2251H9.52656V3.06934ZM0.475001 1.22354C0.71411 0.984428 1.03841 0.850098 1.37656 0.850098H8.87656C9.04895 0.850098 9.21428 0.91858 9.33618 1.04048L13.7112 5.41548C13.8331 5.53738 13.9016 5.70271 13.9016 5.8751V15.8751C13.9016 16.2132 13.7672 16.5375 13.5281 16.7767C13.289 17.0158 12.9647 17.1501 12.6266 17.1501H1.37656C1.03841 17.1501 0.714111 17.0158 0.475001 16.7767C0.235892 16.5375 0.101562 16.2132 0.101562 15.8751V2.1251C0.101562 1.78695 0.235893 1.46265 0.475001 1.22354ZM6.68906 9.0251C5.83992 9.0251 5.15156 9.71346 5.15156 10.5626C5.15156 11.4117 5.83992 12.1001 6.68906 12.1001C7.5382 12.1001 8.22656 11.4117 8.22656 10.5626C8.22656 9.71346 7.5382 9.0251 6.68906 9.0251ZM3.85156 10.5626C3.85156 8.99549 5.12195 7.7251 6.68906 7.7251C8.25617 7.7251 9.52656 8.99549 9.52656 10.5626C9.52656 11.1107 9.37116 11.6225 9.10203 12.0563L9.96118 12.9155C10.215 13.1693 10.215 13.5809 9.96118 13.8347C9.70734 14.0886 9.29578 14.0886 9.04194 13.8347L8.18279 12.9756C7.74895 13.2447 7.23715 13.4001 6.68906 13.4001C5.12195 13.4001 3.85156 12.1297 3.85156 10.5626Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
