import clsx from 'clsx'
import { ComponentProps, FC } from 'react'

interface IProps extends ComponentProps<'span'> {
  active?: boolean
}

export const BreadcrumbHeading: FC<IProps> = ({
  active,
  ...props
}) => {
  return (
    <span
      {...props}
      className={clsx('fs-16', props.className, {
        'fw-500 txt-neutral-N100': !active,
        'fw-700 txt-neutral-800': active
      })}
    >
      {props.children}
    </span>
  )
}
