import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconViewMore: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 24, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.59961 9.9985C2.59961 5.77393 6.02462 2.34961 10.2496 2.34961C14.4746 2.34961 17.8996 5.77393 17.8996 9.9985C17.8996 14.2229 14.4749 17.6471 10.2501 17.6474C9.09758 17.6492 7.96077 17.3896 6.92481 16.8896L4.15887 17.6096C3.94902 17.664 3.72785 17.6628 3.5187 17.6058C3.30954 17.5489 3.11888 17.4383 2.96558 17.285C2.81228 17.1317 2.70166 16.9411 2.64467 16.7319C2.58769 16.5228 2.58631 16.3023 2.64069 16.0925L3.36024 13.3269C2.86238 12.2992 2.59961 11.1665 2.59961 9.9985ZM11.2396 7.60893C10.977 7.34642 10.6209 7.19894 10.2496 7.19894H8.1496C7.7783 7.19894 7.4222 7.34642 7.15965 7.60893C6.8971 7.87144 6.7496 8.22748 6.7496 8.59872V11.3983C6.7496 11.7695 6.8971 12.1256 7.15965 12.3881C7.4222 12.6506 7.7783 12.7981 8.1496 12.7981H10.2496C10.6209 12.7981 10.977 12.6506 11.2396 12.3881C11.5021 12.1256 11.6496 11.7695 11.6496 11.3983V8.59872C11.6496 8.22748 11.5021 7.87144 11.2396 7.60893ZM12.3496 10.8118V9.18522L13.5634 8.03601C13.6379 7.96537 13.7315 7.91813 13.8326 7.90015C13.9337 7.88216 14.0379 7.89421 14.1322 7.9348C14.2265 7.9754 14.3069 8.04276 14.3633 8.12855C14.4197 8.21433 14.4497 8.31478 14.4496 8.41745V11.5795C14.4497 11.6822 14.4197 11.7827 14.3633 11.8684C14.3069 11.9542 14.2265 12.0216 14.1322 12.0622C14.0379 12.1028 13.9337 12.1148 13.8326 12.0968C13.7315 12.0789 13.6379 12.0316 13.5634 11.961L12.3496 10.8118Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
