import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconThumbsUpFilled: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = 'currentColor' /** #0C0C0C */ } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.05838 2.3526C8.3892 2.02179 8.83788 1.83594 9.30572 1.83594C9.75566 1.83591 10.1886 2.00782 10.516 2.3165C10.8426 2.62445 11.0395 3.04529 11.0666 3.49328L11.0697 3.603V6.23994C11.0697 6.35923 11.1664 6.45594 11.2857 6.45594H12.6058C13.038 6.45587 13.4552 6.61451 13.7783 6.90175C14.1005 7.18828 14.3065 7.58287 14.3576 8.01095L14.3667 8.11613L14.3694 8.21201L14.3587 8.31708L13.6975 11.6245C13.4576 12.6431 12.7782 13.2957 12.0604 13.2893L11.9562 13.2842C11.9527 13.284 11.9492 13.2839 11.9457 13.2839H6.66572C6.55698 13.2839 6.45203 13.244 6.37077 13.1717C6.29089 13.1007 6.23937 13.0033 6.22555 12.8974L6.22172 12.8335L6.22238 6.5464C6.22246 6.46854 6.24301 6.39207 6.28198 6.32466C6.32094 6.25725 6.37698 6.20125 6.44441 6.16232C6.75657 5.98204 7.01952 5.72763 7.21001 5.42159C7.4005 5.11555 7.51279 4.7673 7.53673 4.40761L7.54172 4.26742V3.59994C7.54172 3.1321 7.72757 2.68342 8.05838 2.3526Z"
          fill={color}
        />
        <path
          d="M2.61463 12.9894C2.41145 12.801 2.28659 12.5432 2.26474 12.267L2.26172 12.1763V7.55987C2.26163 7.28134 2.36682 7.01308 2.55621 6.80885C2.74462 6.60567 3.0025 6.48081 3.27862 6.45896L3.36932 6.45594H4.02572C4.13446 6.45596 4.23941 6.49588 4.32067 6.56814C4.40054 6.63917 4.45207 6.73657 4.46589 6.84243L4.46972 6.90639V12.8399C4.4697 12.9487 4.42977 13.0536 4.35751 13.1349C4.28649 13.2148 4.18908 13.2663 4.08321 13.2801L4.01927 13.2839H3.36565C3.08712 13.284 2.81886 13.1788 2.61463 12.9894Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
