import clsx from 'clsx'
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { IFirebaseNotification, NotificationService } from 'src/services'
import { Badge } from '../badge'
import Style from './style.module.scss'

interface IProps {
  id?: string
  watchKey: keyof IFirebaseNotification
  notRenderIfZero?: boolean
  onNotificationChange?: (newNotification: boolean) => void
}

export const NoticeBadge: FC<Omit<ComponentProps<typeof Badge>, keyof IProps> & IProps> = ({
  id,
  watchKey,
  notRenderIfZero,
  onNotificationChange,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [count, setCount] = useState(0)
  const keyIsNumber = useMemo(() => ['countNotifications', 'countCredits'], [])

  const onNotificationChangeRef = useAsRef(onNotificationChange)
  useEffect(() => {
    NotificationService.firebaseNotification$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => {
        if (keyIsNumber.includes(watchKey)) {
          const numberNotification = data[watchKey] as number ?? 0
          setCount(numberNotification)
          onNotificationChangeRef.current?.(!!numberNotification)
        }

        if (Array.isArray(data[watchKey])) {
          const arrayNotification = data[watchKey] as number[]
          setCount(arrayNotification.length)
        }
      })
  }, [keyIsNumber, onNotificationChangeRef, unsubscribe$, watchKey])

  if (notRenderIfZero && count === 0) {
    return null
  }

  return (
    <Badge
      {...props}
      className={clsx(Style.numBadge, props.className)}
      content={count}
    />
  )
}
