import clsx from 'clsx'
import {
  ComponentProps,
  FC,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useMemo,
  useState
} from 'react'
import CurrencyInput from 'react-currency-input-field'
import { IconWarning } from 'src/icons'
import { PasswordChecker } from '../password-checker'
import { TogglePassword } from '../toggle-password'
import Style from './style.module.scss'

enum EVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  INTERMEDIATE = 'intermediate'
}

interface IProps extends Pick<ComponentProps<typeof CurrencyInput>, 'onValueChange'> {
  wrapperClassName?: string
  variant?: `${EVariant}`
  type?: 'currency' | ComponentProps<'input'>['type'] | 'textarea'
  label?: string | ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
  isCheckPassword?: boolean
  innerError?: boolean
  error?: boolean | string
  errors?: string[] | Record<string, string>
}

export const Input = forwardRef<HTMLInputElement, Omit<ComponentProps<'input'>, keyof IProps> & IProps>(
  function _({
    className,
    wrapperClassName,
    variant,
    type,
    prefix,
    suffix,
    isCheckPassword,
    error,
    errors,
    innerError,
    onValueChange,
    ...props
  }, ref) {
    const [focus, setFocus] = useState(false)
    const errorMessage = useMemo(() =>
      error ||
        (Array.isArray(errors) && errors[0]) ||
        (Object.isObject(errors) && Object.values(errors as Record<string, string>)[0])
    , [error, errors])

    if (type === 'currency') {
      return (
        <InputLayout
          {...props}
          innerError={innerError}
          errorMessage={errorMessage}
          focus={focus}
          suffix={suffix}
          prefix={prefix}
          className={wrapperClassName}
        >
          <CurrencyInput
            {...props}
            onFocus={(...args) => {
              setFocus(true)
              props.onFocus?.(...args)
            }}
            onBlur={(...args) => {
              setFocus(false)
              props.onBlur?.(...args)
            }}
            step={Number(props.step) || 1}
            defaultValue={['number', 'string'].includes(typeof props.defaultValue) ? props.defaultValue as string : undefined}
            intlConfig={{
              locale: 'en-US',
              currency: 'USD'
            }}
            prefix=" "
            ref={ref}
            className={clsx(className, Style.input)}
            onValueChange={(value) => onValueChange?.(value ?? '')}
          />
        </InputLayout>
      )
    }

    if (type === 'password') {
      return (
        <InputLayout
          {...props}
          isCheckPassword={isCheckPassword}
          innerError={innerError}
          errorMessage={errorMessage}
          focus={focus}
          suffix={suffix}
          prefix={prefix}
          className={wrapperClassName}
        >
          <PasswordInput
            {...props}
            onFocus={(...args) => {
              setFocus(true)
              props.onFocus?.(...args)
            }}
            onBlur={(...args) => {
              setFocus(false)
              props.onBlur?.(...args)
            }}
            ref={ref}
            className={clsx(className, Style.input)}
          />
        </InputLayout>
      )
    }

    return (
      <InputLayout
        {...props}
        innerError={innerError}
        errorMessage={errorMessage}
        focus={focus}
        suffix={suffix}
        prefix={prefix}
        className={wrapperClassName}
      >
        <input
          {...props}
          value={props.value || ''}
          onFocus={(...args) => {
            setFocus(true)
            props.onFocus?.(...args)
          }}
          onBlur={(...args) => {
            setFocus(false)
            props.onBlur?.(...args)
          }}
          ref={ref}
          className={clsx(className, Style.input)}
        />
      </InputLayout>
    )
  }
)

interface IInputLayoutProps {
  value?: ComponentProps<'input'>['value']
  label?: string | ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
  children?: ReactNode
  isCheckPassword?: boolean
  innerError?: boolean
  errorMessage?: string | boolean
  className?: string
  focus: boolean
  disabled?: boolean
}

const InputLayout: FC<IInputLayoutProps> = (props) => {
  return (
    <div className={clsx('fx-column gap-2 relative', props.className)}>
      {props.label && <span className={Style.label}>{props.label}</span>}

      <div
        className={clsx(Style.inputContainer, {
          [Style.focused]: props.focus,
          [Style.error]: !!props.errorMessage,
          [Style.disabled]: props.disabled
        })}
      >
        {props.prefix}
        {props.children}
        {props.suffix}
      </div>

      {!props.isCheckPassword && Boolean(props.errorMessage) && (
        <span className={clsx({ [Style.innerError]: props.innerError, meta: !props.innerError }, 'fx fx-ai-center gap-1 txt-negative-500')}>
          <IconWarning size={16}/>
          {props.errorMessage}
        </span>
      )}

      {props.isCheckPassword && <PasswordChecker password={String(props.value || '')}/>}
    </div>
  )
}

const PasswordInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  function _(props, ref) {
    const [showPassword, setShowPassword] = useState(false)
    return (
      <>
        <input
          {...props}
          ref={ref}
          type={showPassword ? 'text' : 'password'}
        />
        <TogglePassword onClick={() => setShowPassword(!showPassword)}/>
      </>
    )
  }
)
