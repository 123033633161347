import { Typography, useTheme } from '@mui/material'
import { ComponentProps, FC } from 'react'
import { useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { IconPaperPlane } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  email: string
  onSubmit: ComponentProps<'form'>['onSubmit']
}

export const Success: FC<IProps> = ({ onSubmit, email }) => {
  const { eventHandler } = useAnalytic('pswd_reset2')
  const theme = useTheme()

  return (
    <div className={Style.forgotPasswordSuccess}>
      <IconPaperPlane size={120}/>
      <Typography variant="h4">Check your email</Typography>
      <Typography
        className={Style.subText}
        variant="body1-medium"
        color={theme.colors['--color-neutral-theme-300']}
      >
        We sent you a password reset link to <span className={Style.Email}>{email}</span>.
        Click the link to reset your password.
      </Typography>
      <Typography
        variant="body1-regular"
        className={Style.resendEmail}
        color={theme.colors['--color-neutral-theme-300']}
      >
        Didn’t receive the email?
        <br/>
        Check your spam folder or
        <Typography
          component="a"
          variant="body1-regular"
          sx={{ cursor: 'pointer' }}
          onClick={eventHandler(ETrackingEvent.BTN_RESEND_RESET_PSWD_LINK, onSubmit)}
        >
          {' '} click to resend.
        </Typography>
      </Typography>
    </div>
  )
}
