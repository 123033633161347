import { UnregisterCallback } from 'history'
import React, { ComponentProps } from 'react'
import { useHistory } from 'react-router'
import { BehaviorSubject, finalize, take, tap } from 'rxjs'
import { EEnv, PUBLIC_CAMPAIGN_BASE_PATH } from 'src/constants'
import type { TourPopper } from 'src/partials/tour'
import { WithOutNextComplete } from 'types/rxjs'
import { TARGET_ENTER_JOB_DETAIL, TARGET_SHOW_HUMAN_SIDE, TARGET_SHOW_ON_KNOWME } from './create-job-tour.service'
import { PopupTourService } from './popup.service'

const MAX_STEP_TOTAL = 3

const STEP_CONFIGS: ComponentProps<typeof TourPopper>[] = [
  {
    title: 'Show your human side!',
    content: React.createElement('div', { className: 'fx flex-column gap-4 body2-regular color-neutral-theme-900' },
      React.createElement('div', { className: 'fx flex-column' },
        React.createElement('span', null, '🎬 Lights, Camera, Hire!'),
        React.createElement('span', null, 'Share your story with a captivating video – introduce yourself, talk about the job, and show people why it would be great to work for you.'))
    ),
    currentStep: 0,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_SHOW_HUMAN_SIDE.getSelector(),
    ctaText: 'Next',
    onCtaClick: () => GuestOnboardingCreateJobTourService.nextStep(),
    arrowPosition: 'right',
    shouldScroll: true,
    onExitClick: () => GuestOnboardingCreateJobTourService.stopTour(true),
    zIndex: 999
  },
  {
    title: 'Enter your job details',
    content: 'Let\'s start by sharing some essential job details. No pressure if you\'re not ready to post a job yet – you can save a draft and come back to it later.',
    arrowPosition: 'right',
    currentStep: 1,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_ENTER_JOB_DETAIL.getSelector(),
    onBack: () => GuestOnboardingCreateJobTourService.previousStep(),
    ctaText: 'Next',
    onCtaClick: () => GuestOnboardingCreateJobTourService.nextStep(),
    onExitClick: () => GuestOnboardingCreateJobTourService.stopTour(true)
  },
  {
    title: 'Control where people see your job listing',
    content: React.createElement('span', null, 'By toggling this on, we\'ll amplify your reach by posting your job on ',
      React.createElement('a', { className: 'color-cyan-600 text-decorator-underline', href: EEnv.REACT_APP_CANDIDATE_WEB_DNS, target: '_blank' }, 'KnowMe candidate website\'s marketplace'), '. Let\'s get those applications flowing!'),
    currentStep: 2,
    maxStep: MAX_STEP_TOTAL,
    targetSelector: TARGET_SHOW_ON_KNOWME.getSelector(),
    arrowPosition: 'bottom',
    ctaText: 'End Tour',
    onCtaClick: () => GuestOnboardingCreateJobTourService.nextStep(),
    onBack: () => GuestOnboardingCreateJobTourService.previousStep(),
    shouldScroll: false,
    onExitClick: () => GuestOnboardingCreateJobTourService.stopTour(true),
    zIndex: 999
  }
]

export class GuestOnboardingCreateJobTourService {
  private static _currentStep$ = new BehaviorSubject(0)
  private static _enableTour$ = new BehaviorSubject(false)
  private static _routeListener?: UnregisterCallback = undefined
  private static _completed$ = new BehaviorSubject(false)

  public static get completed$(): WithOutNextComplete<
    typeof GuestOnboardingCreateJobTourService._completed$
  > {
    return GuestOnboardingCreateJobTourService._completed$
  }

  public static get currentStep$(): WithOutNextComplete<
    typeof GuestOnboardingCreateJobTourService._currentStep$
  > {
    return GuestOnboardingCreateJobTourService._currentStep$
  }

  public static get enableTour$(): WithOutNextComplete<
    typeof GuestOnboardingCreateJobTourService._enableTour$
  > {
    return GuestOnboardingCreateJobTourService._enableTour$
  }

  public static nextStep(onAfterNext?: () => void) {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep + 1)

    if (currentStep + 1 === MAX_STEP_TOTAL) {
      PopupTourService.completeMission('guest-onboarding-create-job')
        .pipe(
          take(1),
          tap(() => {
            this._completed$.next(true)
            this.stopTour()
            PopupTourService.hide()
          }),
          finalize(() => {
            onAfterNext?.()
          })
        )
        .subscribe(() => {
        })
    }
  }

  public static goToStep(step: number) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this._currentStep$.next(step)
  }

  public static previousStep() {
    if (!this._enableTour$.getValue()) {
      return
    }

    const currentStep = this._currentStep$.getValue()
    this._currentStep$.next(currentStep - 1)
  }

  public static startTour(history?: ReturnType<typeof useHistory>) {
    PopupTourService.hide()
    this._currentStep$.next(0)
    this._enableTour$.next(true)

    this.stopRouteListener()

    this._routeListener = history?.listen((location) => {
      if (!location.pathname.startsWith(`/${PUBLIC_CAMPAIGN_BASE_PATH}`)) {
        this.stopTour(true)
      }
    })
  }

  private static stopRouteListener() {
    if (this._routeListener) {
      this._routeListener()
      this._routeListener = undefined
    }
  }

  public static stopTour(showPopup = false) {
    if (!this._enableTour$.getValue()) {
      return
    }

    this.stopRouteListener()

    if (showPopup) {
      PopupTourService.show()
    }

    this._enableTour$.next(false)
    this._currentStep$.next(0)
  }

  public static getStepConfig() {
    return STEP_CONFIGS[this._currentStep$.getValue()]
  }
}
