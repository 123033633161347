import { FC, useCallback } from 'react'
import { Button } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IconEditRegular } from 'src/icons'
import { TourPopper } from 'src/partials/tour'
import { FirstTimeClaimedJobTourService } from 'src/services/tour/first-time-claimed-job.service'
import { TARGET_JOB_EDIT_BUTTON } from 'src/services/tour/share-job-tour.service'
import Style from './style.module.scss'

interface IProps {
  onClick?: () => void
}

export const ButtonEdit: FC<IProps> = ({ onClick }) => {
  const claimedTourEnabled = useBehaviorMapper(FirstTimeClaimedJobTourService.enableTour$)
  const claimedTourCurrentStep = useBehaviorMapper(FirstTimeClaimedJobTourService.currentStep$)

  const handleClick = useCallback(() => {
    if (!onClick) {
      return
    }

    if (claimedTourEnabled) {
      FirstTimeClaimedJobTourService.nextStep()
    }

    onClick()
  }, [onClick, claimedTourEnabled])

  return (
    <>
      <Button
        id={TARGET_JOB_EDIT_BUTTON.getValue()}
        type="button"
        variant="secondary"
        className={Style.button}
        onClick={handleClick}
      >
        <IconEditRegular/>
      </Button>
      {claimedTourEnabled && claimedTourCurrentStep === 0 && (
        <TourPopper {...FirstTimeClaimedJobTourService.getStepConfig()}/>
      )}
    </>
  )
}
