import { FC } from 'react'
import { Button } from 'src/components'
import { useBehaviorMapper } from 'src/hooks'
import { IconBell } from 'src/icons'
import { ModalAuthentication } from 'src/partials'
import { DialogService } from 'src/services/dialog.service'
import { AuthModule } from 'src/store'
import { NotificationBadge } from './notification-badge'

const UnAuNotification: FC = () => {
  return (
    <Button
      variant="icon"
      size={32}
      onClick={() => DialogService.open(ModalAuthentication, { stage: 'sign-up' })}
    >
      <IconBell/>
    </Button>
  )
}

export const Notification: FC = () => {
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)
  return isAuthenticated ? <NotificationBadge/> : <UnAuNotification/>
}
