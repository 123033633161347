import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconContactStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.5565 12.9868C10.4088 12.8391 10.2085 12.7561 9.99961 12.7561C9.79075 12.7561 9.59045 12.8391 9.44276 12.9868C9.29508 13.1344 9.21211 13.3347 9.21211 13.5436C9.21211 13.7525 9.29508 13.9528 9.44276 14.1004C9.59045 14.2481 9.79075 14.3311 9.99961 14.3311C10.2085 14.3311 10.4088 14.2481 10.5565 14.1004C10.7041 13.9528 10.7871 13.7525 10.7871 13.5436C10.7871 13.3347 10.7041 13.1344 10.5565 12.9868Z"
          fill={color}
        />
        <path
          d="M11.5309 6.49978C11.1248 6.09364 10.574 5.86548 9.99961 5.86548C9.42525 5.86548 8.87441 6.09364 8.46828 6.49978C8.06215 6.90591 7.83398 7.45674 7.83398 8.0311C7.83398 8.18775 7.89621 8.33797 8.00697 8.44874C8.11774 8.5595 8.26797 8.62173 8.42461 8.62173C8.58125 8.62173 8.73148 8.5595 8.84224 8.44874C8.95301 8.33797 9.01523 8.18775 9.01523 8.0311L9.02075 7.9303C9.04734 7.67993 9.16887 7.44926 9.36033 7.28575C9.5518 7.12223 9.79864 7.03829 10.0501 7.05121C10.3015 7.06412 10.5385 7.17289 10.7122 7.35516C10.8859 7.53742 10.9832 7.77932 10.984 8.0311C10.984 8.491 10.8596 8.6989 10.3697 9.18873L10.2366 9.32339C9.64287 9.94158 9.40898 10.3857 9.40898 11.1811C9.40898 11.3377 9.47121 11.488 9.58197 11.5987C9.69274 11.7095 9.84297 11.7717 9.99961 11.7717C10.1563 11.7717 10.3065 11.7095 10.4172 11.5987C10.528 11.488 10.5902 11.3377 10.5902 11.1811C10.5902 10.7212 10.7147 10.5133 11.2045 10.0235L11.3376 9.88882C11.9313 9.27063 12.1652 8.82648 12.1652 8.0311C12.1652 7.45674 11.9371 6.90591 11.5309 6.49978Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99961 2.34961C5.77462 2.34961 2.34961 5.77393 2.34961 9.9985C2.34961 11.1665 2.61238 12.2992 3.11024 13.3269L2.39069 16.0925C2.33631 16.3023 2.33769 16.5228 2.39467 16.7319C2.45166 16.9411 2.56228 17.1317 2.71558 17.285C2.86888 17.4383 3.05954 17.5489 3.26869 17.6058C3.47785 17.6628 3.69903 17.664 3.90887 17.6096L6.67481 16.8896C7.71077 17.3896 8.84758 17.6492 10.0001 17.6474C14.2249 17.6471 17.6496 14.2229 17.6496 9.9985C17.6496 5.77393 14.2246 2.34961 9.99961 2.34961ZM3.64961 9.9985C3.64961 6.4921 6.4924 3.64961 9.99961 3.64961C13.5068 3.64961 16.3496 6.4921 16.3496 9.9985C16.3496 13.5049 13.5068 16.3474 9.99961 16.3474L9.99856 16.3474C8.97152 16.3491 7.95954 16.1005 7.05016 15.6233L6.8276 15.5065L3.67275 16.3278L4.49359 13.1729L4.37618 12.95C3.90181 12.0496 3.64961 11.0429 3.64961 9.9985Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
