import { IRouterOption } from 'src/router'
import { WatchHome } from './components/home'
import { EWatchRoutes } from './routes.enum'

export const WatchRoutes: IRouterOption[] = [
  {
    path: '/watch/:hashId',
    component: WatchHome,
    name: EWatchRoutes.WATCH,
    exact: true,
    meta: {
      requireAuth: false
    }
  }
]
