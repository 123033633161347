import { FC, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { combineLatest, takeUntil } from 'rxjs'
import { useAppDispatch, useBehaviorMapper, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { ERoutes, generate } from 'src/router'
import { CampaignMutationService, CampaignService, SnackbarService } from 'src/services'
import { FirstTimeClaimedJobTourService } from 'src/services/tour/first-time-claimed-job.service'
import { setLayoutLoading } from 'src/store/actions'
import { ActionButtons } from './components/action-buttons'
import { CampaignDetailForm } from './components/detail'
import { CampaignInformation } from './components/information'
import Style from './style.module.scss'

export const ViewCampaignDetail: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const dispatch = useAppDispatch()
  const { campaignId } = useParams<{ campaignId: string }>()
  const router = useHistory()
  const dataError = useBehaviorMapper(CampaignService.error)
  const claimedTourEnabled = useBehaviorMapper(FirstTimeClaimedJobTourService.enableTour$)

  useUnsubscribeEffect((unsubscribe$) => {
    CampaignService.fetchCampaign(+campaignId, unsubscribe$)
  }, [campaignId])

  useEffect(() => {
    if (dataError) {
      router.push({ pathname: '/404' })
    }

    return () => {
      CampaignService.resetError()
    }
  }, [dataError, router])

  useEffect(() => {
    combineLatest([CampaignService.loading$, CampaignMutationService.loading$])
      .pipe(takeUntil(unsubscribe$))
      .subscribe((vals) => dispatch(setLayoutLoading(vals.some((val) => val))))
  }, [dispatch, unsubscribe$])

  useEffect(() => {
    const unblock = router.block(targetLocation => {
      if (targetLocation.pathname === generate(ERoutes.RECORDING) || targetLocation.pathname === generate(ERoutes.PREVIEW_CAMPAIGN)) {
        unblock()
        return
      }

      if (!CampaignMutationService.isPristine && !CampaignMutationService.isDirty) {
        SnackbarService.success('Your changes have been saved.')
      }

      unblock()
    })

    return () => {
      unblock()
    }
  }, [router])

  const containerStyle = useMemo(() => {
    if (claimedTourEnabled) {
      return { overflow: 'hidden' }
    }
  }, [claimedTourEnabled])

  return (
    <div className="relative" style={containerStyle}>
      <div className={Style.container}>
        <div className={Style.content}>
          <div className={Style.content_wrapper}>
            <CampaignDetailForm/>
            <CampaignInformation/>
          </div>
        </div>
      </div>
      <div className={Style.content_action}>
        <ActionButtons/>
      </div>
    </div>
  )
}
