import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconPhoneStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.76688 4.25289C3.58653 4.25289 3.41357 4.32453 3.28604 4.45206C3.16177 4.57633 3.09056 4.74375 3.08701 4.91911C3.22548 7.12618 4.16468 9.20711 5.72867 10.7711C7.29266 12.3351 9.37358 13.2743 11.5807 13.4128C11.756 13.4092 11.9234 13.338 12.0477 13.2137C12.1752 13.0862 12.2469 12.9132 12.2469 12.7329L12.2469 10.6849L9.98493 9.78017L9.31277 10.9004C9.17354 11.1325 8.87958 11.219 8.63687 11.0993C7.2313 10.4061 6.09371 9.26847 5.40051 7.86289C5.28081 7.62018 5.36728 7.32623 5.59934 7.18699L6.7196 6.51484L5.81482 4.25289L3.76688 4.25289ZM2.55065 3.71667C2.87321 3.3941 3.3107 3.21289 3.76688 3.21289L6.16688 3.21289C6.37951 3.21289 6.57071 3.34235 6.64968 3.53977L7.84968 6.53977C7.94447 6.77674 7.85327 7.04747 7.63441 7.17879L6.56121 7.82271C7.07916 8.69317 7.80659 9.4206 8.67706 9.93856L9.32098 8.86535C9.45229 8.6465 9.72303 8.55529 9.96 8.65008L12.96 9.85008C13.1574 9.92905 13.2869 10.1203 13.2869 10.3329L13.2869 12.7329C13.2869 13.1891 13.1057 13.6266 12.7831 13.9491C12.4605 14.2717 12.023 14.4529 11.5669 14.4529C11.5564 14.4529 11.5458 14.4526 11.5353 14.4519C9.06812 14.302 6.74108 13.2543 4.99328 11.5065C3.24547 9.75869 2.19777 7.43165 2.04783 4.96443C2.04719 4.95393 2.04687 4.94341 2.04687 4.93289C2.04687 4.47672 2.22809 4.03923 2.55065 3.71667Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
