/* layout actions */
export const LAYOUT_SET_ASIDE = Symbol('LAYOUT_SET_ASIDE')
/**
 * @deprecated
 */
export const LAYOUT_SET_PAGE_TITLE = Symbol('LAYOUT_SET_PAGE_TITLE')
export const LAYOUT_SET_SCROLL_TO_BOTTOM = Symbol('LAYOUT_SET_SCROLL_TO_BOTTOM')
export const LAYOUT_SET_LOADING = Symbol('LAYOUT_SET_LOADING')
export const LAYOUT_SET_SHARING_TOKEN = Symbol('LAYOUT_SET_SHARING_TOKEN')

/**
 * state
 */
export interface ILayoutState {
  isAside: boolean
  /**
   * deprecated
   */
  pageTitle: string | null
  isScrollToBottom: boolean
  isLoading: boolean
  sharingToken: string | null
}

/**
 * actions
 */
export interface ILayoutAction {
  type: typeof LAYOUT_SET_ASIDE | typeof LAYOUT_SET_PAGE_TITLE | typeof LAYOUT_SET_SCROLL_TO_BOTTOM | typeof LAYOUT_SET_LOADING | typeof LAYOUT_SET_SHARING_TOKEN
  value?: boolean | string | null | void
}
