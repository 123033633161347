export enum EComponentSize {
  SMALL = 'small',
  REGULAR = 'regular',
  LARGE = 'large'
}

export enum EComponentType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  INTERMEDIATE = 'intermediate'
}
