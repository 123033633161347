import { ComponentProps, FC, useMemo } from 'react'
import { IconWrapper } from '../wrapper'

type IProps = Omit<ComponentProps<typeof IconWrapper>, 'rotate'> & {
  pointTo?: 'top' | 'right' | 'bottom' | 'left' | 1 | 1.5 | 2 | 3 | 4 | 4.5 | 5 | 6 | 7 | 7.5 | 8 | 9 | 10 | 10.5 | 11 | 12
}

export const IconArrow: FC<IProps> = ({
  size = 20,
  color = 'currentColor',
  pointTo = 'right',
  ...props
}) => {
  const rotate = useMemo(() => ({
    left: 0,
    top: 90,
    right: 180,
    bottom: 270,
    1: 90 + (30 * 1),
    1.5: 90 + (30 * 1.5),
    2: 90 + (30 * 2),
    3: 90 + (30 * 3),
    4: 90 + (30 * 4),
    4.5: 90 + (30 * 4.5),
    5: 90 + (30 * 5),
    6: 90 + (30 * 6),
    7: 90 + (30 * 7),
    7.5: 90 + (30 * 7.5),
    8: 90 + (30 * 8),
    9: 90 + (30 * 9),
    10: 90 + (30 * 10),
    10.5: 90 + (30 * 10.5),
    11: 90 + (30 * 11),
    12: 90
  }[pointTo]), [pointTo])

  return (
    <IconWrapper {...props} size={size} color={color} rotate={rotate}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.60497 16.3131C8.68152 16.3885 8.77219 16.4482 8.87178 16.4885C8.97137 16.5289 9.07794 16.5493 9.18541 16.5485C9.29288 16.5477 9.39913 16.5258 9.49811 16.4839C9.5971 16.442 9.68686 16.3811 9.76229 16.3045C9.83772 16.228 9.89732 16.1373 9.93771 16.0377C9.9781 15.9381 9.99848 15.8316 9.99768 15.7241C9.99688 15.6166 9.97493 15.5104 9.93306 15.4114C9.8912 15.3124 9.83025 15.2226 9.7537 15.1472L5.36006 10.819H16.5446C16.7616 10.819 16.9697 10.7328 17.1231 10.5794C17.2766 10.426 17.3628 10.2178 17.3628 10.0009C17.3628 9.78385 17.2766 9.57575 17.1231 9.42231C16.9697 9.26887 16.7616 9.18267 16.5446 9.18267H5.36579L9.75452 4.85694C9.90502 4.70376 9.98927 4.49755 9.98908 4.28281C9.9889 4.06806 9.90429 3.862 9.75352 3.70908C9.60275 3.55616 9.3979 3.46865 9.18318 3.46542C8.96846 3.4622 8.76108 3.54353 8.60579 3.69185L2.9407 9.27349C2.84422 9.36863 2.76761 9.482 2.71532 9.60701C2.66303 9.73202 2.63611 9.86617 2.63611 10.0017C2.63611 10.1372 2.66303 10.2713 2.71532 10.3963C2.76761 10.5213 2.84422 10.6347 2.9407 10.7298L8.60579 16.3123L8.60497 16.3131Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
