import { FC, useMemo, useState } from 'react'
import { useDidMountEffect } from 'src/hooks'
import { IconChevronFilled, IconTalent } from 'src/icons'
import { ERoutes } from 'src/router'
import { NavItem } from '../nav-item'

interface IProps {
  expanded: boolean
}

let _expanded = true

export const TalentNav: FC<IProps> = ({ expanded: sidebarExpanded }) => {
  const [expanded, setExpanded] = useState(_expanded)

  useDidMountEffect(() => {
    _expanded = expanded
  }, [expanded])

  const showChild = useMemo(() => expanded && sidebarExpanded, [expanded, sidebarExpanded])

  return (
    <nav className="fx-column px-6 relative">
      <NavItem
        mini={!sidebarExpanded}
        route={ERoutes.TALENT_DASHBOARD}
        icon={<IconTalent className="svg-current-color"/>}
        label={(
          <div className="fx-1 fx fx-jc-space-between fx-ai-center">
            Talent
            <IconChevronFilled
              rotate={expanded ? 90 : 180}
              className="svg-current-color"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setExpanded((prev) => !prev)
              }}
            />
          </div>
        )}
        onClick={() => setExpanded((prev) => !prev)}
      />

      {showChild && (
        <>
          <NavItem
            className="pl-12"
            route={ERoutes.TALENT_MESSAGES}
            label="Messages"
          />
          <NavItem
            className="pl-12"
            route={ERoutes.TALENT_VIBE_TALENT}
            label="Vibe Talent"
          />
          <NavItem
            className="pl-12"
            route={ERoutes.TALENT_CONNECTIONS}
            label="All Connections"
          />
          <div
            style={{
              height: 'calc(100% - 44px)',
              position: 'absolute',
              top: '44px',
              left: '49px',
              width: '2px',
              backgroundColor: 'rgba(213, 250, 223, 0.3)'
            }}
          />
        </>
      )}
    </nav>
  )
}
