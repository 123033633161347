import { EIndustries, EJobTypes, EUserGuide, EWorkInterests } from 'src/enums'
import { ICompanyModel } from './company.model'
import { IFileUploadModel } from './file-upload.model'
import { ILinkedinEducation } from './linkedin-education.model'
import { ILinkedinWorkingExperience } from './linkedin-working-experience.model'
import { IModel } from './model'
import { IUserStatsModel } from './user-stat.model'
import { IVideoModel } from './video.model'

export enum ESalaryRange {
  RANGE = 'range',
  CUSTOM = 'custom',
  EXTRA_AMOUNT = 'extraAmount'
}

export enum ESalaryRate {
  PER_HOUR = 'perHour',
  PER_MONTH = 'perMonth',
  PER_YEAR = 'perYear'
}

export interface IUserModel extends IModel {
  id: number
  tenantId?: number
  lookupId?: string
  email?: string
  username?: string
  password?: string
  userPhoneNo?: string
  fullName?: string
  firstName?: string
  lastName?: string
  zipcode?: string
  bio?: string

  guide?: { [key in EUserGuide]?: boolean | number | string }
  settings?: Record<string, any>
  countLike?: number
  countSubmission?: number
  countInterest?: number
  countSubscribe?: number
  countApplied?: number
  countIntroduced?: number

  pfpId?: number
  pfvId?: number
  pfp?: IFileUploadModel
  pfv?: IVideoModel

  linkedInUrl?: string
  state?: string
  county?: string
  /**
   * @deprecated
   * Use `state` and `county` instead
   */
  address?: string
  portfolio?: string
  openForJob?: boolean
  jobTypes?: Array<`${EJobTypes}`>
  industries?: Array<`${EIndustries}`>
  interestedTrades?: boolean
  startWorkingDate?: string | Date
  resumeId?: number
  resume?: IFileUploadModel
  stats?: IUserStatsModel

  companies?: ICompanyModel[]
  scheduleUrl?: string
  linkedinEducations?: ILinkedinEducation[]
  linkedinWorkingExperiences?: ILinkedinWorkingExperience[]

  workingEmail?: string
  workInterests?: EWorkInterests[]
  willingToRelocate?: boolean
  personalAttributes?: string[]
}

export interface IAvatarUploaded {
  id: string
  lookupId: string
  tenantId: string
  userId: string
  url: string
  filename: string
  category: string
  description: string
  metadata: string
  createdAt: string
  updatedAt: string
  deactivatedAt: string
}
