import clsx from 'clsx'
import { FC, useCallback } from 'react'
import { ErrorMessage, Input, Select, useAnalytic } from 'src/components'
import { CAMPAIGN_SALARY_RANGE_OPTIONS, CAMPAIGN_SALARY_RATE_OPTIONS } from 'src/constants'
import { ETrackingEvent } from 'src/enums'
import { ESalaryRange, ESalaryRate, ICampaignDetail } from 'src/interfaces'
import { formatMoney } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  id?: string
  range?: ESalaryRange
  value?: string | { min?: number; max?: number } | number
  rate?: ESalaryRate
  onChange?: (param: {
    range?: ESalaryRange
    value?: ICampaignDetail['salaryValue']
    rate?: ESalaryRate
  }) => void
  error?: string
}

export const Salary: FC<IProps> = ({
  id,
  range = CAMPAIGN_SALARY_RANGE_OPTIONS[1].value,
  value = '',
  rate = CAMPAIGN_SALARY_RATE_OPTIONS[0].value,
  onChange,
  error = ''
}) => {
  const { eventHandler } = useAnalytic()

  const parseValueBeforeShowing = useCallback((plainValue: number) => plainValue === 0
    ? ''
    : formatMoney(
      plainValue
    ), [])

  const formatValueBeforeChanging = useCallback((plainValue: string) => {
    eventHandler({
      key: ETrackingEvent.SELECT_JOB_SALARY_ATM,
      contextData: {
        value: +(plainValue.replaceAll(',', ''))
      }
    })()

    return +(plainValue.replaceAll(',', ''))
  }, [eventHandler])

  const handleSelectRate = useCallback((v: ESalaryRate) => {
    onChange?.({ range, value, rate: v })

    eventHandler({
      key: ETrackingEvent.SELECT_JOB_SALARY_RATE,
      contextData: {
        rate: v
      }
    })()
  }, [eventHandler, onChange, range, value])

  return (
    <div>
      <div className="fx fx-ai-center gap-8">
        <div className="fx-1 fx fx-ai-center gap-3">
          <Input
            placeholder="Min"
            value={parseValueBeforeShowing((value as { min?: number; max?: number })?.min || 0)}
            onChange={(e) =>
              onChange?.({
                range,
                value: {
                  max: (value as any)?.max,
                  min: formatValueBeforeChanging(e.target.value)
                },
                rate
              })}
            className="py-1"
            wrapperClassName={clsx('fx-1', Style.inputWrapper)}
          />
          -
          <Input
            placeholder="Max"
            value={parseValueBeforeShowing((value as { min?: number; max?: number })?.max || 0)}
            onChange={(e) =>
              onChange?.({
                range,
                value: {
                  min: (value as any)?.min,
                  max: formatValueBeforeChanging(e.target.value)
                },
                rate
              })}
            className="py-1"
            wrapperClassName={clsx('fx-1', Style.inputWrapper)}
          />
        </div>

        <Select
          value={rate}
          options={CAMPAIGN_SALARY_RATE_OPTIONS}
          onChange={(v) => handleSelectRate(v as ESalaryRate)}
          size="large"
          style={{
            width: '100%',
            maxWidth: '160px',
            flexShrink: 0
          }}
        />
      </div>
      {
        error && (
          <ErrorMessage className="mt-2">
            {error}
          </ErrorMessage>
        )
      }
    </div>
  )
}
