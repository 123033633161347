import { FC, useEffect, useState } from 'react'
import { takeUntil } from 'rxjs'
import { useBehaviorMapper, useUnsubscribeEffect } from 'src/hooks'
import { useRouteName } from 'src/router'
import { DialogService } from 'src/services/dialog.service'

type TItem = TArrayItem<typeof DialogService.items$.value>

const DialogItem: FC<{ item: TItem }> = ({ item }) => {
  const [hidden, setHidden] = useState(item.options.hidden ?? false)

  useUnsubscribeEffect((unsubscribe$) => {
    item.update$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => setHidden(value.hidden ?? false))
  }, [item.update$])

  return <div className={hidden ? 'hidden' : undefined}>{item.content}</div>
}

export const DialogContainer: FC = () => {
  const routeName = useRouteName()
  const dialogs = useBehaviorMapper(DialogService.items$)

  useEffect(() => {
    return () => {
      for (const item of DialogService.items$.value) {
        if (!item.options.persist) {
          item.unmount()
        }
      }
    }
  }, [routeName])

  return (
    <>
      {dialogs.map((item) => (
        <DialogItem
          key={item.id}
          item={item}
        />
      ))}
    </>
  )
}
