import { AxiosRequestConfig } from 'axios'
import { ICampaignModel, IPaginationQuery, IReactionModel, IUserModel, TAxiosResponsePagination } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class VibeApi {
  static readonly _prefix = '/vibes'

  static readonly _vibeCandidate = '/vibes/candidates'

  static candidates(
    params?: IPaginationQuery,
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<IUserModel & {
    // reaction of authenticated user (current user)
    reaction?: IReactionModel
    // reaction of this user
    createdReaction?: IReactionModel
  }> {
    return axiosHiringApi.get(this._vibeCandidate, {
      ...config,
      params: params || config?.params
    })
  }

  static readonly _vibeCampaign = '/vibes/campaigns'

  static campaigns(
    params?: IPaginationQuery,
    config?: AxiosRequestConfig
  ): TAxiosResponsePagination<ICampaignModel> {
    return axiosHiringApi.get(this._vibeCampaign, {
      ...config,
      params: params || config?.params
    })
  }
}
