import { IRouterOption } from 'src/router'
import { Recording } from './components'
import { ERecordingRoutes } from './routes.enum'

export const RecordingRoutes: IRouterOption[] = [
  {
    path: '/recording',
    component: Recording,
    name: ERecordingRoutes.RECORDING,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
