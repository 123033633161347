import clsx from 'clsx'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components'
import { IconViewMore } from 'src/icons'
import { ICandidateModel } from 'src/interfaces'
import { mustAuthenticated } from 'src/partials/authentication'
import { ERoutes, generate } from 'src/router'
import { renderVideoTitle } from 'src/utils'
import { TalentImageCard } from '../talent-image-card'
import Style from './style.module.scss'

interface IProps {
  talent?: ICandidateModel
  isDetail?: boolean
}

export const TalentCardBlur: FC<IProps> = ({ talent, isDetail }) => {
  const history = useHistory()

  if (!talent) {
    return null
  }

  return (
    <div key={talent.id} className={Style.talentBlurcontainer}>
      <div className={Style.talentBlurWrapper}>
        <TalentImageCard talent={talent}/>

        <div className="fw-600 txt-black-01 three-dot-1 text-center">{renderVideoTitle(talent?.pfv)}</div>

        {isDetail && (
          <Button
            className="w-fit"
          >
            Details
          </Button>
        )}
      </div>

      <Button
        variant="label"
        className={clsx('w-fit gap-2', Style.viewMore)}
        onClick={() => mustAuthenticated({ kind: 'talent-view-more', redirectTo: () => history.push(generate(ERoutes.TALENT_VIBE_TALENT)) })}
      >
        <IconViewMore/>
        View More
      </Button>
    </div>
  )
}
