export enum EUserGuide {
  IS_DEMO = 'isDemo',
  FIRST_INTRO = 'firstIntro',
  ONBOARDING = 'onboarding',
  LIKE = 'like',
  SUPER_LIKE = 'superLike',
  SUPER_NOVA = 'superNova',
  SKIP = 'skip',
  FIRST_TIME_CLICK_CREDIT = 'firstTimeClickCredit',
  ONBOARDING_CAMPAIGN= 'onboardingCampaign',
  ONBOARDING_TALENT= 'onboardingTalent',
  CAMPAIGN_ID = 'campaignId'
}

export enum EMotivator {
  LEARNER = 'Learner',
  CREATIVE = 'Creative',
  MISSION_DRIVEN = 'Mission-Driven',
  ALTRUISTIC = 'Altruistic',
  ECONOMIC = 'Economic',
  COMPETITIVE = 'Competitive',
  INDIVIDUALISTIC = 'Individualistic'
}

export enum EJobTypes {
  PART_TIME_JOB = 'Part-Time Job',
  MORE_SOCIAL = 'More Social',
  INTERNSHIP = 'Internship',
  FIRST_JOB = 'First Job',
  CORPORATE = 'Corporate',
  CASUAL = 'Casual',
  RETAIL = 'Retail'
}

export enum EIndustries {
  DESIGN = 'Design',
  SOCIAL_MEDIA = 'Social Media',
  FOOD = 'Food',
  THE_TRADES = 'The Trades',
  LAW = 'Law',
  MEDICINE = 'Medicine',
  START_UP = 'Start-Up',
  FINANCE = 'Finance',
  ENGINEERING = 'Engineering',
  REAL_ESTATE = 'Real Estate',
  ENTERTAINMENT = 'Entertainment',
  TECHNOLOGY = 'Technology'
}

export enum EUserType {
  UNSURE = 'unsure',
  NOPE = 'nope',
  LIKE = 'like',
  SUPER_LIKE = 'superlike',
  SKIP = 'skip'
}

export enum EWorkInterests {
  REMOTE = 'Remote',
  HYBRID = 'Hybrid',
  ONSITE = 'Onsite'
}
