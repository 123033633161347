import { ReactNode } from 'react'
import { Subject } from 'rxjs'
import { AxiosUtils } from 'src/utils'

export enum EMessage {
  NONE = 'NONE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO'
}

export interface IMessage {
  id?: number | string
  open?: boolean
  severity: `${EMessage}`
  position?: {
    vertical: 'top' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
  /**
   * Duration in milliseconds
   */
  duration?: number
  onClick?: (message: IMessage) => any
  content: string | ReactNode
  actionText?: string
  secondaryText?: string

  onClickSecondary?: (message: IMessage) => any
}

export const SnackbarService = new (
  class {
    private id = 0

    readonly message$ = new Subject<IMessage>()

    push(msg: IMessage) {
      const defaultMessage: IMessage = {
        id: ++this.id,
        open: true,
        position: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        duration: 5000,
        actionText: 'Close',
        severity: msg.severity,
        content: msg.content
      }

      this.message$.next({
        ...defaultMessage,
        ...msg
      })
    }

    info(msg?: string | ReactNode, options?: Omit<IMessage, 'severity' | 'content'>): void {
      if (msg) {
        return this.push({
          ...options,
          severity: EMessage.INFO,
          content: msg
        })
      }
    }

    success(msg?: string | ReactNode, options?: Omit<IMessage, 'severity' | 'content'>): void {
      if (msg) {
        return this.push({
          ...options,
          severity: 'SUCCESS',
          content: msg
        })
      }
    }

    warn(msg?: string | ReactNode, options?: Omit<IMessage, 'severity' | 'content'>): void {
      if (msg) {
        return this.push({
          ...options,
          severity: EMessage.WARNING,
          content: msg
        })
      }
    }

    error(msg?: string | ReactNode, options?: Omit<IMessage, 'severity' | 'content'>): void {
      if (msg) {
        return this.push({
          ...options,
          severity: 'ERROR',
          content: msg
        })
      }
    }

    axiosError(...args: Parameters<typeof AxiosUtils.getApiErrorMessage>) {
      return this.error(AxiosUtils.getApiErrorMessage(...args))
    }
  }
)()

window.addEventListener('offline', (e) => {
  SnackbarService.error('You are offline.')
})

window.addEventListener('online', (e) => {
  SnackbarService.success('Internet connection restored.')
})

window.addEventListener('message', (event) => {
  if (event.data === 'hadNewUpdate') {
    SnackbarService.push({
      position: {
        vertical: 'top',
        horizontal: 'center'
      },
      onClick() {
        window.location.reload()
      },
      severity: EMessage.INFO,
      content: 'A new version is available, please reload your browser.',
      actionText: 'Update'
    })
  }
})
