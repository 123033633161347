import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { browserHistory } from 'src/router'
import { reducers } from './reducers'

export * from './modules'

const initialStore = () => {
  const middleware = composeWithDevTools(
    applyMiddleware(
      routerMiddleware(browserHistory)
    )
  )

  const _s = createStore(
    reducers,
    {},
    middleware
  )

  return _s
}

export const store = initialStore()

export type TAppState = ReturnType<typeof store.getState>

export type TAppDispatch = typeof store.dispatch

export type TSelectorResult<T> = (state: TAppState) => T
