import clsx from 'clsx'
import { FC, SyntheticEvent, useMemo } from 'react'
import Style from './style.module.scss'

interface IProps {
  value: number // percentage
  mini?: boolean
  onChange?: (value: number) => void
}

export const Range: FC<IProps> = (props) => {
  const inputValue = useMemo(
    () => props.value * 100,
    [props.value]
  )

  const handleChange = (e: Event|SyntheticEvent) => {
    e.stopPropagation()
  }

  return (
    <input
      className={clsx([Style.PlayerRange, {
        [Style.Mini]: props.mini
      }])}
      style={{ backgroundSize: `${inputValue}% 100%` }}
      type="range"
      min={0}
      max={100}
      value={inputValue}
      onChange={e => props.onChange && props.onChange(e.target.valueAsNumber / 100)}
      onClick={handleChange}
    />
  )
}
