import {
  ChangeEventHandler,
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState
} from 'react'
import { catchError, EMPTY, finalize, from, map, switchMap, takeUntil } from 'rxjs'
import { ProfileApi } from 'src/api'
import { Button, Input, Modal, RequiredAsterisk } from 'src/components'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { Logo } from 'src/images'
import { SnackbarService } from 'src/services'
import { AuthModule } from 'src/store'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

interface IProps {
  open?: boolean
  onClose?: () => void
}

export const ModalCompleteSignUp: FC<IProps> = (props) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    companyName: '',
    companyUrl: '',
    userType: ''
  })
  const schema = useMemo(() => object().shape({
    companyName: string().required('This field is required.'),
    companyUrl: string().required('This field is required.'),
    userType: string().required('This field is required.')
  }), [])
  const { errors, validate } = useValidation({
    data: formValues,
    schema
  })

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const { name, value } = e.target
    setFormValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }, [])

  const onCloseRef = useAsRef(props.onClose)
  const onSubmit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)
    const { isValid, value } = await validate()
    if (!isValid || !value) {
      return
    }

    setLoading(true)
    from(ProfileApi.updateCompany(value))
      .pipe(
        takeUntil(unsubscribe$),
        switchMap(() => ProfileApi.getProfile()),
        map(({ data }) => AuthModule.authenticated(data)),
        catchError((error) => {
          SnackbarService.axiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        SnackbarService.success('Company updated successfully')
        onCloseRef.current?.()
      })
  }, [onCloseRef, unsubscribe$, validate])

  return (
    <Modal
      center
      closeBtn
      open={props.open}
      onClose={props.onClose}
      contentClass="fx-column gap-6"
    >
      <img src={Logo} alt="logo" width={48}/>

      <div className="heading-24 txt-black-01 mb-2">
        Complete Hiring Manager Sign Up
      </div>

      <Input
        label={<>Company<RequiredAsterisk/></>}
        id="companyName"
        name="companyName"
        value={formValues.companyName || ''}
        onChange={onChange}
        error={errors.getError('companyName')}
      />
      <Input
        label={<>Company URL<RequiredAsterisk/></>}
        id="companyUrl"
        name="companyUrl"
        value={formValues.companyUrl || ''}
        onChange={onChange}
        error={errors.getError('companyUrl')}
      />
      <Input
        label={<>Position<RequiredAsterisk/></>}
        id="userType"
        name="userType"
        value={formValues.userType || ''}
        onChange={onChange}
        error={errors.getError('userType')}
        required
      />

      <Button
        type="submit"
        className="fx-as-flex-end round-full"
        disabled={errors.hasError() || loading}
        onClick={onSubmit}
      >
        Finish Sign Up
      </Button>
    </Modal>
  )
}
