import { Avatar, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'src/components'
import { useAppDispatch, useBehaviorMapper } from 'src/hooks'
import {
  IconArrowLeft,
  IconClose,
  IconLocation,
  IconMoney
} from 'src/icons'
import { GuestOnboardingService } from 'src/services'
import { setLayoutAside } from 'src/store/actions'
import { stringGrayAvatar } from 'src/utils'
import { CompanySection } from '../company-section'
import { VideoSection } from '../video-section'
import { PreviewFooter } from './components/footer'
import { JobSummary } from './components/job-summary'
import { useJobLocation, useSalaryValue } from './hooks'
import Style from './style.module.scss'

const avatarSmallSize = {
  height: 80,
  width: 80,
  borderRadius: '12px'
}

export const PublicGuestOnboardingPreviewCampaign: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const data = useBehaviorMapper(GuestOnboardingService.tempJobCreationInputData$)
  const videoData = useBehaviorMapper(GuestOnboardingService.campaignVideoData$)

  const jobLocation = useJobLocation()
  const salaryValue = useSalaryValue()

  const [uploadVideoUrl, setUploadVideoUrl] = useState<string>()

  useEffect(() => {
    if (!videoData?.file) return

    const url = URL.createObjectURL(videoData.file)
    setUploadVideoUrl(url)

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [videoData?.file])

  useEffect(() => {
    dispatch(setLayoutAside(false))
  }, [dispatch])

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <Button variant="icon" className={Style.buttonBack} onClick={() => history.goBack()}>
          <IconArrowLeft/>
        </Button>
        <div>
          <span className="subtitle-bold color-neutral-theme-250">Edit / <span className="subtitle-bold color-neutral-theme-700">Job Preview</span></span>
        </div>
      </div>
      <div className={Style.body}>
        <div className={Style.content}>
          <Button
            variant="icon"
            className={Style.close}
            onClick={() => history.goBack()}
          >
            <IconClose/>
          </Button>

          <div className={Style.jobSection}>
            <VideoSection
              recruiterName="John Doe"
              uploadVideoUrl={uploadVideoUrl}
              thumbnail={videoData?.thumbnailUrl}
            />

            <div className={Style.jobInfo}>
              {data?.logoUrl
                ? (
                  <div className={Style.Avatar}>
                    <img
                      alt="company-logo"
                      src={data?.logoUrl}
                    />
                  </div>
                )
                : (
                  <Avatar
                    alt="company-logo"
                    {...stringGrayAvatar(data?.companyName, avatarSmallSize)}
                  />
                )}

              <div className="fx flex-column gap-1">
                <Typography variant="h4">{data?.jobTitle}</Typography>
                <span className="subtitle-regular color-neutral-theme-400">
                  {data?.companyName}
                </span>
              </div>

              <div className="fx flex-column gap-2">
                <div className="fx flex-row gap-2 fx-ai-start">
                  <IconLocation className="mt-1 svg-color-primary-700 fx-shrink-0"/>
                  <span className="body1-medium color-neutral-theme-900">{jobLocation[0]} <span className="body1-regular color-neutral-theme-400">{jobLocation[1]}</span></span>
                </div>

                <div className="fx flex-row gap-2 fx-ai-start fx-shrink-0">
                  <IconMoney className="mt-1 svg-color-primary-700"/>
                  <span className="body1-medium color-neutral-theme-900">{salaryValue}</span>
                </div>
              </div>

              <JobSummary
                maxLineSummary={7}
                summary={data?.jobSummary}
                description={data?.briefDescription}
                url={data?.jdFileUrl}
                file={data?.jdFile}
                fileName={data?.uploadedJdFile?.filename}
              />

              {/* <div className="fx flex-column gap-4">
                <div className="fx flex-column gap-2">
                  <span className="body1-bold color-neutral-theme-900">Application Requirements</span>
                  <span className="body2-regular color-neutral-theme-400 relative">
                    {requirements}
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <CompanySection
            logoUrl={data?.logoUrl}
            companyName={data?.companyName}
            companyWebsiteUrls={data?.companyWebsiteUrls}
          />
        </div>
      </div>
      <PreviewFooter className={Style.footer}/>
    </div>
  )
}
