import { FC } from 'react'
import Style from './style.module.scss'

interface IProps {
  direction: 'left' | 'right' | 'bottom' | 'top'
}

export const Arrow: FC<IProps> = ({ direction }) => {
  if (direction === 'left') {
    return (
      <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={Style.arrowLeft}>
        <path d="M15.9707 0.746094L-4.58863e-07 11.2437L15.9707 21.254L15.9707 0.746094Z" fill="#F7FB6F"/>
      </svg>
    )
  }

  if (direction === 'right') {
    return (
      <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={Style.arrowRight}>
        <path d="M0 20.7539L15.9707 10.2563L-8.96428e-07 0.246027L0 20.7539Z" fill="#F7FB6F"/>
      </svg>
    )
  }

  if (direction === 'top') {
    return (
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={Style.arrowTop}>
        <path d="M21.2539 15.9707L10.7563 0L0.746027 15.9707L21.2539 15.9707Z" fill="#F7FB6F"/>
      </svg>
    )
  }

  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={Style.arrowBottom}>
      <path d="M0.746094 0L11.2437 15.9707L21.254 0L0.746094 0Z" fill="#F7FB6F"/>
    </svg>
  )
}
