import clsx from 'clsx'
import { ComponentProps, FC, ReactNode, useEffect, useState } from 'react'
import Style from './style.module.scss'

interface TabItem {
  key: string
  disabled?: boolean
  label: string
  icon?: ReactNode
  children: ReactNode
}

interface IProps {
  items: TabItem[]
  defaultActiveKey?: string
  activeColor?: string
  onChange?: (key: string) => void
  containerProps?: ComponentProps<'div'>
  suffix?: ReactNode
}

export const TabsNav: FC<Omit<ComponentProps<'div'>, keyof IProps> & IProps> = ({
  items,
  defaultActiveKey,
  activeColor,
  onChange,
  containerProps,
  suffix,
  ...props
}) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey || items[0].key)

  const handleTabClick = (key: string) => {
    setActiveKey(key)
    if (onChange) {
      onChange(key)
    }
  }

  useEffect(() => {
    setActiveKey(defaultActiveKey || items[0].key)
  }, [defaultActiveKey, items])

  return (
    <div {...props}>
      <div {...containerProps} className={clsx(Style.tabsContainer, containerProps?.className)}>
        {items.map((item) => (
          <div
            key={item.key}
            className={clsx(
              Style.tabItem,
              { [Style.tabActive]: activeKey === item.key },
              item.disabled ? 'not-allowed' : 'pointer',
              'fx fx-center gap-2 py-4 fs-14'
            )}
            style={activeKey === item.key && activeColor ? { borderColor: activeColor } : undefined}
            onClick={item.disabled ? undefined : () => handleTabClick(item.key)}
          >
            {item.icon}
            {item.label}
          </div>
        ))}
        {suffix}
      </div>

      {items.find((item) => item.key === activeKey)?.children}
    </div>
  )
}
