import { FC, useMemo } from 'react'
import { Button, VideoPlayer } from 'src/components'
import { IconChat, IconThumbsUp, IconThumbsUpDup, IconThumbsUpFilled } from 'src/icons'
import { Img } from 'src/images'
import { ICampaignModel, IReactionModel } from 'src/interfaces'
import { ReactionUtils, VideoUtils, getFirstCompanyRole, getFullName } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  campaign: ICampaignModel
  onToggleLike?: () => void
  loading?: boolean
}

export const CampaignVideoCard: FC<IProps> = ({ campaign, loading, onToggleLike }) => {
  const video = useMemo(() => campaign.uploadVideo ?? campaign.author?.pfv, [campaign])

  return (
    <div className="w-full relative bg-neutral-900 fx-ai-center fx-jc-center fx-row round-4 overflow-hidden">
      <div className="absolute b-7 z-3 w-full fx fx-ai-center fx-jc-space-between gap-4 px-4">
        <div className="fx fx-ai-center gap-4">
          {campaign.logoUrl && (
            <Img
              src={campaign.logoUrl}
              width={32}
              height={32}
              alt="logo"
              className="round-10 bg-neutral-20"
              style={{ objectFit: 'contain' }}
            />
          )}
          <div className="fx fx-column gap-1">
            <div className="fs-16 fw-500 txt-neutral-white three-dot-1">{getFullName(campaign.author)}</div>
            <div className="fs-12 fw-400 txt-neutral-white three-dot-1">{getFirstCompanyRole(campaign.author) || '--'}</div>
          </div>
        </div>

        <div className="fx fx-ai-center gap-3">
          <Button
            size={32}
            variant="icon"
            green={!!campaign.peopleReaction}
            emphasis={ReactionUtils.isEmphasis(campaign.peopleReaction as IReactionModel)}
            disabled={loading}
            // onClick={() => mustAuthenticated({ kind: 'like', actionMethod: onToggleLike })}
          >
            {!campaign.peopleReaction
              ? <IconThumbsUp size={20}/>
              : ReactionUtils.isEmphasis(campaign.peopleReaction)
                ? <IconThumbsUpDup size={20}/>
                : <IconThumbsUpFilled size={20}/>}
          </Button>

          {ReactionUtils.isEmphasis(campaign.peopleReaction as IReactionModel) && (
            <Button
              size={32}
              variant="icon"
              // onClick={() => Router.push(generate(ERoutes.DASHBOARD_MESSAGES, { lookupId: campaign?.author?.lookupId }))}
            >
              <IconChat size={20}/>
            </Button>
          )}

          {/* <Button
            size={32}
            variant="icon"
            className="h-fit"
          // disabled={loading}
          // onClick={onToggleLike}
          >
            <IconClose size={20}/>
          </Button> */}
        </div>

      </div>

      <VideoPlayer
        className={Style.videoPlayer}
        url={VideoUtils.getVideoSource(video)}
        image={video?.urlVideoImageThumbnail}
      />
    </div>
  )
}
