import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconDownload: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#0C0C0C' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.9" fillRule="evenodd" clipRule="evenodd" d="M10.0001 2.6001C10.3591 2.6001 10.6501 2.89111 10.6501 3.2501L10.6501 12.1809L14.0405 8.79048C14.2943 8.53664 14.7059 8.53664 14.9597 8.79048C15.2136 9.04432 15.2136 9.45588 14.9597 9.70972L10.4597 14.2097C10.2059 14.4636 9.79432 14.4636 9.54048 14.2097L5.04048 9.70972C4.78664 9.45588 4.78664 9.04432 5.04048 8.79048C5.29432 8.53664 5.70588 8.53664 5.95972 8.79048L9.3501 12.1809L9.3501 3.2501C9.3501 2.89111 9.64111 2.6001 10.0001 2.6001ZM4.1001 16.7501C4.1001 16.3911 4.39111 16.1001 4.7501 16.1001L15.2501 16.1001C15.6091 16.1001 15.9001 16.3911 15.9001 16.7501C15.9001 17.1091 15.6091 17.4001 15.2501 17.4001L4.7501 17.4001C4.39111 17.4001 4.1001 17.1091 4.1001 16.7501Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}
