import { Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { AnalyticApi, AuthApi } from 'src/api'
import { Button, Input } from 'src/components'
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICE_URL } from 'src/constants'
import { useUnsubscribe, useValidation } from 'src/hooks'
import { browserHistory, ERoutes, generate } from 'src/router'
import { OverlayService, SnackbarService } from 'src/services'
import { getApiErrorMessage } from 'src/utils'
import { workEmailSchema } from 'src/validation'
import Style from './style.module.scss'

interface IProps {
  showCandidateText?: boolean
  showVVCText?: boolean
  campaignOnboardingSlug?: string
  onboardingPfvId?: number
}

/**
 * @deprecated
 */
export const SignUpModal: FC<IProps> = (props) => {
  const unsubscribe$ = useUnsubscribe()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string>('')
  const { errors, validate } = useValidation({
    data: useMemo(() => ({ email }), [email]),
    schema: workEmailSchema
  })

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.persist()
    const { value } = e.target
    setEmail(value)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler = async (e) => {
    e.preventDefault()

    const { isValid, value } = await validate()
    if (!isValid || !value) {
      return
    }

    setLoading(true)
    from(AuthApi.signUpRequest({
      email: value.email,
      sessionId: AnalyticApi._session,
      campaignOnboardingSlug: props.campaignOnboardingSlug,
      onboardingPfvId: props.onboardingPfvId
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          SnackbarService.error(getApiErrorMessage(error))
          return EMPTY
        }),
        finalize(() => {
          setLoading(false)
        })
      )
      .subscribe(() => {
        OverlayService.reset()
        browserHistory.push(generate(ERoutes.SIGN_UP), email)
      })
  }

  return (
    <div className={Style.container}>
      <div className="fx flex-column fx-ai-center gap-14px">
        <Typography
          variant="h4"
          align="center"
          color="var(--color-neutral-theme-700)"
        >
          {props.showCandidateText && !props.showVVCText && 'Start With An Email To Create Your First Job!'}
          {props.showVVCText && !props.showCandidateText && 'Start With An Email To Sumbit Your First Video Vibe Check!'}
          {!props.showVVCText && !props.showCandidateText && 'Sign Up'}
        </Typography>

        {props.showCandidateText && (
          <div className="fx flex-column">
            <Typography
              variant="body1-regular"
              align="center"
              color="var(--color-neutral-theme-500)"
            >
              Discovering more is a step away - Sign up<br/> to unlock the full experience now!
            </Typography>
          </div>
        )}
      </div>

      <form className="fx flex-column fx-ai-center gap-4" onSubmit={handleSubmit}>
        <div className="w-100 fx flex-column gap-2">
          <span className="body2-bold color-neutral-theme-500">Email</span>
          <Input
            name="email"
            placeholder="name@company.com"
            type="email"
            required
            onChange={handleChangeInput}
            disabled={loading}
            error={errors.getError('email')}
          />
        </div>
        <Button
          className="w-100-p"
          disabled={loading || errors.hasError()}
          type="submit"
        >
          <span className="body1-bold">Sign Up</span>
        </Button>
        <Typography
          align="center"
          variant="body2-regular"
          color="var(--color-neutral-theme-300)"
        >
          By signing up, you agree to KnowMe’s <a href={PRIVACY_POLICY_URL} target="_blank" className="text-decorator-underline color-neutral-theme-300 font-medium" rel="noreferrer">Privacy Policy</a> & <a href={TERMS_AND_SERVICE_URL} className="color-neutral-theme-300 text-decorator-underline font-medium">Terms of Service</a>
        </Typography>
      </form>

      <Typography
        align="center"
        variant="body2-medium"
        color="var(--color-neutral-theme-300)"
      >
        You already have an account? <a href="/sign-in">Sign in</a>
      </Typography>
    </div>
  )
}
