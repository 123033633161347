import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconTalentLike: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 110 } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="110" height="114" viewBox="0 0 110 114" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.60019 65.7251C9.16019 88.7351 10.4702 102.005 18.3102 104.325C26.1502 106.645 27.8302 101.475 34.7502 102.705C41.6802 103.925 53.0602 108.205 68.1402 108.205C83.2202 108.205 96.8702 78.8451 80.3602 61.5351C76.2702 57.2451 68.7802 58.5451 66.8702 55.5551C61.0902 46.4751 65.9502 34.0151 55.0702 34.4351C40.6002 34.9851 51.3902 51.6651 33.4602 55.4351C15.5302 59.1951 8.39019 57.2051 8.60019 65.7251Z" fill="#AFF6C5"/>
        <path d="M11.3701 58.4453C10.8601 73.8353 10.7901 89.2353 11.1701 104.635C11.2101 106.105 13.5401 105.535 13.5201 104.315C13.1501 88.9153 13.2201 73.5153 13.7201 58.1253C13.7701 56.6653 11.4101 57.2253 11.3701 58.4453Z" fill="black"/>
        <path d="M12.2602 105.675C16.8902 106.265 24.9802 107.705 28.3102 103.235C32.1202 98.1149 30.6402 88.8149 30.4402 82.8949C30.3002 78.8349 30.1002 74.7649 29.8302 70.7049C29.6102 67.4349 30.2202 62.0649 28.2802 59.3449C25.3602 55.2449 17.3202 56.2349 13.1802 56.8849C11.8802 57.0849 11.3802 59.2149 12.9902 58.9649C15.6502 58.5449 18.3802 58.2649 21.0602 58.6949C24.7602 59.2949 26.0802 60.0149 26.8902 63.6149C28.1402 69.1349 27.7902 75.6049 28.0102 81.2249C28.2002 86.0149 28.4702 90.8749 28.0502 95.6549C27.7102 99.4949 26.3402 103.205 22.0402 104.025C19.2802 104.555 15.8202 103.935 13.0402 103.585C11.8102 103.425 10.7102 105.475 12.2602 105.675Z" fill="black"/>
        <path d="M29.1502 65.005C35.1902 64.495 40.6402 61.685 44.5102 56.995C46.6802 54.375 48.2502 51.275 49.2202 48.015C49.8602 45.855 50.2302 32.915 54.5102 34.505C55.6402 34.925 57.1902 33.045 55.8302 32.535C50.6602 30.615 48.4802 35.815 48.1002 40.015C47.0902 51.235 41.8702 61.865 29.3402 62.915C28.0202 63.025 27.5202 65.135 29.1502 64.995V65.005Z" fill="black"/>
        <path d="M54.6704 34.3947C65.5803 37.3447 62.1704 50.1047 59.3104 57.6947C58.7604 59.1547 61.0904 59.0747 61.5304 57.9147C64.7104 49.4547 67.8104 35.6347 55.9804 32.4247C54.8304 32.1147 53.2804 34.0147 54.6604 34.3947H54.6704Z" fill="black"/>
        <path d="M54.9801 59.915C60.5501 59.515 66.1301 59.155 71.7101 59.165C76.1701 59.165 84.0701 58.265 87.3001 62.055C88.1301 63.025 90.2301 61.685 89.4201 60.735C86.2101 56.965 80.8501 57.285 76.2801 57.135C69.2401 56.905 62.1901 57.335 55.1701 57.835C53.8401 57.935 53.3501 60.035 54.9801 59.915Z" fill="black"/>
        <path d="M87.2301 61.9648C87.7701 64.8348 87.9501 68.1248 84.2301 68.6748C81.5301 69.0748 78.6801 68.8948 75.9601 68.7248C74.7301 68.6448 73.6301 70.7148 75.1801 70.8148C78.8401 71.0548 84.2601 71.7848 87.4701 69.5648C90.2601 67.6348 90.1001 64.1148 89.5401 61.1348C89.2901 59.8048 87.0001 60.7648 87.2201 61.9748L87.2301 61.9648Z" fill="black"/>
        <path d="M82.37 70.9552C84.66 73.6852 86.45 77.6852 82.09 79.5852C79.22 80.8452 75.61 80.7852 72.55 80.6052C71.32 80.5352 70.22 82.6052 71.77 82.6952C76 82.9452 81.53 83.0152 84.96 80.0852C88.57 76.9852 87.18 72.8352 84.49 69.6352C83.67 68.6552 81.56 69.9952 82.37 70.9552Z" fill="black"/>
        <path d="M79.0401 83.0345C83.0001 84.8045 82.6901 90.0645 78.3601 91.1445C75.5801 91.8445 72.4101 91.6445 69.5701 91.6845C68.3601 91.6945 67.2501 93.7945 68.7901 93.7745C73.1401 93.7245 79.4901 94.4945 82.6901 90.8145C85.5801 87.4845 84.2301 82.7945 80.3501 81.0645C79.2401 80.5645 77.7001 82.4345 79.0301 83.0345H79.0401Z" fill="black"/>
        <path d="M76.1803 94.1151C78.4503 95.3851 79.9503 98.1351 78.7903 100.665C77.4703 103.535 73.6203 103.385 70.9303 103.215C69.7003 103.135 68.6003 105.205 70.1503 105.305C74.1203 105.555 78.9903 105.265 80.9003 101.155C82.5603 97.5951 80.7304 93.9551 77.4904 92.1451C76.4304 91.5551 74.8904 93.3951 76.1704 94.1151H76.1803Z" fill="black"/>
        <path d="M71.21 103.434C65.14 103.494 59.08 103.544 53.01 103.604C46.94 103.664 39 104.374 35.82 97.7345C35.28 96.6045 33.14 97.9045 33.7 99.0545C36.17 104.204 41.4 105.554 46.74 105.704C54.62 105.924 62.55 105.594 70.43 105.514C71.64 105.504 72.75 103.404 71.21 103.424V103.434Z" fill="black"/>
        <path d="M35.07 97.5649C33.3 97.5149 31.54 97.4649 29.77 97.4149C29.15 97.3949 28.49 97.7449 28.27 98.3549C28.08 98.8749 28.39 99.4949 28.99 99.5049C30.76 99.5549 32.52 99.6049 34.29 99.6549C34.91 99.6749 35.57 99.3249 35.79 98.7149C35.98 98.1949 35.67 97.5749 35.07 97.5649Z" fill="black"/>
        <path d="M75.21 42.0348C82.13 36.3848 90.97 35.3748 99.59 36.2848C100.82 36.4148 101.92 34.3548 100.37 34.1948C90.81 33.1848 81.28 34.6048 73.67 40.8148C72.4 41.8548 74.12 42.9348 75.22 42.0348H75.21Z" fill="black"/>
        <path d="M74.4101 42.785C82.6501 42.455 90.5501 44.105 98.0201 47.575C99.1201 48.085 100.67 46.225 99.3401 45.605C91.4901 41.955 83.2401 40.365 74.6001 40.705C73.2701 40.755 72.7901 42.845 74.4101 42.785Z" fill="black"/>
        <path d="M99.0002 35.4848C99.5402 39.2348 99.3802 42.9248 98.5802 46.6148C98.2802 48.0048 100.67 47.4648 100.93 46.2948C101.77 42.4248 101.88 38.5548 101.32 34.6348C101.13 33.3048 98.8302 34.2648 99.0002 35.4748V35.4848Z" fill="black"/>
        <path d="M59.9003 25.8552C58.7403 18.2652 60.1203 10.8952 64.0003 4.26521C64.8203 2.85521 62.7203 2.37521 62.0503 3.52521C57.8803 10.6452 56.3403 18.5352 57.5903 26.7052C57.7903 28.0352 60.0903 27.0752 59.9103 25.8652L59.9003 25.8552Z" fill="black"/>
        <path d="M60.18 26.5045C63 20.4345 67.48 15.5145 73.22 12.0845C74.56 11.2845 73.54 9.65446 72.2 10.4545C65.9 14.2145 61.04 19.6445 57.96 26.2845C57.31 27.6945 59.67 27.6145 60.18 26.5045Z" fill="black"/>
        <path d="M62.5404 4.54489C65.5804 6.98489 68.7304 9.27489 71.9804 11.4249C73.0004 12.0949 74.8704 10.4549 73.7504 9.71489C70.5004 7.56489 67.3504 5.27489 64.3104 2.83489C63.3504 2.06489 61.4704 3.69489 62.5404 4.54489Z" fill="black"/>
        <path d="M38.6303 37.4748C30.6303 37.8348 23.2603 34.9548 17.4803 29.4548C16.6003 28.6148 14.7203 30.2248 15.7103 31.1648C21.9203 37.0748 29.9003 39.9348 38.4403 39.5548C39.7703 39.4948 40.2503 37.4048 38.6303 37.4748Z" fill="black"/>
        <path d="M39.6502 37.7051C33.5602 33.7251 28.9902 28.0851 26.4702 21.2451C26.0002 19.9651 23.7302 20.9451 24.1502 22.0851C26.8002 29.2551 31.4702 35.2151 37.8702 39.4051C38.8902 40.0751 40.7602 38.4251 39.6402 37.6951L39.6502 37.7051Z" fill="black"/>
        <path d="M17.7 30.705C20.13 27.655 22.87 24.885 25.86 22.385C27.12 21.335 25.3999 20.255 24.3099 21.165C21.1499 23.805 18.3099 26.755 15.7499 29.965C14.7399 31.235 16.89 31.725 17.7 30.705Z" fill="black"/>
      </svg>
    </IconWrapper>
  )
}
