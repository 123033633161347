import clsx from 'clsx'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { IconComment } from 'src/icons'
import { ERoutes, generate } from 'src/router'
import Style from './style.module.scss'

export const Messages: FC = () => {
  const history = useHistory()
  const count = 0

  return (
    <div
      className="fx fx-center bg-neutral-10 hover-bg-neutral-30 round-full pointer relative"
      style={{
        width: 32,
        height: 32
      }}
      onClick={() => history.push(generate(ERoutes.TALENT_MESSAGES))}
    >
      <IconComment className="txt-black-01 svg-current-color"/>
      {Boolean(count) && (
        <sub className={clsx(Style.count, 'fx fx-center')}>
          {count > 99 ? '99+' : count}
        </sub>
      )}
    </div>
  )
}
