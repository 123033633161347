import clsx from 'clsx'
import { FC, createContext, useCallback, useState } from 'react'
import { Button, VideoPlayer } from 'src/components'
import { IconClose } from 'src/icons'
import { IVideoModel } from 'src/interfaces'
import { getVideoSource } from 'src/utils'
import { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SAMPLE_VIDEO } from './constants'
import Style from './style.module.scss'

type SamplePlayingContextValue = {
  playingUrl?: string
  setPlayingUrl?: (url: string | undefined) => void
}

const SamplePlayingContext = createContext<SamplePlayingContextValue>({})

interface IProps {
  onClose?: () => void
}

export const MobileSamplePlayers: FC<IProps> = ({ onClose }) => {
  const [playingUrl, setPlayingUrl] = useState<string | undefined>(undefined)
  const setPlayingUrlCallback = useCallback((url: string | undefined) => {
    setPlayingUrl(url)
  }, [])

  const handleOnSlideChange = useCallback(() => {
    setPlayingUrl(undefined)
  }, [])

  return (
    <div className={Style.swiperContainer}>
      <Button
        variant="icon"
        className={Style.close}
        onClick={onClose}
      >
        <IconClose/>
      </Button>

      <SamplePlayingContext.Provider value={{ playingUrl, setPlayingUrl: setPlayingUrlCallback }}>
        <Swiper
          direction="vertical"
          modules={[Mousewheel]}
          mousewheel
          spaceBetween={30}
          slidesPerView={1.1}
          className={Style.swiper}
          onSlideChange={handleOnSlideChange}
        >
          {SAMPLE_VIDEO.map((video, index) => (
            <SwiperSlide
              key={index}
              className={clsx(Style.swiperSlider, { [Style.centerSwiperSlider]: SAMPLE_VIDEO.length === 1 })}
            >
              {({ isActive }) => (
                <SliderComponent
                  video={video}
                  isActive={isActive}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </SamplePlayingContext.Provider>
    </div>
  )
}

const SliderComponent = (props: {
  video: Partial<IVideoModel>
  isActive?: boolean
}) => {
  const video = props.video

  return (
    <div className={Style.playerWrapper}>
      <div className={clsx(Style.player, { [Style.sliderInActive]: !props.isActive })}>
        <VideoPlayer
          className={Style.videoPlayerOverride}
          isPlay={!!props.isActive}
          url={getVideoSource(video)}
          tracks={video?.tracks}
          image={video?.urlVideoImageThumbnail}
          animatedImage={video?.urlVideoAnimatedImage}
          mimeType={video?.internalSourceMetadata?.mimeType}
        />
      </div>
    </div>
  )
}
