import { Avatar, Typography } from '@mui/material'
import { FC } from 'react'
import { IconSquareFacebook, IconSquareLinkedin, IconWebsite } from 'src/icons'
import { DefaultCompany } from 'src/images'
import { stringGrayAvatar } from 'src/utils'
import Style from './style.module.scss'

interface IProps {
  logoUrl?: string
  companyName?: string
  companyWebsiteUrls?: string[]
}

const avatarBigSize = {
  height: 191,
  width: 191,
  borderRadius: '12px',
  fontSize: '54px'
}

export const CompanySection: FC<IProps> = (props) => {
  return (
    <div>

      <div className={Style.companyInfo}>
        {props.logoUrl
          ? (
            <div className={Style.Avatar}>
              <img
                alt="company-logo"
                src={props.logoUrl || DefaultCompany}
              />
            </div>
          )
          : (
            <Avatar
              alt="company-logo"
              {...stringGrayAvatar(props.companyName, avatarBigSize)}
            />
          )}

        <div className="fx flex-column fx-1 gap-4">
          <Typography variant="h4" className={Style.companyName}>{props.companyName}</Typography>
          <div className="fx flex-column gap-2">
            {props.companyWebsiteUrls?.map((url, index) => url && (
              <div className="fx flex-row gap-2 fx-ai-center" key={index}>
                {['facebook.com', 'fb.com'].some((item) => url.includes(item))
                  ? <IconSquareFacebook/>
                  : ['linkedin.com', 'lnkd.in'].some((item) => url.includes(item))
                    ? <IconSquareLinkedin/>
                    : <IconWebsite/>}

                <a
                  href={url.startsWith('http') ? url : '//' + url}
                  target="_blank"
                  rel="noreferrer"
                  className="oneline-text body1-regular color-neutral-theme-400"
                >
                  {url}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}
