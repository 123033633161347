import { FC } from 'react'
import { useHistory } from 'react-router'
import { Button, useAnalytic } from 'src/components'
import { ETrackingEvent } from 'src/enums'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { setItem } from 'src/utils'
import Styles from './style.module.scss'

interface IProps {
  profileId?: number
  onDoLater?: () => void
}

export const DialogVerifyProfile: FC<IProps> = (props) => {
  const history = useHistory()
  const { eventHandler } = useAnalytic('')

  const handleRedirect = (path: string) => {
    OverlayService.reset()
    history.push({
      pathname: path,
      hash: '#btn-upload-video'
    })
  }

  const handleDoLater = () => {
    props.onDoLater?.()
    setItem(`do_pfv_later_${props.profileId}`, true)
    OverlayService.reset()
  }

  return (
    <div className={Styles.dialogVerifyProfile}>
      <span>
        Candidates are 5 times more likely to respond to an outreach if a mutual video vibe check has been made by the Company.
      </span>
      <Button
        className="w-100-p mt-2"
        onClick={eventHandler(ETrackingEvent.BTN_EDIT_PROFILE, () => handleRedirect(generate(ERoutes.SETTING)))}
      >
        OK
      </Button>
      <Button
        className="w-100-p mt-2"
        variant="secondary"
        onClick={handleDoLater}
      >
        Skip
      </Button>
    </div>
  )
}
