import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconChatFilled: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 16, color = 'currentColor' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.25 8C14.25 4.6862 11.5638 2 8.25001 2C4.93621 2 2.25001 4.6862 2.25001 8C2.24876 8.95877 2.47822 9.90376 2.91901 10.7552L2.27821 13.049C2.24246 13.1771 2.24141 13.3123 2.27516 13.441C2.30891 13.5696 2.37625 13.6869 2.47027 13.7809C2.56429 13.875 2.68162 13.9423 2.81024 13.976C2.93885 14.0098 3.07413 14.0087 3.2022 13.973L5.49781 13.3328C6.3485 13.7725 7.2924 14.0013 8.25001 14C11.5638 14 14.25 11.3138 14.25 8ZM6.30001 6.49999H10.203C10.317 6.50017 10.4266 6.54359 10.5098 6.62148C10.593 6.69936 10.6436 6.80591 10.6512 6.91961C10.6589 7.03332 10.6232 7.1457 10.5513 7.23408C10.4793 7.32246 10.3765 7.38025 10.2636 7.39579L10.203 7.39999H6.30001C6.186 7.39995 6.07625 7.35664 5.99293 7.2788C5.90962 7.20097 5.85896 7.09441 5.85119 6.98066C5.84342 6.86691 5.87912 6.75445 5.95107 6.66601C6.02302 6.57756 6.12586 6.51973 6.23881 6.50419L6.30001 6.49999ZM6.30001 8.59999H8.99881C9.11282 8.60002 9.22257 8.64333 9.30588 8.72117C9.38919 8.79901 9.43985 8.90557 9.44762 9.01932C9.4554 9.13306 9.4197 9.24552 9.34775 9.33397C9.2758 9.42241 9.17296 9.48025 9.06001 9.49579L8.99881 9.49999H6.30001C6.186 9.49995 6.07625 9.45664 5.99293 9.3788C5.90962 9.30097 5.85896 9.19441 5.85119 9.08066C5.84342 8.96691 5.87912 8.85445 5.95107 8.76601C6.02302 8.67756 6.12586 8.61973 6.23881 8.60419L6.30001 8.59999Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
