import clsx from 'clsx'
import { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { distinctUntilChanged, filter, takeUntil } from 'rxjs'
import { BreadcrumbHeading } from 'src/components'
import { useAppDispatch, useUnsubscribe } from 'src/hooks'
import { Breadcrumbs } from 'src/layouts/breadcrumbs'
import { EAuthRoutes } from 'src/modules/auth/routes.enum'
import { generate } from 'src/router'
import { GuestOnboardingCreateJobTourService } from 'src/services/tour/guest-onboarding-create-job.service'
import { PopupTourService } from 'src/services/tour/popup.service'
import { setLayoutAside } from 'src/store/actions'
import { EmptyJob } from './components/empty-job'
import Style from './style.module.scss'

export const PublicGuestOnboarding: FC = () => {
  const dispatch = useAppDispatch()
  const router = useHistory()
  const unsubscribe$ = useUnsubscribe()

  useEffect(() => {
    dispatch(setLayoutAside(true))
  }, [dispatch])

  const handleCreateJobClicked = useCallback(() => {
    router.push(generate(EAuthRoutes.SIGN_IN))
  }, [router])

  useEffect(() => {
    GuestOnboardingCreateJobTourService.completed$
      .pipe(
        takeUntil(unsubscribe$),
        filter(completed => completed),
        distinctUntilChanged()
      )
      .subscribe(() => {
        PopupTourService.showVVCPopup()
      })
  }, [unsubscribe$])

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbHeading active>My Jobs</BreadcrumbHeading>
      </Breadcrumbs>

      <div className={clsx(Style.containerGuest, Style.height100)}>
        <div className={clsx(Style.container, Style.height100)}>
          <EmptyJob
            tab={0}
            withBanner={false}
            onCreateJobClick={handleCreateJobClicked}
          />
        </div>
      </div>
    </>
  )
}
