import { AxiosRequestConfig } from 'axios'
import { IUserModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class AuthApi {
  static readonly _prefix = '/auth'

  static login(payload: Pick<IUserModel, 'email' | 'password'>): TAxiosResponseData<{
    id: number
    token: string
    tokenType: 'Bearer'
    tokenExpireAt: string
    tokenExpireIn: number
    profile: IUserModel
  }> {
    return axiosHiringApi.post(`${this._prefix}/sign-in`, payload)
  }

  static logout(): TAxiosResponseData<void> {
    return axiosHiringApi.delete(`${this._prefix}/sign-out`)
  }

  /**
   * SECTION: Sign up
   */

  static signUpQuick(payload: { sharingToken: string; password: string }): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/quick-sign-up`, payload)
  }

  static signUpVerify(params: { token: string }): TAxiosResponseData<{ email: string; inviteToken?: string }> {
    return axiosHiringApi.get(`${this._prefix}/sign-up`, { params })
  }

  static signUpRequest(
    payload: {
      email: string
      inviteToken?: string
      sessionId?: string
      campaignOnboardingSlug?: string
      onboardingPfvId?: number
    },
    config?: AxiosRequestConfig
  ): TAxiosResponseData<{ token: string }> {
    return axiosHiringApi.post(`${this._prefix}/sign-up`, payload, {
      ...config,
      headers: {
        authorization: ''
      }
    })
  }

  static signUpProfile(
    payload: {
      firstName: string
      lastName: string
      companyName: string
      companyUrl: string
      userType?: string
      password: string
      interested?: string
      subscribeMarketingEmails?: boolean
    },
    params: { token: string }
  ): ReturnType<typeof AuthApi.login> {
    return axiosHiringApi.put(`${this._prefix}/sign-up`, payload, {
      params,
      headers: {
        authorization: ''
      }
    })
  }

  /**
   * Complete profile
   */
  static complete(payload: {
    firstName: string
    lastName: string
    companyName: string
    companyUrls: string[]
    userType?: string
    subscribeMarketingEmails: boolean
  }): TAxiosResponseData<void> {
    return axiosHiringApi.post(`${this._prefix}/complete-profile`, payload)
  }
}
