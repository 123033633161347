import { Skeleton, Slider } from '@mui/material'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { Button } from 'src/components'
import { RECORD_FILE_NAME } from 'src/constants'
import { IconVideoAdd } from 'src/icons'
import Style from './style.module.scss'

interface IProps {
  url?: string | File
  onSliderChanged: (_: any, value: number | number[], shouldSeekTo?: boolean) => void
  formatThumbnailSliderValue?: (percent: number) => string
  generatingThumbnail?: boolean
  thumbnailUrl?: string
  onStartOverClick?: () => void
  onContinueClick?: () => void
  isMobile?: boolean
  width?: number
}

export const ThumbnailPicker: FC<IProps> = (props) => {
  const widthStyle = useMemo(() => {
    if (!props.width || props.isMobile) return undefined

    return {
      width: `${props.width - 48}px`
    }
  }, [props.isMobile, props.width])

  return (
    <div className={clsx(Style.container, props.isMobile && Style.containerMobile)} style={widthStyle}>
      <div className="fx flex-row gap-10 fx-jc-space-between">
        <div className="fx flex-column fx-1">
          <span className={clsx('color-neutral-theme-900 subtitle-bold', props.isMobile && Style.colorWhite)}>Video Cover</span>
          <span className={clsx('color-neutral-theme-300 body2-medium', props.isMobile && Style.colorNeutral200)}>Select a frame from your video as a cover image</span>
          <Slider
            defaultValue={0}
            onChangeCommitted={props.onSliderChanged}
            className={Style.thumbnailSlider}
            valueLabelDisplay="auto"
            valueLabelFormat={props.formatThumbnailSliderValue}
          />
        </div>

        <div
          className={clsx(Style.thumbnailPreview, props.url instanceof File && props.url.name === RECORD_FILE_NAME && Style.flip)}
          style={{ backgroundImage: props.generatingThumbnail ? undefined : `url(${props.thumbnailUrl})` }}
        >
          {props.generatingThumbnail && <Skeleton width={61} height={110} variant="rectangular" animation="wave"/>}
        </div>
      </div>

      <div className={Style.thumbnailPickerButtonGroups}>
        {props.isMobile && (
          <Button
            className={clsx('fx flex-row gap-2 fx-ai-center fx-1 fx-jc-center py-4 px-8', Style.buttonStartOver)}
            onClick={props.onStartOverClick}
          >
            <IconVideoAdd className="svg-color-neutral-theme-50 fx-shrink-0"/>
            Start Over
          </Button>
        )}
        <Button
          className="fx flex-row gap-2 fx-ai-center fx-1 fx-jc-center py-4 px-8"
          onClick={props.onContinueClick}
        >Continue
        </Button>
      </div>
    </div>
  )
}
