import axios, { AxiosRequestConfig } from 'axios'
import { EUploadStatus, UploadService } from 'src/services/upload.service'

export type TS3PresignedKeyField = 'key' | 'bucket' | 'X-Amz-Algorithm' | 'X-Amz-Credential' | 'X-Amz-Date' | 'Policy' | 'X-Amz-Signature'

export interface IS3PresignedResult {
  url: string
  fields: Record<TS3PresignedKeyField, string>
}

export interface IS3PresignedMultipartResult {
  bucket: string
  key: string
  uploadId: string
  parts: {
    start: number
    end: number
    url: string
    partNumber: number
  }[]
}

/**
 * @deprecated
 */
export const presignedUpload = (presignedResult: IS3PresignedResult, file: File | Blob) => {
  const form = new FormData()
  form.append('Content-Type', file.type.split(';')[0])

  for (const key of Object.keys(presignedResult.fields)) {
    form.append(key, presignedResult.fields[key as TS3PresignedKeyField])
  }

  form.append('file', file)

  return fetch(presignedResult.url, {
    method: 'POST',
    body: form
  })
}

/**
 * @deprecated
 */
export const presignedMultipartUpload = async (presignedResult: IS3PresignedMultipartResult, file: File | Blob, options?: { id: string }) => {
  const parts = []
  for (const part of presignedResult.parts) {
    const partResult = await axios.put(part.url, file.slice(part.start, part.end), {
      headers: { 'Content-Type': 'application/octet-stream' },
      onUploadProgress: (progressEvent) => handleUploadProgress(progressEvent, presignedResult.parts, part, options?.id)
    }).then(response => ({
      partNumber: part.partNumber,
      etag: response.headers.etag
    }))

    parts.push(partResult)
  }

  return parts
}

/**
 * @deprecated
 */
const handleUploadProgress = (
  progressEvent: ProgressEvent,
  parts: IS3PresignedMultipartResult['parts'],
  part: ItemOf<IS3PresignedMultipartResult['parts']>,
  id?: string
) => {
  if (!id) {
    return
  }

  const partNumberOrder = parts.findIndex(p => p === part)

  const progress = progressEvent.loaded / progressEvent.total
  const percentage = Math.ceil((progress / parts.length + partNumberOrder / parts.length) * 100)

  UploadService.updateTrackUploadItem(id, percentage, EUploadStatus.UPLOADING)
}

export class S3Utils {
  static presignedUpload(presignedResult: IS3PresignedResult, file: File | Blob, options: {
    onUploadProgress?: ((progress: {
      loaded: number
      total: number
    }) => any)
  } = {}) {
    const form = new FormData()
    form.append('Content-Type', file.type.split(';')[0])

    for (const key of Object.keys(presignedResult.fields)) {
      form.append(key, presignedResult.fields[key as TS3PresignedKeyField])
    }

    form.append('file', file)

    const controller = new AbortController()

    const promise = axios.post(presignedResult.url, form, {
      ...options,
      signal: controller.signal
    })

    return {
      abort: () => controller.abort(),
      promise
    }
  }

  static presignedMultipartUpload(
    presignedResult: IS3PresignedMultipartResult,
    file: File | Blob,
    config?: AxiosRequestConfig
  ) {
    // const parts = await Promise.all(
    //   presignedResult.parts.map(
    //     (part) => axios.put(part.url, file.slice(part.start, part.end)).then(
    //       ({ headers }) => ({
    //         partNumber: part.partNumber,
    //         etag: headers.etag
    //       })
    //     )
    //   )
    // )

    if (config?.onUploadProgress) {
      let totalLoaded = 0
      let lastLoaded = 0
      const onUploadProgress = config.onUploadProgress
      config.onUploadProgress = (progressEvent) => {
        totalLoaded += (
          progressEvent.loaded - lastLoaded > 0
            ? progressEvent.loaded - lastLoaded
            : progressEvent.loaded
        )

        lastLoaded = progressEvent.loaded

        onUploadProgress({
          ...progressEvent,
          total: file.size,
          loaded: totalLoaded
        })
      }
    }

    const controller = new AbortController()

    const promise = (async () => {
      const parts = []
      for (const part of presignedResult.parts) {
        const partResult = await axios.put(part.url, file.slice(part.start, part.end), {
          ...config,
          signal: controller.signal
        }).then(
          ({ headers }) => ({
            partNumber: part.partNumber,
            etag: headers.etag
          })
        )

        parts.push(partResult)
      }

      return parts
    })()

    return {
      abort: () => controller.abort(),
      promise
    }
  }
}
