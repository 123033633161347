import { IRouterOption } from 'src/router'
import { Videos } from './components'
import { EVideosRoutes } from './routes.enum'

export const VideosRoutes: IRouterOption[] = [
  {
    path: '/my-videos',
    component: Videos,
    name: EVideosRoutes.MY_VIDEOS,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
