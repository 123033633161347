export const allowedAudioTypes = [
  'audio/3gp',
  'audio/aa',
  'audio/aac',
  'audio/aax',
  'audio/act',
  'audio/aiff',
  'audio/alac',
  'audio/amr',
  'audio/ape',
  'audio/au',
  'audio/awb',
  'audio/dss',
  'audio/dvf',
  'audio/flac',
  'audio/gsm',
  'audio/iklax',
  'audio/ivs',
  'audio/m4a',
  'audio/m4p',
  'audio/nmf',
  'audio/movpkg',
  'audio/mp3',
  'audio/mpc',
  'audio/msv',
  'audio/nmf',
  'audio/ogg',
  'audio/oga',
  'audio/mogg',
  'audio/opus',
  'audio/ra',
  'audio/rm',
  'audio/rf64',
  'audio/sln',
  'audio/tta',
  'audio/voc',
  'audio/vox',
  'audio/wav',
  'audio/wma',
  'audio/wv',
  'audio/8svx',
  'audio/cda',
  'audio/mpeg'
]

const allowedVideoTypes = [
  'video/webm',
  'video/mkv',
  'video/flv',
  'video/vob',
  'video/gif',
  'video/mov',
  'video/mp4',
  'video/m4v',
  'video/3gp'
]

export class FileUtils {
  static readFilePromise(
    file: File | Blob,
    output: 'text' | 'base64' | 'binary' | 'buffer' = 'base64'
  ) {
    return new Promise<FileReader['result']>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function (e: ProgressEvent<FileReader>) {
        resolve(e.target?.result ?? null)
      }

      switch (output) {
        case 'text':
          return reader.readAsText(file)
        case 'base64':
          return reader.readAsDataURL(file)
        case 'buffer':
          return reader.readAsArrayBuffer(file)
        case 'binary':
          return reader.readAsBinaryString(file)
      }
    })
  }

  static isAudioFile(file: File) {
    return allowedAudioTypes.some((audioType) => file.type.includes(audioType))
  }

  static isVideoFile(file: File) {
    return allowedVideoTypes.some((videoType) => file.type.includes(videoType))
  }

  static download(file: string | File, filename = '') {
    const link = document.createElement('a')
    link.href = typeof file === 'string' ? file : URL.createObjectURL(file)

    link.setAttribute('download', filename)

    if (typeof file === 'string') {
      link.setAttribute('target', '_blank')
    }

    document.body.appendChild(link) // Some browsers not trigger download if the a isn't part of DOM
    link.click()

    // clean up "a" element & remove ObjectURL
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
  }

  static isPdf(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type === 'application/pdf'
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file === 'application/pdf'
        : /\.(pdf)$/gim.test(file)
    }
    return false
  }

  static isPhoto(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type.startsWith('image/')
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file.startsWith('image/')
        : /\.(jpeg|jpg|png|gif|bmp)$/gim.test(file)
    }
    return false
  }

  static isAudio(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type.startsWith('audio/')
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file.startsWith('audio/')
        : /\.(wav|mp3)$/gim.test(file)
    }
    return false
  }

  static isVideo(file?: Blob | string, useMimeType?: boolean): boolean {
    if (file instanceof Blob) {
      return file.type.startsWith('video/')
    }
    if (typeof file === 'string') {
      return useMimeType
        ? file.startsWith('video/')
        : /\.(avi|wmv|flv|mpg|mp4|webm|mkv|vob|ogv|ogg|drc|gifv|mng|mov|qt|yuv|rm|rmvb|asf|amv|m4p|m4v|mp2|mpeg|mpe|mpv|svi|3gp|3g2|mxf|roq|nsv|f4v|f4p|f4a|f4b)$/gim.test(
          file
        )
    }
    return false
  }
}
