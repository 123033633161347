import { ComponentProps, FC } from 'react'
import { IconWrapper } from '../wrapper'

export const IconPencilStroke: FC<ComponentProps<typeof IconWrapper>> = (props) => {
  const { size = 20, color = '#111111' } = props
  return (
    <IconWrapper {...props} size={size}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5914 3.48724C12.9555 3.33642 13.3457 3.25879 13.7398 3.25879C14.134 3.25879 14.5242 3.33642 14.8883 3.48724C15.2524 3.63806 15.5833 3.85912 15.862 4.1378C16.1406 4.41648 16.3617 4.74732 16.5125 5.11144C16.6633 5.47555 16.741 5.86581 16.741 6.25992C16.741 6.65403 16.6633 7.04429 16.5125 7.4084C16.3617 7.77252 16.1406 8.10336 15.862 8.38204L15.0318 9.21223C15.0314 9.21259 15.0311 9.21294 15.0307 9.21329C15.0304 9.21364 15.03 9.214 15.0297 9.21435L7.13384 17.1102C7.01194 17.2321 6.84661 17.3005 6.67422 17.3005H3.34922C2.99023 17.3005 2.69922 17.0095 2.69922 16.6505V13.3255C2.69922 13.1532 2.7677 12.9878 2.8896 12.8659L11.6177 4.1378C11.8964 3.85912 12.2272 3.63806 12.5914 3.48724ZM11.2461 6.34791L3.99922 13.5948V16.0005H6.40498L13.6519 8.75367L11.2461 6.34791ZM14.5711 7.83443L12.1653 5.42867L12.537 5.05704C12.6949 4.89907 12.8825 4.77377 13.0889 4.68828C13.2952 4.60279 13.5164 4.55879 13.7398 4.55879C13.9632 4.55879 14.1844 4.60279 14.3908 4.68828C14.5972 4.77377 14.7848 4.89907 14.9427 5.05704C15.1007 5.215 15.226 5.40253 15.3115 5.60892C15.397 5.81531 15.441 6.03652 15.441 6.25992C15.441 6.48332 15.397 6.70452 15.3115 6.91091C15.226 7.1173 15.1007 7.30484 14.9427 7.4628L14.5711 7.83443Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  )
}
