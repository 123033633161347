import { useEffect, useState } from 'react'
import { useWindowSize } from 'src/hooks'

export const useIfHasSpaceForThumbnailPicker = (requiredSpacing: number) => {
  const [hasSpace, setHasSpace] = useState(false)
  const [space, setSpace] = useState(0)
  const [target, setTarget] = useState<HTMLDivElement | null>()
  const size = useWindowSize()

  useEffect(() => {
    if (!target) return
    const rect = target.getBoundingClientRect()
    const _space = window.innerWidth - (rect.left + rect.width)
    setHasSpace(_space > requiredSpacing)
    setSpace(_space)
  }, [requiredSpacing, target, size])

  return {
    ref: setTarget,
    hasSpace,
    space
  }
}
