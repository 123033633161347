import { Typography } from '@mui/material'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import { Button, Spinner } from 'src/components'
import { EEnv } from 'src/constants/env'
import { useBehaviorMapper } from 'src/hooks'
import { IconArrowExpand, IconLocation, IconMoney } from 'src/icons'
import { useCampaignById } from 'src/modules/campaign/hooks/useCampaignById'
import { useCampaignSalary } from 'src/modules/campaign/hooks/useCampaignSalary'
import { formatJobLocation } from 'src/modules/campaign/utils'
import { AuthModule } from 'src/store'
import { getFirstName } from 'src/utils'
import { CompanySection } from '../company-section'
import { VideoSection } from '../video-section'
import Style from './style.module.scss'

interface IProps {
  campaignId?: number
  hashId?: string
}

const companyAvatarStyle = { height: '80px', borderRadius: '12px' }

export const JobDetailPopup: FC<IProps> = ({ campaignId, hashId }) => {
  const profile = useBehaviorMapper(AuthModule.profile$)
  const { campaign, loading } = useCampaignById(campaignId || hashId)
  const jobLocation = useMemo(() => formatJobLocation(campaign), [campaign])
  const salaryValue = useCampaignSalary(campaign)
  // const requirements = useCampaignRequirements(campaign)

  const goToSubmissionPage = () => {
    window.open(`${EEnv.REACT_APP_LANDING_WEB_DNS}/campaigns/${campaign?.slug}`, '_blank')
  }

  return (
    <div className={Style.container}>
      <div className={clsx('fx flex-column gap-6', Style.content)}>
        <div className="fx flex-row fx-jc-space-between fx-ai-start">
          <div className={Style.Avatar}>
            <img
              alt="company-logo"
              style={companyAvatarStyle}
              src={campaign?.logoUrl}
            />
          </div>

          <div className="mt-1">
            <Button
              variant="secondary"
              className="px-8 py-2 h-100 round-3"
              onClick={goToSubmissionPage}
            >
              <IconArrowExpand className="svg-color-neutral-theme-900"/>
              Expand View
            </Button>
          </div>
        </div>

        <div className="fx flex-column gap-2">
          <Typography variant="h4">{campaign?.jobTitle}</Typography>
          <span className="subtitle-regular color-neutral-theme-400 leading-27px">
            {campaign?.companyName}
          </span>
        </div>

        <div className="fx flex-column gap-2">
          <div className="fx flex-row gap-2 fx-ai-start">
            <IconLocation className="mt-1 svg-color-primary-700 fx-shrink-0"/>
            <span className="body1-medium color-neutral-theme-900">{jobLocation[0]} <span className="body1-regular color-neutral-theme-400">{jobLocation[1]}</span></span>
          </div>

          <div className="fx flex-row gap-2 fx-ai-start fx-shrink-0">
            <IconMoney className="mt-1 svg-color-primary-700"/>
            <span className="body1-medium color-neutral-theme-900">{salaryValue}</span>
          </div>
        </div>

        <div className="fx flex-column gap-2">
          <span className="body1-bold color-neutral-theme-900">Job Description</span>
          {campaign?.briefDescription && (
            <div
              className={clsx('body1-regular color-neutral-theme-400', Style.briefDescription)}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(campaign.briefDescription) }}
            />
          )}
        </div>

        {/* <div className="fx flex-column gap-4">
          <div className="fx flex-column gap-2">
            <span className="body1-bold color-neutral-theme-900">Application Requirements</span>
            <span className="body2-regular color-neutral-theme-400 relative">
              {requirements}
            </span>
          </div>
        </div> */}

        <div className="fx flex-column">
          <VideoSection
            recruiterName={profile ? getFirstName(profile) : 'Us'}
            uploadVideoUrl={campaign?.uploadVideoUrl}
            thumbnail={campaign?.uploadVideo?.urlVideoImageThumbnail}
          />
        </div>

        <CompanySection
          logoUrl={campaign?.logoUrl}
          companyName={campaign?.companyName}
          companyWebsiteUrls={campaign?.companyWebsiteUrls}
        />

        {loading
          ? (
            <div className={Style.loadingContainer}>
              <Spinner/>
            </div>
          )
          : null}
      </div>
    </div>
  )
}
