import { EmojiClickData } from 'emoji-picker-react'
import {
  FC,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useState
} from 'react'
import { Button, Input } from 'src/components'
import { EmojiPicker } from 'src/components/emoji-picker'
import { FilePicker } from 'src/components/file-picker'
import { IconFile } from 'src/icons'
import { EventUtils } from 'src/utils'

interface IProps {
  onChooseFiles?: (files: FileList) => void
  className?: string
  iconSlot?: ReactNode
  onSubmitRef?: any
}

export const ChatEditor: FC<IProps> = ({
  onChooseFiles,
  onSubmitRef
}) => {
  const [messageContent, setMessageContent] = useState<string>('')

  const insertEmoji = useCallback((data: EmojiClickData) => {
    setMessageContent(content => content + data.emoji)
  }, [])

  const handleSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
    if (messageContent && event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
      event.preventDefault()
      event.stopPropagation()
      onSubmitRef.current(messageContent)
      setMessageContent('')
    }
  }

  return (
    <form
      onSubmit={(e) => EventUtils.preventDefault(e)}
      className="px-7 py-8 fx fx-column gap-3"
    >
      <Input
        placeholder="Type your message here…"
        value={messageContent}
        onChange={event => setMessageContent(event.target.value)}
        onKeyDown={handleSubmit}
      />
      <div className="fx fx-ai-center fx-jc-space-between">
        <div className="fx fx-ai-center gap-3">
          <FilePicker
            className="pointer"
            inputProps={{ accept: 'image/*,video/*,application/pdf' }}
            onChoose={onChooseFiles}
          >
            <Button variant="icon" size={32}><IconFile/></Button>
          </FilePicker>
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            onEmojiClick={insertEmoji}
            onReactionClick={insertEmoji}
          />
        </div>

        <Button
          onClick={() => {
            if (!messageContent) return
            onSubmitRef.current(messageContent)
            setMessageContent('')
          }}
        >
          Send
        </Button>
      </div>
    </form>
  )
}
