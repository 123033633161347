import { FC } from 'react'
import { NotAllowAuthenticated } from 'src/components'
import { ForgotPasswordForm } from 'src/partials'

export const ForgotPassword: FC = () => {
  return (
    <NotAllowAuthenticated>
      <div className="fx-1 fx fx-center">
        <ForgotPasswordForm/>
      </div>
    </NotAllowAuthenticated>
  )
}
