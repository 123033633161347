import { ICandidateModel, TAxiosResponseData } from 'src/interfaces'
import { axiosHiringApi } from './axios'

export class ViewApi {
  static readonly _prefix = '/view'

  static share(
    sharingToken: string
  ): TAxiosResponseData<ICandidateModel> {
    return axiosHiringApi.get(`${this._prefix}/${sharingToken}`)
  }
}
