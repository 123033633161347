import { FC } from 'react'
import { Divider } from '../divider'

export const BreadcrumbDivider: FC = () => {
  return (
    <Divider
      type="horizontal"
      color="#595959"
      style={{ borderRightWidth: '2px', height: '20px' }}
    />
  )
}
