import { FC, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface IProps {
  children: ReactNode
}

export const Breadcrumbs: FC<IProps> = (props) => {
  const [portal, setPortal] = useState<HTMLElement>()

  useEffect(() => {
    const portal = document.getElementById('header-portal')
    if (portal) {
      return setPortal(portal)
    }

    // wait for portal to be rendered
    const timeout = setTimeout(() => {
      const portal = document.getElementById('header-portal')
      if (portal) {
        setPortal(portal)
      }
    }, 300)
    return () => clearTimeout(timeout)
  }, [])

  /**
   * Magic to hide the previous sibling of the portal
   */
  useEffect(() => {
    if (portal) {
      portal.classList.toggle('fx-1', true)
      const previousSibling = portal.previousSibling as HTMLElement
      previousSibling?.style.setProperty('display', 'none', 'important')
      return () => {
        portal.classList.toggle('fx-1', false)
        previousSibling?.style.removeProperty('display')
      }
    }
  }, [portal, props.children])

  if (!portal) {
    return null
  }

  return createPortal(props.children, portal)
}
