import clsx, { ClassValue } from 'clsx'
import { isNumber } from 'lodash'
import {
  FC,
  ForwardedRef,
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import _ReactPlayer from 'react-player'
import { ReactPlayerProps } from 'react-player/types/lib'
import { takeUntil } from 'rxjs'
import { RECORD_FILE_NAME } from 'src/constants'
import { useAsRef, useDidMountEffect, useUnsubscribe } from 'src/hooks'
import {
  IconPause,
  IconPlay,
  IconSpeakerOff,
  IconSpeakerOn,
  IconSpinnerProgress
} from 'src/icons'
import { StyleUtils, formatSecToMin } from 'src/utils'
import { Control } from './control'
import { EHlsEvents } from './interfaces'
import { PlayerService } from './player.service'
import { Range } from './range'
import Style from './style.module.scss'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

export interface IProgress {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

interface IProps {
  ref?:
  | MutableRefObject<ReactPlayerProps['ref']>
  | ForwardedRef<ReactPlayerProps['ref']>
  id?: string
  className?: ClassValue
  muted?: boolean
  autoplay?: boolean
  image?: string
  animatedImage?: string
  mimeType?: string
  style?: React.CSSProperties
  alwaysShowControl?: boolean
  noInteraction?: boolean
  uploadProgress?: number
  mini?: boolean
  fullScreen?: boolean
  iconPlay?: any
  isPlaying?: boolean
  progressing?: boolean
  hideControl?: boolean
  duration?: number
  hideSpeaker?: boolean
  hideCC?: boolean
  tracks?: {
    label: string
    kind: TextTrackKind
    src: string
    srcLang: string
    default: boolean
  }[]
  onPlay?: (playing: boolean) => void
  onPause?: () => void
  setVideoProgress?: (progress: any) => void
  videoProgress?: IProgress
  setDurations?: (duration: any) => void
  url: ReactPlayerProps['url'] | File | Blob
  speakerClass?: string
  playbackRate?: number

  hidePlayIcon?: boolean
  hideStartEnd?: boolean
  leftBottomSpeaker?: ReactNode
}

export const VideoPlayerNew: FC<IProps> = ({ style, ...props }) => {
  const unsubscribe$ = useUnsubscribe()
  const propsRef = useAsRef(props)
  const [playerRef] = useState(PlayerService.genRef())
  const placeholder = useMemo(
    () => props.image || props.animatedImage,
    [props.image, props.animatedImage]
  )
  const [isShowVideo, setIsShowVideo] = useState(props.autoplay || !placeholder)
  const trackRef = useRef<HTMLParagraphElement>(null)
  const [isMuted, setIsMuted] = useState(props.autoplay ? PlayerService.muted || props.muted : props.muted)
  const [playing, setPlaying] = useState(props.autoplay ?? false)

  const [isFlip, setIsFlip] = useState(false)
  const [player, setPlayer] = useState<_ReactPlayer>()
  const [playEnd, setPlayEnd] = useState(false)
  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState<IProgress>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0
  })

  const [showUploadProgress, setShowUploadProgress] = useState(false)

  /**
   * Select best quality for HLS based on bandwidth estimate (latest bandwidth)
   */
  useEffect(() => {
    if (player) {
      const hslPlayer = player.getInternalPlayer('hls')
      if (hslPlayer && hslPlayer.levels?.length) {
        if (PlayerService.bandwidthEstimate) {
          hslPlayer.currentLevel = Math.max(
            (hslPlayer.levels as { bitrate: number }[]).findIndex(
              ({ bitrate }: { bitrate: number }) => bitrate >= PlayerService.bandwidthEstimate
            ),
            hslPlayer.levels.length - 1
          )
        } else {
          PlayerService.bandwidthEstimate = hslPlayer.bandwidthEstimate
        }

        hslPlayer.on(EHlsEvents.LEVEL_SWITCHED, () => {
          PlayerService.bandwidthEstimate = hslPlayer.bandwidthEstimate
        })
      }
    }
  }, [player, props.url])

  useEffect(() => {
    if (props.videoProgress) {
      setProgress(props.videoProgress)
      player?.seekTo(props.videoProgress?.playedSeconds || 0)
    }
  }
  , [player, props.videoProgress])

  useEffect(() => {
    if (props.uploadProgress === undefined) {
      return
    }

    setShowUploadProgress(true)

    if (props.uploadProgress === 100) {
      setTimeout(() => {
        setShowUploadProgress(false)
      }, 2000)
    }
  }, [props.uploadProgress, setShowUploadProgress])

  const [url, setUrl] = useState<ReactPlayerProps['url']>()
  useEffect(() => {
    if (props.url instanceof Blob || props.url instanceof File) {
      const blobUrl = URL.createObjectURL(props.url)
      setUrl(blobUrl)
      setIsFlip(
        props.url instanceof File && props.url.name === RECORD_FILE_NAME
      )
      return () => {
        URL.revokeObjectURL(blobUrl)
        setIsFlip(false)
      }
    }

    setUrl(props.url)

    if (props.url && typeof props.url === 'string') {
      const url = new URL(props.url as string)
      if (url.searchParams.get('hflip')) {
        setIsFlip(true)
      }

      return () => {
        setIsFlip(false)
      }
    }
  }, [props.url])

  useEffect(() => {
    if (playing) {
      PlayerService.playing = playerRef
    }
  }, [playerRef, playing])

  useEffect(() => {
    PlayerService.playing$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((no) => setPlaying(no === playerRef))

    PlayerService.muted$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((val) => setIsMuted(val))
  }, [playerRef, unsubscribe$])

  useEffect(() => {
    if (!isShowVideo && (props.autoplay || !placeholder)) {
      setIsShowVideo(true)
    }
  }, [isShowVideo, props.autoplay, placeholder])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!player) {
        clearInterval(intervalId)
        return
      }

      const duration = player?.getDuration()
      if (
        (duration === Infinity || !duration) &&
        props.duration !== undefined
      ) {
        setDuration(props.duration)
        clearInterval(intervalId)
        return
      }

      if (duration === Infinity || !duration) return

      setDuration(duration)
      clearInterval(intervalId)
    }, 50)

    return () => clearInterval(intervalId)
  }, [player, props.duration, setDuration, url])

  const handlePlay = () => {
    if (!props.url || props.noInteraction) return

    if (!isShowVideo) {
      setIsShowVideo(true)
    }

    if (playEnd) {
      setPlayEnd(false)
    }

    setPlaying(!playing)
  }

  const handleMuted = useCallback((e: Event | SyntheticEvent) => {
    e.stopPropagation()
    setIsMuted((prev) => {
      PlayerService.setMuted(!prev)
      return !prev
    })
  }, [])

  const handlePlayEnd = useCallback(() => {
    setPlayEnd(true)
    setPlaying(false)
  }, [])

  useDidMountEffect(() => {
    setPlaying(!!props.isPlaying)
  }, [props.isPlaying])

  useDidMountEffect(() => {
    propsRef.current.onPlay?.(playing)
  }, [playing, propsRef])

  useEffect(() => {
    if (duration) {
      propsRef.current.setDurations?.((prev: number[]) =>
        Array.from(new Set([...prev, duration]))
      )
    }
  }, [duration, propsRef])

  useEffect(() => {
    if (props.hideCC || isFlip) {
      return
    }

    const videoTag = player?.getInternalPlayer?.() as HTMLVideoElement

    const tracksEl: HTMLTrackElement[] = []
    for (const track of props.tracks || []) {
      const trackEl = document.createElement('track')
      trackEl.src = track.src
      trackEl.kind = track.kind
      trackEl.label = track.label
      trackEl.srclang = track.srcLang
      trackEl.default = track.default
      trackEl.addEventListener('load', function () {
        trackEl.track.mode = 'hidden'
      })

      videoTag?.appendChild(trackEl)
      tracksEl.push(trackEl)
    }

    if (!trackRef.current) return

    const replaceText = function (text: string) {
      if (trackRef.current) {
        trackRef.current.innerHTML = text
      }
    }

    const showText = function () {
      if (trackRef.current) {
        trackRef.current.style.display = 'block'
      }
    }

    const hideText = function () {
      if (trackRef.current) {
        trackRef.current.style.display = 'none'
      }
    }

    const cueEnter = function (this: any) {
      replaceText(this.text)
      showText()
    }

    const cueExit = function () {
      hideText()
    }

    // const videoTag = _player?.getInternalPlayer?.() as HTMLVideoElement

    const videoLoaded = function (e: any) {
      setTimeout(() => {
        try {
          const textTrack = videoTag.textTracks[0]
          if (!textTrack) return
          textTrack.mode = 'hidden'
          const cues = textTrack.cues

          if (!cues) return

          for (const i in cues) {
            const cue = cues[i]
            if (isNumber(cue) || !('onenter' in cue) || !('onexit' in cue)) {
              console.log('cue', cue)
              continue
            }

            cue.onenter = cueEnter
            cue.onexit = cueExit
          }
        } catch (err) {
          console.error('videoLoaded', err)
        }
      }, 500)
    }

    if (videoTag?.readyState > 0) {
      videoLoaded(null)
    } else {
      videoTag?.addEventListener('loadedmetadata', videoLoaded)
    }

    return () => {
      for (const el of tracksEl) {
        el.track.mode = 'disabled'
        videoTag?.removeChild(el)
      }
    }
  }, [isFlip, player, props.hideCC, props.tracks])

  const [mimeType, setMimeType] = useState<'audio' | 'video'>()
  useEffect(() => {
    const video = player?.getInternalPlayer?.() as HTMLVideoElement
    if (progress.playedSeconds && video) {
      setMimeType(!video.videoWidth || !video.videoHeight ? 'audio' : 'video')
    }
  }, [player, progress.playedSeconds])

  // const trackStyle = useMemo(() => {
  //   if (isEmpty(props.tracks)) {
  //     return {
  //       opacity: 0
  //     }
  //   }
  //   if (!playing) {
  //     return {
  //       opacity: 0
  //     }
  //   }
  //   if (props.hovering || !playing) {
  //     return {
  //       opacity: 0
  //     }
  //   }
  //   return {
  //     opacity: 1
  //   }
  // }, [props.tracks, props.hovering, playing])

  return (
    <div
      className={clsx([
        'overflow-hidden pointer bg-neutral-black fx-column fx relative w-100 h-100',
        Style.reactVideoPlayer,
        props.className,
        {
          [Style.fullScreen]: props.fullScreen,
          [Style.flip]: isFlip,
          'pointer-none': props.progressing && !progress.loaded
        }
      ])}
      onClick={handlePlay}
      style={{
        ...style,
        zIndex: isShowVideo ? 0 : 2,
        backgroundImage:
          progress.playedSeconds && mimeType === 'video'
            ? undefined
            : StyleUtils.backgroundImage(placeholder)
      }}
    >
      <div className={Style.overlay} style={playing ? { backgroundColor: 'unset' } : undefined}/>

      {showUploadProgress && (
        <div
          className={clsx(
            'fx-column gap-1 py-2 pl-2 pr-10',
            Style.uploadProgressing
          )}
        >
          <div className="fx fx-ai-center fx-jc-space-between">
            <span className="meta txt-neutral-white">
              {(props.uploadProgress || 0) < 100
                ? 'Video is being uploaded...'
                : 'Video is uploaded successfully'}
            </span>
            <span
              className={clsx('meta fw-bold', {
                'txt-primary-500': (props.uploadProgress || 0) < 100,
                'txt-positive-500': props.uploadProgress === 100
              })}
            >
              {props.uploadProgress}%
            </span>
          </div>

          <div className={clsx('relative bg-neutral-200', Style.progressBar)}>
            <div
              className={clsx(Style.activeProgressBar, {
                'bg-primary-500': (props.uploadProgress || 0) < 100,
                'bg-positive-500': props.uploadProgress === 100
              })}
              style={{ width: `${props.uploadProgress}%` }}
            />
          </div>
        </div>
      )}

      {!!props.tracks?.length && (
        <p ref={trackRef} className={clsx('txt-neutral-white p-1 round-1 text-left', Style.track)}>...</p>
      )}

      {props.progressing && !progress.loaded && (
        <div
          className="absolute w-100 h-100 bg-neutral-black-02 fx fx-ai-center fx-jc-center"
          style={{ zIndex: 10 }}
        >
          <div className="fx-column gap-2">
            <div className={clsx('relative fx fx-jc-center fx-ai-center')}>
              <div
                className={clsx(
                  'fx fx-ai-center fx-jc-center',
                  Style.loadingContainer
                )}
              >
                <IconSpinnerProgress className={Style.loadingIcon}/>
              </div>
              {/* <IconLogoHorizontal34 className={Style.logoLoadingIcon}/> */}
            </div>
            <p className="meta f-medium txt-neutral-white text-center m-0">
              Sorry, the video is being
              <br/> processed. This won't take long.
            </p>
          </div>
        </div>
      )}

      {isShowVideo && (
        <div className="w-100 h-100">
          <ReactPlayer
            width="100%"
            height="100%"
            playing={playing}
            muted={isMuted}
            url={url}
            onReady={(_player) => setPlayer(_player)}
            onProgress={(_progress) => {
              setProgress(_progress)
              props.setVideoProgress?.(_progress)
            }}
            onEnded={handlePlayEnd}
            onPause={props.onPause}
            playsinline
            config={{
              file: {
                attributes: {
                  preload: 'auto',
                  crossOrigin: 'anonymous'
                },
                forceAudio: props.mimeType
                  ? props.mimeType.startsWith('audio')
                  : false
              }
            }}
            playbackRate={props.playbackRate || 1}
            id={props.id}
          />

          {!props.noInteraction && (!props.progressing || progress.loaded) && !props.hideControl && (
            <Control alwayShow={props.alwaysShowControl} mini={props.mini}>
              <>
                {!props.hideStartEnd && (
                  <div>{formatSecToMin(progress.playedSeconds)}</div>
                )}

                {props.leftBottomSpeaker}

                <Range
                  value={progress.played}
                  mini
                  onChange={(percent) => {
                    setProgress((prev) => ({
                      ...prev,
                      played: percent
                    }))
                    player?.seekTo(duration * percent)
                    props.setVideoProgress?.((prev: any) => ({
                      ...prev,
                      played: percent
                    }))
                  }}
                />
                {!props.hideStartEnd && (
                  <div>{formatSecToMin(duration)}</div>
                )}
              </>
            </Control>
          )}
        </div>
      )}

      {!props.noInteraction && (!props.progressing || progress.loaded) && !props.hideSpeaker && (
        <div className={clsx('pointer', Style.speaker, props.speakerClass)} onClick={handleMuted}>
          {isMuted
            ? <IconSpeakerOff className="txt-neutral-white"/>
            : <IconSpeakerOn className="txt-neutral-white"/>}
        </div>
      )}

      {!props.noInteraction && !props.hidePlayIcon && (!props.progressing || progress.loaded) && (
        <div className={clsx('fx', Style.play, { [Style.pausing]: playing })}>
          {playing
            ? <IconPause className="txt-neutral-white"/>
            : <IconPlay className="txt-neutral-white"/>}
        </div>
      )}
    </div>
  )
}

VideoPlayerNew.displayName = 'VideoPlayer'
