import {
  Dialog,
  Slide,
  StyledEngineProvider,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { FC, PropsWithChildren } from 'react'
import { Button } from 'src/components'
import { IconClose } from 'src/icons'
import Style from './style.module.scss'

interface IProps extends PropsWithChildren<any> {
  open: boolean
  onClose: () => void
}

export const NotificationDialog: FC<IProps> = (props) => {
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <div className={Style.STHeader}>
        <StyledEngineProvider injectFirst>
          <Typography className={Style.STTitle}>Notification</Typography>
        </StyledEngineProvider>
        <Button variant="icon" onClick={props.onClose}>
          <IconClose/>
        </Button>
      </div>
      {props.children}
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props}/>
})
