import { useMediaQuery } from '@mui/material'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { CommentApi, ViewApi } from 'src/api'
import { VideoPlayer } from 'src/components'
import { useAppDispatch, useBehaviorMapper, useElementSize } from 'src/hooks'
import { IconWatchComment } from 'src/icons'
import { ICampaignSubmissionModel, IShareModel, IVideoModel } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { OverlayService } from 'src/services'
import { AuthModule } from 'src/store'
import { setLayoutAside } from 'src/store/actions'
import { getVideoSource } from 'src/utils'
import { Comment } from '../comment'
import Style from './style.module.scss'

export const WatchHome: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 769px)')
  const dispatch = useAppDispatch()

  const history = useHistory()
  const { hashId = '' } = useParams<{ hashId: string }>()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  const [isCommented, setIsCommented] = useState(false)
  const [watchData, setWatchData] = useState<
    Partial<IShareModel & { Video: IVideoModel; CampaignSubmission?: ICampaignSubmissionModel }>
  >({})

  const [firstLoadedVideoRatio, setFirstLoadedVideoRatio] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [elWidth, elHeight] = useElementSize(containerRef.current)

  const [firstLoadedCommentRatio, setFirstLoadedCommentRatio] = useState(0)
  const videoContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(setLayoutAside(false))
    return () => {
      dispatch(setLayoutAside(true))
    }
  }, [dispatch])

  useEffect(() => {
    if (!firstLoadedVideoRatio) {
      setFirstLoadedVideoRatio(true)
    }
  }, [firstLoadedVideoRatio])

  useEffect(() => {
    setFirstLoadedCommentRatio(videoContainerRef?.current?.offsetWidth || 0)
    return () => {
      setFirstLoadedCommentRatio(0)
    }
  }, [])

  useEffect(() => {
    const loadWatchData = async (hashId: string) => {
      try {
        const { data } = await ViewApi.share(hashId)
        setWatchData(data)
      } catch (error) {
        const statusCode = (error as AxiosError)?.response?.status
        history.push(
          statusCode
            ? `/${statusCode}`
            : generate(ERoutes.ERROR_404)
        )
      }
    }

    if (hashId) {
      loadWatchData(hashId)
    }
  }, [hashId, history])

  useEffect(() => {
    const checkCommented = async (candidateId: number, email: string) => {
      try {
        const { data } = await CommentApi.checkCommented({
          candidateId,
          email
        })

        setIsCommented(data)
      } catch (error) {
        console.error('not be able to get shared video data', error)
      }
    }

    if (watchData?.candidateId && watchData?.email) {
      checkCommented(watchData.candidateId, watchData.email)
    }
  }, [watchData?.candidateId, watchData?.email])

  useEffect(() => {
    if (isAuthenticated && watchData?.candidateId) {
      if (watchData.CampaignSubmission) {
        // history.push(
        //   `/campaigns/${watchData.CampaignSubmission.campaignId}/submissions/${watchData.CampaignSubmission.id}`
        // )

        // NB!: Not redirect if campaign shared, event user has sign in
        return
      }

      history.push(
        `/likes?detailCandidateId=${watchData?.candidateId}&detailTab=1`
      )
    }
  }, [isAuthenticated, history, watchData?.candidateId, watchData?.CampaignSubmission])

  const handleOpenComment = useCallback(() => {
    OverlayService.setOverlay({
      open: true,
      blank: true,
      disabled: true,
      overlayBackground: 'rgba(24,25,32,0.5)',
      closeStyle: {
        background: Style.neutral500,
        color: Style.neutralWhite,
        width: '32px',
        height: '32px'
      },
      closeIconStyle: {
        width: '16px',
        height: '16px'
      },
      content: (
        <Comment
          externalIsCommented={isCommented}
          onChangeExternalIsCommented={() => setIsCommented(true)}
          watchData={watchData}
          onClose={() => OverlayService.setOverlay({ open: false })}
        />
      )
    })
  }, [isCommented, watchData])

  return (
    <div className={clsx('', Style.WatchHome)} ref={containerRef}>
      <div
        ref={videoContainerRef}
        id="video-container"
        className={Style.Vibe}
        style={{
          aspectRatio: '518 / 805',
          [elWidth / elHeight > 518 / 805 ? 'height' : 'width']:
            'calc(100% - 132px)'
        }}
      >
        <VideoPlayer
          style={{ aspectRatio: '518 / 805' }}
          url={getVideoSource(watchData?.Video)}
          tracks={watchData?.Video?.tracks}
        />
        {!isDesktop && (
          <button className={Style.CommentBtn} onClick={handleOpenComment}>
            <IconWatchComment/>
          </button>
        )}
      </div>
      {isDesktop && (
        <Comment
          externalIsCommented={isCommented}
          onChangeExternalIsCommented={() => setIsCommented(true)}
          width={firstLoadedCommentRatio}
          watchData={watchData}
        />
      )}
    </div>
  )
}
