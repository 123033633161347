import { IRouterOption } from 'src/router'
import { PaymentHistory } from './components/list'
import { EPaymentHistoryRoutes } from './routes.enum'

export const PaymentHistoryRoutes: IRouterOption[] = [
  {
    path: '/payment-history',
    component: PaymentHistory,
    name: EPaymentHistoryRoutes.PAYMENT_HISTORY,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
]
