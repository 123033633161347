import { ComponentProps, FC } from 'react'
import { Modal } from '../modal'

export const ModalCenter: FC<Omit<ComponentProps<typeof Modal>, 'center'>> = (props) => {
  return (
    <Modal
      {...props}
      center
      closeBtn
    />
  )
}
