import { FC, ReactNode, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useBehaviorMapper } from 'src/hooks'
import { AuthModule } from 'src/store'

export const NotAllowAuthenticated: FC<{ children?: ReactNode }> = (props) => {
  const history = useHistory()
  const location = useLocation<{ from: string }>()
  const isAuthenticated = useBehaviorMapper(AuthModule.isAuthenticated$)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/', {
        from: location?.state?.from
      })
    }
  }, [history, isAuthenticated, location?.state?.from])

  return <>{props.children}</>
}
