import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'

export const LoadingModule = new (
  class {
    private readonly _loading$ = new BehaviorSubject<boolean>(false)
    get loading$(): WithOutNextComplete<typeof this._loading$> {
      return this._loading$
    }

    toggle(val?: boolean) {
      this._loading$.next(val ?? !this._loading$.value)
    }
  }
)()
