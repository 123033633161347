import { IRouterOption } from 'src/router'
import { Vibes } from './components/list'
import { EVibesRoutes } from './routes.enum'

export const VibesRoutes: IRouterOption[] = [
  {
    path: '/vibes',
    component: Vibes,
    name: EVibesRoutes.VIBES,
    exact: true,
    meta: {
      requireAuth: true
    }
  }
  // {
  //   path: '/vibes/public',
  //   component: VibesGuest,
  //   name: EVibesRoutes.PUBLIC_VIBES,
  //   exact: true,
  //   meta: {
  //     requireAuth: false
  //   }
  // }
]
