import { isNil } from 'lodash'
import {
  PropsWithChildren,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { createPortal } from 'react-dom'

export interface ISpotlightConfig {
  el: HTMLElement | null
  shape?: 'circle' | 'square'
  size?: number
  stepIndex?: number
}

function SpotlightConfig({ children }: PropsWithChildren<unknown>) {
  const outlet = useRef<HTMLElement | null>(null)
  const [didMount, setDidMount] = useState(false)
  useEffect(() => {
    let outletDiv = document.getElementById('spotlight-outlet')
    if (!outletDiv) {
      outletDiv = document.createElement('div')
      outletDiv.id = 'spotlight-outlet'
      document.body.appendChild(outletDiv)
    }

    outlet.current = outletDiv
    setDidMount(true)
  }, [outlet])

  if (!didMount) return null
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return createPortal(didMount ? children : null, outlet.current!)
}

function clearCircle(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number) {
  ctx.save()
  ctx.beginPath()
  ctx.arc(x, y, radius, 0, 2 * Math.PI, true)
  ctx.clip()
  ctx.clearRect(x - radius, y - radius, radius * 2, radius * 2)
  ctx.restore()
}

function renderConfig(
  ctx: CanvasRenderingContext2D,
  { el, shape = 'circle', size = 25 }: ISpotlightConfig
) {
  if (el == null) return

  const elRect = el.getBoundingClientRect()

  if (shape === 'circle') {
    clearCircle(ctx, elRect.x + elRect.width / 2, elRect.y + elRect.height / 2 + window.scrollY, size)
  }

  if (shape === 'square') {
    ctx.clearRect(elRect.x, elRect.y + window.scrollY, elRect.width, elRect.height)
  }
}

function isShowingScrollbar() {
  return document.documentElement.scrollHeight > window.innerHeight
}

function getCanvasContext(canvas: HTMLCanvasElement) {
  const height = Math.max(document.body.scrollHeight, window.innerHeight)
  const width = Math.max(document.body.clientWidth, window.innerWidth)

  let toAddUp = 0
  if (isShowingScrollbar()) {
    toAddUp = 8
  }

  canvas.height = height + toAddUp
  canvas.width = width

  const canvasRect = canvas.getBoundingClientRect()
  const ctx = canvas.getContext('2d')

  if (ctx == null) return null

  ctx.fillStyle = 'black'

  ctx.globalAlpha = 0.7
  ctx.fillRect(0, 0, canvasRect.width + toAddUp, canvasRect.height + toAddUp)
  ctx.globalAlpha = 1

  return ctx
}

interface IProps {
  configs: ISpotlightConfig[]
  currentStep?: number
  onClick?: () => void
}

export function CustomSpotlight({ configs, currentStep, onClick }: IProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)

  useLayoutEffect(() => {
    if (canvas == null) {
      return
    }

    function drawSpotlight() {
      if (containerRef.current != null) {
        containerRef.current.style.height = `${document.body.scrollHeight}px`
        containerRef.current.style.width = `${document.body.clientWidth}px`
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ctx = getCanvasContext(canvas!)
      if (ctx == null) return

      if (!isNil(currentStep)) {
        let currentConfig = configs.find(config => config.stepIndex === currentStep)
        currentConfig = currentConfig || configs[currentStep]
        if (currentConfig) {
          renderConfig(ctx, currentConfig)
        }
      } else {
        for (const config of configs) {
          renderConfig(ctx, config)
        }
      }
    }

    const resizeObserver = new ResizeObserver(drawSpotlight)
    resizeObserver.observe(document.body)
    for (const { el } of configs) {
      if (el) resizeObserver.observe(el)
    }

    const drawInitialFrame = requestAnimationFrame(drawSpotlight)
    return () => {
      cancelAnimationFrame(drawInitialFrame)
      resizeObserver.disconnect()
    }
  }, [canvas, configs, currentStep])

  const hasEls = configs.some(({ el }) => el != null)
  if (!hasEls) return null

  return (
    <SpotlightConfig>
      <div
        ref={containerRef}
        onClick={onClick}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 1002,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <canvas ref={setCanvas}/>
      </div>
    </SpotlightConfig>
  )
}

export const Spotlight = memo(CustomSpotlight, (prev, next) => {
  if (prev.configs.length !== next.configs.length) return false
  return prev.configs.every(
    (el, i) =>
      el.el === next.configs[i].el &&
      el.shape === next.configs[i].shape &&
      el.size === next.configs[i].size
  ) && prev.currentStep === next.currentStep
})
